import Web3 from "web3";
import React, { useEffect, useState } from "react";
import { fetchOpenSea, fetchLooksRare } from "./fetchNFTS";
import Dashboard from "./Dashboard";
import { FaEthereum, FaTwitter, FaHeart } from "react-icons/fa";
import Typewriter from "typewriter-effect";
import PropagateLoader from "react-spinners/PropagateLoader";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import axios from "axios";
import MetaTags from "react-meta-tags";

const delayExecution = (mls) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve("ok"), mls);
  });
};

const Web4 = require("web3");
const web4 = new Web4("https://rpc.ankr.com/eth");

const App = () => {
  let web3;
  const [error, setError] = useState("");
  const [web, setWeb] = useState(null);
  const [account, setAccount] = useState();
  const [data, setData] = useState();
  const [num, setNum] = useState();
  const [eth, setETH] = useState();
  const [y, setY] = useState();
  const [x2y2, setX2Y2] = useState();
  const [x2y2Count, setX2Y2Count] = useState();
  const [look, setLook] = useState();
  const [lookCount, setLookCount] = useState();
  const [totalNFT, setTotalNFT] = useState();
  const [totalETH, setTotalETH] = useState();
  const [totalSpent, setTotalSpent] = useState();
  const [totalSpentTwo, setTotalSpentTwo] = useState();
  const [totalOpenSeaSpent, setOpenSeaTotalSpent] = useState();
  const [totalX2Y2Spent, setX2Y2TotalSpent] = useState();
  const [looksRareTotalSpent, setLooksRareTotalSpent] = useState();
  const [actualTotalSpent, setActualTotalSpent] = useState();
  const [leaderboardAccount, setLeaderboardAccount] = useState();
  const [blur, setBlur] = useState();
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(true);
  const [loadText, setLoadText] = useState("");
  const [nodata, setNoData] = useState(false);
  const [leaderboardArray, setLeaderboardArry] = useState([]);
  const placeholderText = [
    "Loading...",
    "No royalties is the future.",
    "Analyzing The Data...",
    "We love BLUR",
    "BLUR royalty fees are 0.",
    "Eat. Sleep. Mint. Repeat.",
    "No fungin way!",
    "Use a marketplace that is choosing you.",
    "Welcome a Bored.",
    "Blur maxis",
  ];

  const changePlaceholder = async () => {
    for (let i = 0; i < placeholderText.length; i++) {
      await delayExecution(3500);
      if (placeholderText.length - 1 === i) {
        i = 0;
      }
      setLoadText(placeholderText[i]);
    }
  };
  const download = () => {
    let img = document.getElementById("downloadImg");
    html2canvas(document.querySelector("#capture")).then((canvas) => {
      img.src = canvas.toDataURL("image/png");
      img.id = "asda";
      img.width = 500;
      saveAs(canvas.toDataURL("image/png"), "image.png");
    });
    window.open(
      `https://twitter.com/intent/tweet?via=ederyeth&text=So%20far%2C%20I%20paid%20more%20than%20Ξ${totalETH}%20in%20royalties%20to%20marketplaces.%20Find%20out%20with%20https%3A//royalty.wtf&hashtags=royalties`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const setTotal = () => {
    let a = 0;
    let b = 0;
    let c = 0;
    let d = 0;
    let g = 0;
    let h = 0;
    let l = 0;
    let f = 0;
    let i = 0;
    let j = 0;

    if (look) a = Number(look);
    if (x2y2) b = Number(x2y2);
    if (data) c = Number(data);
    let e = (a + b + c).toFixed(2);
    setTotalETH(e);
    if (x2y2Count) d = Number(x2y2Count);
    if (lookCount) g = Number(lookCount);
    if (num) h = Number(num);
    if (blur) l = Number(blur);
    let z = d + g + h + l;
    setTotalNFT(z);
    if (looksRareTotalSpent) f = Number(looksRareTotalSpent);
    if (totalOpenSeaSpent) i = Number(totalOpenSeaSpent);
    if (totalX2Y2Spent) j = Number(totalX2Y2Spent);
    let k = f + i + j;
    setActualTotalSpent(k);
  };
  const setLeaderboard = async () => {
    const options = {
      method: "GET",
      url: `https://all-nrdj.onrender.com/api/flips`,
      headers: { "Content-Type": "application/json" },
      params: {
        address: leaderboardAccount,
        sellingVolumes: totalSpent + totalSpentTwo,
        totalNFT: totalNFT,
        totalETHs: totalETH,
        overallSpends: actualTotalSpent,
        totalDollars: totalETH * eth,
      },
    };
    try {
      let results = await axios.request(options).then(function(resp) {
        return resp;
      });
      let array = [];
      for (let item of results.data) {
        array.push(item.stats[0]);
      }
      setLeaderboardArry(array);
    } catch (error) {
      console.log(error);
    }
  };
  const getEth = async () => {
    const ethPrice = await fetch(
      "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD"
    )
      .then((res) => res.json())
      .then((d) => {
        return d.USD;
      });
    let round_number = ethPrice.toFixed(0);
    setY(round_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    setETH(round_number);
  };

  useEffect(() => {
    changePlaceholder();
    getEth();
    setTotal();
  }, []);

  useEffect(() => {
    setTotal();
  }, [data, look, x2y2, totalETH, leaderboardArray]);

  const connectWallet = async () => {
    if (
      typeof window !== "undefined" &&
      typeof window.ethereum !== "undefined"
    ) {
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        web3 = new Web3(window.ethereum);
        setWeb(web3);
        setError("");
        setX2Y2(0);
        setX2Y2Count(0);
        setData(0);
        setNum(0);
        setLook(0);
        setLookCount(0);
        setTotalSpent(0);
        setTotalSpentTwo(0);
        setOpenSeaTotalSpent(0);
        setX2Y2TotalSpent(0);
        setLooksRareTotalSpent(0);
        const accounts = await web3.eth.getAccounts();
        setAccount(accounts[0]);
        setLoading(true);
        setStart(false);
        setNoData(false);
        setX2Y2(0);
        setX2Y2Count(0);
        setLook(0);
        setLeaderboardAccount(null);
        setLookCount(0);
        setTotalSpent(0);
        setBlur(0);
        setTotalSpentTwo(0);
        setOpenSeaTotalSpent(0);
        setLooksRareTotalSpent(0);
        setX2Y2TotalSpent(0);
        await fetchLooksRare(
          accounts[0],
          setLook,
          setLookCount,
          setTotalSpentTwo,
          setLoading,
          setStart,
          setError,
          setLooksRareTotalSpent
        );
        await fetchOpenSea(
          setData,
          accounts[0],
          setNum,
          setX2Y2,
          setX2Y2Count,
          setLoading,
          setError,
          setStart,
          setNoData,
          setTotal,
          setTotalSpent,
          setBlur,
          setOpenSeaTotalSpent,
          setX2Y2TotalSpent
        );
        setTotal();
        setLeaderboardAccount(accounts[0]);
        setLeaderboard();
        setAccount(null);
      } catch (err) {
        setError(err.message);
        setAccount(null);
      }
    } else {
    }
  };
  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      if (account.charAt(0) === "0") {
        setLoading(true);
        setStart(false);
        setNoData(false);
        setX2Y2(0);
        setX2Y2Count(0);
        setLeaderboardAccount(null);
        setLook(0);
        setLookCount(0);
        setTotalSpent(0);
        setBlur(0);
        setTotalSpentTwo(0);
        setOpenSeaTotalSpent(0);
        setX2Y2TotalSpent(0);
        setLooksRareTotalSpent(0);
        await fetchLooksRare(
          account,
          setLook,
          setLookCount,
          setTotalSpentTwo,
          setLoading,
          setStart,
          setError,
          setLooksRareTotalSpent
        );
        await fetchOpenSea(
          setData,
          account,
          setNum,
          setX2Y2,
          setX2Y2Count,
          setLoading,
          setError,
          setStart,
          setNoData,
          setTotal,
          setTotalSpent,
          setBlur,
          setOpenSeaTotalSpent,
          setX2Y2TotalSpent
        );
        setTotal();
        setLeaderboardAccount(account);
        setLeaderboard();
        setAccount(null);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onSubmits = async (e) => {
    e.preventDefault();
    await fetchLooksRare(
      account,
      setLook,
      setLookCount,
      setTotalSpentTwo,
      setLoading,
      setStart,
      setError,
      setLooksRareTotalSpent
    );
    await fetchOpenSea(
      setData,
      account,
      setNum,
      setX2Y2,
      setX2Y2Count,
      setLoading,
      setError,
      setStart,
      setNoData,
      setTotal,
      setTotalSpent,
      setBlur,
      setOpenSeaTotalSpent,
      setX2Y2TotalSpent
    );
    setTotal();
    setAccount(null);
  };

  const getEns = async (e) => {
    try {
      let b = web4.eth.ens.getAddress(e).then((address) => {
        return address;
      });
      return b;
    } catch (error) {
      console.log(error);
    }
  };
  const handleChange = async (event) => {
    try {
      if (event.target.value.charAt(0) === "0") {
        setAccount(event.target.value);
      } else {
        let b = await getEns(event.target.value);
        if (b.charAt(0) === "0") {
          setAccount(b);
          setLoading(true);
          setStart(false);
          setNoData(false);
          setX2Y2(0);
          setX2Y2Count(0);
          setLook(0);
          setLeaderboardAccount(null);
          setLookCount(0);
          setTotalSpent(0);
          setBlur(0);
          setTotalSpentTwo(0);
          setOpenSeaTotalSpent(0);
          setX2Y2TotalSpent(0);
          setLooksRareTotalSpent(0);
          console.log("here");
          fetchLooksRare(
            b,
            setLook,
            setLookCount,
            setTotalSpentTwo,
            setLoading,
            setStart,
            setError,
            setLooksRareTotalSpent
          );
          fetchOpenSea(
            setData,
            b,
            setNum,
            setX2Y2,
            setX2Y2Count,
            setLoading,
            setError,
            setStart,
            setNoData,
            setTotal,
            setTotalSpent,
            setBlur,
            setOpenSeaTotalSpent,
            setX2Y2TotalSpent
          );
          setLeaderboardAccount(b);
          setLeaderboard();
          setTotal();
          setAccount(null);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <MetaTags>
        <title>Royalties.wtf | NFT marketplaces royalties calculator</title>
        <meta
          id="meta-description"
          name="Royalties.wtf | NFT marketplaces royalties calculator"
          content="Want to find out what how much fees have you been paying to NFT marketplaces? Use our calculator to calculate your fees in ETH and USD terms."
        />
        <meta
          id="og-title"
          property="og:title"
          content="Royalties.wtf | NFT marketplaces royalties calculator"
        />
      </MetaTags>
      <div className="a">
        {start === true ? (
          <div className="firstSection">
            <h1 className="heading">
              <Typewriter
                onInit={(typewriter) => {
                  typewriter.typeString("Hi, welcome to royalty.wtf").start();
                }}
              />
            </h1>
            <div className="para">
              <span onClick={connectWallet} className="underline">
                Connect MetaMask
              </span>{" "}
              to Check How Much Royalty Fees You Paid To Marketplaces Since You
              Started Trading Or Search Address.
            </div>
            <div className="error">{error}</div>
            <form className="webflow-style-input">
              <input
                placeholder="Enter Eth Address or ENS..."
                className="account"
                onChange={handleChange}
              />
              <button className="btn" onClick={onSubmit}>
                {" "}
                <FaEthereum />{" "}
              </button>
            </form>
            <div className="footer">
              <div className="vers">
                made with <FaHeart /> by{" "}
                <a href="https://twitter.com/ederyeth" className="underlines">
                  edery{" "}
                </a>
              </div>
              <a className="twitIcon" href="https://twitter.com/ederyeth">
                {" "}
                <FaTwitter />
              </a>
            </div>
          </div>
        ) : (
          ""
        )}
        {loading === true ? (
          <div className="load">
            <div className="loading"> {loadText}</div>
            <PropagateLoader color="white" loading={true} />
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="board">
        {loading === false && start === false && nodata === false ? (
          <Dashboard
            blur={blur}
            sellingVolume={(totalSpent + totalSpentTwo).toFixed(2)}
            download={download}
            data={data}
            totalDollar={totalETH * eth}
            totalETH={totalETH}
            totalNFT={totalNFT}
            price={num}
            x2y2={x2y2}
            x2y2Count={x2y2Count}
            x2y2Dollar={eth * x2y2}
            open={(eth * data).toFixed(2)}
            look={look}
            lookCount={lookCount}
            onSubmit={onSubmit}
            handleChange={handleChange}
            lookTotal={(eth * look).toFixed(2)}
            actualOpenSea={totalOpenSeaSpent.toFixed(2)}
            actualX2Y2={totalX2Y2Spent.toFixed(2)}
            actualLooksSpent={looksRareTotalSpent.toFixed(2)}
            overallActualSpent={actualTotalSpent}
            leaderboardAccount={leaderboardArray}
          />
        ) : (
          ""
        )}

        {nodata === true ? (
          <>
            <form className="input">
              <input
                placeholder="Enter Eth Address or ENS..."
                className="account"
                onChange={handleChange}
              />
              <button className="btn" onClick={onSubmit}>
                {" "}
                <FaEthereum />{" "}
              </button>
            </form>
            <div id="capture" className="dashboard">
              <div className="data">
                {" "}
                In total, You sold <span className="result"> 0</span> NFTs, You
                paid for marketplaces <span className="result">Ξ0</span> on
                royalties.{" "}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default App;
