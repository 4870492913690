import Web3 from "web3";
import { Network, Alchemy } from "alchemy-sdk";
import { FaEthereum, FaTwitter, FaHeart } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import { fetchOpenSea } from "./fetchNFTS";
import opensea from "./images/opensea.png";
import looksrare from "./images/looksrare.png";
import x2y2s from "./images/x2y2.png";
import blurs from "./images/blurs.webp";
import royalty from "./images/royalty.png";
import axios from "axios";
import Leaderboard from "./Leaderboard";

const Dashboard = ({
  data,
  price,
  open,
  x2y2,
  x2y2Count,
  x2y2Dollar,
  lookCount,
  look,
  lookTotal,
  totalDollar,
  totalNFT,
  totalETH,
  onSubmit,
  handleChange,
  download,
  sellingVolume,
  blur,
  actualOpenSea,
  actualX2Y2,
  actualLooksSpent,
  overallActualSpent,
  leaderboardAccount,
}) => {
  // const [leaderboardArray, setLeaderboardArry] = useState([]);
  // useEffect(() => {
  //   setLeaderboard();
  // }, []);

  return (
    <>
      <form className="input">
        <input
          placeholder="Enter Eth Address or ENS.."
          className="account"
          onChange={handleChange}
        />
        <button className="btn" onClick={onSubmit}>
          {" "}
          <FaEthereum />{" "}
        </button>
      </form>
      <div id="capture" className="dashboard">
        <div className="data">
          {" "}
          Your NFTs selling volume is{" "}
          <span className="result">
            Ξ{sellingVolume.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </span>
          .<br></br> You sold{" "}
          <span className="result">
            {" "}
            {totalNFT.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </span>{" "}
          NFTs and paid <span className="result">Ξ{totalETH}</span> in royalties
          for Marketplaces which is worth{" "}
          <span className="result">
            $
            {overallActualSpent
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </span>{" "}
          according to the ETH/USD historical data. <br></br> In today's ETH
          value, this's{" "}
          <span className="result">
            $
            {totalDollar
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </span>
          .
        </div>
        <div className="tbl-content">
          <table className="styled-table">
            <thead>
              <tr>
                <th></th>
                <th>NFTs Sold</th>
                <th>Royalties Paid</th>
                <th>USD Value</th>
                <th>Estimated USD Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="main">
                  <img src={opensea} alt="openSea" width="32px" height="auto" />
                </th>
                <td>
                  {price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td>
                  Ξ{data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td>
                  ${open.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td>
                  $
                  {actualOpenSea
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
              </tr>
              <tr className="active-row">
                <th className="main">
                  <img src={x2y2s} alt="X2Y2" width="32px" height="auto" />
                </th>
                <td>
                  {x2y2Count
                    ? x2y2Count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : "0"}
                </td>
                <td>
                  Ξ
                  {x2y2
                    ? x2y2
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : "0"}
                </td>
                <td>
                  $
                  {x2y2Dollar
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td>
                  ${actualX2Y2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
              </tr>
              <tr className="active-row">
                <th className="main">
                  <img
                    src={looksrare}
                    alt="Looks rare"
                    width="35px"
                    height="auto"
                  />
                </th>
                <td>
                  {lookCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td>
                  Ξ{look.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td>
                  ${lookTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td>
                  $
                  {actualLooksSpent
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
              </tr>
              <tr className="active">
                <th className="main">
                  <img src={blurs} alt="Blur" width="35px" height="auto" />
                </th>
                <td className="orange">{blur}</td>
                <td className="orange">Ξ0</td>
                <td className="orange">$0</td>
                <td className="orange">$0</td>
              </tr>
            </tbody>
          </table>
        </div>

        <img src={royalty} alt="royalty.wtf" />
      </div>

      <button className="share" onClick={download}>
        <FaTwitter /> Share On Twitter
      </button>
      {leaderboardAccount.length > 5 ? (
        <Leaderboard leaderboardAccount={leaderboardAccount} />
      ) : (
        ""
      )}

      <div className="footer">
        <div className="vers">
          made with <FaHeart /> by{" "}
          <a href="https://twitter.com/ederyeth" className="underlines">
            edery{" "}
          </a>
        </div>
        <a className="twitIcon" href="https://twitter.com/ederyeth">
          {" "}
          <FaTwitter />
        </a>
      </div>
      <div id="up">
        <img id="downloadImg" />
      </div>
    </>
  );
};

export default Dashboard;
