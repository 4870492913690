import Web3 from "web3";
import { Network, Alchemy } from "alchemy-sdk";
import { FaEthereum, FaTwitter, FaHeart } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import { fetchOpenSea } from "./fetchNFTS";
import opensea from "./images/opensea.png";
import looksrare from "./images/looksrare.png";
import x2y2s from "./images/x2y2.png";
import blurs from "./images/blurs.webp";
import onegoldencarrot from "./images/oneGoldenCarrot.png";
import twogoldencarrot from "./images/twoGoldenCarrot.png";
import threegoldencarrot from "./images/threeGoldenCarrot.png";
import fourgoldencarrot from "./images/fourGoldenCarrot.png";
import fivegoldencarrot from "./images/fiveGoldenCarrot.png";
import sixgoldencarrot from "./images/sixGoldenCarrot.png";
import sevengoldencarrot from "./images/sevenGoldenCarrot.png";
import eightgoldencarrot from "./images/eightGoldenCarrot.png";
import ninegoldencarrot from "./images/nineGoldenCarrot.png";
import tengoldencarrot from "./images/tenGoldenCarrot.png";

import axios from "axios";

const Leaderboard = ({ leaderboardAccount }) => {
  // const [leaderboardArray, setLeaderboardArry] = useState([]);
  // useEffect(() => {
  //   setLeaderboard();
  // }, []);

  return (
    <>
      <div id="capture" className="dashboard">
        <div>
          <img
            src={onegoldencarrot}
            alt="goldenCarrots"
            width="120px"
            height="auto"
          />
          <div className="leaderboard-title">CarrotBoard</div>
          <div className="leaderboard-description">10 Badges of honor</div>
        </div>

        <div className="tbl-contenttwo">
          <table className="styled-tabletwo">
            <thead>
              <tr>
                <th>Position</th>
                <th>Address</th>
                <th>Selling Volume</th>
                <th>Royalties Paid</th>
                <th>USD Value</th>
                <th>Estimated USD Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="main">
                  <img
                    src={onegoldencarrot}
                    alt="goldenCarrot"
                    width="48px"
                    height="auto"
                  />
                </th>
                <td>
                  {leaderboardAccount[0].ens != null
                    ? leaderboardAccount[0].ens
                    : leaderboardAccount[0].address.substr(0, 12)}
                </td>
                <td>
                  Ξ
                  {parseFloat(leaderboardAccount[0].sellingVolumes)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>

                <td className="active">
                  Ξ
                  {parseFloat(leaderboardAccount[0].totalETHs)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td>
                  $
                  {parseFloat(leaderboardAccount[0].totalDollars)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td>
                  $
                  {parseFloat(leaderboardAccount[0].overallSpends)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
              </tr>
              <tr>
                <th className="main">
                  <img
                    src={twogoldencarrot}
                    alt="goldenCarrot"
                    width="48px"
                    height="auto"
                  />
                </th>
                <td className="address">
                  {leaderboardAccount[1].ens
                    ? leaderboardAccount[1].ens
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : leaderboardAccount[1].address.substr(0, 12)}
                </td>
                <td>
                  Ξ
                  {parseFloat(leaderboardAccount[1].sellingVolumes)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>

                <td className="active">
                  Ξ
                  {parseFloat(leaderboardAccount[1].totalETHs)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td>
                  $
                  {parseFloat(leaderboardAccount[1].totalDollars)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td>
                  $
                  {parseFloat(leaderboardAccount[1].overallSpends)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
              </tr>
              <tr>
                <th className="main">
                  <img
                    src={threegoldencarrot}
                    alt="goldenCarrot"
                    width="48px"
                    height="auto"
                  />
                </th>
                <td>
                  {leaderboardAccount[2].ens
                    ? leaderboardAccount[2].ens
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : leaderboardAccount[2].address.substr(0, 12)}
                </td>
                <td>
                  Ξ
                  {parseFloat(leaderboardAccount[2].sellingVolumes)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>

                <td className="active">
                  Ξ
                  {parseFloat(leaderboardAccount[2].totalETHs)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td>
                  $
                  {parseFloat(leaderboardAccount[2].totalDollars)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td>
                  $
                  {parseFloat(leaderboardAccount[2].overallSpends)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
              </tr>
              <tr>
                <th className="main">
                  <img
                    src={fourgoldencarrot}
                    alt="goldenCarrot"
                    width="48px"
                    height="auto"
                  />
                </th>
                <td>
                  {leaderboardAccount[3].ens
                    ? leaderboardAccount[3].ens
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : leaderboardAccount[3].address.substr(0, 12)}
                </td>
                <td>
                  Ξ
                  {parseFloat(leaderboardAccount[3].sellingVolumes)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>

                <td className="active">
                  Ξ
                  {parseFloat(leaderboardAccount[3].totalETHs)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td>
                  $
                  {parseFloat(leaderboardAccount[3].totalDollars)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td>
                  $
                  {parseFloat(leaderboardAccount[3].overallSpends)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
              </tr>
              <tr>
                <th className="main">
                  <img
                    src={fivegoldencarrot}
                    alt="goldenCarrot"
                    width="48px"
                    height="auto"
                  />
                </th>
                <td>
                  {leaderboardAccount[4].ens
                    ? leaderboardAccount[4].ens
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : leaderboardAccount[4].address.substr(0, 12)}
                </td>
                <td>
                  Ξ
                  {parseFloat(leaderboardAccount[4].sellingVolumes)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>

                <td className="active">
                  Ξ
                  {parseFloat(leaderboardAccount[4].totalETHs)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td>
                  $
                  {parseFloat(leaderboardAccount[4].totalDollars)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td>
                  $
                  {parseFloat(leaderboardAccount[4].overallSpends)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
              </tr>
              <tr>
                <th className="main">
                  <img
                    src={sixgoldencarrot}
                    alt="goldenCarrot"
                    width="48px"
                    height="auto"
                  />
                </th>
                <td>
                  {leaderboardAccount[5].ens
                    ? leaderboardAccount[5].ens
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : leaderboardAccount[5].address.substr(0, 12)}
                </td>
                <td>
                  Ξ
                  {parseFloat(leaderboardAccount[5].sellingVolumes)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>

                <td className="active">
                  Ξ
                  {parseFloat(leaderboardAccount[5].totalETHs)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td>
                  $
                  {parseFloat(leaderboardAccount[5].totalDollars)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td>
                  $
                  {parseFloat(leaderboardAccount[5].overallSpends)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
              </tr>
              <tr>
                <th className="main">
                  <img
                    src={sevengoldencarrot}
                    alt="goldenCarrot"
                    width="48px"
                    height="auto"
                  />
                </th>
                <td>
                  {leaderboardAccount[6].ens
                    ? leaderboardAccount[6].ens
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : leaderboardAccount[6].address.substr(0, 12)}
                </td>
                <td>
                  Ξ
                  {parseFloat(leaderboardAccount[6].sellingVolumes)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>

                <td className="active">
                  Ξ
                  {parseFloat(leaderboardAccount[6].totalETHs)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td>
                  $
                  {parseFloat(leaderboardAccount[6].totalDollars)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td>
                  $
                  {parseFloat(leaderboardAccount[6].overallSpends)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
              </tr>
              <tr>
                <th className="main">
                  <img
                    src={eightgoldencarrot}
                    alt="goldenCarrot"
                    width="48px"
                    height="auto"
                  />
                </th>
                <td>
                  {leaderboardAccount[7].ens
                    ? leaderboardAccount[7].ens
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : leaderboardAccount[7].address.substr(0, 12)}
                </td>
                <td>
                  Ξ
                  {parseFloat(leaderboardAccount[7].sellingVolumes)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>

                <td className="active">
                  Ξ
                  {parseFloat(leaderboardAccount[7].totalETHs)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td>
                  $
                  {parseFloat(leaderboardAccount[7].totalDollars)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td>
                  $
                  {parseFloat(leaderboardAccount[7].overallSpends)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
              </tr>
              <tr>
                <th className="main">
                  <img
                    src={ninegoldencarrot}
                    alt="goldenCarrot"
                    width="48px"
                    height="auto"
                  />
                </th>
                <td>
                  {leaderboardAccount[8].ens
                    ? leaderboardAccount[8].ens
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : leaderboardAccount[8].address.substr(0, 12)}
                </td>
                <td>
                  Ξ
                  {parseFloat(leaderboardAccount[8].sellingVolumes)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>

                <td className="active">
                  Ξ
                  {parseFloat(leaderboardAccount[8].totalETHs)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td>
                  $
                  {parseFloat(leaderboardAccount[8].totalDollars)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td>
                  $
                  {parseFloat(leaderboardAccount[8].overallSpends)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
              </tr>
              <tr>
                <th className="main">
                  <img
                    src={tengoldencarrot}
                    alt="goldenCarrot"
                    width="48px"
                    height="auto"
                  />
                </th>
                <td>
                  {leaderboardAccount[9].ens
                    ? leaderboardAccount[9].ens
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : leaderboardAccount[9].address.substr(0, 12)}
                </td>
                <td>
                  Ξ
                  {parseFloat(leaderboardAccount[9].sellingVolumes)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>

                <td className="active">
                  Ξ
                  {parseFloat(leaderboardAccount[9].totalETHs)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td>
                  $
                  {parseFloat(leaderboardAccount[9].totalDollars)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td>
                  $
                  {parseFloat(leaderboardAccount[9].overallSpends)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Leaderboard;
