import { Network, Alchemy } from "alchemy-sdk";
import axios from "axios";

const config = {
  apiKey: "8ajQ1ziufCt9OHtyMv2ZeQUudTQIN-Pq",
  network: Network.ETH_MAINNET,
};

const alchemy = new Alchemy(config);

export const fetchOpenSea = async (
  setNFT,
  address,
  setNumber,
  setX2Y2,
  setX2Y2Count,
  setLoading,
  setError,
  setStart,
  setNoData,
  setTotal,
  setTotalSpent,
  setBlur,
  setOpenSeaTotalSpent,
  setX2Y2TotalSpent
) => {
  console.log("s");
  try {
    let hisotricalEthPrice = {
      prices: [
        [1438905600000, 2.83162],
        [1438992000000, 1.33075],
        [1439164800000, 0.6875857090838133],
        [1439251200000, 1.067378879429946],
        [1439337600000, 1.256613175101658],
        [1439424000000, 1.825395446319752],
        [1439510400000, 1.825974891355639],
        [1439596800000, 1.670949668937766],
        [1439683200000, 1.476607254205028],
        [1439769600000, 1.20387143115441],
        [1439856000000, 1.281393051659975],
        [1439942400000, 1.252738651679077],
        [1440028800000, 1.484195074171873],
        [1440115200000, 1.40631477379752],
        [1440201600000, 1.380721536476638],
        [1440288000000, 1.355182100454404],
        [1440374400000, 1.24657168269902],
        [1440460800000, 1.160828327907264],
        [1440547200000, 1.119639156482987],
        [1440633600000, 1.133690694008804],
        [1440720000000, 1.188895698722608],
        [1440806400000, 1.174343905252208],
        [1440892800000, 1.322350879918967],
        [1440979200000, 1.353368417391325],
        [1441065600000, 1.350786688734447],
        [1441152000000, 1.278810765308764],
        [1441238400000, 1.262770719520856],
        [1441324800000, 1.269520099878085],
        [1441411200000, 1.334976229386974],
        [1441497600000, 1.29888313413515],
        [1441584000000, 1.256223439965584],
        [1441670400000, 1.236033260936938],
        [1441756800000, 1.206529945086729],
        [1441843200000, 1.167234841402144],
        [1441929600000, 0.9765090926915041],
        [1442016000000, 1.038203585306107],
        [1442102400000, 0.9386521502557871],
        [1442188800000, 0.87402296242848],
        [1442275200000, 0.9418231857274298],
        [1442361600000, 0.9032199195724051],
        [1442448000000, 0.8830787289063511],
        [1442534400000, 0.8460709193872474],
        [1442620800000, 0.8821037996855977],
        [1442707200000, 0.9363277614869444],
        [1442793600000, 0.9010928790890973],
        [1442880000000, 0.8971422650551497],
        [1442966400000, 0.8940475222994914],
        [1443052800000, 0.8160373080893036],
        [1443139200000, 0.7405192998515878],
        [1443225600000, 0.7778014991497727],
        [1443312000000, 0.7213584231130563],
        [1443398400000, 0.5830570046974366],
        [1443484800000, 0.657832489515796],
        [1443571200000, 0.7347630535971914],
        [1443657600000, 0.6858121897138929],
        [1443744000000, 0.6762217338750541],
        [1443830400000, 0.6874757575411915],
        [1443916800000, 0.6675468579375702],
        [1444003200000, 0.6282550742429386],
        [1444089600000, 0.64565776230867],
        [1444176000000, 0.6100126973575233],
        [1444262400000, 0.6234833021554507],
        [1444348800000, 0.650768764684828],
        [1444435200000, 0.6297949417268295],
        [1444521600000, 0.6370439806918999],
        [1444608000000, 0.628787281832734],
        [1444694400000, 0.6026272723327593],
        [1444780800000, 0.517689435598842],
        [1444867200000, 0.5614990042588522],
        [1444953600000, 0.5331480200007419],
        [1445040000000, 0.5490283089973744],
        [1445126400000, 0.5184445473595721],
        [1445212800000, 0.4870672984193326],
        [1445299200000, 0.4329785990312485],
        [1445385600000, 0.4397689744048958],
        [1445472000000, 0.5654615066780281],
        [1445558400000, 0.540737766800056],
        [1445644800000, 0.5685737685118122],
        [1445731200000, 0.6319388092249569],
        [1445817600000, 0.7439579097275092],
        [1445904000000, 0.8544552504832845],
        [1445990400000, 1.010409721259424],
        [1446076800000, 1.163748635576317],
        [1446163200000, 1.041849054896385],
        [1446249600000, 0.9070916284167455],
        [1446336000000, 1.058541941291151],
        [1446422400000, 0.9550458603200441],
        [1446508800000, 1.002344802929553],
        [1446595200000, 0.9018086605486009],
        [1446681600000, 0.906601136813436],
        [1446768000000, 0.9094421987209234],
        [1446854400000, 0.922482091197951],
        [1446940800000, 1.030499514179018],
        [1447027200000, 0.9958029845225516],
        [1447113600000, 0.9348342219016499],
        [1447200000000, 0.7887611772679245],
        [1447286400000, 0.90074206650109],
        [1447372800000, 0.9040815579448993],
        [1447459200000, 0.8842289054037304],
        [1447545600000, 0.9108255481961639],
        [1447632000000, 0.9338407839578461],
        [1447718400000, 0.9952733578828389],
        [1447804800000, 0.9944285799213992],
        [1447891200000, 0.9514712892422663],
        [1447977600000, 0.9268031189591781],
        [1448064000000, 0.9735724342696529],
        [1448150400000, 0.96473853235469],
        [1448236800000, 0.9452203362307195],
        [1448323200000, 0.8993611354663962],
        [1448409600000, 0.8677070917677269],
        [1448496000000, 0.8948747707881739],
        [1448582400000, 0.8707128002545287],
        [1448668800000, 0.9173987754375574],
        [1448755200000, 0.8717449537893333],
        [1448841600000, 0.8736009320890139],
        [1448928000000, 0.8750039976447704],
        [1449014400000, 0.8227337811375524],
        [1449100800000, 0.8247651888810106],
        [1449187200000, 0.8387909512811372],
        [1449273600000, 0.8645837297560469],
        [1449360000000, 0.8349924869606098],
        [1449446400000, 0.8007502839259887],
        [1449532800000, 0.8188527088752511],
        [1449619200000, 0.7918293483107267],
        [1449705600000, 0.8474422420390545],
        [1449792000000, 0.9167389424445134],
        [1449878400000, 0.9712583097472548],
        [1449964800000, 0.9583031735578238],
        [1450051200000, 0.9954898346339888],
        [1450137600000, 1.0170637001075],
        [1450224000000, 0.9889124423815733],
        [1450310400000, 0.9402283251940493],
        [1450396800000, 0.9211717831099321],
        [1450483200000, 0.9096329169325174],
        [1450569600000, 0.9032316429089903],
        [1450656000000, 0.8968062870238118],
        [1450742400000, 0.8789593130596947],
        [1450828800000, 0.8675654552724993],
        [1450915200000, 0.861363016488459],
        [1451001600000, 0.8707183439745884],
        [1451088000000, 0.8530178649009634],
        [1451174400000, 0.8588870868776094],
        [1451260800000, 0.8421696130307879],
        [1451347200000, 0.8694878107781919],
        [1451433600000, 0.9003543017405369],
        [1451520000000, 0.9365399677803522],
        [1451606400000, 0.9463133430749894],
        [1451692800000, 0.9381261621726148],
        [1451779200000, 0.9721969755035614],
        [1451865600000, 0.9592978352147374],
        [1451952000000, 0.9531022411427653],
        [1452038400000, 0.9684340117908705],
        [1452124800000, 0.9589767803876823],
        [1452211200000, 0.9955747811931686],
        [1452297600000, 1.02478096778813],
        [1452384000000, 1.01996585607406],
        [1452470400000, 1.068726621062779],
        [1452556800000, 1.167362900827917],
        [1452643200000, 1.12958834642001],
        [1452729600000, 1.157377553252501],
        [1452816000000, 1.21073546288485],
        [1452902400000, 1.215691295024754],
        [1452988800000, 1.321855711753408],
        [1453075200000, 1.39830112692888],
        [1453161600000, 1.3769900190471],
        [1453248000000, 1.565411904541257],
        [1453334400000, 1.544691862235827],
        [1453420800000, 1.471341822504971],
        [1453507200000, 1.692156284936261],
        [1453593600000, 2.131691495370684],
        [1453680000000, 2.48139608647395],
        [1453766400000, 2.086782726986503],
        [1453852800000, 2.384501158704437],
        [1453939200000, 2.519719734523484],
        [1454025600000, 2.486203232055895],
        [1454112000000, 2.437601273512591],
        [1454198400000, 2.298766557449123],
        [1454284800000, 2.221883059355513],
        [1454371200000, 2.403111855327034],
        [1454457600000, 2.515949933456795],
        [1454544000000, 2.557178944302694],
        [1454630400000, 2.532878565491474],
        [1454716800000, 2.517776369343559],
        [1454803200000, 2.950776113177157],
        [1454889600000, 3.165335693696386],
        [1454976000000, 3.720453286688504],
        [1455062400000, 4.427431437703359],
        [1455148800000, 6.005000308112697],
        [1455235200000, 5.475649283683189],
        [1455321600000, 5.373284921940013],
        [1455408000000, 5.169512436402438],
        [1455494400000, 5.319058286377442],
        [1455580800000, 4.368970122064963],
        [1455667200000, 3.744022470740231],
        [1455753600000, 4.397939042003922],
        [1455840000000, 4.493895382965731],
        [1455926400000, 4.301875677560981],
        [1456012800000, 4.653970273276003],
        [1456099200000, 5.608714257576696],
        [1456185600000, 5.612091439849516],
        [1456272000000, 6.134381259039694],
        [1456358400000, 5.93625743323269],
        [1456444800000, 5.897351626440898],
        [1456531200000, 6.357091762158404],
        [1456617600000, 6.440650848847282],
        [1456704000000, 6.312028458049726],
        [1456790400000, 7.560157049617909],
        [1456876800000, 8.398318163034869],
        [1456963200000, 9.504937042998197],
        [1457049600000, 10.03776475108964],
        [1457136000000, 10.93519773927983],
        [1457222400000, 10.96466178163567],
        [1457308800000, 9.397469868813554],
        [1457395200000, 9.800690787314164],
        [1457481600000, 11.97157975649351],
        [1457568000000, 11.2064229700308],
        [1457654400000, 11.20893669288255],
        [1457740800000, 13.37620667356022],
        [1457827200000, 14.89359978457807],
        [1457913600000, 12.28982460670519],
        [1458000000000, 12.966897673599],
        [1458086400000, 12.52943314528079],
        [1458172800000, 10.956232214701],
        [1458259200000, 10.79765033887468],
        [1458345600000, 10.43953586817164],
        [1458432000000, 10.21749097388716],
        [1458518400000, 11.85112269425594],
        [1458604800000, 11.31742767517068],
        [1458691200000, 12.40319150724304],
        [1458777600000, 11.12762218636002],
        [1458864000000, 10.64069143211582],
        [1458950400000, 10.96552244468764],
        [1459036800000, 10.37751681820161],
        [1459123200000, 11.67625154039094],
        [1459209600000, 11.73817083046575],
        [1459296000000, 11.88228675953419],
        [1459382400000, 11.35463054290018],
        [1459468800000, 11.63607180334684],
        [1459555200000, 11.5639776320382],
        [1459641600000, 11.51238012010477],
        [1459728000000, 11.18688123888369],
        [1459814400000, 10.41948468988903],
        [1459900800000, 10.66622342007389],
        [1459987200000, 10.0295684039377],
        [1460073600000, 9.758944911021473],
        [1460160000000, 9.899719595415823],
        [1460246400000, 8.814617175830122],
        [1460332800000, 8.66829211893249],
        [1460419200000, 7.51681223420342],
        [1460505600000, 8.015604593105122],
        [1460592000000, 8.43365602127963],
        [1460678400000, 8.20613745870167],
        [1460764800000, 8.555981966976974],
        [1460851200000, 9.323170276346234],
        [1460937600000, 8.960482405053712],
        [1461024000000, 8.696773038727313],
        [1461110400000, 8.484150722584234],
        [1461196800000, 8.11104074507033],
        [1461283200000, 7.855106665179344],
        [1461369600000, 8.377192623957283],
        [1461456000000, 7.995361207240304],
        [1461542400000, 7.812751656077315],
        [1461628800000, 7.424663266229029],
        [1461715200000, 7.724694549946334],
        [1461801600000, 7.250216005225782],
        [1461888000000, 7.47581760975781],
        [1461974400000, 8.892970091837213],
        [1462060800000, 8.832454372832895],
        [1462147200000, 10.10782116866719],
        [1462233600000, 9.285335810954495],
        [1462320000000, 9.414136136064808],
        [1462406400000, 9.827130178456441],
        [1462492800000, 9.277042146778028],
        [1462579200000, 9.381202893584621],
        [1462665600000, 9.52920206226682],
        [1462752000000, 9.353276545574158],
        [1462838400000, 9.350283703928604],
        [1462924800000, 9.981302920467385],
        [1463011200000, 10.091492287586432],
        [1463097600000, 10.585661577212786],
        [1463184000000, 10.176098567981153],
        [1463270400000, 9.960446892869543],
        [1463356800000, 11.1336459827202],
        [1463443200000, 12.157137161266911],
        [1463529600000, 13.35361430554958],
        [1463616000000, 14.71832475600216],
        [1463702400000, 13.69368343400459],
        [1463788800000, 13.99924843857703],
        [1463875200000, 14.248986066415835],
        [1463961600000, 13.23503660198636],
        [1464048000000, 12.66434299000911],
        [1464134400000, 12.50426452924122],
        [1464220800000, 12.498353409631168],
        [1464307200000, 11.19174476153149],
        [1464393600000, 11.76324560569715],
        [1464480000000, 12.38905213533701],
        [1464566400000, 12.67843106889236],
        [1464652800000, 14.00927375901662],
        [1464739200000, 13.857937834861247],
        [1464825600000, 13.77433887468257],
        [1464912000000, 13.8427358773047],
        [1464998400000, 13.697394260187224],
        [1465084800000, 13.970529067786634],
        [1465171200000, 13.942005553967727],
        [1465257600000, 14.549216948648322],
        [1465344000000, 14.43177555298705],
        [1465430400000, 14.44062374394472],
        [1465516800000, 13.943733928604544],
        [1465603200000, 13.960460970231287],
        [1465689600000, 15.697007269686255],
        [1465776000000, 17.55275028575095],
        [1465862400000, 18.770374371361417],
        [1465948800000, 18.421853687438038],
        [1466035200000, 20.509400413759888],
        [1466121600000, 15.397397817568129],
        [1466208000000, 11.197136666677007],
        [1466294400000, 12.23058702890821],
        [1466380800000, 11.814834520499266],
        [1466467200000, 12.984597875196858],
        [1466553600000, 12.737756210638716],
        [1466640000000, 13.676478995775716],
        [1466726400000, 13.94189690132606],
        [1466812800000, 14.276287720956573],
        [1466899200000, 13.845029961871877],
        [1466985600000, 13.932543491059052],
        [1467072000000, 12.295660556232374],
        [1467158400000, 12.800496100256193],
        [1467244800000, 13.33562669996461],
        [1467331200000, 12.329645963731734],
        [1467417600000, 12.01798250397972],
        [1467504000000, 11.825161030775883],
        [1467590400000, 11.421738727166776],
        [1467676800000, 10.540860504216047],
        [1467763200000, 10.51055993812399],
        [1467849600000, 10.098931265626275],
        [1467936000000, 11.39547874677082],
        [1468022400000, 10.99572234732321],
        [1468108800000, 10.957294339543301],
        [1468195200000, 10.515451706239658],
        [1468281600000, 10.606193607117202],
        [1468368000000, 10.489054686552203],
        [1468454400000, 11.242970957141127],
        [1468540800000, 11.32509705331988],
        [1468627200000, 11.701716015155743],
        [1468713600000, 11.314787410063753],
        [1468800000000, 11.022556335862294],
        [1468886400000, 11.582501423627564],
        [1468972800000, 12.152788273478947],
        [1469059200000, 12.605917129706976],
        [1469145600000, 14.319877369541702],
        [1469232000000, 14.389725342721574],
        [1469318400000, 12.803851809790078],
        [1469404800000, 13.816630680711038],
        [1469491200000, 11.771641757280554],
        [1469577600000, 12.96633340222508],
        [1469664000000, 12.883149607897323],
        [1469750400000, 12.840037637363167],
        [1469836800000, 12.547081780784822],
        [1469923200000, 11.868408414551208],
        [1470009600000, 10.968397852276404],
        [1470096000000, 8.352592747838974],
        [1470182400000, 10.22174781589873],
        [1470268800000, 11.032160216870203],
        [1470355200000, 10.940014108153814],
        [1470441600000, 10.898636348546457],
        [1470528000000, 10.920308055489057],
        [1470614400000, 11.245805121088916],
        [1470700800000, 12.191218707659688],
        [1470787200000, 12.141222798878122],
        [1470873600000, 11.988140188861896],
        [1470960000000, 11.973720126245913],
        [1471046400000, 11.577472667681942],
        [1471132800000, 11.25990352208696],
        [1471219200000, 11.230807046622242],
        [1471305600000, 10.25082244872534],
        [1471392000000, 10.73534634440297],
        [1471478400000, 10.724216809737694],
        [1471564800000, 10.718344718920152],
        [1471651200000, 11.262754476834717],
        [1471737600000, 11.154044310153234],
        [1471824000000, 11.061377388887204],
        [1471910400000, 11.012543081655863],
        [1471996800000, 11.013811994175523],
        [1472083200000, 11.339127441192723],
        [1472169600000, 11.256812662022648],
        [1472256000000, 11.14950758737516],
        [1472342400000, 10.91304107690759],
        [1472428800000, 10.906290588906247],
        [1472515200000, 11.210877311518441],
        [1472601600000, 11.49662475092663],
        [1472688000000, 12.002877477501157],
        [1472774400000, 12.09104280233147],
        [1472860800000, 11.797585220569804],
        [1472947200000, 11.662579543984544],
        [1473033600000, 11.751897477880805],
        [1473120000000, 11.684755437748173],
        [1473206400000, 11.550025415616256],
        [1473292800000, 11.33639873415642],
        [1473379200000, 11.63011682103449],
        [1473465600000, 12.117830861883727],
        [1473552000000, 11.59605624659764],
        [1473638400000, 11.84714198083278],
        [1473724800000, 11.881729455789054],
        [1473811200000, 11.912008011075965],
        [1473897600000, 11.901478405174705],
        [1473984000000, 12.522369283417852],
        [1474070400000, 12.68454280449742],
        [1474156800000, 12.369052139120052],
        [1474243200000, 13.160168063025644],
        [1474329600000, 14.308046581659253],
        [1474416000000, 13.705179799911743],
        [1474502400000, 13.233802742993412],
        [1474588800000, 13.318993324210759],
        [1474675200000, 12.859605765847848],
        [1474761600000, 13.045598643367276],
        [1474848000000, 12.818746399021148],
        [1474934400000, 13.074726797493888],
        [1475020800000, 13.313989439262565],
        [1475107200000, 13.09665700624727],
        [1475193600000, 13.194818155480155],
        [1475280000000, 13.269827224677638],
        [1475366400000, 13.197909934562759],
        [1475452800000, 13.470600090767412],
        [1475539200000, 13.261962214071206],
        [1475625600000, 13.056650848974803],
        [1475712000000, 12.826128461125474],
        [1475798400000, 12.655679278508105],
        [1475884800000, 12.175874962913426],
        [1475971200000, 12.029519227976937],
        [1476057600000, 11.837832089591664],
        [1476144000000, 11.750267273762638],
        [1476230400000, 11.854687660404304],
        [1476316800000, 11.96064220847878],
        [1476403200000, 11.916089474549377],
        [1476489600000, 11.944605165006873],
        [1476576000000, 11.930313388885365],
        [1476662400000, 11.974984398539725],
        [1476748800000, 12.509511262679014],
        [1476835200000, 11.973806158360992],
        [1476921600000, 12.016522079125743],
        [1477008000000, 12.090575972840435],
        [1477094400000, 12.045038278304451],
        [1477180800000, 11.933800410433603],
        [1477267200000, 11.890535450541087],
        [1477353600000, 11.272711525960538],
        [1477440000000, 11.497257370206905],
        [1477526400000, 11.456673402613983],
        [1477612800000, 11.523584376027783],
        [1477699200000, 10.336819684434698],
        [1477785600000, 11.186557158904845],
        [1477872000000, 10.88070260049341],
        [1477958400000, 10.792187379279612],
        [1478044800000, 10.783585401157607],
        [1478131200000, 10.811630097854405],
        [1478217600000, 11.129995658864795],
        [1478304000000, 11.066058259923683],
        [1478390400000, 10.920762843886132],
        [1478476800000, 10.874200829423744],
        [1478563200000, 10.890105501340495],
        [1478649600000, 10.664918162834825],
        [1478736000000, 10.519281388267027],
        [1478822400000, 10.29308735231344],
        [1478908800000, 9.664325241310054],
        [1478995200000, 10.110719459728225],
        [1479081600000, 9.991652804266907],
        [1479168000000, 10.200083856801202],
        [1479254400000, 10.025367620097992],
        [1479340800000, 9.96397798540216],
        [1479427200000, 9.453697368045109],
        [1479513600000, 9.66730580728121],
        [1479600000000, 9.530496491166998],
        [1479686400000, 9.575907932522316],
        [1479772800000, 9.81249150160966],
        [1479859200000, 9.753493133033384],
        [1479945600000, 9.180997416192705],
        [1480032000000, 9.335116893823114],
        [1480118400000, 9.288306186526965],
        [1480204800000, 8.907402683417718],
        [1480291200000, 8.70845246717361],
        [1480377600000, 8.158494315710797],
        [1480464000000, 8.650154047457612],
        [1480550400000, 8.439785438702646],
        [1480636800000, 7.637917965068979],
        [1480723200000, 7.892622837022096],
        [1480809600000, 7.469132189830329],
        [1480896000000, 6.815609222087059],
        [1480982400000, 7.631916157246792],
        [1481068800000, 8.13507798254848],
        [1481155200000, 8.302596338463099],
        [1481241600000, 8.504900484563588],
        [1481328000000, 8.098585501342257],
        [1481414400000, 8.214985823104497],
        [1481500800000, 8.472511708508414],
        [1481587200000, 8.35100726243699],
        [1481673600000, 8.207853104398847],
        [1481760000000, 7.770357969755126],
        [1481846400000, 7.796626831717168],
        [1481932800000, 7.820282701062171],
        [1482019200000, 7.856185612715422],
        [1482105600000, 7.5922361645492975],
        [1482192000000, 7.623783721340898],
        [1482278400000, 7.856099400557245],
        [1482364800000, 7.575643744053494],
        [1482451200000, 7.201805732308588],
        [1482537600000, 7.2227628476531995],
        [1482624000000, 7.2132341590915585],
        [1482710400000, 7.268475378158216],
        [1482796800000, 7.171806594177698],
        [1482883200000, 7.613307736356936],
        [1482969600000, 8.172329056763505],
        [1483056000000, 8.15378652716307],
        [1483142400000, 8.010852235417396],
        [1483228800000, 8.064789989459687],
        [1483315200000, 8.361286160728705],
        [1483401600000, 9.626792384988086],
        [1483488000000, 10.972252853086058],
        [1483574400000, 10.115254241883068],
        [1483660800000, 10.154658304812745],
        [1483747200000, 9.824278869463066],
        [1483833600000, 10.267836701334787],
        [1483920000000, 10.285955970375603],
        [1484006400000, 10.523650713762722],
        [1484092800000, 9.661693873756436],
        [1484179200000, 9.66715894243992],
        [1484265600000, 9.650805118856487],
        [1484352000000, 9.695262751794939],
        [1484438400000, 9.785923385543578],
        [1484524800000, 9.5212839167112],
        [1484611200000, 10.258458296131915],
        [1484697600000, 10.125537740945934],
        [1484784000000, 10.385306173596472],
        [1484870400000, 10.549960559535752],
        [1484956800000, 10.872633993948424],
        [1485043200000, 10.745356049118469],
        [1485129600000, 10.751024402450716],
        [1485216000000, 10.399087248402381],
        [1485302400000, 10.471671231503688],
        [1485388800000, 10.555255877288236],
        [1485475200000, 10.509979092454431],
        [1485561600000, 10.554962953815345],
        [1485648000000, 10.430442595335876],
        [1485734400000, 10.570411298387052],
        [1485820800000, 10.677298023363218],
        [1485907200000, 10.700989418222473],
        [1485993600000, 10.81046108694813],
        [1486080000000, 10.937685579154774],
        [1486166400000, 11.33331586102519],
        [1486252800000, 11.242238837962214],
        [1486339200000, 11.294016130984236],
        [1486425600000, 11.433167440255266],
        [1486512000000, 11.335153011135779],
        [1486598400000, 10.914963949831499],
        [1486684800000, 11.275640644361795],
        [1486771200000, 11.399125371500645],
        [1486857600000, 11.354164564644952],
        [1486944000000, 11.281947015245585],
        [1487030400000, 12.972145045966517],
        [1487116800000, 12.934716009243267],
        [1487203200000, 12.835968798993127],
        [1487289600000, 12.7034838481457],
        [1487376000000, 12.816285332013342],
        [1487462400000, 12.775445603327524],
        [1487548800000, 12.750223774537398],
        [1487635200000, 12.73614094049859],
        [1487721600000, 12.600296405431427],
        [1487808000000, 13.15516545737877],
        [1487894400000, 13.071871231178674],
        [1487980800000, 13.546938608109645],
        [1488067200000, 14.547676188997867],
        [1488153600000, 15.358690360839047],
        [1488240000000, 15.702781231900772],
        [1488326400000, 17.168029711638418],
        [1488412800000, 18.993581792674195],
        [1488499200000, 19.58574948053123],
        [1488585600000, 18.63867345258356],
        [1488672000000, 19.247364659986754],
        [1488758400000, 19.670705614004156],
        [1488844800000, 18.82733332066123],
        [1488931200000, 16.7497435780077],
        [1489017600000, 17.632128818481693],
        [1489104000000, 19.26359042821057],
        [1489190400000, 21.396413225585537],
        [1489276800000, 23.09884795827443],
        [1489363200000, 28.4460651994335],
        [1489449600000, 28.53666459127697],
        [1489536000000, 34.19736420741617],
        [1489622400000, 39.86185607085268],
        [1489708800000, 44.06729598863206],
        [1489795200000, 32.26798526618718],
        [1489881600000, 44.08879749579081],
        [1489968000000, 41.86369288259116],
        [1490054400000, 42.47845801399063],
        [1490140800000, 41.262612537182946],
        [1490227200000, 43.274929979555615],
        [1490313600000, 50.894791438930966],
        [1490400000000, 50.678966487888445],
        [1490486400000, 50.049454214237386],
        [1490572800000, 49.017113803037155],
        [1490659200000, 50.259542864330534],
        [1490745600000, 52.84379426461343],
        [1490832000000, 52.313175209019796],
        [1490918400000, 49.9646672803426],
        [1491004800000, 50.42578760723248],
        [1491091200000, 48.37846727683558],
        [1491177600000, 43.57923995912754],
        [1491264000000, 41.79779504583054],
        [1491350400000, 45.590295052351756],
        [1491436800000, 42.978525212227865],
        [1491523200000, 42.40137665621101],
        [1491609600000, 44.501284387189116],
        [1491696000000, 44.1907610730727],
        [1491782400000, 43.90534128958704],
        [1491868800000, 43.85889483882994],
        [1491955200000, 46.197883489516954],
        [1492041600000, 49.7503375730287],
        [1492128000000, 47.56205172159332],
        [1492214400000, 49.122793411699824],
        [1492300800000, 48.70871927867354],
        [1492387200000, 48.34641781155764],
        [1492473600000, 50.759170818800456],
        [1492560000000, 48.1594963219902],
        [1492646400000, 50.065253649182445],
        [1492732800000, 48.80173571247641],
        [1492819200000, 49.0798706465032],
        [1492905600000, 50.60871348408776],
        [1492992000000, 51.09741863901202],
        [1493078400000, 50.97375547481341],
        [1493164800000, 54.87817856677162],
        [1493251200000, 63.95735376256868],
        [1493337600000, 73.0109629151518],
        [1493424000000, 70.45153824766224],
        [1493510400000, 79.8025539393512],
        [1493596800000, 76.84617780144701],
        [1493683200000, 77.34604386935915],
        [1493769600000, 80.9294915785314],
        [1493856000000, 94.11226519917764],
        [1493942400000, 91.16038713753753],
        [1494028800000, 96.42767965195394],
        [1494115200000, 89.73967947914684],
        [1494201600000, 87.94812005196805],
        [1494288000000, 87.50244390780135],
        [1494374400000, 88.41058886851773],
        [1494460800000, 88.45664874349296],
        [1494547200000, 88.18109149325619],
        [1494633600000, 89.13893167771396],
        [1494720000000, 90.05504056395782],
        [1494806400000, 90.50413159922428],
        [1494892800000, 87.09148558444505],
        [1494979200000, 88.21316751381906],
        [1495065600000, 96.62584622982801],
        [1495152000000, 122.16278299258484],
        [1495238400000, 123.02820305804211],
        [1495324800000, 148.65732047983943],
        [1495411200000, 153.99006380727735],
        [1495497600000, 171.08002832171815],
        [1495584000000, 190.42581317146121],
        [1495670400000, 176.81781507886726],
        [1495756800000, 162.94448638549966],
        [1495843200000, 159.05524764233746],
        [1495929600000, 172.3406699356334],
        [1496016000000, 196.0785933386944],
        [1496102400000, 230.79837139121355],
        [1496188800000, 231.37212671271118],
        [1496275200000, 221.82807122254022],
        [1496361600000, 224.31670548594502],
        [1496448000000, 225.39759443485244],
        [1496534400000, 247.4275410848339],
        [1496620800000, 248.93012523209245],
        [1496707200000, 266.13970806704395],
        [1496793600000, 256.21892337063565],
        [1496880000000, 260.3415097477362],
        [1496966400000, 280.333547997722],
        [1497052800000, 337.7921754587072],
        [1497139200000, 341.0796489185503],
        [1497225600000, 398.4307850845948],
        [1497312000000, 389.9448221775502],
        [1497398400000, 347.78135772227546],
        [1497484800000, 346.36906994261665],
        [1497571200000, 358.2845168676378],
        [1497657600000, 372.3570110185702],
        [1497744000000, 359.4387121135216],
        [1497830400000, 362.0442891370599],
        [1497916800000, 358.0532310647092],
        [1498003200000, 327.8909356942679],
        [1498089600000, 326.9598139741636],
        [1498176000000, 331.9138051489657],
        [1498262400000, 310.05462840596994],
        [1498348800000, 288.04702626534885],
        [1498435200000, 258.91946273454556],
        [1498521600000, 280.8130146788337],
        [1498608000000, 317.2936774724324],
        [1498694400000, 300.18215256198584],
        [1498780800000, 284.69108328848546],
        [1498867200000, 262.47807335859795],
        [1498953600000, 286.1336070099866],
        [1499040000000, 278.5971953381099],
        [1499126400000, 269.7366305781692],
        [1499212800000, 265.9369454655199],
        [1499299200000, 267.4697236476019],
        [1499385600000, 241.4766205298395],
        [1499472000000, 246.21497097327446],
        [1499558400000, 238.75431849081662],
        [1499644800000, 210.52819727984127],
        [1499731200000, 194.33493649003975],
        [1499817600000, 222.13885617231483],
        [1499904000000, 206.41325042813503],
        [1499990400000, 197.96945236247697],
        [1500076800000, 171.77744094493255],
        [1500163200000, 156.46899162898947],
        [1500249600000, 188.3527773486568],
        [1500336000000, 228.50328003667275],
        [1500422400000, 199.59051553264698],
        [1500508800000, 223.4613483872583],
        [1500595200000, 217.08647395834424],
        [1500681600000, 231.14352894295098],
        [1500768000000, 226.7185992848529],
        [1500854400000, 224.91340709342114],
        [1500940800000, 206.81050478775018],
        [1501027200000, 200.3285558009793],
        [1501113600000, 203.18932178158303],
        [1501200000000, 192.58568390577597],
        [1501286400000, 205.51111919711502],
        [1501372800000, 197.19896347978235],
        [1501459200000, 203.28719634179097],
        [1501545600000, 227.08056705038587],
        [1501632000000, 219.05792926762416],
        [1501718400000, 224.86795657659746],
        [1501804800000, 221.67495953018923],
        [1501891200000, 253.15566612330053],
        [1501977600000, 263.2220861638244],
        [1502064000000, 268.49662903477497],
        [1502150400000, 295.14709831672786],
        [1502236800000, 297.0696048197405],
        [1502323200000, 296.7767240757822],
        [1502409600000, 306.826433219032],
        [1502496000000, 308.625531335255],
        [1502582400000, 297.15980274719976],
        [1502668800000, 296.3972895244633],
        [1502755200000, 288.4507671723357],
        [1502841600000, 300.0393294082391],
        [1502928000000, 300.5703437604035],
        [1503014400000, 296.114635373622],
        [1503100800000, 296.6220895183796],
        [1503187200000, 295.17157732771335],
        [1503273600000, 322.2012201364436],
        [1503360000000, 312.1744705574116],
        [1503446400000, 316.7889202107443],
        [1503532800000, 321.7852984049241],
        [1503619200000, 329.0252809929819],
        [1503705600000, 329.86578262442157],
        [1503792000000, 343.34133712814116],
        [1503878400000, 344.2011314209417],
        [1503964800000, 366.809589912788],
        [1504051200000, 378.90633073469184],
        [1504137600000, 382.9344507545728],
        [1504224000000, 386.3430570009356],
        [1504310400000, 346.3987754868897],
        [1504396800000, 349.92924044114534],
        [1504483200000, 299.7173318855491],
        [1504569600000, 318.9728041158723],
        [1504656000000, 333.20632999273016],
        [1504742400000, 329.4156625176296],
        [1504828800000, 304.47510826802426],
        [1504915200000, 299.6944444705654],
        [1505001600000, 292.7796567856866],
        [1505088000000, 296.4753988432914],
        [1505174400000, 290.58653195906925],
        [1505260800000, 277.33365793127047],
        [1505347200000, 214.3117136334975],
        [1505433600000, 246.9218572871298],
        [1505520000000, 244.80561175288247],
        [1505606400000, 249.66654597502836],
        [1505692800000, 287.96109568877347],
        [1505779200000, 280.4456323420793],
        [1505865600000, 279.4176492108516],
        [1505952000000, 257.465074465559],
        [1506038400000, 263.46977368373445],
        [1506124800000, 283.22215899335055],
        [1506211200000, 280.68104473460187],
        [1506297600000, 288.0857009417575],
        [1506384000000, 283.9294530726591],
        [1506470400000, 302.1518453516756],
        [1506556800000, 294.3138380117381],
        [1506643200000, 288.11459689868053],
        [1506729600000, 297.2689355700251],
        [1506816000000, 299.6748890236153],
        [1506902400000, 295.8400297394862],
        [1506988800000, 292.2107206992577],
        [1507075200000, 293.0357027858311],
        [1507161600000, 293.71934612048636],
        [1507248000000, 307.7089805559562],
        [1507334400000, 309.5982491240284],
        [1507420800000, 307.05813687389934],
        [1507507200000, 295.901045084102],
        [1507593600000, 299.9170113647283],
        [1507680000000, 303.2474665353709],
        [1507766400000, 302.0199976805083],
        [1507852800000, 337.9526134340301],
        [1507939200000, 335.33824482981413],
        [1508025600000, 335.3913549863649],
        [1508112000000, 331.967214731027],
        [1508198400000, 317.898212514702],
        [1508284800000, 314.456554240651],
        [1508371200000, 308.4746839282341],
        [1508457600000, 302.35649692557024],
        [1508544000000, 300.69384596228315],
        [1508630400000, 295.4882479105146],
        [1508716800000, 289.4140251237576],
        [1508803200000, 298.75573430773346],
        [1508889600000, 298.4839668215046],
        [1508976000000, 295.7078546189935],
        [1509062400000, 297.9291296930045],
        [1509148800000, 296.4649529071602],
        [1509235200000, 303.74113040048763],
        [1509321600000, 306.2494615338919],
        [1509408000000, 303.54211702851427],
        [1509494400000, 292.33053872865435],
        [1509580800000, 287.0696687458828],
        [1509667200000, 305.8432726789198],
        [1509753600000, 299.9549103522952],
        [1509840000000, 297.5173231052644],
        [1509926400000, 301.370794552855],
        [1510012800000, 295.23511338265723],
        [1510099200000, 308.9339866357258],
        [1510185600000, 322.32952048358885],
        [1510272000000, 301.3549333527746],
        [1510358400000, 316.2583419604271],
        [1510444800000, 314.1661277072687],
        [1510531200000, 315.34395637188766],
        [1510617600000, 338.937627406501],
        [1510704000000, 334.286932546348],
        [1510790400000, 330.021315646911],
        [1510876800000, 333.351255037181],
        [1510963200000, 348.74721545667],
        [1511049600000, 353.43572964676],
        [1511136000000, 365.786815977961],
        [1511222400000, 361.055822571048],
        [1511308800000, 379.409057274538],
        [1511395200000, 412.557611501513],
        [1511481600000, 475.002014978137],
        [1511568000000, 467.446390045639],
        [1511654400000, 468.998099373249],
        [1511740800000, 484.362624150733],
        [1511827200000, 474.182894748523],
        [1511913600000, 428.345443336342],
        [1512000000000, 454.160981519821],
        [1512086400000, 469.863479570669],
        [1512172800000, 469.857050536754],
        [1512259200000, 461.880481908051],
        [1512345600000, 472.252735495896],
        [1512432000000, 467.016662982671],
        [1512518400000, 448.328222164605],
        [1512604800000, 451.825581860168],
        [1512691200000, 467.881662612901],
        [1512777600000, 482.035518858509],
        [1512864000000, 446.691058078548],
        [1512950400000, 509.000876237775],
        [1513036800000, 661.841332287634],
        [1513123200000, 709.883193572531],
        [1513209600000, 704.621843737326],
        [1513296000000, 693.09706413276],
        [1513382400000, 702.244612799479],
        [1513468800000, 728.700471211578],
        [1513555200000, 798.115404033608],
        [1513641600000, 829.989201766042],
        [1513728000000, 813.403602277568],
        [1513814400000, 812.885159103535],
        [1513900800000, 669.276492289395],
        [1513987200000, 715.719068265809],
        [1514073600000, 686.208308919806],
        [1514160000000, 733.902545910265],
        [1514246400000, 774.110198124999],
        [1514332800000, 764.967062014162],
        [1514419200000, 736.840047017461],
        [1514505600000, 755.343641318154],
        [1514592000000, 736.909191519636],
        [1514678400000, 774.696255457107],
        [1514764800000, 776.080330355968],
        [1514851200000, 876.890258814416],
        [1514937600000, 963.0557506903511],
        [1515024000000, 978.416104183522],
        [1515110400000, 1004.8949722625625],
        [1515196800000, 1052.02336010703],
        [1515283200000, 1137.6549188901813],
        [1515369600000, 1184.92393027798],
        [1515456000000, 1361.4396682787146],
        [1515542400000, 1345.5876017375906],
        [1515628800000, 1181.6514100305303],
        [1515715200000, 1315.6187580839762],
        [1515801600000, 1448.1800861342022],
        [1515888000000, 1410.0002145085193],
        [1515974400000, 1329.6713965043375],
        [1516060800000, 1068.6004106894156],
        [1516147200000, 1022.3652131699663],
        [1516233600000, 1036.072312788025],
        [1516320000000, 1046.8985973958734],
        [1516406400000, 1172.9172062239459],
        [1516492800000, 1053.6332024800395],
        [1516579200000, 1000.3871327788332],
        [1516665600000, 991.665320638703],
        [1516752000000, 1056.8792325682198],
        [1516838400000, 1059.3154960264808],
        [1516924800000, 1048.7989058434846],
        [1517011200000, 1095.7825142892807],
        [1517097600000, 1237.3241758467964],
        [1517184000000, 1169.6342569193446],
        [1517270711070, 1161.9086430994253],
        [1517356800000, 1058.0960529662955],
        [1517443200000, 1106.1381723552724],
        [1517529600000, 1021.2693786534147],
        [1517616000000, 914.6388425021536],
        [1517702400000, 941.8032295185238],
        [1517788800000, 834.8891703333355],
        [1517875200000, 684.3133397266745],
        [1517961600000, 785.7389155049871],
        [1518048323681, 751.8878893661607],
        [1518134400000, 807.8227505154956],
        [1518220800000, 871.3268829432668],
        [1518307200000, 854.3498784217315],
        [1518393600000, 817.2905243571003],
        [1518480000000, 862.7487725902944],
        [1518566400000, 833.7791837295838],
        [1518652800000, 915.0765430188819],
        [1518739200000, 928.2935214045194],
        [1518825600000, 934.6767104527537],
        [1518912000000, 964.2765079107337],
        [1518998400000, 915.0920469360246],
        [1519084800000, 928.2902425457683],
        [1519171200000, 902.9690554385094],
        [1519257600000, 809.1296597083212],
        [1519344000000, 812.4468626177468],
        [1519430400000, 864.6697536212578],
        [1519516800000, 828.7233989886703],
        [1519603200000, 842.6280247519165],
        [1519689600000, 863.6502752378826],
        [1519776000000, 869.2632438249697],
        [1519862400000, 846.3652117960792],
        [1519948800000, 855.6787797305122],
        [1520035200000, 850.4429895810156],
        [1520121600000, 844.2552535175925],
        [1520208000000, 843.7565763098702],
        [1520294400000, 840.1509954642488],
        [1520380800000, 808.4491332246333],
        [1520467200000, 752.5931712474129],
        [1520553600000, 699.1898984866136],
        [1520640000000, 710.8916532666208],
        [1520726400000, 684.934340074043],
        [1520812800000, 713.1953300017516],
        [1520899200000, 695.3142030222126],
        [1520985600000, 684.6415843660401],
        [1521072000000, 608.3931488499501],
        [1521158400000, 612.4435397197692],
        [1521244800000, 607.879487479319],
        [1521331200000, 560.1094330840288],
        [1521417600000, 538.6905900543026],
        [1521504000000, 555.0775482373977],
        [1521590400000, 554.2098259834895],
        [1521676800000, 561.1465342247278],
        [1521763200000, 537.7059724380829],
        [1521849600000, 538.109029163154],
        [1521936000000, 519.4855998398031],
        [1522022400000, 519.3780445915389],
        [1522108800000, 487.5396120553992],
        [1522195200000, 449.05225249207564],
        [1522281600000, 443.9491009578748],
        [1522368000000, 385.32709566886405],
        [1522454400000, 395.32626329061816],
        [1522540800000, 395.79638095782116],
        [1522627200000, 379.1235134110076],
        [1522713600000, 384.8259822881638],
        [1522800000000, 415.7162339703384],
        [1522886400000, 380.28195959120734],
        [1522972800000, 384.46448544618835],
        [1523059200000, 369.9138976693199],
        [1523145600000, 383.44636088273523],
        [1523232000000, 396.98175550697044],
        [1523318400000, 398.77335502107474],
        [1523404800000, 412.6893456266917],
        [1523491200000, 427.3849460078481],
        [1523577600000, 488.60548934434905],
        [1523664000000, 488.99218126708564],
        [1523750400000, 495.11291363236046],
        [1523836800000, 525.5274445398754],
        [1523923200000, 507.11031309582995],
        [1524009600000, 498.46700764950907],
        [1524096000000, 517.3740431959737],
        [1524182400000, 566.3920838512778],
        [1524268800000, 590.27192246768],
        [1524355200000, 600.2596767733208],
        [1524441600000, 617.2024078855728],
        [1524528000000, 636.3671638965957],
        [1524614400000, 705.0276448443027],
        [1524700800000, 613.7267252906634],
        [1524787200000, 657.0577934851548],
        [1524873600000, 641.9936056225328],
        [1524960000000, 672.5969368274542],
        [1525046400000, 680.1204953886593],
        [1525132800000, 662.732735837356],
        [1525219200000, 665.2257861413076],
        [1525305600000, 676.5525639388837],
        [1525392000000, 781.4257804747286],
        [1525478400000, 777.9076470179848],
        [1525564800000, 807.0673291637275],
        [1525651200000, 785.89274351052],
        [1525737600000, 748.8131413938073],
        [1525824000000, 744.3248257193555],
        [1525910400000, 743.6398157399032],
        [1525996800000, 717.4128743633592],
        [1526083200000, 675.2917525022057],
        [1526169600000, 683.1702676131072],
        [1526256000000, 726.8719105255797],
        [1526342400000, 727.8026816427881],
        [1526428800000, 703.1556814584169],
        [1526515200000, 702.4531187046043],
        [1526601600000, 665.6275313370153],
        [1526688000000, 686.2132687257347],
        [1526774400000, 685.8467539789947],
        [1526860800000, 703.8146666492306],
        [1526947200000, 688.9132212295281],
        [1527033600000, 641.1003511423173],
        [1527120000000, 581.2411880572671],
        [1527206400000, 597.848771866914],
        [1527292800000, 582.2793862832264],
        [1527379200000, 580.4834597869499],
        [1527465600000, 565.9260648024518],
        [1527552000000, 511.61588041076885],
        [1527638400000, 561.7406614509548],
        [1527724800000, 555.3491509790109],
        [1527811200000, 571.7271134994469],
        [1527897600000, 573.8335504842785],
        [1527984000000, 583.1316392150303],
        [1528070400000, 609.2946747342367],
        [1528156800000, 586.5759760467295],
        [1528243200000, 600.6782790386933],
        [1528329600000, 598.2160984640701],
        [1528416000000, 595.1261406729993],
        [1528502400000, 590.4981451663734],
        [1528588800000, 581.1212496803587],
        [1528675200000, 522.9980386707371],
        [1528761600000, 529.1308297528115],
        [1528848000000, 495.0763558611063],
        [1528934400000, 474.85911303649493],
        [1529020800000, 516.6441703707438],
        [1529107200000, 485.4328365159148],
        [1529193600000, 493.0678580653148],
        [1529280000000, 491.40027597923427],
        [1529366400000, 512.4288524669856],
        [1529452800000, 532.0039133610575],
        [1529539200000, 529.7630465685196],
        [1529625600000, 520.6671645141439],
        [1529712000000, 463.94082865220423],
        [1529798400000, 472.9772647615346],
        [1529884800000, 455.71843063066746],
        [1529971200000, 458.9258012406738],
        [1530057600000, 430.60178441047594],
        [1530144000000, 437.8765374730084],
        [1530230400000, 420.86212416397933],
        [1530316800000, 432.50722049091496],
        [1530403200000, 449.43004507185094],
        [1530489600000, 447.6805505735615],
        [1530576000000, 472.31165843777023],
        [1530662400000, 459.6987235407423],
        [1530748800000, 463.22220830490755],
        [1530835200000, 466.87948010075564],
        [1530921600000, 467.5901144927255],
        [1531008000000, 481.523823511904],
        [1531094400000, 483.8040307751051],
        [1531180800000, 469.7022293732207],
        [1531267200000, 431.76445446277353],
        [1531353600000, 443.0254304264658],
        [1531440000000, 427.79224436391297],
        [1531526400000, 431.266939712935],
        [1531612800000, 431.78456841825795],
        [1531699200000, 446.60952086822346],
        [1531785600000, 478.20850696097756],
        [1531872000000, 497.1775460849197],
        [1531958400000, 475.9901579076109],
        [1532044800000, 464.78994267592014],
        [1532131200000, 447.2032909319223],
        [1532217600000, 458.0790283587359],
        [1532304000000, 455.5332019326154],
        [1532390400000, 447.92137912561867],
        [1532476800000, 477.2092716797165],
        [1532563200000, 468.73749232171497],
        [1532649600000, 460.8693339260698],
        [1532736000000, 467.9579265520383],
        [1532822400000, 465.8991552657435],
        [1532908800000, 466.41922911559277],
        [1532995200000, 458.080921209812],
        [1533081600000, 432.3501179219696],
        [1533168000000, 417.27540676628155],
        [1533254400000, 410.9251331069581],
        [1533340800000, 417.35539322792266],
        [1533427200000, 406.8839911959127],
        [1533513600000, 409.16493540460897],
        [1533600000000, 404.81719789129863],
        [1533686400000, 377.75090114785144],
        [1533772800000, 353.6461229266765],
        [1533859200000, 363.6851798621928],
        [1533945600000, 332.49243311549486],
        [1534032000000, 317.3144774285816],
        [1534118400000, 317.9561130787788],
        [1534204800000, 284.9586646577589],
        [1534291200000, 277.7460166184284],
        [1534377600000, 281.1411931356025],
        [1534464000000, 286.3202214369417],
        [1534550400000, 314.79325272659554],
        [1534636800000, 293.17699177790365],
        [1534723200000, 299.4351225630472],
        [1534809600000, 271.06155217805997],
        [1534896000000, 280.3747171450871],
        [1534982400000, 269.2483994291051],
        [1535068800000, 275.80838507029057],
        [1535155200000, 281.7346420014112],
        [1535241600000, 277.96671056981506],
        [1535328000000, 273.50341418810274],
        [1535414400000, 286.9611037603499],
        [1535500800000, 296.29612763685776],
        [1535587200000, 288.5053605724647],
        [1535673600000, 285.1028726269231],
        [1535760000000, 282.22317870221497],
        [1535846400000, 295.6461617616964],
        [1535932800000, 294.5856884801363],
        [1536019200000, 288.85282563770716],
        [1536105600000, 285.4857566471215],
        [1536192000000, 232.59576871683151],
        [1536278400000, 229.37495804862965],
        [1536364800000, 215.43223745188737],
        [1536451200000, 196.8653868522827],
        [1536537600000, 195.87733831990175],
        [1536624000000, 196.3615326706261],
        [1536710400000, 184.08223182704117],
        [1536796800000, 182.94557625329006],
        [1536883200000, 211.82672748652598],
        [1536969600000, 211.3835617204549],
        [1537056000000, 223.05308230390335],
        [1537142400000, 220.1415794346937],
        [1537228800000, 196.5766097002653],
        [1537315200000, 210.15069647518163],
        [1537401600000, 209.1425267776257],
        [1537488000000, 224.38097767293752],
        [1537574400000, 247.41001789452824],
        [1537660800000, 241.8437317592413],
        [1537747200000, 244.7847954629194],
        [1537833600000, 228.34091150907454],
        [1537920000000, 219.50434375691938],
        [1538006400000, 214.9793802543281],
        [1538092800000, 229.22195926025518],
        [1538179200000, 222.229239761749],
        [1538265600000, 231.73587329828854],
        [1538352000000, 232.78138524562493],
        [1538438400000, 231.1436846227436],
        [1538524800000, 225.79485382934473],
        [1538611200000, 220.47446029228064],
        [1538697600000, 222.19584779064576],
        [1538784000000, 228.03607024893918],
        [1538870400000, 225.06880731767052],
        [1538956800000, 225.65834864130284],
        [1539043200000, 229.52461502629828],
        [1539129600000, 227.9689077917784],
        [1539216000000, 225.66201617746165],
        [1539302400000, 192.0010841477226],
        [1539388800000, 197.06801594741614],
        [1539475200000, 201.05349968429928],
        [1539561600000, 196.3186984116144],
        [1539648000000, 212.2030694387269],
        [1539734400000, 212.4442865474299],
        [1539820800000, 211.08269302502703],
        [1539907200000, 204.31304892929978],
        [1539993600000, 203.97626253061992],
        [1540080000000, 206.88209866230514],
        [1540166400000, 206.30982679591034],
        [1540252800000, 205.03775490876436],
        [1540339200000, 204.08394558056767],
        [1540425600000, 203.52219877380867],
        [1540512000000, 202.35302275695466],
        [1540598400000, 203.38496856526396],
        [1540684800000, 203.18739632422128],
        [1540771200000, 204.7223407022085],
        [1540857600000, 195.7514045211852],
        [1540944000000, 197.44844958499],
        [1541030400000, 198.6390488336609],
        [1541116800000, 199.1226070207313],
        [1541203200000, 202.28275716666218],
        [1541289600000, 200.13622776255878],
        [1541376000000, 206.8144295995958],
        [1541462400000, 209.10887661978714],
        [1541548800000, 219.16088708430863],
        [1541635200000, 219.0157747507676],
        [1541721600000, 213.40763439234544],
        [1541808000000, 210.79061959752252],
        [1541894400000, 212.9658278769949],
        [1541980800000, 212.4733247441558],
        [1542067200000, 211.65690924875202],
        [1542153600000, 208.58229647385883],
        [1542240000000, 184.02255937246562],
        [1542326400000, 182.448242594831],
        [1542412800000, 176.02240566453227],
        [1542499200000, 175.6674163561008],
        [1542585600000, 177.72868960741215],
        [1542672000000, 150.39116276572497],
        [1542758400000, 131.476679735108],
        [1542844800000, 137.1730431934016],
        [1542931200000, 126.06638760845392],
        [1543017600000, 124.15029635415753],
        [1543104000000, 114.28793125942474],
        [1543190400000, 117.52527750634898],
        [1543276800000, 109.43098854688948],
        [1543363200000, 110.43494358866081],
        [1543449600000, 122.44642499081692],
        [1543536000000, 117.70538590366436],
        [1543622400000, 113.4028831522645],
        [1543708800000, 118.10075903673732],
        [1543795200000, 115.94428273243844],
        [1543881600000, 108.67684762425776],
        [1543968000000, 109.85191500097886],
        [1544054400000, 102.51104175852288],
        [1544140800000, 91.10115845588969],
        [1544227200000, 92.96795017189604],
        [1544313600000, 90.67825609691094],
        [1544400000000, 93.53012682199429],
        [1544486400000, 89.85371730794834],
        [1544572800000, 87.85041070425926],
        [1544659200000, 89.37790161622951],
        [1544745600000, 85.95872706359158],
        [1544832000000, 83.78596235654511],
        [1544918400000, 83.8335821320138],
        [1545004800000, 84.46584639727314],
        [1545091200000, 94.07306838255042],
        [1545177600000, 100.05002539321067],
        [1545264000000, 99.49155365807758],
        [1545350400000, 114.42918370038834],
        [1545436800000, 107.85318110517642],
        [1545523200000, 115.04582181530175],
        [1545609600000, 129.20650341412852],
        [1545696000000, 138.95781833191495],
        [1545782400000, 127.63707252348217],
        [1545868800000, 129.51137399263007],
        [1545955200000, 114.1013635506553],
        [1546041600000, 134.01993952035144],
        [1546128000000, 138.75426661197633],
        [1546214400000, 137.6316929791457],
        [1546300800000, 131.45872465603802],
        [1546387200000, 138.14480220535194],
        [1546473600000, 152.86045271321305],
        [1546560000000, 146.7305994341217],
        [1546646400000, 153.0565669307744],
        [1546732800000, 153.65187149457],
        [1546819200000, 154.69930839905126],
        [1546905600000, 149.89869762072908],
        [1546992000000, 148.14786553107623],
        [1547078400000, 148.7388798515161],
        [1547164800000, 126.62475249770274],
        [1547251200000, 124.6267970725248],
        [1547337600000, 123.75936261029351],
        [1547424000000, 114.76604584662175],
        [1547510400000, 127.40527329081972],
        [1547596800000, 119.81437324652917],
        [1547683200000, 122.24553850486993],
        [1547769600000, 121.92726666997898],
        [1547856000000, 119.1707391686563],
        [1547942400000, 122.92817578942176],
        [1548028800000, 117.6078729013454],
        [1548115200000, 115.82686433322588],
        [1548201600000, 117.81718880676009],
        [1548288000000, 116.41601969858114],
        [1548374400000, 116.24543567436463],
        [1548460800000, 115.14990605895021],
        [1548547200000, 115.33716687142687],
        [1548633600000, 112.51348924314217],
        [1548720000000, 105.35239189764611],
        [1548806400000, 104.59784628074063],
        [1548892800000, 108.27309466982854],
        [1548979200000, 106.33599581366398],
        [1549065600000, 107.00955827242754],
        [1549152000000, 108.77817692653159],
        [1549238400000, 107.19999697185268],
        [1549324800000, 107.61824539435413],
        [1549411200000, 107.31703853339418],
        [1549497600000, 104.62362623241391],
        [1549584000000, 104.26536439234141],
        [1549670400000, 119.33360463375858],
        [1549756800000, 119.48955517293045],
        [1549843200000, 124.88963479029995],
        [1549929600000, 120.97026174727318],
        [1550016000000, 122.54208894762355],
        [1550102400000, 122.09412965721424],
        [1550188800000, 120.59217399552682],
        [1550275200000, 121.57227847601175],
        [1550361600000, 122.93860835926709],
        [1550448000000, 133.88146961685993],
        [1550534400000, 145.32602670603126],
        [1550620800000, 143.65523417640586],
        [1550707200000, 148.8684346762975],
        [1550793600000, 145.2958232437471],
        [1550880000000, 148.04165031619752],
        [1550966400000, 157.67559560191677],
        [1551052800000, 134.31602869596247],
        [1551139200000, 137.8218665264574],
        [1551225600000, 135.99783785109707],
        [1551312000000, 134.84981713392082],
        [1551398400000, 135.4906837644075],
        [1551484800000, 134.95470877409352],
        [1551571200000, 132.40742658761687],
        [1551657600000, 130.5403477406027],
        [1551744000000, 125.6240824924571],
        [1551830400000, 136.5502476885833],
        [1551916800000, 137.32665152792868],
        [1552003200000, 136.78614228385894],
        [1552089600000, 133.5416931933968],
        [1552176000000, 137.1960003042231],
        [1552262400000, 135.62080210154824],
        [1552348800000, 132.38753404384025],
        [1552435200000, 133.28742563523164],
        [1552521600000, 131.6683444532404],
        [1552608000000, 132.14623792639355],
        [1552694400000, 136.4478648131974],
        [1552780800000, 141.08804416286097],
        [1552867200000, 138.55063028189403],
        [1552953600000, 137.65794893090973],
        [1553040000000, 138.5225166550466],
        [1553126400000, 139.0759731796369],
        [1553212800000, 135.05601072736965],
        [1553299200000, 136.0995360201361],
        [1553385600000, 137.19806866408575],
        [1553472000000, 136.05748300079634],
        [1553558400000, 133.8175199792062],
        [1553644800000, 134.02990438257248],
        [1553731200000, 139.63660664431762],
        [1553817600000, 137.81198852309657],
        [1553904000000, 143.14644309796884],
        [1553990400000, 142.28965262894044],
        [1554076800000, 141.586389044902],
        [1554163200000, 141.51455520152766],
        [1554249600000, 164.05054289558294],
        [1554336000000, 160.43283831978377],
        [1554422400000, 157.41222580794496],
        [1554508800000, 165.1385234713877],
        [1554595200000, 165.32515186458005],
        [1554681600000, 174.09052297795662],
        [1554768000000, 180.01192355336678],
        [1554854400000, 175.2941224274854],
        [1554940800000, 177.3253178087243],
        [1555027200000, 165.03710682659573],
        [1555113600000, 164.57194837218958],
        [1555200000000, 163.97160033492332],
        [1555286400000, 167.61800117879454],
        [1555372800000, 160.53249078494966],
        [1555459200000, 166.4845378681717],
        [1555545600000, 166.3272646030099],
        [1555632000000, 173.41362781077277],
        [1555718400000, 173.2282621688014],
        [1555804800000, 173.16628767058037],
        [1555891200000, 169.98788930945395],
        [1555977600000, 171.0434707895899],
        [1556064000000, 170.91296418483168],
        [1556150400000, 165.66966005027155],
        [1556236800000, 153.9304406219976],
        [1556323200000, 157.3757012457437],
        [1556409600000, 158.28919434200932],
        [1556496000000, 156.5951891487867],
        [1556582400000, 154.08028243009934],
        [1556668800000, 160.74469184721738],
        [1556755200000, 159.48931440238658],
        [1556841600000, 160.95718796104512],
        [1556928000000, 166.63211526803485],
        [1557014400000, 162.83631690744346],
        [1557100800000, 162.37013497277283],
        [1557187200000, 172.8283284518378],
        [1557273600000, 170.09589748059332],
        [1557360000000, 170.11063311538186],
        [1557446400000, 170.57549417946805],
        [1557532800000, 172.68209089135948],
        [1557619200000, 196.6901677286205],
        [1557705600000, 187.2720183708742],
        [1557792000000, 196.0761130134244],
        [1557878400000, 218.28460968764938],
        [1557964800000, 248.74500426984366],
        [1558051200000, 264.90012138907406],
        [1558137600000, 243.57049917993308],
        [1558224000000, 235.32720134101993],
        [1558310400000, 260.59698403060327],
        [1558396800000, 251.9614994995826],
        [1558483200000, 256.0830401834705],
        [1558569600000, 244.54946301361036],
        [1558656000000, 244.6460957973966],
        [1558742400000, 248.54526290535333],
        [1558828800000, 250.82036469745617],
        [1558915200000, 266.12218207843597],
        [1559001600000, 273.40508817754096],
        [1559088000000, 271.227867157993],
        [1559174400000, 268.7857564563058],
        [1559260800000, 255.37736314606394],
        [1559347200000, 268.12603920482644],
        [1559433600000, 264.72732428744166],
        [1559520000000, 269.9388294795906],
        [1559606400000, 251.75257532903564],
        [1559692800000, 240.23943365820054],
        [1559779200000, 246.82656909017393],
        [1559865600000, 249.9679117723965],
        [1559952000000, 250.63689323005363],
        [1560038400000, 244.62980571926562],
        [1560124800000, 232.04158416760484],
        [1560211200000, 247.25227622797598],
        [1560297600000, 245.08937853941526],
        [1560384000000, 260.5666340627198],
        [1560470400000, 255.1204266417473],
        [1560556800000, 263.92853843827874],
        [1560643200000, 268.1121928964469],
        [1560729600000, 269.0716935274996],
        [1560816000000, 273.12368845247914],
        [1560902400000, 264.78793107969506],
        [1560988800000, 269.1527997781388],
        [1561075200000, 272.334690986303],
        [1561161600000, 294.47562296593696],
        [1561248000000, 310.65499867231955],
        [1561334400000, 307.9472102635873],
        [1561420800000, 311.06822902414945],
        [1561507200000, 318.635944657736],
        [1561593600000, 337.3409578098138],
        [1561680000000, 295.8416528876357],
        [1561766400000, 309.949031472406],
        [1561852800000, 321.43279903776084],
        [1561939200000, 292.83887601724456],
        [1562025600000, 295.2558980709613],
        [1562112000000, 290.9596721071729],
        [1562198400000, 304.1705424011823],
        [1562284800000, 285.2194708797813],
        [1562371200000, 287.98591058443674],
        [1562457600000, 289.57713834106636],
        [1562544000000, 305.33625290070216],
        [1562630400000, 312.4663688290414],
        [1562716800000, 307.7796837520068],
        [1562803200000, 290.009967157574],
        [1562889600000, 268.6352130477999],
        [1562976000000, 275.80920843487644],
        [1563062400000, 270.314761247692],
        [1563148800000, 227.12207268042678],
        [1563235200000, 230.18019411198864],
        [1563321600000, 199.94052185387875],
        [1563408000000, 210.83853712737297],
        [1563494400000, 226.3035342868679],
        [1563580800000, 221.11374095039795],
        [1563667200000, 228.96480408491627],
        [1563753600000, 226.15340772656594],
        [1563840000000, 217.65256165832335],
        [1563926400000, 213.5021516080946],
        [1564012800000, 217.19777774847861],
        [1564099200000, 219.48779097276912],
        [1564185600000, 219.58243262777626],
        [1564272000000, 206.531772895918],
        [1564358400000, 211.229753661743],
        [1564444800000, 210.82351650905207],
        [1564531200000, 209.64626074112806],
        [1564617600000, 218.61308553620958],
        [1564704000000, 216.630420657716],
        [1564790400000, 217.79546207706508],
        [1564876800000, 221.41611875255478],
        [1564963200000, 221.98815294300547],
        [1565049600000, 232.82368350463972],
        [1565136000000, 227.02071107575],
        [1565222400000, 226.4011931773696],
        [1565308800000, 221.99588620712112],
        [1565395200000, 210.15361895773407],
        [1565481600000, 206.50462546838185],
        [1565568000000, 216.4855805517722],
        [1565654400000, 211.11314126304725],
        [1565740800000, 209.26573714761406],
        [1565827200000, 185.65530058955875],
        [1565913600000, 188.15278116773473],
        [1566000000000, 185.15324355177958],
        [1566086400000, 185.76004109383433],
        [1566172800000, 194.35736761658467],
        [1566259200000, 202.95975075968417],
        [1566345600000, 196.27274728566928],
        [1566432000000, 187.01618910896025],
        [1566518400000, 191.52119596772542],
        [1566604800000, 194.2093873568753],
        [1566691200000, 190.55575089343063],
        [1566777600000, 186.32236897942659],
        [1566864000000, 188.37725745413954],
        [1566950400000, 186.97068487781738],
        [1567036800000, 173.02703573027782],
        [1567123200000, 168.79916502828434],
        [1567209600000, 168.6708883739598],
        [1567296000000, 172.07296929356409],
        [1567382400000, 170.72754514461607],
        [1567468800000, 177.71647895327314],
        [1567555200000, 178.82647784328285],
        [1567641600000, 174.77627554812665],
        [1567728000000, 174.1938100520476],
        [1567814400000, 169.42286694793444],
        [1567900800000, 177.85258889110943],
        [1567987200000, 181.4839320522675],
        [1568073600000, 181.01465623196765],
        [1568160000000, 180.1493915298145],
        [1568246400000, 178.08294980618456],
        [1568332800000, 181.010836085851],
        [1568419200000, 181.2119450035023],
        [1568505600000, 188.30568158376607],
        [1568592000000, 189.25504633546134],
        [1568678400000, 197.60691638393752],
        [1568764800000, 209.21116304181604],
        [1568851200000, 210.24907372866744],
        [1568937600000, 221.74292599489385],
        [1569024000000, 217.95344382394939],
        [1569110400000, 215.34523960456264],
        [1569196800000, 211.61753840452246],
        [1569283200000, 201.00337752581868],
        [1569369600000, 168.67357964603033],
        [1569456000000, 170.28886769224317],
        [1569542400000, 166.21680322909975],
        [1569628800000, 173.90494900595044],
        [1569715200000, 174.07970541243265],
        [1569801600000, 169.6894747522977],
        [1569888000000, 179.75716197925505],
        [1569974400000, 180.63244082835516],
        [1570060800000, 180.57893058773016],
        [1570147200000, 176.85852555342686],
        [1570233600000, 175.86315365350248],
        [1570320000000, 176.2772565733896],
        [1570406400000, 170.44710628243018],
        [1570492800000, 180.14424760253544],
        [1570579200000, 180.78979326727546],
        [1570665600000, 193.33993781390976],
        [1570752000000, 191.04108656991255],
        [1570838400000, 180.55244905183102],
        [1570924800000, 179.94653567610214],
        [1571011200000, 181.2872472944888],
        [1571097600000, 186.61606753146776],
        [1571184000000, 180.74563079187547],
        [1571270400000, 175.05406935469742],
        [1571356800000, 177.4769186344818],
        [1571443200000, 172.88626058117322],
        [1571529600000, 171.85241043187662],
        [1571616000000, 175.21210857661558],
        [1571702400000, 174.35449608806442],
        [1571788800000, 171.4265472158501],
        [1571875200000, 161.5840435929357],
        [1571961600000, 160.67839136074096],
        [1572048000000, 181.45319156222183],
        [1572134400000, 179.53527008259897],
        [1572220800000, 183.58089983827585],
        [1572307200000, 181.86179987269554],
        [1572393600000, 190.50596091786502],
        [1572480000000, 183.13375148673262],
        [1572566400000, 182.75570124695298],
        [1572652800000, 183.064191451962],
        [1572739200000, 183.21568174708486],
        [1572825600000, 181.8244399622803],
        [1572912000000, 185.76433086001398],
        [1572998400000, 189.16575518025138],
        [1573084800000, 191.3004004540056],
        [1573171200000, 186.81653195658674],
        [1573257600000, 183.6736227613274],
        [1573344000000, 184.95814543107952],
        [1573430400000, 189.04498222352015],
        [1573516800000, 184.839873654298],
        [1573603200000, 186.8659398462863],
        [1573689600000, 187.84288849379362],
        [1573776000000, 184.78830689468228],
        [1573862400000, 180.16871140792335],
        [1573948800000, 182.63065416042576],
        [1574035200000, 183.9879638750186],
        [1574121600000, 178.3855993731378],
        [1574208000000, 175.8204845763924],
        [1574294400000, 174.80445727797053],
        [1574380800000, 161.15147727923085],
        [1574467200000, 149.92569206419535],
        [1574553600000, 151.96608493452732],
        [1574640000000, 140.24681084120735],
        [1574726400000, 146.34618694453036],
        [1574812800000, 147.57506848968612],
        [1574899200000, 152.615068348219],
        [1574985600000, 150.91034572010813],
        [1575072000000, 154.25530586508248],
        [1575158400000, 151.73585404476006],
        [1575244800000, 151.02315688624967],
        [1575331200000, 148.86726798663648],
        [1575417600000, 147.12795176955626],
        [1575504000000, 145.40170953026762],
        [1575590400000, 147.79749210977155],
        [1575676800000, 148.60569815279229],
        [1575763200000, 147.1457864691915],
        [1575849600000, 150.2924488050236],
        [1575936000000, 147.42926132729735],
        [1576022400000, 145.56867918491014],
        [1576108800000, 143.12297867633916],
        [1576195200000, 145.02592545573296],
        [1576281600000, 144.6187808332106],
        [1576368000000, 141.84486515951662],
        [1576454400000, 142.21086918955265],
        [1576540800000, 132.72274321129302],
        [1576627200000, 121.770107290006],
        [1576713600000, 132.74136111736684],
        [1576800000000, 128.0791538700726],
        [1576886400000, 128.2701941791178],
        [1576972800000, 126.93287325611249],
        [1577059200000, 132.17746965077103],
        [1577145600000, 128.16997170630987],
        [1577232000000, 127.72559890360142],
        [1577318400000, 125.01870585042732],
        [1577404800000, 125.61458431453704],
        [1577491200000, 126.32718018933488],
        [1577577600000, 128.0669874384903],
        [1577664000000, 134.3236471998861],
        [1577750400000, 131.4621363838881],
        [1577836800000, 129.18638529253852],
        [1577923200000, 130.4846850311141],
        [1578009600000, 127.04525801179804],
        [1578096000000, 133.70264861844188],
        [1578182400000, 134.1368826978575],
        [1578268800000, 135.00571364233375],
        [1578355200000, 143.80639795566535],
        [1578441600000, 143.019432264986],
        [1578528000000, 140.27393527388705],
        [1578614400000, 137.86055928182662],
        [1578700800000, 144.6047743464792],
        [1578787200000, 142.18233327497873],
        [1578873600000, 145.42287633530452],
        [1578960000000, 143.58796905036286],
        [1579046400000, 165.99143854583525],
        [1579132800000, 166.2530969048888],
        [1579219200000, 163.80590111423027],
        [1579305600000, 171.15621787671842],
        [1579392000000, 174.23837318258896],
        [1579478400000, 166.6271059505621],
        [1579564800000, 166.93852805776982],
        [1579651200000, 169.27993585839258],
        [1579737600000, 167.8265296405669],
        [1579824000000, 162.51937110634034],
        [1579910400000, 162.40947975163584],
        [1579996800000, 160.6736108617405],
        [1580083200000, 167.6474095857562],
        [1580169600000, 169.7364357354222],
        [1580256000000, 175.19000305345378],
        [1580342400000, 173.70688823620796],
        [1580428800000, 184.72621843141044],
        [1580515200000, 179.22910385240368],
        [1580601600000, 183.33693755210257],
        [1580688000000, 188.55064179431113],
        [1580774400000, 189.86176346298166],
        [1580860800000, 188.84220736948276],
        [1580947200000, 203.8593183747095],
        [1581033600000, 212.73341421352782],
        [1581120000000, 223.27631974791998],
        [1581206400000, 223.30077697426225],
        [1581292800000, 228.29226083217347],
        [1581379200000, 224.14699652028605],
        [1581465600000, 236.78534852972567],
        [1581552000000, 264.03276824299627],
        [1581638400000, 267.6704445042514],
        [1581724800000, 284.2318914741183],
        [1581811200000, 263.8999463915962],
        [1581897600000, 262.1562443168135],
        [1581984000000, 267.9356656159883],
        [1582070400000, 281.9457403055278],
        [1582156800000, 259.1838037480287],
        [1582243200000, 257.9883953048601],
        [1582329600000, 265.1644591338591],
        [1582416000000, 260.99654013152536],
        [1582502400000, 274.6312157785517],
        [1582588800000, 265.2421912203732],
        [1582675200000, 248.31738139395102],
        [1582761600000, 224.81050829744677],
        [1582848000000, 225.42971511683754],
        [1582934400000, 227.70528448376498],
        [1583020800000, 218.34835692985286],
        [1583107200000, 218.93979697447656],
        [1583193600000, 231.67944604592213],
        [1583280000000, 223.96011601810258],
        [1583366400000, 224.13487737180202],
        [1583452800000, 228.0809351016884],
        [1583539200000, 244.23846951978683],
        [1583625600000, 237.38079020191347],
        [1583712000000, 198.81964228768578],
        [1583798400000, 200.84687148188746],
        [1583884800000, 200.74903854851766],
        [1583971200000, 194.21794124025206],
        [1584057600000, 110.5978978308351],
        [1584144000000, 132.57285770180832],
        [1584230400000, 123.030844108903],
        [1584316800000, 124.60342553062202],
        [1584403200000, 110.99159845334059],
        [1584489600000, 117.21704138518466],
        [1584576000000, 117.7004246469663],
        [1584662400000, 136.81187578973578],
        [1584748800000, 131.96469397290025],
        [1584835200000, 131.94321845316688],
        [1584921600000, 122.5205987665882],
        [1585008000000, 135.58865798037067],
        [1585094400000, 138.77657127268222],
        [1585180800000, 136.23392142176775],
        [1585267200000, 138.76693759902926],
        [1585353600000, 130.2865618875773],
        [1585440000000, 131.42405214081145],
        [1585526400000, 125.31887451568916],
        [1585612800000, 132.36373895414985],
        [1585699200000, 133.2364468438201],
        [1585785600000, 136.21638557312852],
        [1585872000000, 141.4537053523866],
        [1585958400000, 141.26154326644877],
        [1586044800000, 144.200802681986],
        [1586131200000, 142.8508834648482],
        [1586217600000, 169.85504979336824],
        [1586304000000, 164.5160197107307],
        [1586390400000, 172.80356826747519],
        [1586476800000, 170.09510352387568],
        [1586563200000, 157.74015800780134],
        [1586649600000, 158.32787794181905],
        [1586736000000, 158.8638257195104],
        [1586822400000, 156.70135879140688],
        [1586908800000, 158.26715118298955],
        [1586995200000, 153.2228636800284],
        [1587081600000, 171.7759907508837],
        [1587168000000, 170.445890477055],
        [1587254400000, 187.14354411560592],
        [1587340800000, 180.05859654051866],
        [1587427200000, 170.70405868653958],
        [1587513600000, 170.45154288047368],
        [1587600000000, 182.2767576620704],
        [1587686400000, 184.59370248021838],
        [1587772800000, 187.34336861419638],
        [1587859200000, 194.11423682570447],
        [1587945600000, 197.22990444623989],
        [1588032000000, 196.46262535084685],
        [1588118400000, 197.15473156634306],
        [1588204800000, 215.54817266567386],
        [1588291200000, 205.55600541063674],
        [1588377600000, 211.96829534904975],
        [1588464000000, 213.94301121407548],
        [1588550400000, 210.02470947464775],
        [1588636800000, 206.8325342346069],
        [1588723200000, 205.22517973985205],
        [1588809600000, 200.25479450009442],
        [1588896000000, 212.29247620086525],
        [1588982400000, 211.68904835113835],
        [1589068800000, 210.4318181376063],
        [1589155200000, 188.13722615896586],
        [1589241600000, 185.67471809740294],
        [1589328000000, 189.30578181851274],
        [1589414400000, 199.36937254304945],
        [1589500800000, 203.21124699378416],
        [1589587200000, 194.27309778153102],
        [1589673600000, 200.58339090310048],
        [1589760000000, 206.76889403683904],
        [1589846400000, 214.35764164526782],
        [1589932800000, 213.68672021176667],
        [1590019200000, 209.9207882888633],
        [1590105600000, 198.9175157507187],
        [1590192000000, 206.22772963994552],
        [1590278400000, 206.4112069583191],
        [1590364800000, 200.69072858230618],
        [1590451200000, 203.81011834234977],
        [1590537600000, 200.96361540675866],
        [1590624000000, 207.84589389085414],
        [1590710400000, 219.59156405880168],
        [1590796800000, 220.49116734603788],
        [1590883200000, 242.70977026782677],
        [1590969600000, 232.33100303896677],
        [1591056000000, 248.04456658448655],
        [1591142400000, 237.26532012867918],
        [1591228800000, 244.1253366100756],
        [1591315200000, 243.70782131595863],
        [1591401600000, 240.24696898765103],
        [1591488000000, 242.02164036721499],
        [1591574400000, 244.22674439497985],
        [1591660800000, 246.21278326044262],
        [1591747200000, 243.8495266518988],
        [1591833600000, 247.5419715295243],
        [1591920000000, 232.00078536182022],
        [1592006400000, 237.59341087744238],
        [1592092800000, 238.29362910089247],
        [1592179200000, 232.11252047744935],
        [1592265600000, 231.0170115525279],
        [1592352000000, 235.28847948297147],
        [1592438400000, 233.8636184621818],
        [1592524800000, 231.68485732135008],
        [1592611200000, 228.9592128032193],
        [1592697600000, 228.8691487972198],
        [1592784000000, 227.79190590968685],
        [1592870400000, 243.36470999245498],
        [1592956800000, 243.07948223903702],
        [1593043200000, 234.5649846444734],
        [1593129600000, 232.64870349961575],
        [1593216000000, 229.6365260272679],
        [1593302400000, 221.0260852973422],
        [1593388800000, 225.12294616525017],
        [1593475200000, 228.20576377381224],
        [1593561600000, 225.59281496891896],
        [1593648000000, 230.66194359094902],
        [1593734400000, 226.57442434030435],
        [1593820800000, 225.1764054804081],
        [1593907200000, 229.09471646218861],
        [1593993600000, 227.8993750381917],
        [1594080000000, 241.52685187431663],
        [1594166400000, 238.96448054067793],
        [1594252800000, 246.85900786802029],
        [1594339200000, 241.97512857897888],
        [1594425600000, 240.95495378293265],
        [1594512000000, 239.10202554671358],
        [1594598400000, 241.7715226007061],
        [1594684800000, 239.63878681174097],
        [1594771200000, 240.25147066847245],
        [1594857600000, 238.66359720631723],
        [1594944000000, 233.837074562722],
        [1595030400000, 232.78147743826054],
        [1595116800000, 235.6811447764246],
        [1595203200000, 238.89523167867932],
        [1595289600000, 236.06009844538684],
        [1595376000000, 245.23373502634252],
        [1595462400000, 263.7624639660021],
        [1595548800000, 274.7236202591814],
        [1595635200000, 279.42705624758224],
        [1595721600000, 305.0381394111965],
        [1595808000000, 310.51799095173624],
        [1595894400000, 320.47619130968184],
        [1595980800000, 316.4067660520556],
        [1596067200000, 317.5390729161191],
        [1596153600000, 334.7419220365616],
        [1596240000000, 346.00350607288686],
        [1596326400000, 387.94242117124685],
        [1596412800000, 370.7323024312388],
        [1596499200000, 386.24744771780877],
        [1596585600000, 389.3830083498991],
        [1596672000000, 400.18436250317995],
        [1596758400000, 394.5417454611831],
        [1596844800000, 379.1351558498163],
        [1596931200000, 391.47843864211467],
        [1597017600000, 389.62293111291143],
        [1597104000000, 395.20991365718925],
        [1597190400000, 379.17476395275435],
        [1597276800000, 387.50053082397886],
        [1597363200000, 427.49026536796873],
        [1597449600000, 438.7625874702974],
        [1597536000000, 432.6546652389318],
        [1597622400000, 433.43186093211244],
        [1597708800000, 430.03250508972275],
        [1597795200000, 422.10665286599425],
        [1597881600000, 406.5744691821068],
        [1597968000000, 416.46977582279794],
        [1598054400000, 386.8238914189483],
        [1598140800000, 395.88501828908585],
        [1598227200000, 390.7891350477376],
        [1598313600000, 408.2691456317229],
        [1598400000000, 383.66214311829924],
        [1598486400000, 385.75249987492117],
        [1598572800000, 381.8376513210842],
        [1598659200000, 395.1382132386204],
        [1598745600000, 399.374543834064],
        [1598832000000, 428.2956791635218],
        [1598918400000, 435.6930232991007],
        [1599004800000, 475.6846642626859],
        [1599091200000, 439.35376021531494],
        [1599177600000, 383.6915311779978],
        [1599264000000, 387.9040290939166],
        [1599350400000, 334.46859301354317],
        [1599436800000, 352.99300214607035],
        [1599523200000, 351.8778602923998],
        [1599609600000, 337.84505193506857],
        [1599696000000, 351.2322670832359],
        [1599782400000, 367.63892881000675],
        [1599868800000, 373.9149059897517],
        [1599955200000, 387.72351629002264],
        [1600041600000, 365.695506306262],
        [1600128000000, 376.394561113407],
        [1600214400000, 364.6900450860686],
        [1600300800000, 365.13055081164816],
        [1600387200000, 388.7974562319938],
        [1600473600000, 383.17286599152135],
        [1600560000000, 385.4721234936851],
        [1600646400000, 370.9678951624775],
        [1600732800000, 341.27713087183076],
        [1600819200000, 343.9175471009491],
        [1600905600000, 321.0774351739628],
        [1600992000000, 348.8210199785493],
        [1601078400000, 351.3315670221176],
        [1601164800000, 354.2615819143104],
        [1601251200000, 357.0664364067552],
        [1601337600000, 354.4295835375506],
        [1601424000000, 359.4260895383702],
        [1601510400000, 359.59898475085777],
        [1601596800000, 353.1978481381707],
        [1601683200000, 345.9125682279143],
        [1601769600000, 345.99246319342933],
        [1601856000000, 352.4588065039611],
        [1601942400000, 353.6044273109067],
        [1602028800000, 340.6160218128092],
        [1602115200000, 341.4437964782809],
        [1602201600000, 350.0556765681245],
        [1602288000000, 365.33944481939363],
        [1602374400000, 370.4718109734285],
        [1602460800000, 374.4970150925736],
        [1602547200000, 387.25555835657167],
        [1602633600000, 381.16877389892255],
        [1602720000000, 379.21025376092774],
        [1602806400000, 377.15868107868175],
        [1602892800000, 365.9906423831468],
        [1602979200000, 368.6504703636898],
        [1603065600000, 378.0493653237596],
        [1603152000000, 379.6394773321801],
        [1603238400000, 368.2270142350454],
        [1603324800000, 391.16674217757225],
        [1603411200000, 413.2018903361906],
        [1603497600000, 408.88948864371923],
        [1603584000000, 411.95921775305027],
        [1603670400000, 406.157094148215],
        [1603756800000, 393.38290839224464],
        [1603843200000, 403.529837833562],
        [1603929600000, 388.87733988207765],
        [1604016000000, 386.4460129872924],
        [1604102400000, 382.9007710380906],
        [1604188800000, 385.8445887975079],
        [1604275200000, 394.9363420491719],
        [1604361600000, 383.8498034220404],
        [1604448000000, 387.616373722075],
        [1604534400000, 401.7330968324722],
        [1604620800000, 415.9277704309719],
        [1604707200000, 454.6515430984697],
        [1604793600000, 435.4183540833094],
        [1604880000000, 455.3583669721691],
        [1604966400000, 445.0512933420698],
        [1605052800000, 449.8161845012962],
        [1605139200000, 463.18087172032307],
        [1605225600000, 462.2056195794902],
        [1605312000000, 475.9690936576015],
        [1605398400000, 462.7183970607933],
        [1605484800000, 449.2068016397434],
        [1605571200000, 461.3711967052673],
        [1605657600000, 482.1983847235396],
        [1605744000000, 479.43766136247405],
        [1605830400000, 471.31795839441156],
        [1605916800000, 508.79221458524455],
        [1606003200000, 548.842116249849],
        [1606089600000, 560.5088874583059],
        [1606176000000, 608.2733109189421],
        [1606262400000, 602.6662623938871],
        [1606348800000, 568.2751530404493],
        [1606435200000, 518.4670452162002],
        [1606521600000, 517.5530264056014],
        [1606608000000, 537.3926120944484],
        [1606694400000, 574.7511196548439],
        [1606780800000, 612.2637856122055],
        [1606867200000, 589.5818443540976],
        [1606953600000, 598.760610095824],
        [1607040000000, 616.506661565682],
        [1607126400000, 571.1904316349736],
        [1607212800000, 595.9194139680376],
        [1607299200000, 601.9689349244918],
        [1607385600000, 592.3865066820175],
        [1607472000000, 554.329674302381],
        [1607558400000, 573.8953091099941],
        [1607644800000, 560.4542654076048],
        [1607731200000, 545.9828352633687],
        [1607817600000, 568.3587534073821],
        [1607904000000, 590.3244403029342],
        [1607990400000, 585.5418949360015],
        [1608076800000, 589.0668316450424],
        [1608163200000, 635.9618893796135],
        [1608249600000, 644.0652701929779],
        [1608336000000, 654.4204162965752],
        [1608422400000, 659.3188076372094],
        [1608508800000, 639.5154368778184],
        [1608595200000, 610.4270278980672],
        [1608681600000, 634.9797234431858],
        [1608768000000, 587.9588954992439],
        [1608854400000, 612.8796570505974],
        [1608940800000, 626.4567387784558],
        [1609027200000, 636.7423171944997],
        [1609113600000, 689.6598573269875],
        [1609200000000, 732.9570292975275],
        [1609286400000, 735.5908981625738],
        [1609372800000, 752.8559324490188],
        [1609459200000, 738.6169381520413],
        [1609545600000, 730.1473402196374],
        [1609632000000, 777.6960653039432],
        [1609718400000, 967.0005967288458],
        [1609804800000, 1025.6547675669035],
        [1609891200000, 1103.3582516225742],
        [1609977600000, 1208.5750928728885],
        [1610064000000, 1229.4713150147782],
        [1610150400000, 1223.7296883535275],
        [1610236800000, 1282.979575527323],
        [1610323200000, 1267.7310031512136],
        [1610409600000, 1092.9143378806064],
        [1610496000000, 1045.406815230412],
        [1610582400000, 1132.0155910164729],
        [1610668800000, 1216.9147884634556],
        [1610755200000, 1171.8600177046465],
        [1610841600000, 1234.6318700828049],
        [1610928000000, 1229.379698072007],
        [1611014400000, 1255.976691513213],
        [1611100800000, 1383.4840902277115],
        [1611187200000, 1385.8529958752583],
        [1611273600000, 1122.912432537305],
        [1611360000000, 1236.6834427536564],
        [1611446400000, 1231.176379704471],
        [1611532800000, 1392.5397634449737],
        [1611619200000, 1323.4295031020106],
        [1611705600000, 1355.233724240999],
        [1611792000000, 1253.141671558609],
        [1611878400000, 1328.773619066556],
        [1611964800000, 1380.2842591352105],
        [1612051200000, 1372.427228964234],
        [1612137600000, 1317.0474362291777],
        [1612224000000, 1368.6644548123775],
        [1612310400000, 1514.2251957516528],
        [1612396800000, 1661.000824367477],
        [1612483200000, 1587.8008991859367],
        [1612569600000, 1724.8569083393102],
        [1612656000000, 1683.941297865053],
        [1612742400000, 1608.6405479857196],
        [1612828800000, 1750.997553719215],
        [1612915200000, 1769.053533934599],
        [1613001600000, 1739.164209004836],
        [1613088000000, 1782.5088691218523],
        [1613174400000, 1841.1977507032907],
        [1613260800000, 1810.8425457323726],
        [1613347200000, 1804.9841464366498],
        [1613433600000, 1775.758351701864],
        [1613520000000, 1782.5753090505934],
        [1613606400000, 1845.5739221567521],
        [1613692800000, 1938.5694368074617],
        [1613779200000, 1969.9797177628186],
        [1613865600000, 1929.367692879665],
        [1613952000000, 1941.4267676797988],
        [1614038400000, 1788.6152102806425],
        [1614124800000, 1563.9246059011416],
        [1614211200000, 1628.391533792655],
        [1614297600000, 1468.8601037286776],
        [1614384000000, 1450.988746804119],
        [1614470400000, 1480.1295768477528],
        [1614556800000, 1416.661552837565],
        [1614643200000, 1570.3996896340539],
        [1614729600000, 1497.0891042633873],
        [1614816000000, 1579.4271687603093],
        [1614902400000, 1546.4996211167372],
        [1614988800000, 1539.0484290947375],
        [1615075200000, 1661.9279698998157],
        [1615161600000, 1727.463096224567],
        [1615248000000, 1837.5330305439452],
        [1615334400000, 1869.3311016255],
        [1615420800000, 1802.3110916881178],
        [1615507200000, 1826.0574768119818],
        [1615593600000, 1770.9361793697751],
        [1615680000000, 1927.7207203999014],
        [1615766400000, 1866.0715447008808],
        [1615852800000, 1791.047851830107],
        [1615939200000, 1808.5512173614395],
        [1616025600000, 1828.7548332342565],
        [1616112000000, 1780.1596459528505],
        [1616198400000, 1817.1329628084297],
        [1616284800000, 1817.8601431294944],
        [1616371200000, 1790.3780747526928],
        [1616457600000, 1686.8911971580417],
        [1616544000000, 1673.8591835627929],
        [1616630400000, 1581.631055931366],
        [1616716800000, 1587.2979641585232],
        [1616803200000, 1700.3668693581185],
        [1616889600000, 1713.8378756834459],
        [1616976000000, 1689.0367982059097],
        [1617062400000, 1817.626388088868],
        [1617148800000, 1840.294951985396],
        [1617235200000, 1915.8325358462234],
        [1617321600000, 1970.4711995232753],
        [1617408000000, 2134.101788297294],
        [1617494400000, 2016.6672472930366],
        [1617580800000, 2077.755212422407],
        [1617667200000, 2097.7963827792596],
        [1617753600000, 2115.0554517461082],
        [1617840000000, 1989.1480619041722],
        [1617926400000, 2081.3540625398964],
        [1618012800000, 2069.6677960855295],
        [1618099200000, 2142.7960653929085],
        [1618185600000, 2150.2651427927744],
        [1618272000000, 2142.476275865736],
        [1618358400000, 2304.3435163110134],
        [1618444800000, 2429.6615277515734],
        [1618531200000, 2514.1684155812304],
        [1618617600000, 2424.5538447081194],
        [1618704000000, 2345.266962776132],
        [1618790400000, 2245.7607533508544],
        [1618876800000, 2168.032726735651],
        [1618963200000, 2324.2846872281943],
        [1619049600000, 2373.501344298211],
        [1619136000000, 2426.0711497906045],
        [1619222400000, 2364.2312036020908],
        [1619308800000, 2212.8437976342047],
        [1619395200000, 2307.35532084471],
        [1619481600000, 2532.3868028851084],
        [1619568000000, 2647.158189660288],
        [1619654400000, 2748.7845851218613],
        [1619740800000, 2757.497552304977],
        [1619827200000, 2776.70371169874],
        [1619913600000, 2944.9169473185057],
        [1620000000000, 2953.2973480804735],
        [1620086400000, 3439.8550695192603],
        [1620172800000, 3245.663148933579],
        [1620259200000, 3524.562728476055],
        [1620345600000, 3495.0758690360576],
        [1620432000000, 3493.534500000208],
        [1620518400000, 3912.7429165968124],
        [1620604800000, 3932.754068170969],
        [1620691200000, 3979.6086519212827],
        [1620777600000, 4182.790285752286],
        [1620864000000, 3906.108903329409],
        [1620950400000, 3750.3415950592753],
        [1621036800000, 4088.73170832043],
        [1621123200000, 3659.9218442136184],
        [1621209600000, 3602.0048969299023],
        [1621296000000, 3288.2298872378637],
        [1621382400000, 3399.0492796569383],
        [1621468800000, 2505.014945945662],
        [1621555200000, 2778.279660560299],
        [1621641600000, 2419.1032171150196],
        [1621728000000, 2306.371266867117],
        [1621814400000, 2120.0373745099428],
        [1621900800000, 2640.1596323488234],
        [1621987200000, 2695.4778071496326],
        [1622073600000, 2882.483407624011],
        [1622160000000, 2742.990862697615],
        [1622246400000, 2433.328865728611],
        [1622332800000, 2294.626285512794],
        [1622419200000, 2395.8532280027384],
        [1622505600000, 2708.429865793973],
        [1622592000000, 2632.6565998696506],
        [1622678400000, 2717.1540367097723],
        [1622764800000, 2858.27670209791],
        [1622851200000, 2694.4976666790503],
        [1622937600000, 2624.7689150594238],
        [1623024000000, 2711.5479390111605],
        [1623110400000, 2580.5355800598445],
        [1623196800000, 2528.022300878691],
        [1623283200000, 2620.6253893038547],
        [1623369600000, 2486.600068788676],
        [1623456000000, 2356.6347741896757],
        [1623542400000, 2379.991747950219],
        [1623628800000, 2517.771695525423],
        [1623715200000, 2587.381610979154],
        [1623801600000, 2561.1883309320033],
        [1623888000000, 2365.872786190649],
        [1623974400000, 2380.744519633922],
        [1624060800000, 2231.554315497782],
        [1624147200000, 2176.308536317709],
        [1624233600000, 2251.5605593660407],
        [1624320000000, 1900.1222998935382],
        [1624406400000, 1875.3576944712033],
        [1624492800000, 1971.1059779809414],
        [1624579200000, 1990.0761511910587],
        [1624665600000, 1833.4631609981645],
        [1624752000000, 1817.047665540675],
        [1624838400000, 1973.9268648383472],
        [1624924800000, 2087.5187237874175],
        [1625011200000, 2169.400067865984],
        [1625097600000, 2279.354161419647],
        [1625184000000, 2121.657900633364],
        [1625270400000, 2157.880584866453],
        [1625356800000, 2228.532398568573],
        [1625443200000, 2329.004750998825],
        [1625529600000, 2217.3018530598792],
        [1625616000000, 2320.6549288317],
        [1625702400000, 2317.23694270242],
        [1625788800000, 2126.4424673902267],
        [1625875200000, 2156.5809569525845],
        [1625961600000, 2123.0585974934124],
        [1626048000000, 2144.0142572722566],
        [1626134400000, 2042.4991866143628],
        [1626220800000, 1944.3950166372085],
        [1626307200000, 1997.6633136705987],
        [1626393600000, 1910.7269516309964],
        [1626480000000, 1874.2002496808186],
        [1626566400000, 1899.8427500663568],
        [1626652800000, 1905.723382843169],
        [1626739200000, 1824.929392091902],
        [1626825600000, 1794.973425186835],
        [1626912000000, 2003.7239214521073],
        [1626998400000, 2027.5334479167045],
        [1627084800000, 2117.154607794504],
        [1627171200000, 2183.627414557696],
        [1627257600000, 2209.4993601030114],
        [1627344000000, 2230.212067673471],
        [1627430400000, 2292.579636803809],
        [1627516800000, 2299.689405936291],
        [1627603200000, 2383.443259961735],
        [1627689600000, 2462.3999826480103],
        [1627776000000, 2541.674599365905],
        [1627862400000, 2555.408178513038],
        [1627948800000, 2611.6730699481845],
        [1628035200000, 2521.268474889094],
        [1628121600000, 2724.5322427136302],
        [1628208000000, 2821.649692746136],
        [1628294400000, 2888.7322742752317],
        [1628380800000, 3151.2175169176753],
        [1628467200000, 3012.3085592702105],
        [1628553600000, 3163.0646551948857],
        [1628640000000, 3147.842995401753],
        [1628726400000, 3166.6472179679286],
        [1628812800000, 3048.4126816864195],
        [1628899200000, 3323.19799054098],
        [1628985600000, 3268.5481770314927],
        [1629072000000, 3309.7549104132368],
        [1629158400000, 3153.583683763619],
        [1629244800000, 3007.1440271712713],
        [1629331200000, 3037.230251196439],
        [1629417600000, 3144.8184367851927],
        [1629504000000, 3276.9698366433354],
        [1629590400000, 3224.0004601521537],
        [1629676800000, 3243.4863583537394],
        [1629763200000, 3320.4091697810586],
        [1629849600000, 3177.6637963046905],
        [1629936000000, 3231.4414517287996],
        [1630022400000, 3122.971797237849],
        [1630108800000, 3267.539434750998],
        [1630195200000, 3245.430222387629],
        [1630281600000, 3233.383151693551],
        [1630368000000, 3232.7338632854617],
        [1630454400000, 3440.5623358869034],
        [1630540800000, 3790.6139962215557],
        [1630627200000, 3793.3007433464186],
        [1630713600000, 3936.1633916244464],
        [1630800000000, 3894.937511605067],
        [1630886400000, 3950.270344526561],
        [1630972800000, 3943.256785301908],
        [1631059200000, 3440.3417568365676],
        [1631145600000, 3496.859022996073],
        [1631232000000, 3435.97993265133],
        [1631318400000, 3209.915696090501],
        [1631404800000, 3268.1041621348804],
        [1631491200000, 3417.839366766938],
        [1631577600000, 3301.186776828727],
        [1631664000000, 3425.2508733748823],
        [1631750400000, 3595.9625714292315],
        [1631836800000, 3573.3075160249905],
        [1631923200000, 3412.177182421673],
        [1632009600000, 3427.58426153173],
        [1632096000000, 3335.884886514119],
        [1632182400000, 2977.3226793404747],
        [1632268800000, 2744.1109995297],
        [1632355200000, 3074.119761245824],
        [1632441600000, 3159.269865581771],
        [1632528000000, 2930.7427060738946],
        [1632614400000, 2946.9708461401983],
        [1632700800000, 3063.31634471348],
        [1632787200000, 2939.742282853775],
        [1632873600000, 2798.9844172601042],
        [1632960000000, 2855.6117305265157],
        [1633046400000, 3013.4932320320772],
        [1633132800000, 3305.107041443724],
        [1633219200000, 3393.9242492696653],
        [1633305600000, 3426.387080851377],
        [1633392000000, 3390.310407759615],
        [1633478400000, 3520.3422566393974],
        [1633564800000, 3592.1761295000256],
        [1633651200000, 3594.918357967647],
        [1633737600000, 3558.549577083409],
        [1633824000000, 3588.0809215501645],
        [1633910400000, 3431.0193069150096],
        [1633996800000, 3537.840087124068],
        [1634083200000, 3498.1052921206597],
        [1634169600000, 3605.6503342981764],
        [1634256000000, 3794.516888224821],
        [1634342400000, 3885.641764529699],
        [1634428800000, 3854.498461449342],
        [1634515200000, 3854.223686940845],
        [1634601600000, 3752.61872738495],
        [1634688000000, 3884.5872952429017],
        [1634774400000, 4170.107932886265],
        [1634860800000, 4074.8601575202883],
        [1634947200000, 3990.711976433473],
        [1635033600000, 4179.44229816945],
        [1635120000000, 4094.9389934187225],
        [1635206400000, 4230.208371594039],
        [1635292800000, 4152.570288731245],
        [1635379200000, 3944.090861781322],
        [1635465600000, 4288.09721878663],
        [1635552000000, 4422.940535593754],
        [1635638400000, 4324.6099257118785],
        [1635724800000, 4292.04047236198],
        [1635811200000, 4330.553151595248],
        [1635897600000, 4596.59309058429],
        [1635984000000, 4607.699273078144],
        [1636070400000, 4550.014435142697],
        [1636156800000, 4494.80276090967],
        [1636243200000, 4527.104154150622],
        [1636329600000, 4626.6292884486975],
        [1636416000000, 4815.004634322234],
        [1636502400000, 4742.08091060724],
        [1636588800000, 4641.528764849704],
        [1636675200000, 4732.9244503493555],
        [1636761600000, 4685.10635551733],
        [1636848000000, 4666.498498194288],
        [1636934400000, 4652.947394238871],
        [1637020800000, 4583.211418759011],
        [1637107200000, 4243.352906802173],
        [1637193600000, 4302.804958962834],
        [1637280000000, 3993.846595161859],
        [1637366400000, 4317.603195646166],
        [1637452800000, 4436.192766930451],
        [1637539200000, 4319.361566856902],
        [1637625600000, 4101.062876490145],
        [1637712000000, 4355.420957796475],
        [1637798400000, 4269.437219728043],
        [1637884800000, 4515.843300142739],
        [1637971200000, 4048.3128440761725],
        [1638057600000, 4084.0884856959556],
        [1638144000000, 4290.091862536401],
        [1638230400000, 4444.528279821561],
        [1638316800000, 4637.121616831405],
        [1638403200000, 4589.610617539151],
        [1638489600000, 4519.441028028784],
        [1638576000000, 4240.155516808957],
        [1638662400000, 4101.656791810778],
        [1638748800000, 4198.572874562462],
        [1638835200000, 4347.615630598069],
        [1638921600000, 4310.566646424531],
        [1639008000000, 4431.540647139759],
        [1639094400000, 4153.333310724186],
        [1639180800000, 3918.200743402953],
        [1639267200000, 4079.814930569866],
        [1639353600000, 4135.841510137412],
        [1639440000000, 3782.8952622347147],
        [1639526400000, 3858.1644681806974],
        [1639612800000, 4015.72254331389],
        [1639699200000, 3971.5597663421136],
        [1639785600000, 3886.7473617182886],
        [1639872000000, 3966.4253516705508],
        [1639958400000, 3928.8417238716206],
        [1640044800000, 3950.4823919860964],
        [1640131200000, 4036.549717813428],
        [1640217600000, 3992.5945766373156],
        [1640304000000, 4113.529931874606],
        [1640390400000, 4055.1173669313243],
        [1640476800000, 4110.571870853741],
        [1640563200000, 4075.03161894955],
        [1640649600000, 4045.051350119327],
        [1640736000000, 3807.3603674991996],
        [1640822400000, 3644.405516878227],
        [1640908800000, 3714.945456115339],
        [1640995200000, 3686.4025423019352],
        [1641081600000, 3780.315874327848],
        [1641168000000, 3835.3959914939574],
        [1641254400000, 3769.4049401148714],
        [1641340800000, 3794.908130100449],
        [1641427200000, 3556.9062274078738],
        [1641513600000, 3416.8260086159135],
        [1641600000000, 3201.79457434796],
        [1641686400000, 3101.0409016141402],
        [1641772800000, 3156.967262159481],
        [1641859200000, 3086.7290024387257],
        [1641945600000, 3246.413723028553],
        [1642032000000, 3378.0358951715943],
        [1642118400000, 3256.7588661330324],
        [1642204800000, 3311.986061274383],
        [1642291200000, 3331.246655159985],
        [1642377600000, 3356.2954438590405],
        [1642464000000, 3216.592980619574],
        [1642550400000, 3166.4539115046164],
        [1642636800000, 3097.0214660626802],
        [1642723200000, 3015.588777543836],
        [1642809600000, 2564.3433423740635],
        [1642896000000, 2407.377851608285],
        [1642982400000, 2537.836727861043],
        [1643068800000, 2447.831512095295],
        [1643155200000, 2465.21922984942],
        [1643241600000, 2470.4304120157067],
        [1643328000000, 2411.863855325421],
        [1643414400000, 2551.1110735194716],
        [1643500800000, 2603.603872339056],
        [1643587200000, 2610.1824055910874],
        [1643673600000, 2689.2208672204492],
        [1643760000000, 2797.434855273681],
        [1643846400000, 2690.319739476765],
        [1643932800000, 2667.256728215686],
        [1644019200000, 2993.0994395135976],
        [1644105600000, 3018.6733981571506],
        [1644192000000, 3062.8058084507857],
        [1644278400000, 3149.953285509783],
        [1644364800000, 3127.438106740214],
        [1644451200000, 3240.9210444877285],
        [1644537600000, 3081.911501840844],
        [1644624000000, 2930.5631692063735],
        [1644710400000, 2918.0853674291184],
        [1644796800000, 2889.235678281073],
        [1644883200000, 2935.6454998606846],
        [1644969600000, 3179.3002065350443],
        [1645056000000, 3128.640780542462],
        [1645142400000, 2881.613122162859],
        [1645228800000, 2792.3038477186237],
        [1645315200000, 2768.9716520348597],
        [1645401600000, 2632.49139911745],
        [1645488000000, 2574.513383778016],
        [1645574400000, 2644.153655546016],
        [1645660800000, 2594.700959110155],
        [1645747200000, 2599.9335696870216],
        [1645833600000, 2771.890509646429],
        [1645920000000, 2785.3389569017427],
        [1646006400000, 2629.48312082149],
        [1646092800000, 2919.294962425658],
        [1646179200000, 2977.276039756805],
        [1646265600000, 2953.315776286441],
        [1646352000000, 2836.9063366005576],
        [1646438400000, 2619.356137634489],
        [1646524800000, 2668.70597865687],
        [1646611200000, 2558.359874049847],
        [1646697600000, 2498.658864965217],
        [1646784000000, 2576.6271539947625],
        [1646870400000, 2731.037685293996],
        [1646956800000, 2611.4647225047097],
        [1647043200000, 2562.8323535359655],
        [1647129600000, 2579.4581356883637],
        [1647216000000, 2518.492845270615],
        [1647302400000, 2591.5420409591425],
        [1647388800000, 2620.363852420936],
        [1647475200000, 2771.759457292368],
        [1647561600000, 2817.401074966818],
        [1647648000000, 2945.7459101862714],
        [1647734400000, 2947.226158224087],
        [1647820800000, 2860.641960901919],
        [1647907200000, 2896.5869324317464],
        [1647993600000, 2969.78498365825],
        [1648080000000, 3028.8352577107285],
        [1648166400000, 3106.657023584939],
        [1648252800000, 3106.072054158695],
        [1648339200000, 3140.8757105064406],
        [1648425600000, 3285.1730967305425],
        [1648512000000, 3328.9341246867148],
        [1648598400000, 3401.184431040135],
        [1648684800000, 3383.7887617814154],
        [1648771200000, 3283.3028425717257],
        [1648857600000, 3451.1960908672754],
        [1648944000000, 3440.3362384891684],
        [1649030400000, 3521.5847247785246],
        [1649116800000, 3520.9671175043636],
        [1649203200000, 3422.9698837547116],
        [1649289600000, 3171.371104498954],
        [1649376000000, 3232.834988999582],
        [1649462400000, 3192.0250912893603],
        [1649548800000, 3265.938067729151],
        [1649635200000, 3219.159553453764],
        [1649721600000, 2992.7021064252435],
        [1649808000000, 3038.209421996366],
        [1649894400000, 3121.399823462041],
        [1649980800000, 3023.417211885316],
        [1650067200000, 3045.42807480128],
        [1650153600000, 3066.3580141449797],
        [1650240000000, 2995.719421019825],
        [1650326400000, 3061.8905711469047],
        [1650412800000, 3104.688447526111],
        [1650499200000, 3079.6764776018495],
        [1650585600000, 2987.4888760341605],
        [1650672000000, 2967.085285238213],
        [1650758400000, 2940.6879777714407],
        [1650844800000, 2922.901865199808],
        [1650931200000, 3008.3363470853],
        [1651017600000, 2806.748835957046],
        [1651104000000, 2889.5922230230817],
        [1651190400000, 2932.455083612903],
        [1651276800000, 2817.4898821916195],
        [1651363200000, 2738.1741359527714],
        [1651449600000, 2832.513106895354],
        [1651536000000, 2861.3723755560436],
        [1651622400000, 2786.0472478011084],
        [1651708800000, 2942.052313122658],
        [1651795200000, 2753.936566546688],
        [1651881600000, 2699.7072470893745],
        [1651968000000, 2641.229106940243],
        [1652054400000, 2517.8299224886478],
        [1652140800000, 2249.8909622207443],
        [1652227200000, 2344.797715643986],
        [1652313600000, 2080.910243657776],
        [1652400000000, 1966.6991711336661],
        [1652486400000, 2010.214051125259],
        [1652572800000, 2064.229357512243],
        [1652659200000, 2147.047447880575],
        [1652745600000, 2025.8886983912162],
        [1652832000000, 2095.178884796724],
        [1652918400000, 1915.1771232664505],
        [1653004800000, 2023.8482593608173],
        [1653091200000, 1963.9909395294865],
        [1653177600000, 1978.1471325624789],
        [1653264000000, 2046.6463496545232],
        [1653350400000, 1974.5811944790914],
        [1653436800000, 1979.770545006472],
        [1653523200000, 1944.8428445072448],
        [1653609600000, 1807.9694742881136],
        [1653696000000, 1724.8757340323443],
        [1653782400000, 1798.6948545788846],
        [1653868800000, 1814.9831598025937],
        [1653955200000, 1995.9364841552344],
        [1654041600000, 1944.078766836179],
        [1654128000000, 1828.8926546074292],
        [1654214400000, 1833.089840845753],
        [1654300800000, 1776.979907458187],
        [1654387200000, 1804.2616695112486],
        [1654473600000, 1805.3313949894373],
        [1654560000000, 1860.1813068416047],
        [1654646400000, 1818.3877119829308],
        [1654732800000, 1794.539625671828],
        [1654819200000, 1788.4182866616045],
        [1654905600000, 1663.844367412088],
        [1654992000000, 1530.0386174317211],
        [1655078400000, 1454.6867601728409],
        [1655164800000, 1205.5952855404787],
        [1655251200000, 1214.8662649132034],
        [1655337600000, 1230.3643353556051],
        [1655424000000, 1068.6035748646073],
        [1655510400000, 1087.8139244584365],
        [1655596800000, 995.2525692707909],
        [1655683200000, 1125.7540917942745],
        [1655769600000, 1132.0543935828048],
        [1655856000000, 1124.5613920165545],
        [1655942400000, 1051.0740681589236],
        [1656028800000, 1144.4807534808065],
        [1656115200000, 1229.3040854466553],
        [1656201600000, 1244.2862044953927],
        [1656288000000, 1201.237790641909],
        [1656374400000, 1194.2245084746116],
        [1656460800000, 1143.5335357209958],
        [1656547200000, 1098.9055176105344],
        [1656633600000, 1057.6309990608017],
        [1656720000000, 1068.8194401301964],
        [1656806400000, 1068.5415025999594],
        [1656892800000, 1074.6073175787587],
        [1656979200000, 1153.3483147880897],
        [1657065600000, 1134.017069659217],
        [1657152000000, 1188.72564292538],
        [1657238400000, 1240.3784056617153],
        [1657324800000, 1233.5146792602989],
        [1657411200000, 1216.8497068484603],
        [1657497600000, 1169.012707661018],
        [1657584000000, 1097.4494380477286],
        [1657670400000, 1040.7971455029597],
        [1657756800000, 1112.9207827012565],
        [1657843200000, 1191.1308371103116],
        [1657929600000, 1234.0991389763224],
        [1658016000000, 1355.04564021949],
        [1658102400000, 1344.7202840019017],
        [1658188800000, 1570.6589588642053],
        [1658275200000, 1542.6298214332235],
        [1658361600000, 1527.4139307593093],
        [1658448000000, 1576.8252394976823],
        [1658534400000, 1536.124922238913],
        [1658620800000, 1552.4964891908746],
        [1658707200000, 1601.2079670127046],
        [1658793600000, 1450.4822852139405],
        [1658880000000, 1438.9880382230651],
        [1658966400000, 1636.9491814677851],
        [1659052800000, 1723.5485206790597],
        [1659139200000, 1739.0421771356112],
        [1659225600000, 1696.7850982136515],
        [1659312000000, 1682.011373614746],
        [1659398400000, 1636.0185563573557],
        [1659484800000, 1638.3786826572205],
        [1659571200000, 1621.3413236364065],
        [1659657600000, 1612.4189067269824],
        [1659744000000, 1725.3826270211505],
        [1659830400000, 1693.2965918360276],
        [1659916800000, 1699.0064178886953],
        [1660003200000, 1775.7013555270828],
        [1660089600000, 1698.9661290126492],
        [1660176000000, 1852.8785552656473],
        [1660262400000, 1881.4274054352998],
        [1660348800000, 1959.3309247187408],
        [1660435200000, 1982.4118280514233],
        [1660521600000, 1936.7011636052605],
        [1660608000000, 1908.2776419059185],
        [1660694400000, 1880.6001012594395],
        [1660780800000, 1834.8264526426372],
        [1660867200000, 1850.1112910473696],
        [1660953600000, 1618.5325042633535],
        [1661040000000, 1579.4157230953704],
        [1661126400000, 1624.149982884847],
        [1661212800000, 1622.30703457785],
        [1661299200000, 1665.7919073919315],
        [1661385600000, 1657.7936424663385],
        [1661472000000, 1697.0769719636442],
        [1661558400000, 1510.1891114840514],
        [1661644800000, 1496.0554659420902],
        [1661731200000, 1438.7470503278116],
        [1661817600000, 1555.1774560415913],
        [1661904000000, 1525.400116232047],
        [1661990400000, 1552.5629662281337],
        [1662076800000, 1588.9803510869067],
        [1662163200000, 1575.1766307276075],
        [1662249600000, 1555.6740772078124],
        [1662336000000, 1578.9493013433012],
        [1662422400000, 1617.9354554854337],
        [1662508800000, 1564.5589540302462],
        [1662595200000, 1629.8832311636493],
        [1662681600000, 1635.9321760130483],
        [1662768000000, 1717.0769569840472],
        [1662854400000, 1779.5190288833046],
        [1662940800000, 1761.317355880691],
        [1663027200000, 1710.1642908515214],
        [1663113600000, 1573.2052136951713],
        [1663200000000, 1635.3682061921256],
        [1663286400000, 1471.999592516298],
        [1663372800000, 1432.215807478607],
        [1663459200000, 1471.2039553630448],
        [1663545600000, 1335.8525523850524],
        [1663632000000, 1379.920704526258],
        [1663718400000, 1324.1905646155858],
        [1663804800000, 1252.7745621685783],
        [1663891200000, 1330.7927929218301],
        [1663977600000, 1329.558806730321],
        [1664064000000, 1318.099347021849],
        [1664150400000, 1294.0750952797787],
        [1664236800000, 1335.1212051991713],
        [1664323200000, 1331.1501769338627],
        [1664409600000, 1340.0230594816517],
        [1664496000000, 1335.7389706335669],
        [1664582400000, 1329.1460320707963],
        [1664668800000, 1311.2633244162857],
        [1664755200000, 1277.901345279672],
        [1664841600000, 1323.5040179722914],
        [1664928000000, 1363.2371821945037],
        [1665014400000, 1352.5865243024869],
        [1665100800000, 1351.4273691979538],
        [1665187200000, 1324.8947380290601],
        [1665273600000, 1315.857799905668],
        [1665360000000, 1322.2697552193713],
        [1665446400000, 1291.0816596695552],
        [1665532800000, 1279.8833075472612],
        [1665619200000, 1294.2710513882173],
        [1665705600000, 1288.1640147020812],
        [1665792000000, 1297.6276053223842],
        [1665878400000, 1275.6937773947975],
        [1665964800000, 1306.943118828839],
        [1666051200000, 1333.4408776301655],
        [1666137600000, 1311.8067255158232],
        [1666224000000, 1284.654933702257],
        [1666310400000, 1282.0426467759612],
        [1666396800000, 1299.8019052506386],
        [1666483200000, 1313.767778723105],
        [1666569600000, 1364.4868713063736],
        [1666656000000, 1343.334491985092],
        [1666742400000, 1462.0489375629863],
        [1666828800000, 1568.7475855708392],
        [1666915200000, 1514.8635252533977],
        [1667001600000, 1555.0292135813938],
        [1667088000000, 1619.4295836277443],
        [1667174400000, 1591.0517734961002],
        [1667260800000, 1572.979514885975],
        [1667347200000, 1580.3787334402593],
        [1667433600000, 1520.6383643789845],
        [1667520000000, 1531.112630741574],
        [1667606400000, 1644.1928578089398],
        [1667692800000, 1626.8064304933644],
        [1667779200000, 1572.9477769721586],
        [1667865600000, 1569.9886360033397],
        [1667952000000, 1334.2879331082395],
        [1668038400000, 1095.1839771132936],
        [1668124800000, 1301.5538803074423],
        [1668211200000, 1293.9115948871518],
        [1668297600000, 1255.6010507578437],
        [1668384000000, 1223.0064433283683],
        [1668470400000, 1241.997092728311],
        [1668556800000, 1252.7067308366848],
        [1668643200000, 1217.7020892163014],
        [1668729600000, 1201.7898419641292],
        [1668816000000, 1213.49631006335],
        [1668902400000, 1218.8936571112463],
        [1668988800000, 1144.2531525085535],
        [1669075200000, 1110.2882130124592],
        [1669161600000, 1135.175811894246],
        [1669248000000, 1184.4617581029652],
        [1669334400000, 1204.2078028331202],
        [1669420800000, 1198.9755884889066],
        [1669507200000, 1205.6579602157142],
        [1669593600000, 1196.1962195659255],
        [1669680000000, 1170.115741471507],
        [1669766400000, 1216.936420693739],
        [1669852800000, 1298.94077037352],
        [1669939200000, 1276.6393714030964],
        [1670025600000, 1293.9480231782143],
        [1670112000000, 1243.0408423289737],
        [1670198400000, 1282.4164481394064],
        [1670284800000, 1259.7575748565346],
        [1670371200000, 1271.9354761263946],
        [1670457600000, 1233.6250483990402],
        [1670544000000, 1281.210637067264],
        [1670630400000, 1264.4773467215684],
        [1670716800000, 1264.7922793495136],
        [1670803200000, 1264.317985551174],
        [1670889600000, 1275.332421155143],
        [1670976000000, 1319.413007743119],
        [1671062400000, 1308.4001523403851],
        [1671148800000, 1263.7464240856877],
        [1671235200000, 1165.9737772823325],
        [1671321600000, 1189.4275215641987],
        [1671408000000, 1186.7779210362507],
        [1671494400000, 1166.9895905156618],
        [1671580800000, 1217.8888916641765],
        [1671667200000, 1213.3431612255224],
        [1671753600000, 1217.8525497108176],
        [1671840000000, 1220.701744919786],
        [1671926400000, 1221.3595303484499],
        [1672012800000, 1219.286343216304],
        [1672099200000, 1226.2534148310056],
        [1672185600000, 1211.819562063861],
        [1672272000000, 1188.7286638821608],
        [1672358400000, 1201.5431060594274],
        [1672444800000, 1199.1104971711857],
        [1672531200000, 1196.6130648084502],
        [1672617600000, 1200.65921690107],
        [1672704000000, 1214.3789459253508],
        [1672790400000, 1214.760969419338],
        [1672876800000, 1256.4366037834864],
        [1672963200000, 1250.7922522355914],
        [1673049600000, 1270.028610178057],
        [1673136000000, 1264.8369433837202],
        [1673222400000, 1284.5754041342987],
        [1673308800000, 1321.676571173056],
        [1673395200000, 1336.017071771777],
        [1673481600000, 1389.7716620941067],
        [1673568000000, 1417.134908743914],
        [1673654400000, 1453.3769102089225],
        [1673740800000, 1549.111474081304],
        [1673827200000, 1551.324517175301],
        [1673913600000, 1577.725890482971],
        [1674000000000, 1569.5308332272923],
        [1674086400000, 1516.555475473704],
        [1674172800000, 1550.5088875585636],
        [1674259200000, 1661.3349193405402],
        [1674345600000, 1625.9093109886562],
        [1674432000000, 1630.8448549407967],
        [1674518400000, 1632.9617267135266],
        [1674604800000, 1557.0597608721732],
        [1674691200000, 1614.6771850629295],
        [1674777600000, 1602.8469492523193],
        [1674864000000, 1598.4701935450053],
        [1674950400000, 1573.0634394330648],
        [1675036800000, 1646.5159900930198],
        [1675123200000, 1568.64771349631],
        [1675209600000, 1586.5395765836909],
        [1675296000000, 1642.857168158598],
        [1675382400000, 1648.6796840768716],
        [1675468800000, 1665.4278712238624],
        [1675555200000, 1667.2716925637008],
        [1675641600000, 1631.3725233636533],
        [1675728000000, 1617.144163289206],
        [1675814400000, 1672.8226777758136],
        [1675900800000, 1651.4083786023796],
        [1675987200000, 1546.383768658398],
        [1676073600000, 1515.5340268615535],
        [1676160000000, 1541.9688769412712],
        [1676246400000, 1515.3346311806397],
        [1676332800000, 1506.9162010135053],
        [1676419200000, 1556.963484345393],
        [1676505600000, 1674.858207473528],
        [1676592000000, 1646.143501886728],
        [1676678400000, 1697.0848665737892],
        [1676764800000, 1692.518962831222],
        [1676851200000, 1680.3786857000732],
        [1676937600000, 1700.1986814992663],
        [1677024000000, 1656.582369571119],
        [1677110400000, 1643.1733937453491],
        [1677196800000, 1651.850006691427],
        [1677283200000, 1608.1034936538365],
        [1677369600000, 1593.9519737908456],
        [1677456000000, 1640.5827101943062],
        [1677542400000, 1633.9766289833817],
        [1677628800000, 1606.632639454843],
        [1677715200000, 1663.925777294461],
        [1677801600000, 1647.6646669077718],
        [1677888000000, 1570.668210599776],
        [1677974400000, 1568.4956010562191],
        [1678060800000, 1563.2256620056303],
        [1678147200000, 1567.3501465252189],
        [1678233600000, 1563.8131822475007],
        [1678320000000, 1535.2602520971097],
        [1678406400000, 1440.1676618801841],
        [1678492800000, 1429.6031691041849],
        [1678579200000, 1474.40324440156],
        [1678665600000, 1588.1968020530471],
        [1678752000000, 1678.915634393308],
        [1678838400000, 1708.300897366666],
        [1678924800000, 1658.0701085663115],
        [1679011200000, 1679.7924762080895],
        [1679097600000, 1793.2988017342332],
        [1679184000000, 1769.4253269123851],
        [1679270400000, 1801.3674190114587],
        [1679356800000, 1744.4414599600184],
        [1679443200000, 1811.4112436682735],
        [1679529600000, 1747.6400281141143],
        [1679616000000, 1824.9566578949327],
        [1679702400000, 1751.2194078261725],
        [1679788800000, 1753.982636507409],
        [1679875200000, 1776.867690456723],
        [1679961600000, 1719.1709726748606],
        [1679966032000, 1709.1271834872894],
      ],
      market_caps: [
        [1438905600000, 0.0],
        [1438992000000, 80339475.0],
        [1439164800000, 41556309.21112176],
        [1439251200000, 64539006.306272194],
        [1439337600000, 76013261.05415402],
        [1439424000000, 110468836.22388984],
        [1439510400000, 110555313.26025319],
        [1439596800000, 101215246.3781064],
        [1439683200000, 89480939.24025029],
        [1439769600000, 87313387.94547918],
        [1439856000000, 92957858.0620156],
        [1439942400000, 90920695.86547753],
        [1440028800000, 107749185.84108424],
        [1440115200000, 102131022.8278611],
        [1440201600000, 100308763.78229792],
        [1440288000000, 98488001.44464436],
        [1440374400000, 90625948.31797116],
        [1440460800000, 84423109.08066371],
        [1440547200000, 81454879.46968535],
        [1440633600000, 82504022.8230964],
        [1440720000000, 86549490.63266213],
        [1440806400000, 85515599.87435573],
        [1440892800000, 96321049.6917207],
        [1440979200000, 98608290.53954794],
        [1441065600000, 98446826.49425755],
        [1441152000000, 93224371.25915022],
        [1441238400000, 92075482.63297981],
        [1441324800000, 92596631.55333723],
        [1441411200000, 97402782.61913483],
        [1441497600000, 94801706.70501994],
        [1441584000000, 91718481.31789045],
        [1441670400000, 90274690.39947063],
        [1441756800000, 88150718.66876175],
        [1441843200000, 85308945.82326],
        [1441929600000, 71394234.94958797],
        [1442016000000, 75931677.01254052],
        [1442102400000, 68673818.80135794],
        [1442188800000, 63965969.21105757],
        [1442275200000, 68951445.2301325],
        [1442361600000, 66147947.57992838],
        [1442448000000, 64693831.07862287],
        [1442534400000, 62003275.12077277],
        [1442620800000, 64666306.22983543],
        [1442707200000, 68666556.12460059],
        [1442793600000, 66105164.81214318],
        [1442880000000, 65835322.779180124],
        [1442966400000, 65633959.754653834],
        [1443052800000, 59927327.71396765],
        [1443139200000, 54400658.247102216],
        [1443225600000, 57158415.438244216],
        [1443312000000, 53028193.82255717],
        [1443398400000, 42874816.973063745],
        [1443484800000, 48389042.902712226],
        [1443571200000, 54065419.507539704],
        [1443657600000, 50481342.528862044],
        [1443744000000, 49792779.28891763],
        [1443830400000, 50638684.01549935],
        [1443916800000, 49187272.3502002],
        [1444003200000, 46307572.65224096],
        [1444089600000, 47605896.39364778],
        [1444176000000, 44984157.19104538],
        [1444262400000, 45977519.79859987],
        [1444348800000, 47989631.2526218],
        [1444435200000, 46490996.55001768],
        [1444521600000, 47026115.24213034],
        [1444608000000, 46416611.842303865],
        [1444694400000, 44485499.29942276],
        [1444780800000, 38272635.02078042],
        [1444867200000, 41527413.54435173],
        [1444953600000, 39443831.31743269],
        [1445040000000, 40632190.61699083],
        [1445126400000, 38382124.41691709],
        [1445212800000, 36070935.31062339],
        [1445299200000, 32076052.631905656],
        [1445385600000, 32590298.008333907],
        [1445472000000, 41918540.78268511],
        [1445558400000, 40100107.0136459],
        [1445644800000, 42179070.75754259],
        [1445731200000, 46895926.257060364],
        [1445817600000, 55226993.78944921],
        [1445904000000, 63451496.574236006],
        [1445990400000, 75057962.81264551],
        [1446076800000, 86478981.5524642],
        [1446163200000, 77447839.42741764],
        [1446249600000, 67453442.47710526],
        [1446336000000, 78742733.24541084],
        [1446422400000, 71066352.05814125],
        [1446508800000, 74613449.56251672],
        [1446595200000, 67152505.55125615],
        [1446681600000, 67532501.09696001],
        [1446768000000, 67767390.10082617],
        [1446854400000, 68761146.27837914],
        [1446940800000, 76839299.32470989],
        [1447027200000, 74277547.07634276],
        [1447113600000, 69753492.14382021],
        [1447200000000, 58873875.70678452],
        [1447286400000, 67254888.20415637],
        [1447372800000, 67527257.40972713],
        [1447459200000, 66066714.4889687],
        [1447545600000, 68078325.7946219],
        [1447632000000, 69812830.40735959],
        [1447718400000, 74440734.3005621],
        [1447804800000, 74402718.74542972],
        [1447891200000, 71212297.76056388],
        [1447977600000, 69388368.57982641],
        [1448064000000, 72914368.80252853],
        [1448150400000, 72276339.25126061],
        [1448236800000, 70839197.81949022],
        [1448323200000, 67424299.39769948],
        [1448409600000, 65072847.332706206],
        [1448496000000, 67132055.60740013],
        [1448582400000, 65341845.392888874],
        [1448668800000, 68868768.28657502],
        [1448755200000, 65463342.96555324],
        [1448841600000, 65624853.97047546],
        [1448928000000, 65752486.02943543],
        [1449014400000, 61844779.03171155],
        [1449100800000, 62018808.085017644],
        [1449187200000, 63094325.07829986],
        [1449273600000, 65056337.64166404],
        [1449360000000, 62850264.41510667],
        [1449446400000, 60292476.61320164],
        [1449532800000, 61675859.110314034],
        [1449619200000, 59661067.18085799],
        [1449705600000, 63872552.27588074],
        [1449792000000, 69119370.62718369],
        [1449878400000, 73250296.07272834],
        [1449964800000, 72302484.28350292],
        [1450051200000, 75133022.39506158],
        [1450137600000, 76787012.60189861],
        [1450224000000, 74686005.22814946],
        [1450310400000, 71033582.40629953],
        [1450396800000, 69616990.9878865],
        [1450483200000, 68767429.85047308],
        [1450569600000, 68305705.24538197],
        [1450656000000, 67842615.39188164],
        [1450742400000, 66514758.19337365],
        [1450828800000, 65674405.654046126],
        [1450915200000, 65226512.003279686],
        [1451001600000, 65957619.83793369],
        [1451088000000, 64637552.400460914],
        [1451174400000, 65104495.77797423],
        [1451260800000, 63858382.55360898],
        [1451347200000, 65951485.1558242],
        [1451433600000, 68315404.54669571],
        [1451520000000, 71083912.69961053],
        [1451606400000, 71849843.66511223],
        [1451692800000, 71252107.9687766],
        [1451779200000, 73864483.22222728],
        [1451865600000, 72909192.00505088],
        [1451952000000, 72462361.87937805],
        [1452038400000, 73650960.93328463],
        [1452124800000, 72931723.30621576],
        [1452211200000, 75767382.4398899],
        [1452297600000, 78016544.33428131],
        [1452384000000, 77676004.65241879],
        [1452470400000, 81416101.6377075],
        [1452556800000, 88958959.89936547],
        [1452643200000, 86108971.48293594],
        [1452729600000, 88257219.96322113],
        [1452816000000, 92329493.82516772],
        [1452902400000, 92765190.35300171],
        [1452988800000, 100866229.13386133],
        [1453075200000, 106770204.99600992],
        [1453161600000, 105177492.60810892],
        [1453248000000, 119608403.90910526],
        [1453334400000, 118064297.75775222],
        [1453420800000, 112494896.69290045],
        [1453507200000, 129390552.65124127],
        [1453593600000, 163090024.6945184],
        [1453680000000, 189898537.77611715],
        [1453766400000, 159744115.0673894],
        [1453852800000, 182534589.0343229],
        [1453939200000, 193030853.2041578],
        [1454025600000, 190524391.83507282],
        [1454112000000, 186862759.72151402],
        [1454198400000, 176275775.71473008],
        [1454284800000, 170434539.30474818],
        [1454371200000, 184396131.5986807],
        [1454457600000, 193120920.3597334],
        [1454544000000, 196350197.91323307],
        [1454630400000, 194548161.01786965],
        [1454716800000, 193450250.47357807],
        [1454803200000, 226795324.20954534],
        [1454889600000, 243366581.58299115],
        [1454976000000, 286139280.9840226],
        [1455062400000, 340624194.15097886],
        [1455148800000, 462140079.76210976],
        [1455235200000, 421542668.4566254],
        [1455321600000, 413798928.6182676],
        [1455408000000, 398234915.54443055],
        [1455494400000, 409885035.83075976],
        [1455580800000, 336781865.25572217],
        [1455667200000, 288700599.2729368],
        [1455753600000, 339233532.70569825],
        [1455840000000, 346746765.74089813],
        [1455926400000, 332040532.28508496],
        [1456012800000, 359334417.7208708],
        [1456099200000, 433193897.2217689],
        [1456185600000, 433593972.8044955],
        [1456272000000, 474102955.51858103],
        [1456358400000, 458942242.84471726],
        [1456444800000, 456082096.8564275],
        [1456531200000, 491795616.48932266],
        [1456617600000, 498424354.01221955],
        [1456704000000, 488620056.2443799],
        [1456790400000, 585403826.3853717],
        [1456876800000, 650547162.2177418],
        [1456963200000, 736507630.9102448],
        [1457049600000, 778040245.1399581],
        [1457136000000, 847886363.7817264],
        [1457222400000, 850440219.9342833],
        [1457308800000, 729122199.5218774],
        [1457395200000, 760648959.2261459],
        [1457481600000, 929438076.2759757],
        [1457568000000, 870327228.7272445],
        [1457654400000, 870802676.0308328],
        [1457740800000, 1039511436.0395219],
        [1457827200000, 1157819808.965224],
        [1457913600000, 955721344.4457039],
        [1458000000000, 1008754617.7585647],
        [1458086400000, 975104638.0634549],
        [1458172800000, 856001603.1676563],
        [1458259200000, 843946887.9342747],
        [1458345600000, 816289545.7437397],
        [1458432000000, 799245003.4284257],
        [1458518400000, 927407651.003754],
        [1458604800000, 886765230.0935057],
        [1458691200000, 972225642.275035],
        [1458777600000, 872598276.4440267],
        [1458864000000, 834753145.0583116],
        [1458950400000, 860590847.122157],
        [1459036800000, 814770808.1488085],
        [1459123200000, 917099302.4921898],
        [1459209600000, 922332031.7043784],
        [1459296000000, 934038009.0318996],
        [1459382400000, 892916961.5825855],
        [1459468800000, 915415691.5298438],
        [1459555200000, 910108352.6203126],
        [1459641600000, 906254482.4679867],
        [1459728000000, 881135506.2361231],
        [1459814400000, 821026031.4553995],
        [1459900800000, 840796803.7682226],
        [1459987200000, 790923840.975488],
        [1460073600000, 769898368.4666554],
        [1460160000000, 781106339.0553539],
        [1460246400000, 695943784.1793737],
        [1460332800000, 684660770.877576],
        [1460419200000, 593949307.4480366],
        [1460505600000, 633611436.0474921],
        [1460592000000, 666925784.5002216],
        [1460678400000, 649194329.0397049],
        [1460764800000, 677141041.3390801],
        [1460851200000, 738147546.3926145],
        [1460937600000, 709720474.7865463],
        [1461024000000, 689106961.8888905],
        [1461110400000, 672532357.9918683],
        [1461196800000, 643204549.648147],
        [1461283200000, 623163882.7267805],
        [1461369600000, 664843804.3114134],
        [1461456000000, 634792414.6359913],
        [1461542400000, 620380381.4729422],
        [1461628800000, 589953549.0718942],
        [1461715200000, 614038719.7664939],
        [1461801600000, 576551379.9087085],
        [1461888000000, 594731702.4822702],
        [1461974400000, 707751631.3173722],
        [1462060800000, 703213705.2833785],
        [1462147200000, 805073117.8028661],
        [1462233600000, 739862094.2932651],
        [1462320000000, 750420702.3036536],
        [1462406400000, 783653892.6075463],
        [1462492800000, 740079043.0208727],
        [1462579200000, 748684467.9155403],
        [1462665600000, 760794311.5000647],
        [1462752000000, 747045608.4385856],
        [1462838400000, 747107780.4041426],
        [1462924800000, 797843002.2628187],
        [1463011200000, 806976736.4777818],
        [1463097600000, 846829870.6061078],
        [1463184000000, 814388813.0253446],
        [1463270400000, 797447113.6994009],
        [1463356800000, 891725938.6611807],
        [1463443200000, 974091672.7174443],
        [1463529600000, 1070392165.1001045],
        [1463616000000, 1180254102.1753519],
        [1463702400000, 1098527825.600999],
        [1463788800000, 1123480271.01703],
        [1463875200000, 1143985930.6592457],
        [1463961600000, 1063005899.322382],
        [1464048000000, 1017513902.0341961],
        [1464134400000, 1005116816.0568345],
        [1464220800000, 1005043803.4223615],
        [1464307200000, 900334792.3619336],
        [1464393600000, 946689008.4566064],
        [1464480000000, 997114649.2059603],
        [1464566400000, 1021153612.0329038],
        [1464652800000, 1128785731.519008],
        [1464739200000, 1117038106.1276379],
        [1464825600000, 1110747962.0861454],
        [1464912000000, 1116709699.2231467],
        [1464998400000, 1105420836.3746781],
        [1465084800000, 1127904559.166551],
        [1465171200000, 1126059079.2933497],
        [1465257600000, 1175566661.3926558],
        [1465344000000, 1166543234.5850925],
        [1465430400000, 1167724056.8805668],
        [1465516800000, 1127971788.6557844],
        [1465603200000, 1129771063.2214296],
        [1465689600000, 1270814827.7909074],
        [1465776000000, 1421627594.3482137],
        [1465862400000, 1520848128.9016523],
        [1465948800000, 1493198806.5705338],
        [1466035200000, 1663058590.2352068],
        [1466121600000, 1249053416.9715872],
        [1466208000000, 908703581.3956258],
        [1466294400000, 992977657.9993956],
        [1466380800000, 959600009.086865],
        [1466467200000, 1055028896.3957832],
        [1466553600000, 1035382450.4098117],
        [1466640000000, 1112128273.0865185],
        [1466726400000, 1134160918.5765297],
        [1466812800000, 1161808477.683748],
        [1466899200000, 1127161866.113175],
        [1466985600000, 1134738651.812568],
        [1467072000000, 1001816707.4205686],
        [1467158400000, 1043352935.7311049],
        [1467244800000, 1087390815.1043506],
        [1467331200000, 1005775062.8852906],
        [1467417600000, 980549295.6466436],
        [1467504000000, 965373887.5132511],
        [1467590400000, 932812328.2042702],
        [1467676800000, 861199508.1291671],
        [1467763200000, 859068161.5290377],
        [1467849600000, 825747093.0896322],
        [1467936000000, 932118254.1453621],
        [1468022400000, 899784687.8420677],
        [1468108800000, 896985032.1099088],
        [1468195200000, 861156597.6960213],
        [1468281600000, 868920981.0675113],
        [1468368000000, 859665552.2352576],
        [1468454400000, 921808085.7160168],
        [1468540800000, 928901844.3372735],
        [1468627200000, 960169025.1824887],
        [1468713600000, 928781022.9092299],
        [1468800000000, 905150788.2259313],
        [1468886400000, 951503951.3461838],
        [1468972800000, 998549464.8233292],
        [1469059200000, 1036388808.2886974],
        [1469145600000, 1177755541.070433],
        [1469232000000, 1183972887.7908795],
        [1469318400000, 1053890906.6279503],
        [1469404800000, 1137688271.2296822],
        [1469491200000, 969670072.7276816],
        [1469577600000, 1068505615.2937703],
        [1469664000000, 1062067696.4315883],
        [1469750400000, 1058924002.9712181],
        [1469836800000, 1035160533.0510787],
        [1469923200000, 979546056.9825448],
        [1470009600000, 905616046.061989],
        [1470096000000, 689901283.219963],
        [1470182400000, 844612892.2756267],
        [1470268800000, 911931922.0513974],
        [1470355200000, 904674489.3809131],
        [1470441600000, 901592852.5380467],
        [1470528000000, 903742755.7733909],
        [1470614400000, 931042241.4656782],
        [1470700800000, 1009705614.3654938],
        [1470787200000, 1005960650.7643628],
        [1470873600000, 993657810.9651861],
        [1470960000000, 992852191.9212197],
        [1471046400000, 960366408.498685],
        [1471132800000, 934377927.8960145],
        [1471219200000, 932326143.2853824],
        [1471305600000, 851307063.4618484],
        [1471392000000, 891887550.1509097],
        [1471478400000, 891315247.0386077],
        [1471564800000, 891164434.8398446],
        [1471651200000, 936793411.7477161],
        [1471737600000, 928118646.4965856],
        [1471824000000, 920760944.831664],
        [1471910400000, 917054933.5053089],
        [1471996800000, 917509231.7189381],
        [1472083200000, 944980815.0070009],
        [1472169600000, 938479976.3330711],
        [1472256000000, 929893628.9463943],
        [1472342400000, 910522226.8620988],
        [1472428800000, 910306587.9266042],
        [1472515200000, 936081383.0388741],
        [1472601600000, 960315422.2529753],
        [1472688000000, 1002979070.4858418],
        [1472774400000, 1010736310.5378789],
        [1472860800000, 986576697.7263979],
        [1472947200000, 975664137.5387731],
        [1473033600000, 983510024.4105914],
        [1473120000000, 978261092.6084977],
        [1473206400000, 967355053.646816],
        [1473292800000, 949823761.5796945],
        [1473379200000, 974812003.5841372],
        [1473465600000, 1016072616.831647],
        [1473552000000, 972702164.2737088],
        [1473638400000, 994145301.609289],
        [1473724800000, 997422104.3891094],
        [1473811200000, 1000350301.4766209],
        [1473897600000, 999855875.8932285],
        [1473984000000, 1052421113.1733788],
        [1474070400000, 1066458048.1588708],
        [1474156800000, 1040236010.887626],
        [1474243200000, 1107285960.0478551],
        [1474329600000, 1204332733.2298675],
        [1474416000000, 1154027780.2700548],
        [1474502400000, 1114753105.9884236],
        [1474588800000, 1122387371.9483435],
        [1474675200000, 1084102058.9176946],
        [1474761600000, 1100233097.311396],
        [1474848000000, 1081499008.0468965],
        [1474934400000, 1103562692.3435485],
        [1475020800000, 1124190330.6353893],
        [1475107200000, 1106268658.338769],
        [1475193600000, 1114978390.1540408],
        [1475280000000, 1121767153.3880613],
        [1475366400000, 1116158962.9099944],
        [1475452800000, 1139675567.6165462],
        [1475539200000, 1122448916.2531135],
        [1475625600000, 1105495725.0792377],
        [1475712000000, 1086400169.4406376],
        [1475798400000, 1072424202.4064465],
        [1475884800000, 1032213087.4672256],
        [1475971200000, 1020207839.2316508],
        [1476057600000, 1004236335.549423],
        [1476144000000, 997294452.394914],
        [1476230400000, 1006549794.2460625],
        [1476316800000, 1015964123.7672851],
        [1476403200000, 1012567915.6864122],
        [1476489600000, 1015379673.8239383],
        [1476576000000, 1014551965.6012961],
        [1476662400000, 1018744183.3917441],
        [1476748800000, 1064628048.1422825],
        [1476835200000, 1019440133.5922542],
        [1476921600000, 1023487873.4228995],
        [1477008000000, 1030182192.7281948],
        [1477094400000, 1026687902.3286453],
        [1477180800000, 1017581341.656798],
        [1477267200000, 1014274172.0789354],
        [1477353600000, 961902672.6889603],
        [1477440000000, 981270911.3193351],
        [1477526400000, 978335224.2052029],
        [1477612800000, 984269328.3943177],
        [1477699200000, 883346994.5449576],
        [1477785600000, 956201948.7341646],
        [1477872000000, 930359100.1537943],
        [1477958400000, 923284106.0659813],
        [1478044800000, 922879134.3461332],
        [1478131200000, 925622888.0829407],
        [1478217600000, 953223436.315442],
        [1478304000000, 948094434.4750459],
        [1478390400000, 935993552.9610422],
        [1478476800000, 932357915.5754921],
        [1478563200000, 934033643.8762665],
        [1478649600000, 915091038.306155],
        [1478736000000, 902927283.1963692],
        [1478822400000, 883831055.7586268],
        [1478908800000, 830154646.5339867],
        [1478995200000, 868826185.2627618],
        [1479081600000, 858907304.312002],
        [1479168000000, 877146449.7853684],
        [1479254400000, 862443066.8905919],
        [1479340800000, 857469644.9666562],
        [1479427200000, 813868305.3690429],
        [1479513600000, 832570726.9340843],
        [1479600000000, 821098057.2191805],
        [1479686400000, 825310560.8512948],
        [1479772800000, 846010291.5384672],
        [1479859200000, 841238275.5424864],
        [1479945600000, 792161484.4680631],
        [1480032000000, 805772134.224589],
        [1480118400000, 802029049.6889203],
        [1480204800000, 769442732.486036],
        [1480291200000, 752553451.4139076],
        [1480377600000, 705297428.006268],
        [1480464000000, 748071852.89044],
        [1480550400000, 730151014.1821295],
        [1480636800000, 661018149.1755499],
        [1480723200000, 683319039.0920178],
        [1480809600000, 646883448.723041],
        [1480896000000, 590496504.1573621],
        [1480982400000, 661467705.6118599],
        [1481068800000, 705334665.7063091],
        [1481155200000, 720121915.7352629],
        [1481241600000, 737938568.3108796],
        [1481328000000, 702944255.6811203],
        [1481414400000, 713308213.0334481],
        [1481500800000, 735934880.9008343],
        [1481587200000, 725643347.5173056],
        [1481673600000, 713465176.9517916],
        [1481760000000, 675681120.2742975],
        [1481846400000, 678214580.9425403],
        [1481932800000, 680519827.6040249],
        [1482019200000, 683897439.864293],
        [1482105600000, 661159386.32716],
        [1482192000000, 664148518.5948613],
        [1482278400000, 684634858.1163107],
        [1482364800000, 660427962.7659905],
        [1482451200000, 628063010.6930844],
        [1482537600000, 630117081.0725466],
        [1482624000000, 629516509.2890977],
        [1482710400000, 634564176.0517044],
        [1482796800000, 626352253.3784304],
        [1482883200000, 665151773.2559114],
        [1482969600000, 714246947.4367769],
        [1483056000000, 712888825.7382647],
        [1483142400000, 700638437.1090508],
        [1483228800000, 705615194.8270631],
        [1483315200000, 731817457.4649888],
        [1483401600000, 842886495.3799952],
        [1483488000000, 961038889.8439155],
        [1483574400000, 886296492.9314206],
        [1483660800000, 890067630.5937623],
        [1483747200000, 861427362.560421],
        [1483833600000, 900645822.1454092],
        [1483920000000, 902556769.2719904],
        [1484006400000, 923748369.397242],
        [1484092800000, 848391822.1686144],
        [1484179200000, 849172695.9853683],
        [1484265600000, 848042631.0951145],
        [1484352000000, 852254946.1784115],
        [1484438400000, 860534322.7461399],
        [1484524800000, 837562733.6341596],
        [1484611200000, 902735897.6068891],
        [1484697600000, 891354114.8712552],
        [1484784000000, 914555495.2617246],
        [1484870400000, 929384722.9448366],
        [1484956800000, 958152131.9422481],
        [1485043200000, 947279384.7763175],
        [1485129600000, 948113651.4735702],
        [1485216000000, 917408215.3892527],
        [1485302400000, 924140033.97176],
        [1485388800000, 931851533.4501834],
        [1485475200000, 928178829.5799544],
        [1485561600000, 932486646.1251798],
        [1485648000000, 921810453.3952467],
        [1485734400000, 934513900.731524],
        [1485820800000, 944300621.5689718],
        [1485907200000, 946733901.535968],
        [1485993600000, 956759156.4673628],
        [1486080000000, 968360894.192527],
        [1486166400000, 1003747585.8791431],
        [1486252800000, 996031546.0667974],
        [1486339200000, 1000976621.5619533],
        [1486425600000, 1013517743.3509269],
        [1486512000000, 1005343885.9063299],
        [1486598400000, 968413072.7002903],
        [1486684800000, 1000774841.3196372],
        [1486771200000, 1012095683.2413529],
        [1486857600000, 1008457060.9062703],
        [1486944000000, 1002404862.0123965],
        [1487030400000, 1152983307.7665765],
        [1487116800000, 1150065646.2625835],
        [1487203200000, 1141695360.3501627],
        [1487289600000, 1130310857.329892],
        [1487376000000, 1140747285.3365533],
        [1487462400000, 1137511955.6917048],
        [1487548800000, 1135537849.161545],
        [1487635200000, 1134283628.7370799],
        [1487721600000, 1122185283.3355997],
        [1487808000000, 1171602048.167043],
        [1487894400000, 1165941515.2567716],
        [1487980800000, 1208749236.7164543],
        [1488067200000, 1298504264.2843328],
        [1488153600000, 1371373069.7338753],
        [1488240000000, 1402597198.622161],
        [1488326400000, 1534004366.1496954],
        [1488412800000, 1697730102.3249254],
        [1488499200000, 1751276476.8602574],
        [1488585600000, 1667171877.3033454],
        [1488672000000, 1722225376.4707189],
        [1488758400000, 1760717955.0918417],
        [1488844800000, 1685811625.6439984],
        [1488931200000, 1500309874.2410994],
        [1489017600000, 1579882452.1833007],
        [1489104000000, 1726669640.058529],
        [1489190400000, 1918511338.945731],
        [1489276800000, 2071872695.7437222],
        [1489363200000, 2552384188.382959],
        [1489449600000, 2561402786.7498617],
        [1489536000000, 3070584067.9730344],
        [1489622400000, 3580085215.1633782],
        [1489708800000, 3959577512.5822687],
        [1489795200000, 2900403757.617106],
        [1489881600000, 3964282980.887029],
        [1489968000000, 3765537571.6064487],
        [1490054400000, 3822148614.067184],
        [1490140800000, 3714048002.0475125],
        [1490227200000, 3896515676.7178783],
        [1490313600000, 4584161758.556292],
        [1490400000000, 4566322356.351229],
        [1490486400000, 4511139438.293101],
        [1490572800000, 4419631301.180356],
        [1490659200000, 4533191634.755726],
        [1490745600000, 4767901725.458708],
        [1490832000000, 4721666246.305043],
        [1490918400000, 4511239008.808598],
        [1491004800000, 4554446900.250353],
        [1491091200000, null],
        [1491177600000, 3938736158.3307056],
        [1491264000000, 3779040762.106836],
        [1491350400000, 4123329711.6029277],
        [1491436800000, 3888436554.318116],
        [1491523200000, 3837553639.6237936],
        [1491609600000, 4028965746.7053437],
        [1491696000000, 4001279550.297905],
        [1491782400000, 3977660680.7776713],
        [1491868800000, 3974803439.6935434],
        [1491955200000, 4188162262.675274],
        [1492041600000, 4511719460.307157],
        [1492128000000, 4314764267.296191],
        [1492214400000, 4457826105.639198],
        [1492300800000, 4421737497.550737],
        [1492387200000, 4390331578.008309],
        [1492473600000, 4610962772.624808],
        [1492560000000, 4376229928.945044],
        [1492646400000, 4550946376.025764],
        [1492732800000, 4437559543.365543],
        [1492819200000, 4464325598.471732],
        [1492905600000, 4604950657.5048485],
        [1492992000000, 4650945908.7491455],
        [1493078400000, 4641256266.642054],
        [1493164800000, 4998350382.018841],
        [1493251200000, 5827188142.875711],
        [1493337600000, 6654236244.652257],
        [1493424000000, 6423030318.594092],
        [1493510400000, 7277893964.101945],
        [1493596800000, 7010408300.280772],
        [1493683200000, 7058413636.898035],
        [1493769600000, 7387794149.972096],
        [1493856000000, 8594013017.409878],
        [1493942400000, 8327157234.5526085],
        [1494028800000, 8811175463.021584],
        [1494115200000, 8202729258.547628],
        [1494201600000, 8041549109.429107],
        [1494288000000, 8003377431.08859],
        [1494374400000, 8089058133.497479],
        [1494460800000, 8095903577.443339],
        [1494547200000, 8073353519.484268],
        [1494633600000, 8163626646.569916],
        [1494720000000, 8250106526.458163],
        [1494806400000, 8293823327.380839],
        [1494892800000, 7983587476.027811],
        [1494979200000, 8088870648.097549],
        [1495065600000, 8862576243.544483],
        [1495152000000, 11208881844.541496],
        [1495238400000, 11291783022.019232],
        [1495324800000, 13648265757.584177],
        [1495411200000, 14142415430.127079],
        [1495497600000, 15715507206.448889],
        [1495584000000, 17498667475.10237],
        [1495670400000, 16254143683.05323],
        [1495756800000, 14983523257.18021],
        [1495843200000, 14630471573.094501],
        [1495929600000, 15857470558.37399],
        [1496016000000, 18047458437.093563],
        [1496102400000, 21249999565.212254],
        [1496188800000, 21309492258.258083],
        [1496275200000, 20436692118.01529],
        [1496361600000, 20671991234.40535],
        [1496448000000, 20777803231.835773],
        [1496534400000, 22815254964.736126],
        [1496620800000, 22960811912.55524],
        [1496707200000, 24555497266.27736],
        [1496793600000, 23647279711.566154],
        [1496880000000, 24034963007.9528],
        [1496966400000, 25888383498.268276],
        [1497052800000, 31204109902.87976],
        [1497139200000, 31517336371.290997],
        [1497225600000, 36827986612.086975],
        [1497312000000, 36054646089.31767],
        [1497398400000, 32166013290.582638],
        [1497484800000, 32045187612.760445],
        [1497571200000, 33157744080.501328],
        [1497657600000, 34470617407.87629],
        [1497744000000, 33285012838.7317],
        [1497830400000, 33536649090.290455],
        [1497916800000, 33172832683.333572],
        [1498003200000, 30390293651.200687],
        [1498089600000, 30312664724.45932],
        [1498176000000, 30780636114.235588],
        [1498262400000, 28761584162.47397],
        [1498348800000, 26727797729.147892],
        [1498435200000, 24031469340.43858],
        [1498521600000, 26071528057.27424],
        [1498608000000, 29466974350.047752],
        [1498694400000, 27885580759.15084],
        [1498780800000, 26454457715.02758],
        [1498867200000, 24397386527.037548],
        [1498953600000, 26603794019.452686],
        [1499040000000, 25910676121.598396],
        [1499126400000, 25093983117.385654],
        [1499212800000, 24747605746.348026],
        [1499299200000, 24892807780.231613],
        [1499385600000, 22473685750.315968],
        [1499472000000, 22932342735.97489],
        [1499558400000, 22243889410.906918],
        [1499644800000, 19619308390.378407],
        [1499731200000, 18114890100.247456],
        [1499817600000, 20711938391.132465],
        [1499904000000, 19250616800.120193],
        [1499990400000, 18467839703.61626],
        [1500076800000, 16028622412.657797],
        [1500163200000, 14603949935.719194],
        [1500249600000, 17584242919.82978],
        [1500336000000, 21338075701.63201],
        [1500422400000, 18643050491.147614],
        [1500508800000, 20878190202.346775],
        [1500595200000, 20287767362.233944],
        [1500681600000, 21607085358.114548],
        [1500768000000, 21198900249.379704],
        [1500854400000, 21035610498.530434],
        [1500940800000, 19347591148.68737],
        [1501027200000, 18746083965.650166],
        [1501113600000, 19018740572.79166],
        [1501200000000, 18030982561.483517],
        [1501286400000, 19246182898.41244],
        [1501372800000, 18472631835.069103],
        [1501459200000, 19047827948.6107],
        [1501545600000, 21282137210.92791],
        [1501632000000, 20534872983.74952],
        [1501718400000, 21084238670.106236],
        [1501804800000, 20789568173.053577],
        [1501891200000, 23747316372.895435],
        [1501977600000, 24697194959.234104],
        [1502064000000, 25197894463.869026],
        [1502150400000, 27705377543.833405],
        [1502236800000, 27892280499.832973],
        [1502323200000, 27871169339.54446],
        [1502409600000, 28821677184.531666],
        [1502496000000, 28997352513.86263],
        [1502582400000, 27926704137.990173],
        [1502668800000, 27861579961.952854],
        [1502755200000, 27120920523.5159],
        [1502841600000, 28217360455.396397],
        [1502928000000, 28267299986.362434],
        [1503014400000, 27861556924.972927],
        [1503100800000, 27916001533.15132],
        [1503187200000, 27786029857.799793],
        [1503273600000, 30337762878.381535],
        [1503360000000, 29400686225.9616],
        [1503446400000, 29842462532.356785],
        [1503532800000, 30320399298.400482],
        [1503619200000, 31008765515.118908],
        [1503705600000, 31031409011.107296],
        [1503792000000, 32370755160.220387],
        [1503878400000, 32458167725.598194],
        [1503964800000, 34597128016.55826],
        [1504051200000, 35745271112.44432],
        [1504137600000, 36132449087.43318],
        [1504224000000, 36461379831.851746],
        [1504310400000, 32698140251.89353],
        [1504396800000, 33037915918.358833],
        [1504483200000, 28302865423.90704],
        [1504569600000, 30127332537.80147],
        [1504656000000, 31478005326.47652],
        [1504742400000, 31126200677.32708],
        [1504828800000, 28775477244.445538],
        [1504915200000, 28329354911.608036],
        [1505001600000, 27681278352.42371],
        [1505088000000, 28036287702.51409],
        [1505174400000, 27485022514.19706],
        [1505260800000, 26236732489.431683],
        [1505347200000, 20278768201.760014],
        [1505433600000, 23369190516.539547],
        [1505520000000, 23173710726.76121],
        [1505606400000, 23638636295.481937],
        [1505692800000, 27269907986.777264],
        [1505779200000, 26563613422.607845],
        [1505865600000, 26471592194.703312],
        [1505952000000, 24396830727.284477],
        [1506038400000, 24969981050.950962],
        [1506124800000, 26846246384.287216],
        [1506211200000, 26609714675.1668],
        [1506297600000, 27316005855.910435],
        [1506384000000, 26926232192.184574],
        [1506470400000, 28658972908.46477],
        [1506556800000, 27919976584.315685],
        [1506643200000, 27336170625.13594],
        [1506729600000, 28209139740.688995],
        [1506816000000, 28442001971.008823],
        [1506902400000, 28082506249.729813],
        [1506988800000, 27742435562.94357],
        [1507075200000, 27825239443.20582],
        [1507161600000, 27894664349.155266],
        [1507248000000, 29227935374.982],
        [1507334400000, 29412044193.592102],
        [1507420800000, 29175396936.827038],
        [1507507200000, 28119791744.856277],
        [1507593600000, 28506074217.358105],
        [1507680000000, 28827311270.280365],
        [1507766400000, 28715264952.728848],
        [1507852800000, 32136884949.300323],
        [1507939200000, 31893398413.31946],
        [1508025600000, 31903683068.492874],
        [1508112000000, 31583306698.85391],
        [1508198400000, 30251186909.138367],
        [1508284800000, 29930081747.853603],
        [1508371200000, 29366946306.63264],
        [1508457600000, 28790749976.831596],
        [1508544000000, 28638437008.879925],
        [1508630400000, 28148745034.196087],
        [1508716800000, 27575920805.165585],
        [1508803200000, 28472255904.29292],
        [1508889600000, 28452391049.617855],
        [1508976000000, 28192911056.673782],
        [1509062400000, 28411617292.93479],
        [1509148800000, 28278036785.292797],
        [1509235200000, 28978234226.357674],
        [1509321600000, 29223725935.84833],
        [1509408000000, 28971625622.98315],
        [1509494400000, 27907457658.55725],
        [1509580800000, 27411044537.063046],
        [1509667200000, 29209850779.092915],
        [1509753600000, 28653533860.136066],
        [1509840000000, 28426818646.719738],
        [1509926400000, 28801102723.032696],
        [1510012800000, 28220768686.35795],
        [1510099200000, 29536454012.04308],
        [1510185600000, 30823742534.2921],
        [1510272000000, 28824163917.314198],
        [1510358400000, 30256030132.159668],
        [1510444800000, 30062176933.46014],
        [1510531200000, 30181430682.324654],
        [1510617600000, 32446360785.0723],
        [1510704000000, 32007998528.5458],
        [1510790400000, 31606240405.8994],
        [1510876800000, 31931961399.8328],
        [1510963200000, 33413835153.7437],
        [1511049600000, 33870265876.0073],
        [1511136000000, 35061352161.7675],
        [1511222400000, 34615252499.3341],
        [1511308800000, 36382569909.3727],
        [1511395200000, 39569679447.7058],
        [1511481600000, 45568623853.9817],
        [1511568000000, 44853255083.9293],
        [1511654400000, 45011721609.851],
        [1511740800000, 46496118377.9175],
        [1511827200000, 45528555887.0189],
        [1511913600000, 41136242461.6215],
        [1512000000000, 43624717838.8218],
        [1512086400000, 45142640782.4465],
        [1512172800000, 45151510929.4977],
        [1512259200000, 44394413397.1167],
        [1512345600000, 45400977761.2147],
        [1512432000000, 44907252844.2554],
        [1512518400000, 43118585011.2992],
        [1512604800000, 43465282557.5874],
        [1512691200000, 45019411221.6764],
        [1512777600000, 46391200044.4373],
        [1512864000000, 42998769813.0645],
        [1512950400000, 49007073662.9809],
        [1513036800000, 63736319679.7109],
        [1513123200000, 68377310760.8715],
        [1513209600000, 67885165267.6573],
        [1513296000000, 66789398252.0671],
        [1513382400000, 67685238860.8431],
        [1513468800000, 70249967413.1925],
        [1513555200000, 76958112624.052],
        [1513641600000, 80048859677.1559],
        [1513728000000, 78466047306.039],
        [1513814400000, 78432622738.5783],
        [1513900800000, 64585322053.99],
        [1513987200000, 69087031853.6697],
        [1514073600000, 66252429575.9089],
        [1514160000000, 70871970751.0918],
        [1514246400000, 74770905370.1416],
        [1514332800000, 73903417373.3621],
        [1514419200000, 71200955427.2237],
        [1514505600000, 73004412362.8661],
        [1514592000000, 71238101432.8975],
        [1514678400000, 74906560027.8485],
        [1514764800000, 75056629369.4547],
        [1514851200000, 84822222588.5437],
        [1514937600000, 93178531120.43204],
        [1515024000000, 94681149308.52457],
        [1515110400000, 97269228886.25314],
        [1515196800000, 101852997666.87328],
        [1515283200000, 110167827718.61086],
        [1515369600000, 114769005376.76157],
        [1515456000000, 131894965357.881],
        [1515542400000, 130387621608.28636],
        [1515628800000, 114526940296.8931],
        [1515715200000, 127538307119.98058],
        [1515801600000, 140419500475.92773],
        [1515888000000, 136747164703.8492],
        [1515974400000, 128980953671.9811],
        [1516060800000, 103677753320.31013],
        [1516147200000, 99216267384.45854],
        [1516233600000, 100567876886.70789],
        [1516320000000, 101640355096.62448],
        [1516406400000, 113899479853.58607],
        [1516492800000, 102337202453.45827],
        [1516579200000, 97186332455.0951],
        [1516665600000, 96359326865.87158],
        [1516752000000, 102717867498.02875],
        [1516838400000, 102976145173.28937],
        [1516924800000, 101975238868.2182],
        [1517011200000, 106559849459.01985],
        [1517097600000, 120356395893.4201],
        [1517184000000, 113796120454.081],
        [1517270711070, 113043826616.74722],
        [1517356800000, 102965815556.0668],
        [1517443200000, 107663538775.87933],
        [1517529600000, 99423764457.44884],
        [1517616000000, 89061856063.48083],
        [1517702400000, 91725607014.4353],
        [1517788800000, 81329819333.7154],
        [1517875200000, 66675677460.41152],
        [1517961600000, 76574516020.88303],
        [1518048323681, 73290929917.86972],
        [1518134400000, 78759712266.80615],
        [1518220800000, 84968804616.78447],
        [1518307200000, 83330877020.24867],
        [1518393600000, 79732678121.41658],
        [1518480000000, 84185155652.77072],
        [1518566400000, 81375375877.96892],
        [1518652800000, 89328231140.60481],
        [1518739200000, 90637629785.66489],
        [1518825600000, 91279135809.19406],
        [1518912000000, 94190008583.4062],
        [1518998400000, 89404389580.2483],
        [1519084800000, 90713108252.71552],
        [1519171200000, 88256609941.35635],
        [1519257600000, 79118626282.25874],
        [1519344000000, 79442544254.30933],
        [1519430400000, 84567348658.27484],
        [1519516800000, 81068136131.60957],
        [1519603200000, 82445384433.1456],
        [1519689600000, 84519903484.51317],
        [1519776000000, 85086932065.08655],
        [1519862400000, 82862945950.985],
        [1519948800000, 83792455743.35178],
        [1520035200000, 83297136123.66147],
        [1520121600000, 82708301723.55193],
        [1520208000000, 82676499793.85762],
        [1520294400000, 82340571913.28821],
        [1520380800000, 79250313700.00667],
        [1520467200000, 73790376421.96692],
        [1520553600000, 68568491974.3163],
        [1520640000000, 69730146644.19576],
        [1520726400000, 67198010840.67886],
        [1520812800000, 69985307146.27713],
        [1520899200000, 68245214878.500916],
        [1520985600000, 67211282137.89535],
        [1521072000000, 59738408621.9634],
        [1521158400000, 60148621435.96764],
        [1521244800000, 59712695037.709236],
        [1521331200000, 55031620530.236534],
        [1521417600000, 52938616457.57076],
        [1521504000000, 54560292401.575806],
        [1521590400000, 54486305509.98849],
        [1521676800000, 55179662649.949524],
        [1521763200000, 52885690223.31916],
        [1521849600000, 52936241666.49759],
        [1521936000000, 51114768972.69823],
        [1522022400000, 51114766847.01147],
        [1522108800000, 47991343456.18946],
        [1522195200000, 44211929435.11478],
        [1522281600000, 43718505250.02615],
        [1522368000000, 37953505528.35885],
        [1522454400000, 38946452754.23128],
        [1522540800000, 39000868214.27854],
        [1522627200000, 37365640069.82155],
        [1522713600000, 37935548084.04267],
        [1522800000000, 40989129292.88682],
        [1522886400000, 37503095784.24193],
        [1522972800000, 37923466118.640205],
        [1523059200000, 36495759521.398056],
        [1523145600000, 37838661360.00252],
        [1523232000000, 39182417250.92415],
        [1523318400000, 39367328706.210175],
        [1523404800000, 40749602575.92843],
        [1523491200000, 42209337332.354004],
        [1523577600000, 48265439663.55072],
        [1523664000000, 48313044128.328636],
        [1523750400000, 48928578121.79471],
        [1523836800000, 51944970387.794846],
        [1523923200000, 50134949937.02363],
        [1524009600000, 49290625426.76034],
        [1524096000000, 51170694522.39032],
        [1524182400000, 56030501148.69197],
        [1524268800000, 58403874540.99289],
        [1524355200000, 59405441433.091866],
        [1524441600000, 61094825885.43238],
        [1524528000000, 63004894796.41469],
        [1524614400000, 69817174451.75433],
        [1524700800000, 60788471582.80268],
        [1524787200000, 65093958669.996185],
        [1524873600000, 63614677083.81106],
        [1524960000000, 66660769651.39336],
        [1525046400000, 67420561666.31582],
        [1525132800000, 65710538602.21053],
        [1525219200000, 65971279396.12401],
        [1525305600000, 67108704081.6629],
        [1525392000000, 77526578541.87308],
        [1525478400000, 77194381804.21214],
        [1525564800000, 80104681672.56717],
        [1525651200000, 78019321356.67586],
        [1525737600000, 74353698902.32837],
        [1525824000000, 73923190963.45912],
        [1525910400000, 73870430059.20319],
        [1525996800000, 71279821648.68193],
        [1526083200000, 67108755487.4065],
        [1526169600000, 67905759626.548164],
        [1526256000000, 72264507767.86824],
        [1526342400000, 72371889345.97685],
        [1526428800000, 69935482264.3191],
        [1526515200000, 69879914021.89139],
        [1526601600000, 66230317444.47082],
        [1526688000000, 68292780311.34642],
        [1526774400000, 68270520548.85238],
        [1526860800000, 70073661062.42535],
        [1526947200000, 68604235449.74915],
        [1527033600000, 63856048464.81863],
        [1527120000000, 57906139337.23845],
        [1527206400000, 59572815257.15423],
        [1527292800000, 58033414970.12175],
        [1527379200000, 57866488201.56909],
        [1527465600000, 56426783298.552086],
        [1527552000000, 51022311776.744835],
        [1527638400000, 56032720818.86429],
        [1527724800000, 55406697681.99651],
        [1527811200000, 57052511798.36029],
        [1527897600000, 57274516594.26361],
        [1527984000000, 58214660996.41215],
        [1528070400000, 60839012156.67801],
        [1528156800000, 58582699275.82673],
        [1528243200000, 60003382388.410774],
        [1528329600000, 59769818240.26592],
        [1528416000000, 59473431631.17932],
        [1528502400000, 59023188216.61767],
        [1528588800000, 58097979895.17439],
        [1528675200000, 52297773130.593605],
        [1528761600000, 52922017100.80877],
        [1528848000000, 49526210494.67609],
        [1528934400000, 47513487722.27301],
        [1529020800000, 51705041671.85295],
        [1529107200000, 48591417578.37274],
        [1529193600000, 49365820389.2502],
        [1529280000000, 49208886719.57955],
        [1529366400000, 51325180407.15406],
        [1529452800000, 53296770078.92655],
        [1529539200000, 53083203703.900185],
        [1529625600000, 52182383816.74685],
        [1529712000000, 46506729655.2268],
        [1529798400000, 47422371814.92663],
        [1529884800000, 45701228403.58992],
        [1529971200000, 46032293630.19799],
        [1530057600000, 43200150825.554535],
        [1530144000000, 43938995892.49492],
        [1530230400000, 42240235077.69152],
        [1530316800000, 43417851340.14835],
        [1530403200000, 45125931776.53015],
        [1530489600000, 44959418542.5589],
        [1530576000000, 47442809332.14308],
        [1530662400000, 46185301814.019936],
        [1530748800000, 46548745985.99023],
        [1530835200000, 46925838686.869354],
        [1530921600000, 47006819801.91246],
        [1531008000000, 48417457589.09996],
        [1531094400000, 48656609005.5521],
        [1531180800000, 47248080178.500404],
        [1531267200000, 43440645610.1465],
        [1531353600000, 44582775234.01637],
        [1531440000000, 43058583252.44312],
        [1531526400000, 43417089532.37335],
        [1531612800000, 43478040808.58002],
        [1531699200000, 44980010137.45454],
        [1531785600000, 48172237051.237144],
        [1531872000000, 50093375646.64564],
        [1531958400000, 47968300051.31038],
        [1532044800000, 46849108851.47914],
        [1532131200000, 45085461873.32423],
        [1532217600000, 46191354520.09791],
        [1532304000000, 45944085755.28768],
        [1532390400000, 45185546979.493904],
        [1532476800000, 48149869331.10582],
        [1532563200000, 47304793330.43528],
        [1532649600000, 46520159725.86072],
        [1532736000000, 47245239452.64461],
        [1532822400000, 47046915978.43651],
        [1532908800000, 47108994325.494484],
        [1532995200000, 46276223652.12072],
        [1533081600000, 43685748084.88833],
        [1533168000000, 42171077988.81626],
        [1533254400000, 41537743616.40968],
        [1533340800000, 42196294616.106125],
        [1533427200000, 41145986285.06191],
        [1533513600000, 41385054072.208534],
        [1533600000000, 40953537749.75725],
        [1533686400000, 38223074883.08225],
        [1533772800000, 35791291191.22547],
        [1533859200000, 36814735141.44988],
        [1533945600000, 33664024161.54582],
        [1534032000000, 32133841450.27239],
        [1534118400000, 32205233601.695885],
        [1534204800000, 28868747042.36861],
        [1534291200000, 28143782821.250084],
        [1534377600000, 28457713331.487896],
        [1534464000000, 29013771356.624912],
        [1534550400000, 31900584937.308926],
        [1534636800000, 29731648689.35023],
        [1534723200000, 30373462667.100254],
        [1534809600000, 27502092651.06376],
        [1534896000000, 28451639052.689304],
        [1534982400000, 27322879481.120026],
        [1535068800000, 27993194187.78576],
        [1535155200000, 28606495007.326126],
        [1535241600000, 28227624888.171623],
        [1535328000000, 27764964383.52451],
        [1535414400000, 29048911774.353527],
        [1535500800000, 30108250592.80309],
        [1535587200000, 29316888089.791355],
        [1535673600000, 28983027590.772324],
        [1535760000000, 28697700432.171036],
        [1535846400000, 30072245775.768417],
        [1535932800000, 29960438166.83158],
        [1536019200000, 29383711204.563396],
        [1536105600000, 29050036562.782276],
        [1536192000000, 23699297868.898872],
        [1536278400000, 23333059272.415604],
        [1536364800000, 21937448595.359604],
        [1536451200000, 20053781147.975986],
        [1536537600000, 19952064720.055923],
        [1536624000000, 19995395751.499054],
        [1536710400000, 18758112117.52692],
        [1536796800000, 18646346738.68822],
        [1536883200000, 21593950253.338764],
        [1536969600000, 21564238470.858032],
        [1537056000000, 22752839429.228737],
        [1537142400000, 22455303413.059746],
        [1537228800000, 20051344766.00986],
        [1537315200000, 21444053203.54171],
        [1537401600000, 21345953864.748203],
        [1537488000000, 22905841034.88813],
        [1537574400000, 25265891712.53375],
        [1537660800000, 24698456681.437977],
        [1537747200000, 25008142946.45999],
        [1537833600000, 23326983438.531864],
        [1537920000000, 22430430093.888412],
        [1538006400000, 21996083267.123955],
        [1538092800000, 23427304227.532307],
        [1538179200000, 22728786275.759216],
        [1538265600000, 23704835590.94445],
        [1538352000000, 23810956054.875587],
        [1538438400000, 23660128684.62694],
        [1538524800000, 23102075847.08329],
        [1538611200000, 22578266460.225845],
        [1538697600000, 22746296668.385403],
        [1538784000000, 23346587563.266563],
        [1538870400000, 23047878586.785908],
        [1538956800000, 23108155611.30217],
        [1539043200000, 23515310563.890266],
        [1539129600000, 23360773268.500908],
        [1539216000000, 23136932206.46423],
        [1539302400000, 19696329362.922806],
        [1539388800000, 20205253420.305264],
        [1539475200000, 20618468437.571827],
        [1539561600000, 20154043106.326366],
        [1539648000000, 21758441436.75235],
        [1539734400000, 21797843239.523815],
        [1539820800000, 21665771379.758472],
        [1539907200000, 20978898040.00994],
        [1539993600000, 20944981899.996338],
        [1540080000000, 21245961049.679523],
        [1540166400000, 21194118735.35679],
        [1540252800000, 21065455939.758324],
        [1540339200000, 20971271070.992756],
        [1540425600000, 20915202600.824116],
        [1540512000000, 20806627800.42047],
        [1540598400000, 20912618603.71542],
        [1540684800000, 20895701368.92201],
        [1540771200000, 21050011679.152504],
        [1540857600000, 20141044164.60023],
        [1540944000000, 20319037427.370758],
        [1541030400000, 20443374890.788353],
        [1541116800000, 20504797883.626476],
        [1541203200000, 20827898393.480133],
        [1541289600000, 20607885132.10309],
        [1541376000000, 21305283379.90364],
        [1541462400000, 21542826214.062332],
        [1541548800000, 22564364808.51298],
        [1541635200000, 22526589761.074043],
        [1541721600000, 22014228200.633472],
        [1541808000000, 21743426340.7329],
        [1541894400000, 21899054776.88674],
        [1541980800000, 21915333222.812687],
        [1542067200000, 21835416808.274967],
        [1542153600000, 21526572966.38387],
        [1542240000000, 18991988237.013927],
        [1542326400000, 18833157524.209366],
        [1542412800000, 18173414086.234],
        [1542499200000, 18140877471.665012],
        [1542585600000, 18355720231.296604],
        [1542672000000, 15568477359.93989],
        [1542758400000, 13480873430.564436],
        [1542844800000, 14199281074.8261],
        [1542931200000, 13145643371.545767],
        [1543017600000, 12835445196.81356],
        [1543104000000, 11817983686.122585],
        [1543190400000, 12164898237.895905],
        [1543276800000, 11351812370.701126],
        [1543363200000, 11441393467.752829],
        [1543449600000, 12659773332.697258],
        [1543536000000, 12182154959.33421],
        [1543622400000, 11708062196.90978],
        [1543708800000, 12257640966.96176],
        [1543795200000, 11998542053.561508],
        [1543881600000, 11252886409.15223],
        [1543968000000, 11368927707.526571],
        [1544054400000, 10626501697.030668],
        [1544140800000, 9411545128.787203],
        [1544227200000, 9610584223.413237],
        [1544313600000, 9437707619.370005],
        [1544400000000, 9708220956.92728],
        [1544486400000, 9323959135.591385],
        [1544572800000, 9111391456.283594],
        [1544659200000, 9261188928.81479],
        [1544745600000, 8913719805.958307],
        [1544832000000, 8696897485.61424],
        [1544918400000, 8697750641.337017],
        [1545004800000, 8803144021.88444],
        [1545091200000, 9769901072.309902],
        [1545177600000, 10392925527.200373],
        [1545264000000, 10336785710.638008],
        [1545350400000, 11865192793.01515],
        [1545436800000, 11228235724.642712],
        [1545523200000, 11930676648.438414],
        [1545609600000, 13420144843.953455],
        [1545696000000, 14528137553.21583],
        [1545782400000, 13232839336.104912],
        [1545868800000, 13473635804.054379],
        [1545955200000, 11924339225.89629],
        [1546041600000, 13935614204.209122],
        [1546128000000, 14676638050.826061],
        [1546214400000, 14310166049.405546],
        [1546300800000, 13677300455.463268],
        [1546387200000, 14392313036.760887],
        [1546473600000, 15922214147.12651],
        [1546560000000, 15277722818.958939],
        [1546646400000, 15940848169.781616],
        [1546732800000, 16018302447.242657],
        [1546819200000, 16115376656.500986],
        [1546905600000, 15614190928.600574],
        [1546992000000, 15452130779.24307],
        [1547078400000, 15516781022.69595],
        [1547164800000, 13229569871.910849],
        [1547251200000, 12975985683.226751],
        [1547337600000, 12909473758.745958],
        [1547424000000, 11970884149.01689],
        [1547510400000, 13312835355.826275],
        [1547596800000, 12511274618.87375],
        [1547683200000, 12790017531.356869],
        [1547769600000, 12732832650.46048],
        [1547856000000, 12441677743.686483],
        [1547942400000, 12841756778.277468],
        [1548028800000, 12288060353.539341],
        [1548115200000, 12103985769.178844],
        [1548201600000, 12313725371.510918],
        [1548288000000, 12169120160.834082],
        [1548374400000, 12153158417.51909],
        [1548460800000, 12040473330.175814],
        [1548547200000, 12061900817.036028],
        [1548633600000, 11774157608.884583],
        [1548720000000, 11010938397.321363],
        [1548806400000, 10955187408.251522],
        [1548892800000, 11329885765.71566],
        [1548979200000, 11125649845.205639],
        [1549065600000, 11200980015.417768],
        [1549152000000, 11387807226.075611],
        [1549238400000, 11229439070.409464],
        [1549324800000, 11267355154.846409],
        [1549411200000, 11245947689.319769],
        [1549497600000, 10959290841.797949],
        [1549584000000, 10923364917.939417],
        [1549670400000, 12490351594.278725],
        [1549756800000, 12520670865.692413],
        [1549843200000, 13087985837.282696],
        [1549929600000, 12680390197.310715],
        [1550016000000, 12845310573.797472],
        [1550102400000, 12793730452.297075],
        [1550188800000, 12668201016.366795],
        [1550275200000, 12742765419.399738],
        [1550361600000, 12903776985.469166],
        [1550448000000, 14098327882.16335],
        [1550534400000, 15243928177.166456],
        [1550620800000, 15072819715.874296],
        [1550707200000, 15611440200.106087],
        [1550793600000, 15225793598.680445],
        [1550880000000, 15537602228.061953],
        [1550966400000, 16550258314.845478],
        [1551052800000, 14284713525.163023],
        [1551139200000, 14472468022.306929],
        [1551225600000, 14307393197.668602],
        [1551312000000, 14178777736.552544],
        [1551398400000, 14233212113.400938],
        [1551484800000, 14187938674.158926],
        [1551571200000, 13922485507.386408],
        [1551657600000, 13708633834.78851],
        [1551744000000, 13206850625.680403],
        [1551830400000, 14358338788.931305],
        [1551916800000, 14452766138.766956],
        [1552003200000, 14369621725.628769],
        [1552089600000, 14031460882.113087],
        [1552176000000, 14428962251.829548],
        [1552262400000, 14309692872.294827],
        [1552348800000, 13919712301.577475],
        [1552435200000, 14031839989.199167],
        [1552521600000, 13852442749.06418],
        [1552608000000, 13903058196.646849],
        [1552694400000, 14361253091.20203],
        [1552780800000, 14864545411.806246],
        [1552867200000, 14586546977.699165],
        [1552953600000, 14504816696.08246],
        [1553040000000, 14588139258.964716],
        [1553126400000, 14646332154.545843],
        [1553212800000, 14226418657.384989],
        [1553299200000, 14349872413.090286],
        [1553385600000, 14458951512.820686],
        [1553472000000, 14349083379.676003],
        [1553558400000, 14114508110.83552],
        [1553644800000, 14127347666.266151],
        [1553731200000, 14728909325.816017],
        [1553817600000, 14528588960.681654],
        [1553904000000, 15091573435.250275],
        [1553990400000, 15005382590.00451],
        [1554076800000, 14930496588.280657],
        [1554163200000, 14928001079.066639],
        [1554249600000, 17376379496.109585],
        [1554336000000, 17073994347.06151],
        [1554422400000, 16631238666.397068],
        [1554508800000, 17415767438.96335],
        [1554595200000, 17431813826.27367],
        [1554681600000, 18330615695.15078],
        [1554768000000, 19005528101.207676],
        [1554854400000, 18514941900.58221],
        [1554940800000, 18738487750.9329],
        [1555027200000, 17442825237.19966],
        [1555113600000, 17386399385.122326],
        [1555200000000, 17327865226.321712],
        [1555286400000, 17700075886.552334],
        [1555372800000, 16995977336.81406],
        [1555459200000, 17609171351.003345],
        [1555545600000, 17588516336.63911],
        [1555632000000, 18314771488.822433],
        [1555718400000, 18342659764.36273],
        [1555804800000, 18306343766.281067],
        [1555891200000, 17972610400.17828],
        [1555977600000, 18087557354.45475],
        [1556064000000, 18058590378.524723],
        [1556150400000, 17513743539.566673],
        [1556236800000, 16278218288.123909],
        [1556323200000, 16557928986.13092],
        [1556409600000, 16729345051.253407],
        [1556496000000, 16559733009.547436],
        [1556582400000, 16314567668.36402],
        [1556668800000, 17017994744.389439],
        [1556755200000, 16881275146.405905],
        [1556841600000, 17057211134.916904],
        [1556928000000, 17632782365.111347],
        [1557014400000, 17251245248.674953],
        [1557100800000, 17213549032.231903],
        [1557187200000, 18328559385.30721],
        [1557273600000, 18164993797.052807],
        [1557360000000, 18006362667.522446],
        [1557446400000, 18116042476.197884],
        [1557532800000, 18289505929.628407],
        [1557619200000, 21127886686.212933],
        [1557705600000, 19825564759.39219],
        [1557792000000, 20785018542.369095],
        [1557878400000, 23191421813.968952],
        [1557964800000, 26321593931.32679],
        [1558051200000, 27977152978.337208],
        [1558137600000, 25882741084.84837],
        [1558224000000, 24992104363.05817],
        [1558310400000, 27696392705.66153],
        [1558396800000, 26710731424.55328],
        [1558483200000, 27208222408.161823],
        [1558569600000, 25986179169.035725],
        [1558656000000, 26015066752.046535],
        [1558742400000, 26403380130.094868],
        [1558828800000, 26638407046.872597],
        [1558915200000, 28248518480.178585],
        [1559001600000, 29061104048.029087],
        [1559088000000, 28849462681.914993],
        [1559174400000, 28545915354.884007],
        [1559260800000, 27163085813.469257],
        [1559347200000, 28499454382.30483],
        [1559433600000, 28173804952.878986],
        [1559520000000, 28695353819.42512],
        [1559606400000, 26744262117.273018],
        [1559692800000, 25505094563.584915],
        [1559779200000, 26217390413.075325],
        [1559865600000, 26581663259.716743],
        [1559952000000, 26701545587.49556],
        [1560038400000, 26034305601.98276],
        [1560124800000, 24672828577.74306],
        [1560211200000, 26297325019.25777],
        [1560297600000, 26090193634.664776],
        [1560384000000, 27568882097.168007],
        [1560470400000, 27144233034.692554],
        [1560556800000, 28177766390.07106],
        [1560643200000, 28555069614.98048],
        [1560729600000, 28730739929.02647],
        [1560816000000, 29075955330.534096],
        [1560902400000, 28216787936.482452],
        [1560988800000, 28662304286.474934],
        [1561075200000, 29062957235.75888],
        [1561161600000, 31419443062.588604],
        [1561248000000, 33084459417.56612],
        [1561334400000, 32863219243.396492],
        [1561420800000, 33154422291.905163],
        [1561507200000, 33909642984.874393],
        [1561593600000, 36033928259.050896],
        [1561680000000, 31578278682.118446],
        [1561766400000, 33044592666.437965],
        [1561852800000, 34306768610.59132],
        [1561939200000, 31176379854.59102],
        [1562025600000, 31498970007.217487],
        [1562112000000, 30959350149.490692],
        [1562198400000, 32463482348.950417],
        [1562284800000, 30439535096.682316],
        [1562371200000, 30740130568.29118],
        [1562457600000, 30937887121.98499],
        [1562544000000, 32585483973.188705],
        [1562630400000, 33325496553.950596],
        [1562716800000, 32850299631.681156],
        [1562803200000, 30956973577.480614],
        [1562889600000, 28636795382.556053],
        [1562976000000, 29477046976.610035],
        [1563062400000, 28810921295.770554],
        [1563148800000, 24246464997.184734],
        [1563235200000, 24761194859.108467],
        [1563321600000, 21404242629.096542],
        [1563408000000, 22555777896.46238],
        [1563494400000, 24469199432.985012],
        [1563580800000, 23638366539.887043],
        [1563667200000, 24370718738.349724],
        [1563753600000, 24197616725.96213],
        [1563840000000, 23300408113.461063],
        [1563926400000, 22833250656.49764],
        [1564012800000, 23289112074.867935],
        [1564099200000, 23512755315.51417],
        [1564185600000, 23510568057.420704],
        [1564272000000, 22113111388.41074],
        [1564358400000, 22676505895.192112],
        [1564444800000, 22532332968.39789],
        [1564531200000, 22459680355.763912],
        [1564617600000, 23395542240.652126],
        [1564704000000, 23212512549.342392],
        [1564790400000, 23340842011.61525],
        [1564876800000, 23728815859.720734],
        [1564963200000, 23801827044.905396],
        [1565049600000, 24942936105.273483],
        [1565136000000, 24246876267.774906],
        [1565222400000, 24246033283.292213],
        [1565308800000, 23748361769.302696],
        [1565395200000, 22496236375.06767],
        [1565481600000, 22164896130.494186],
        [1565568000000, 23199201016.471577],
        [1565654400000, 22642683362.924953],
        [1565740800000, 22436740682.994324],
        [1565827200000, 19905411203.78774],
        [1565913600000, 20166571469.133984],
        [1566000000000, 19875538705.637814],
        [1566086400000, 19947175522.901073],
        [1566172800000, 20870523158.477386],
        [1566259200000, 21805354686.68139],
        [1566345600000, 21099092813.884506],
        [1566432000000, 20090814029.40346],
        [1566518400000, 20579733353.237606],
        [1566604800000, 20886288389.848602],
        [1566691200000, 20497351303.834663],
        [1566777600000, 20024597154.008755],
        [1566864000000, 20251623745.88952],
        [1566950400000, 20099329728.45356],
        [1567036800000, 18598885730.967613],
        [1567123200000, 18182244936.177788],
        [1567209600000, 18137815999.552658],
        [1567296000000, 18507223778.142094],
        [1567382400000, 18351207184.536457],
        [1567468800000, 19113080684.062847],
        [1567555200000, 19324838795.229195],
        [1567641600000, 18790062457.39357],
        [1567728000000, 18756294625.6701],
        [1567814400000, 18244685096.248436],
        [1567900800000, 19145726416.543644],
        [1567987200000, 19532846873.76019],
        [1568073600000, 19501252636.159073],
        [1568160000000, 19398770840.28421],
        [1568246400000, 19174005533.27935],
        [1568332800000, 19496200878.17006],
        [1568419200000, 19522068957.29138],
        [1568505600000, 20297648872.897217],
        [1568592000000, 20389680178.128475],
        [1568678400000, 21223483200.061485],
        [1568764800000, 22573128316.388668],
        [1568851200000, 22689784462.139828],
        [1568937600000, 23838258551.56916],
        [1569024000000, 23468933218.644382],
        [1569110400000, 23241002834.289497],
        [1569196800000, 22812625864.180454],
        [1569283200000, 21659224040.642677],
        [1569369600000, 18418924820.330776],
        [1569456000000, 18387961477.667904],
        [1569542400000, 18012013650.053577],
        [1569628800000, 18764435751.517056],
        [1569715200000, 18778305331.488625],
        [1569801600000, 18340364798.35873],
        [1569888000000, 19371744530.934284],
        [1569974400000, 19506079956.197968],
        [1570060800000, 19515449012.870743],
        [1570147200000, 19083597997.072533],
        [1570233600000, 19013321261.257965],
        [1570320000000, 19009613625.563313],
        [1570406400000, 18414715905.660275],
        [1570492800000, 19492030354.883118],
        [1570579200000, 19537703569.993225],
        [1570665600000, 20896473661.552616],
        [1570752000000, 20655168596.9981],
        [1570838400000, 19490975562.25558],
        [1570924800000, 19456204702.653095],
        [1571011200000, 19636637034.909355],
        [1571097600000, 20182332549.589005],
        [1571184000000, 19551821811.16607],
        [1571270400000, 18931847830.822693],
        [1571356800000, 19185722528.28324],
        [1571443200000, 18701016660.84209],
        [1571529600000, 18584020382.348053],
        [1571616000000, 18941808902.195774],
        [1571702400000, 18867384241.158245],
        [1571788800000, 18558740241.107227],
        [1571875200000, 17459645841.196804],
        [1571961600000, 17399743469.054173],
        [1572048000000, 19637705780.19648],
        [1572134400000, 19404848951.545723],
        [1572220800000, 19901421621.580063],
        [1572307200000, 19665873705.505238],
        [1572393600000, 20630859876.73889],
        [1572480000000, 19844553056.696297],
        [1572566400000, 19802989402.16403],
        [1572652800000, 19841838289.17925],
        [1572739200000, 19859708138.2688],
        [1572825600000, 19716789940.491096],
        [1572912000000, 20141787853.298763],
        [1572998400000, 20492280668.62314],
        [1573084800000, 20737428494.66662],
        [1573171200000, 20266199492.292927],
        [1573257600000, 19922829399.444794],
        [1573344000000, 20084569101.090305],
        [1573430400000, 20498434183.96548],
        [1573516800000, 20053149255.624203],
        [1573603200000, 20253767836.417084],
        [1573689600000, 20432066850.22492],
        [1573776000000, 20069162255.474976],
        [1573862400000, 19545090767.174282],
        [1573948800000, 19844844655.043144],
        [1574035200000, 19958777557.908268],
        [1574121600000, 19368856364.95644],
        [1574208000000, 19065767350.091602],
        [1574294400000, 18988847253.54836],
        [1574380800000, 17491549308.65305],
        [1574467200000, 16287556192.270582],
        [1574553600000, 16542855738.706274],
        [1574640000000, 15475834863.359888],
        [1574726400000, 15886464708.930641],
        [1574812800000, 16079208209.774965],
        [1574899200000, 16624532572.655481],
        [1574985600000, 16410189698.68012],
        [1575072000000, 16758887510.141882],
        [1575158400000, 16526371128.750948],
        [1575244800000, 16406763648.56546],
        [1575331200000, 16190436587.112755],
        [1575417600000, 16020038545.991947],
        [1575504000000, 15854160155.08143],
        [1575590400000, 16080828336.473515],
        [1575676800000, 16139573040.723936],
        [1575763200000, 16002334420.498518],
        [1575849600000, 16377855974.169815],
        [1575936000000, 16043706474.69232],
        [1576022400000, 15849875150.02611],
        [1576108800000, 15578842749.139772],
        [1576195200000, 15788957248.11528],
        [1576281600000, 15750794124.489826],
        [1576368000000, 15443355857.400137],
        [1576454400000, 15508762801.226492],
        [1576540800000, 14450939875.930876],
        [1576627200000, 13287532088.063696],
        [1576713600000, 14431239759.79371],
        [1576800000000, 13910360785.04459],
        [1576886400000, 13960011409.004557],
        [1576972800000, 13835225941.813122],
        [1577059200000, 14364983064.322144],
        [1577145600000, 13932981482.574308],
        [1577232000000, 13937886620.894697],
        [1577318400000, 13638505587.030851],
        [1577404800000, 13697038140.554945],
        [1577491200000, 13773176348.366127],
        [1577577600000, 13985982758.17354],
        [1577664000000, 14666626034.39065],
        [1577750400000, 14330460495.39055],
        [1577836800000, 14097451631.783957],
        [1577923200000, 14230588104.281237],
        [1578009600000, 13859793329.814827],
        [1578096000000, 14613194775.854177],
        [1578182400000, 14636213160.069315],
        [1578268800000, 14738834254.266087],
        [1578355200000, 15692578635.119993],
        [1578441600000, 15656491188.064734],
        [1578528000000, 15364547561.67077],
        [1578614400000, 14998595868.023438],
        [1578700800000, 15647071448.436348],
        [1578787200000, 15564837467.520994],
        [1578873600000, 15859031094.44091],
        [1578960000000, 15715287657.358463],
        [1579046400000, 18075462340.568443],
        [1579132800000, 18104742352.015366],
        [1579219200000, 17869543294.566414],
        [1579305600000, 18727969032.79025],
        [1579392000000, 19138755594.52358],
        [1579478400000, 18220366702.244606],
        [1579564800000, 18243871990.6739],
        [1579651200000, 18460475135.804626],
        [1579737600000, 18358377435.95901],
        [1579824000000, 17722969842.693172],
        [1579910400000, 17777723072.283943],
        [1579996800000, 17587666193.195988],
        [1580083200000, 18279197138.315884],
        [1580169600000, 18576520021.386745],
        [1580256000000, 19212409346.36024],
        [1580342400000, 19140249467.28951],
        [1580428800000, 20090242968.224346],
        [1580515200000, 19751392940.997242],
        [1580601600000, 20079997942.89275],
        [1580688000000, 20619310835.276108],
        [1580774400000, 20791014743.301926],
        [1580860800000, 20665245888.10715],
        [1580947200000, 22414850796.683693],
        [1581033600000, 23314707733.86607],
        [1581120000000, 24407934017.312393],
        [1581206400000, 24478412143.01876],
        [1581292800000, 25011126326.73704],
        [1581379200000, 24577280465.17777],
        [1581465600000, 25966283262.662064],
        [1581552000000, 29001954205.996136],
        [1581638400000, 29312880235.867893],
        [1581724800000, 31059453816.69378],
        [1581811200000, 28909877146.05339],
        [1581897600000, 28746450516.039288],
        [1581984000000, 29075052114.63163],
        [1582070400000, 31034929114.66128],
        [1582156800000, 28662558273.135616],
        [1582243200000, 28258309183.25315],
        [1582329600000, 29114570356.366127],
        [1582416000000, 28657670954.134422],
        [1582502400000, 30135977087.940575],
        [1582588800000, 28974292556.854683],
        [1582675200000, 27274600634.783813],
        [1582761600000, 24823014146.598347],
        [1582848000000, 24879553838.25944],
        [1582934400000, 25137364865.97101],
        [1583020800000, 24105658618.96741],
        [1583107200000, 24049874527.90718],
        [1583193600000, 25392608994.495888],
        [1583280000000, 24709361638.74632],
        [1583366400000, 24635535324.34428],
        [1583452800000, 25072941968.371387],
        [1583539200000, 26861442576.626507],
        [1583625600000, 26199142579.509506],
        [1583712000000, 22145350491.45419],
        [1583798400000, 22001125399.41239],
        [1583884800000, 22178131746.591427],
        [1583971200000, 21375073649.951973],
        [1584057600000, 11956631650.303354],
        [1584144000000, 14567054564.19822],
        [1584230400000, 13505998666.239887],
        [1584316800000, 13756072458.523424],
        [1584403200000, 12178760413.015514],
        [1584489600000, 12991193734.51619],
        [1584576000000, 12900856057.87349],
        [1584662400000, 15068113354.89011],
        [1584748800000, 14451395504.502293],
        [1584835200000, 14575207631.098148],
        [1584921600000, 13499834314.773163],
        [1585008000000, 14944699607.313162],
        [1585094400000, 15385255695.77982],
        [1585180800000, 15020580578.564753],
        [1585267200000, 15277363230.972204],
        [1585353600000, 14958737456.74538],
        [1585440000000, 14468066254.835968],
        [1585526400000, 13819843925.360764],
        [1585612800000, 14615131244.892355],
        [1585699200000, 14699970260.177477],
        [1585785600000, 14896572551.520117],
        [1585872000000, 15522802609.007648],
        [1585958400000, 15597757554.270603],
        [1586044800000, 15898519073.894499],
        [1586131200000, 15770466064.128725],
        [1586217600000, 18679968361.111393],
        [1586304000000, 18166748814.85624],
        [1586390400000, 19089126835.76443],
        [1586476800000, 18787453383.948494],
        [1586563200000, 17375118634.991734],
        [1586649600000, 17489565908.771362],
        [1586736000000, 17790409219.818943],
        [1586822400000, 17316641314.145325],
        [1586908800000, 17559917949.258163],
        [1586995200000, 16933905371.155535],
        [1587081600000, 18989526634.23506],
        [1587168000000, 18844786940.26037],
        [1587254400000, 20686869606.075146],
        [1587340800000, 19894212517.443478],
        [1587427200000, 18894679854.84961],
        [1587513600000, 18883297426.60788],
        [1587600000000, 20165266729.19022],
        [1587686400000, 20371607313.859978],
        [1587772800000, 20756708748.58927],
        [1587859200000, 21449928671.504494],
        [1587945600000, 21798344583.464844],
        [1588032000000, 21715118585.744934],
        [1588118400000, 21824818237.698734],
        [1588204800000, 23796586177.48911],
        [1588291200000, 22894189506.373463],
        [1588377600000, 23540629181.114624],
        [1588464000000, 23700169401.049606],
        [1588550400000, 23254687127.813503],
        [1588636800000, 22819858077.111965],
        [1588723200000, 22740460359.466698],
        [1588809600000, 22529347281.111214],
        [1588896000000, 23678773321.927563],
        [1588982400000, 23476076684.66088],
        [1589068800000, 23328821609.133904],
        [1589155200000, 20859785783.852856],
        [1589241600000, 20616754314.258286],
        [1589328000000, 20993946147.950317],
        [1589414400000, 22169692740.7452],
        [1589500800000, 22562017222.46954],
        [1589587200000, 21656663418.09443],
        [1589673600000, 22256509489.004852],
        [1589760000000, 22938430778.9383],
        [1589846400000, 23793534838.638046],
        [1589932800000, 23656360817.218487],
        [1590019200000, 23303624068.60854],
        [1590105600000, 22089212414.442772],
        [1590192000000, 22960218146.427925],
        [1590278400000, 22943289960.014706],
        [1590364800000, 22417907964.48714],
        [1590451200000, 22639118588.39778],
        [1590537600000, 22325672542.330963],
        [1590624000000, 23085068545.910442],
        [1590710400000, 24328143646.44253],
        [1590796800000, 24504031642.684784],
        [1590883200000, 27012940828.466957],
        [1590969600000, 25671662155.409367],
        [1591056000000, 27559451838.43636],
        [1591142400000, 26414164713.757725],
        [1591228800000, 27137533088.4328],
        [1591315200000, 27105225697.6403],
        [1591401600000, 26722521046.76495],
        [1591488000000, 26923196533.750423],
        [1591574400000, 27129407338.340355],
        [1591660800000, 27441367803.298782],
        [1591747200000, 27188436885.46926],
        [1591833600000, 27480866985.686268],
        [1591920000000, 25655172292.438347],
        [1592006400000, 26414947184.76374],
        [1592092800000, 26503078976.085632],
        [1592179200000, 25856836267.17579],
        [1592265600000, 25743924409.197376],
        [1592352000000, 26206196967.82947],
        [1592438400000, 26079437153.80169],
        [1592524800000, 25847377552.221436],
        [1592611200000, 25534271650.26011],
        [1592697600000, 25501270877.342506],
        [1592784000000, 25381090910.620564],
        [1592870400000, 27083046206.889904],
        [1592956800000, 27097142032.576195],
        [1593043200000, 26239099771.426495],
        [1593129600000, 25963410301.573772],
        [1593216000000, 25643178437.809326],
        [1593302400000, 24650756780.212147],
        [1593388800000, 25059706534.523754],
        [1593475200000, 25465535919.172665],
        [1593561600000, 25169333234.47631],
        [1593648000000, 25738012877.807854],
        [1593734400000, 25284990219.958416],
        [1593820800000, 25132071680.625286],
        [1593907200000, 25572481115.808292],
        [1593993600000, 25442148685.628662],
        [1594080000000, 26966777214.595856],
        [1594166400000, 26667827703.348965],
        [1594252800000, 27538955079.081337],
        [1594339200000, 27017961197.637337],
        [1594425600000, 26917385878.316235],
        [1594512000000, 26712364780.153114],
        [1594598400000, 26889793823.339703],
        [1594684800000, 26757968884.421734],
        [1594771200000, 26870705032.694412],
        [1594857600000, 26686755678.99533],
        [1594944000000, 26142878196.66504],
        [1595030400000, 26015174195.367386],
        [1595116800000, 26352655621.666836],
        [1595203200000, 26715274452.48371],
        [1595289600000, 26401433618.61543],
        [1595376000000, 27404691587.94453],
        [1595462400000, 29528105819.948067],
        [1595548800000, 30714049723.16856],
        [1595635200000, 31236557399.18939],
        [1595721600000, 34136825749.960796],
        [1595808000000, 34754323492.16516],
        [1595894400000, 35873239729.40686],
        [1595980800000, 35422047874.02414],
        [1596067200000, 35553131948.946304],
        [1596153600000, 37564052087.124535],
        [1596240000000, 38794882275.39032],
        [1596326400000, 43411673380.48453],
        [1596412800000, 41469942047.79405],
        [1596499200000, 43272376856.193855],
        [1596585600000, 43787281284.318275],
        [1596672000000, 44844619093.01642],
        [1596758400000, 44217734699.095276],
        [1596844800000, 42541561650.30221],
        [1596931200000, 43821378072.89572],
        [1597017600000, 43682388559.8162],
        [1597104000000, 44298575284.664795],
        [1597190400000, 42639702440.41078],
        [1597276800000, 43436076387.69582],
        [1597363200000, 47693538253.1255],
        [1597449600000, 49022330904.64755],
        [1597536000000, 48462771178.61529],
        [1597622400000, 48635333662.57019],
        [1597708800000, 48259667903.08671],
        [1597795200000, 47400903599.29812],
        [1597881600000, 45642757811.590775],
        [1597968000000, 46754739209.41609],
        [1598054400000, 43434501400.18814],
        [1598140800000, 44454504740.72998],
        [1598227200000, 44021875258.788315],
        [1598313600000, 45914782850.88361],
        [1598400000000, 43097616381.47341],
        [1598486400000, 43337732292.52325],
        [1598572800000, 42903096293.90094],
        [1598659200000, 44402928843.0099],
        [1598745600000, 44884412904.61814],
        [1598832000000, 48092923342.6494],
        [1598918400000, 49098776608.15353],
        [1599004800000, 54146717970.58548],
        [1599091200000, 49401551509.00041],
        [1599177600000, 43135808902.68998],
        [1599264000000, 43627087090.328964],
        [1599350400000, 37655618254.050865],
        [1599436800000, 39710363028.2121],
        [1599523200000, 39639961273.34071],
        [1599609600000, 37835807856.34224],
        [1599696000000, 39580368571.35996],
        [1599782400000, 41300327191.463684],
        [1599868800000, 42135842526.32081],
        [1599955200000, 43393172173.54062],
        [1600041600000, 41170490544.06533],
        [1600128000000, 42353184458.585205],
        [1600214400000, 41019180031.929504],
        [1600300800000, 41136175614.140686],
        [1600387200000, 43828530075.491394],
        [1600473600000, 43009572687.415535],
        [1600560000000, 43455110863.06883],
        [1600646400000, 41929980576.65271],
        [1600732800000, 38695806846.97656],
        [1600819200000, 38679912764.2938],
        [1600905600000, 36239170986.342445],
        [1600992000000, 39314719146.84341],
        [1601078400000, 39598389061.56603],
        [1601164800000, 39946698187.61045],
        [1601251200000, 40273903426.08249],
        [1601337600000, 39794887269.461235],
        [1601424000000, 40574256067.82458],
        [1601510400000, 40586538615.954575],
        [1601596800000, 39782583382.89109],
        [1601683200000, 39005631807.605095],
        [1601769600000, 39098719406.98244],
        [1601856000000, 39758373727.61445],
        [1601942400000, 39890007481.293],
        [1602028800000, 38419834420.75508],
        [1602115200000, 38556379197.91778],
        [1602201600000, 39539898845.29083],
        [1602288000000, 41261693685.49925],
        [1602374400000, 41919449520.12781],
        [1602460800000, 42273071872.950066],
        [1602547200000, 43787622889.79302],
        [1602633600000, 43068111158.10915],
        [1602720000000, 42827891085.95138],
        [1602806400000, 42625398559.363594],
        [1602892800000, 41424753265.12099],
        [1602979200000, 41654901940.043465],
        [1603065600000, 42730815492.78595],
        [1603152000000, 42919073937.74135],
        [1603238400000, 41623179886.79757],
        [1603324800000, 44137912522.42472],
        [1603411200000, 46776265907.292244],
        [1603497600000, 46233128841.054825],
        [1603584000000, 46625376044.79527],
        [1603670400000, 45985592948.88831],
        [1603756800000, 44537523611.830605],
        [1603843200000, 45687568527.06391],
        [1603929600000, 43943405500.51123],
        [1604016000000, 43798925859.1192],
        [1604102400000, 43341298725.501526],
        [1604188800000, 43692767478.3082],
        [1604275200000, 44687833549.98935],
        [1604361600000, 43437224863.28803],
        [1604448000000, 43903834507.362854],
        [1604534400000, 45360338717.11154],
        [1604620800000, 47077208350.069885],
        [1604707200000, 51546745852.677986],
        [1604793600000, 49463138492.48698],
        [1604880000000, 51682115129.3641],
        [1604966400000, 50377035452.20988],
        [1605052800000, 51000796202.07293],
        [1605139200000, 52569790942.69943],
        [1605225600000, 52548309254.49251],
        [1605312000000, 53963588841.127716],
        [1605398400000, 52476765553.11609],
        [1605484800000, 50978678612.60896],
        [1605571200000, 52356872782.26836],
        [1605657600000, 54716609289.542305],
        [1605744000000, 54435713128.05311],
        [1605830400000, 53447508889.231575],
        [1605916800000, 57875755337.83428],
        [1606003200000, 62062910159.27977],
        [1606089600000, 64219272617.02369],
        [1606176000000, 68705033455.82259],
        [1606262400000, 68461935305.63655],
        [1606348800000, 64369854808.743355],
        [1606435200000, 58947811751.581924],
        [1606521600000, 58773961397.899704],
        [1606608000000, 61290956964.36442],
        [1606694400000, 65121266717.863914],
        [1606780800000, 69576210026.93625],
        [1606867200000, 66950738084.67838],
        [1606953600000, 68153602806.21818],
        [1607040000000, 70109767421.9594],
        [1607126400000, 64944263291.91259],
        [1607212800000, 67764042948.9772],
        [1607299200000, 68483582708.01654],
        [1607385600000, 67474119685.86511],
        [1607472000000, 63305670197.47529],
        [1607558400000, 65276424697.152855],
        [1607644800000, 63759073874.73225],
        [1607731200000, 62124158560.85701],
        [1607817600000, 64655118180.3175],
        [1607904000000, 67134794851.681],
        [1607990400000, 66629545532.422066],
        [1608076800000, 67041487308.61978],
        [1608163200000, 72301103108.2225],
        [1608249600000, 72955533475.1786],
        [1608336000000, 74135277407.04251],
        [1608422400000, 75092677592.1047],
        [1608508800000, 72618785345.5407],
        [1608595200000, 69545799024.42459],
        [1608681600000, 72327947935.45154],
        [1608768000000, 67468061368.13851],
        [1608854400000, 69867514814.01097],
        [1608940800000, 71422858091.1277],
        [1609027200000, 72391403990.8806],
        [1609113600000, 78833072066.0927],
        [1609200000000, 83575563402.66385],
        [1609286400000, 83885243123.81543],
        [1609372800000, 85790178023.44371],
        [1609459200000, 84037694047.35515],
        [1609545600000, 83294083323.20639],
        [1609632000000, 88595346035.55156],
        [1609718400000, 112525376104.65454],
        [1609804800000, 116693162132.74934],
        [1609891200000, 125112930227.72487],
        [1609977600000, 137306782958.13135],
        [1610064000000, 139084939665.71393],
        [1610150400000, 139145700786.27826],
        [1610236800000, 147397891905.5662],
        [1610323200000, 144775179901.584],
        [1610409600000, 124728988376.03725],
        [1610496000000, 119330774034.78914],
        [1610582400000, 128582223216.03041],
        [1610668800000, 138240285220.32953],
        [1610755200000, 133962341262.37857],
        [1610841600000, 141369013157.282],
        [1610928000000, 142087383372.707],
        [1611014400000, 143378258827.21744],
        [1611100800000, 157929393133.71045],
        [1611187200000, 156457140894.28006],
        [1611273600000, 130713677392.4996],
        [1611360000000, 140271927461.8329],
        [1611446400000, 140773242456.76056],
        [1611532800000, 158167202909.14236],
        [1611619200000, 151940348541.692],
        [1611705600000, 155446593758.92108],
        [1611792000000, 141774137750.6659],
        [1611878400000, 152877389472.9714],
        [1611964800000, 158354114909.26474],
        [1612051200000, 157410718624.21973],
        [1612137600000, 150322722005.1637],
        [1612224000000, 155894407853.50046],
        [1612310400000, 174557101587.54166],
        [1612396800000, 189023794263.7008],
        [1612483200000, 183982328605.4526],
        [1612569600000, 196424150368.47876],
        [1612656000000, 192164042318.95844],
        [1612742400000, 184109385158.1494],
        [1612828800000, 198719063426.16904],
        [1612915200000, 203102939465.473],
        [1613001600000, 199919369275.37125],
        [1613088000000, 203774834938.25632],
        [1613174400000, 210344318124.73236],
        [1613260800000, 207890872554.91016],
        [1613347200000, 207057026271.03055],
        [1613433600000, 203972439068.60135],
        [1613520000000, 204528599567.24704],
        [1613606400000, 211713928319.03534],
        [1613692800000, 221863277318.4042],
        [1613779200000, 226038410300.14252],
        [1613865600000, 221404545098.43417],
        [1613952000000, 223004310602.46854],
        [1614038400000, 204731064486.9056],
        [1614124800000, 179691686731.1511],
        [1614211200000, 185843232352.39114],
        [1614297600000, 168658293547.98462],
        [1614384000000, 167151176870.9801],
        [1614470400000, 167833687528.28204],
        [1614556800000, 163289758568.53607],
        [1614643200000, 179885012253.05722],
        [1614729600000, 172017777678.2973],
        [1614816000000, 182133382817.63593],
        [1614902400000, 177647949967.0106],
        [1614988800000, 176268279969.45792],
        [1615075200000, 191505024384.2956],
        [1615161600000, 198695961501.5439],
        [1615248000000, 211132676701.59906],
        [1615334400000, 214412034435.93906],
        [1615420800000, 207916309215.61444],
        [1615507200000, 210018342198.09778],
        [1615593600000, 203771682506.1516],
        [1615680000000, 222405459430.76758],
        [1615766400000, 215367930757.887],
        [1615852800000, 207264868908.9888],
        [1615939200000, 207059072434.72623],
        [1616025600000, 210150824167.56415],
        [1616112000000, 205022936620.98633],
        [1616198400000, 209188785173.5874],
        [1616284800000, 209455699202.5177],
        [1616371200000, 206013222508.0891],
        [1616457600000, 195434685366.63266],
        [1616544000000, 192388701720.41965],
        [1616630400000, 183005042939.6772],
        [1616716800000, 182810172113.94278],
        [1616803200000, 195511706252.9431],
        [1616889600000, 197943394049.89084],
        [1616976000000, 194647792269.71985],
        [1617062400000, 209088522651.80023],
        [1617148800000, 212080597430.12268],
        [1617235200000, 221210690537.73203],
        [1617321600000, 227187483748.8278],
        [1617408000000, 246446284273.69153],
        [1617494400000, 233519912234.63],
        [1617580800000, 239721240194.1402],
        [1617667200000, 242118495324.83636],
        [1617753600000, 244306254505.299],
        [1617840000000, 229324263726.26553],
        [1617926400000, 240116470865.16214],
        [1618012800000, 238825167325.68292],
        [1618099200000, 246336623343.47812],
        [1618185600000, 247431817683.18906],
        [1618272000000, 247289165411.20078],
        [1618358400000, 265314823430.1463],
        [1618444800000, 281118122172.12756],
        [1618531200000, 290591950682.4659],
        [1618617600000, 280617563098.8275],
        [1618704000000, 271060261616.9552],
        [1618790400000, 259329079995.44214],
        [1618876800000, 251090482814.8267],
        [1618963200000, 269456185695.19864],
        [1619049600000, 274296843910.20834],
        [1619136000000, 280712855517.3182],
        [1619222400000, 273323480799.64493],
        [1619308800000, 258340689899.58817],
        [1619395200000, 266776799157.93356],
        [1619481600000, 292829223558.3116],
        [1619568000000, 306136280873.3804],
        [1619654400000, 317353332381.2044],
        [1619740800000, 318971242360.58826],
        [1619827200000, 321155845972.33514],
        [1619913600000, 340730211563.77893],
        [1620000000000, 341953594507.36664],
        [1620086400000, 398258027094.341],
        [1620172800000, 380565712269.779],
        [1620259200000, 409191426695.66266],
        [1620345600000, 405355930465.5294],
        [1620432000000, 404284565609.95483],
        [1620518400000, 450716254202.56744],
        [1620604800000, 455448294000.9345],
        [1620691200000, 461040789541.05756],
        [1620777600000, 481380994613.09344],
        [1620864000000, 465647015108.638],
        [1620950400000, 431105320829.47766],
        [1621036800000, 473724804234.6842],
        [1621123200000, 425715131137.06134],
        [1621209600000, 414265118099.5364],
        [1621296000000, 381161976477.9278],
        [1621382400000, 390227141901.1161],
        [1621468800000, 295232973801.7795],
        [1621555200000, 322162621074.79614],
        [1621641600000, 279623071718.589],
        [1621728000000, 268503273693.00333],
        [1621814400000, 244070264760.92874],
        [1621900800000, 306046123107.58295],
        [1621987200000, 312566215516.573],
        [1622073600000, 334435114557.1313],
        [1622160000000, 319404492002.5219],
        [1622246400000, 282228507934.91974],
        [1622332800000, 268672927561.12476],
        [1622419200000, 278270782653.56213],
        [1622505600000, 314463232386.7883],
        [1622592000000, 306488816882.1444],
        [1622678400000, 315313344228.8267],
        [1622764800000, 333964598115.05035],
        [1622851200000, 312991097366.8545],
        [1622937600000, 302743417251.8253],
        [1623024000000, 315044778229.29706],
        [1623110400000, 299857516850.1987],
        [1623196800000, 294068580258.7281],
        [1623283200000, 304761367058.1624],
        [1623369600000, 289444120869.42664],
        [1623456000000, 272748408238.43475],
        [1623542400000, 278223731471.048],
        [1623628800000, 292767118262.08405],
        [1623715200000, 300405113218.4146],
        [1623801600000, 298324322330.12646],
        [1623888000000, 274666655404.67456],
        [1623974400000, 275415199641.5411],
        [1624060800000, 260039646334.19235],
        [1624147200000, 253396709038.79898],
        [1624233600000, 261478202409.18997],
        [1624320000000, 223202708788.72086],
        [1624406400000, 216876757208.59253],
        [1624492800000, 229518675836.76538],
        [1624579200000, 231644258719.40115],
        [1624665600000, 213871849531.6296],
        [1624752000000, 209989265221.48502],
        [1624838400000, 229354016311.0035],
        [1624924800000, 243040539437.43396],
        [1625011200000, 253607549372.41992],
        [1625097600000, 265951575833.03497],
        [1625184000000, 247210647940.18707],
        [1625270400000, 250754468066.2188],
        [1625356800000, 259079167729.78622],
        [1625443200000, 271472550895.4458],
        [1625529600000, 258719784082.81683],
        [1625616000000, 270697947572.19247],
        [1625702400000, 270116728160.1227],
        [1625788800000, 248124573571.6789],
        [1625875200000, 251611015863.3228],
        [1625961600000, 247860822594.06396],
        [1626048000000, 250027222000.60455],
        [1626134400000, 238086643137.17004],
        [1626220800000, 227135827760.92883],
        [1626307200000, 232939479730.00745],
        [1626393600000, 223534356736.75906],
        [1626480000000, 218477848474.5608],
        [1626566400000, 221838551362.95905],
        [1626652800000, 222305469349.28598],
        [1626739200000, 214106247241.3399],
        [1626825600000, 209392844466.0158],
        [1626912000000, 233005814487.5354],
        [1626998400000, 236554595198.76672],
        [1627084800000, 247068794868.14566],
        [1627171200000, 255023351844.8617],
        [1627257600000, 258067996305.1722],
        [1627344000000, 261543645427.02673],
        [1627430400000, 267339757878.51932],
        [1627516800000, 268838784855.6686],
        [1627603200000, 278785893426.22424],
        [1627689600000, 287344044145.01373],
        [1627776000000, 297505707149.10004],
        [1627862400000, 297290528238.90314],
        [1627948800000, 306483554365.1923],
        [1628035200000, 294760000421.45154],
        [1628121600000, 319613239659.44226],
        [1628208000000, 330223699956.3277],
        [1628294400000, 337961452767.8669],
        [1628380800000, 368235028077.73975],
        [1628467200000, 352872000029.7298],
        [1628553600000, 363242219000.13837],
        [1628640000000, 368146417310.3951],
        [1628726400000, 371120421692.36896],
        [1628812800000, 356667351344.9034],
        [1628899200000, 387650495386.42346],
        [1628985600000, 383333523376.4307],
        [1629072000000, 387075388591.617],
        [1629158400000, 369733082268.3038],
        [1629244800000, 354390048289.18036],
        [1629331200000, 357527359807.9584],
        [1629417600000, 360685430760.7975],
        [1629504000000, 383483277312.2806],
        [1629590400000, 378500212583.48236],
        [1629676800000, 380718215752.5816],
        [1629763200000, 389578100858.59686],
        [1629849600000, 373455291323.226],
        [1629936000000, 378980836224.52765],
        [1630022400000, 367860898491.3749],
        [1630108800000, 383644799228.1656],
        [1630195200000, 380750077080.94684],
        [1630281600000, 378786220548.79956],
        [1630368000000, 378977088509.6317],
        [1630454400000, 403657783560.1046],
        [1630540800000, 444243407062.371],
        [1630627200000, 444936758974.6008],
        [1630713600000, 462019852288.094],
        [1630800000000, 456929768632.2518],
        [1630886400000, 463973074693.0737],
        [1630972800000, 463012228435.44434],
        [1631059200000, 404516339491.4938],
        [1631145600000, 413866162089.43915],
        [1631232000000, 403936419964.8866],
        [1631318400000, 376789025177.2447],
        [1631404800000, 383321475836.21576],
        [1631491200000, 402834673220.724],
        [1631577600000, 387934440817.1596],
        [1631664000000, 402557959131.82196],
        [1631750400000, 421477522819.9356],
        [1631836800000, 419510540250.7301],
        [1631923200000, 401297385415.4547],
        [1632009600000, 403306688199.6298],
        [1632096000000, 392513622186.47833],
        [1632182400000, 348966852277.93604],
        [1632268800000, 322261202754.7664],
        [1632355200000, 359840797180.9415],
        [1632441600000, 371681653192.37537],
        [1632528000000, 349627373001.0197],
        [1632614400000, 347010586155.9232],
        [1632700800000, 359680654321.7558],
        [1632787200000, 346015184170.8788],
        [1632873600000, 330646942649.06793],
        [1632960000000, 335476370747.3378],
        [1633046400000, 355017980604.5097],
        [1633132800000, 388950236002.1381],
        [1633219200000, 399455864987.66345],
        [1633305600000, 403971441903.55585],
        [1633392000000, 400955543856.1877],
        [1633478400000, 415494260964.53455],
        [1633564800000, 423560000564.3804],
        [1633651200000, 423610266229.8694],
        [1633737600000, 419918377734.5956],
        [1633824000000, 421836191767.0316],
        [1633910400000, 404765054558.19244],
        [1633996800000, 415159189478.8265],
        [1634083200000, 412579624206.76843],
        [1634169600000, 425077639326.6074],
        [1634256000000, 447060207235.15424],
        [1634342400000, 457796523350.2234],
        [1634428800000, 453064999520.12646],
        [1634515200000, 453191180361.09015],
        [1634601600000, 443346920004.58325],
        [1634688000000, 457653555321.406],
        [1634774400000, 491589810670.4509],
        [1634860800000, 481528471021.37964],
        [1634947200000, 471642481174.71185],
        [1635033600000, 493125402509.4343],
        [1635120000000, 482164548780.28937],
        [1635206400000, 499491060520.2567],
        [1635292800000, 489450382752.54156],
        [1635379200000, 466683206492.3765],
        [1635465600000, 505970778337.4693],
        [1635552000000, 521746545883.6817],
        [1635638400000, 511066583044.32874],
        [1635724800000, 507889381626.9908],
        [1635811200000, 511441678721.3504],
        [1635897600000, 539884557845.201],
        [1635984000000, 543311992233.9774],
        [1636070400000, 534623910067.6353],
        [1636156800000, 531116824617.7209],
        [1636243200000, 534139628777.6332],
        [1636329600000, 546469946549.37506],
        [1636416000000, 571665766496.0859],
        [1636502400000, 560964266378.8303],
        [1636588800000, 548699344220.5352],
        [1636675200000, 560226056358.6504],
        [1636761600000, 552938385233.9376],
        [1636848000000, 551228905630.0963],
        [1636934400000, 546963335748.59625],
        [1637020800000, 541673876615.1028],
        [1637107200000, 501391963927.7868],
        [1637193600000, 507387229348.5558],
        [1637280000000, 472465696561.25507],
        [1637366400000, 509116804761.8423],
        [1637452800000, 524536655944.9339],
        [1637539200000, 514388218179.73724],
        [1637625600000, 485391988431.9048],
        [1637712000000, 515843824526.39667],
        [1637798400000, 505609296276.3257],
        [1637884800000, 535082405000.054],
        [1637971200000, 479414339295.46326],
        [1638057600000, 483997326749.38934],
        [1638144000000, 508385171334.4435],
        [1638230400000, 527331112023.3339],
        [1638316800000, 549758919636.0618],
        [1638403200000, 544051345075.8544],
        [1638489600000, 537814995667.8384],
        [1638576000000, 502863051143.01685],
        [1638662400000, 485429065737.4593],
        [1638748800000, 497049834886.77576],
        [1638835200000, 515778007028.56696],
        [1638921600000, 511409310852.7245],
        [1639008000000, 525850414062.19104],
        [1639094400000, 494841071220.93524],
        [1639180800000, 467266449092.32294],
        [1639267200000, 484278596802.5053],
        [1639353600000, 490316653455.3707],
        [1639440000000, 449137302105.9432],
        [1639526400000, 457658155854.8058],
        [1639612800000, 476630620294.2814],
        [1639699200000, 471365651341.3005],
        [1639785600000, 462267569583.3989],
        [1639872000000, 471195598700.43604],
        [1639958400000, 467396185408.52454],
        [1640044800000, 471319155562.1543],
        [1640131200000, 480570348192.474],
        [1640217600000, 475334762859.67194],
        [1640304000000, 488290658809.1535],
        [1640390400000, 482498690510.26416],
        [1640476800000, 488792890035.21063],
        [1640563200000, 484795501758.7465],
        [1640649600000, 481030381194.1921],
        [1640736000000, 453976220406.2239],
        [1640822400000, 432082393736.3374],
        [1640908800000, 441654436877.796],
        [1640995200000, 439790867552.76666],
        [1641081600000, 449804685410.7303],
        [1641168000000, 456852400304.9327],
        [1641254400000, 448609586227.47644],
        [1641340800000, 454574318762.6068],
        [1641427200000, 424088794962.4891],
        [1641513600000, 407652437129.1328],
        [1641600000000, 381283293110.7904],
        [1641686400000, 369734313550.987],
        [1641772800000, 375219467789.3698],
        [1641859200000, 367654760059.0176],
        [1641945600000, 387505881839.82996],
        [1642032000000, 402763358249.81146],
        [1642118400000, 388039946028.0792],
        [1642204800000, 394664610644.99524],
        [1642291200000, 396899234936.6389],
        [1642377600000, 400232172277.67896],
        [1642464000000, 383428420242.12994],
        [1642550400000, 377918294939.4783],
        [1642636800000, 371296193085.12195],
        [1642723200000, 360580494818.73],
        [1642809600000, 306644499897.7337],
        [1642896000000, 286799916366.57184],
        [1642982400000, 302626363351.5348],
        [1643068800000, 292022326101.0083],
        [1643155200000, 293702336549.67474],
        [1643241600000, 295723346868.75024],
        [1643328000000, 287829476402.6202],
        [1643414400000, 304305302571.33734],
        [1643500800000, 310059710546.2545],
        [1643587200000, 311602914058.75037],
        [1643673600000, 320354563857.1168],
        [1643760000000, 334032944115.64844],
        [1643846400000, 321296521004.9529],
        [1643932800000, 317503695895.34064],
        [1644019200000, 354558118293.0548],
        [1644105600000, 361600442694.43896],
        [1644192000000, 366038313761.43805],
        [1644278400000, 376351043607.997],
        [1644364800000, 374043919421.316],
        [1644451200000, 387321193246.79065],
        [1644537600000, 368377710139.77985],
        [1644624000000, 350133590835.08734],
        [1644710400000, 347517755313.83966],
        [1644796800000, 345252969326.4971],
        [1644883200000, 351368910987.00305],
        [1644969600000, 381791121976.2447],
        [1645056000000, 376071884044.5087],
        [1645142400000, 345252572787.0988],
        [1645228800000, 333997569707.84204],
        [1645315200000, 330814655802.00714],
        [1645401600000, 315015971538.73315],
        [1645488000000, 308836894427.69006],
        [1645574400000, 317004525462.3889],
        [1645660800000, 310997586955.65283],
        [1645747200000, 311533514951.14],
        [1645833600000, 333625150661.6757],
        [1645920000000, 332645349329.944],
        [1646006400000, 314566178973.5596],
        [1646092800000, 349056335389.1799],
        [1646179200000, 355855644056.71844],
        [1646265600000, 353807222059.12616],
        [1646352000000, 340585349633.38696],
        [1646438400000, 314336377085.58026],
        [1646524800000, 319334318240.9434],
        [1646611200000, 306675297178.70435],
        [1646697600000, 299834957580.4126],
        [1646784000000, 309291567872.3395],
        [1646870400000, 327144318378.6509],
        [1646956800000, 313054369877.37134],
        [1647043200000, 307992205234.7309],
        [1647129600000, 309539252974.5316],
        [1647216000000, 302103577555.32056],
        [1647302400000, 310467033555.37006],
        [1647388800000, 314931220494.93384],
        [1647475200000, 333204590005.1927],
        [1647561600000, 338097336083.8262],
        [1647648000000, 353539105454.5327],
        [1647734400000, 353770129278.67426],
        [1647820800000, 343170506313.24133],
        [1647907200000, 347666776668.2674],
        [1647993600000, 356813271188.88025],
        [1648080000000, 363715804798.513],
        [1648166400000, 373177111114.7027],
        [1648252800000, 373074920726.7511],
        [1648339200000, 377825418684.3759],
        [1648425600000, 394486853105.0086],
        [1648512000000, 399977979554.21063],
        [1648598400000, 408153998267.8274],
        [1648684800000, 407460530687.7479],
        [1648771200000, 394628513775.2061],
        [1648857600000, 414854897478.46454],
        [1648944000000, 413595262456.1556],
        [1649030400000, 423410516389.8548],
        [1649116800000, 423367935034.98956],
        [1649203200000, 412944890807.9602],
        [1649289600000, 382808702033.7895],
        [1649376000000, 388867320187.2107],
        [1649462400000, 382662893229.3914],
        [1649548800000, 392551461530.81824],
        [1649635200000, 388982079663.2629],
        [1649721600000, 360149250762.0514],
        [1649808000000, 365661388410.7189],
        [1649894400000, 375767680796.0061],
        [1649980800000, 363962080917.9128],
        [1650067200000, 366695398817.36127],
        [1650153600000, 369787917596.8767],
        [1650240000000, 360748880962.95123],
        [1650326400000, 368758484169.854],
        [1650412800000, 373782979942.61957],
        [1650499200000, 370910549448.9755],
        [1650585600000, 359917773311.257],
        [1650672000000, 357702343019.6129],
        [1650758400000, 354358143094.8383],
        [1650844800000, 352895958715.2752],
        [1650931200000, 362590296737.94165],
        [1651017600000, 338098235743.8579],
        [1651104000000, 348355348857.35516],
        [1651190400000, 353388170472.60077],
        [1651276800000, 340291788026.5969],
        [1651363200000, 330211251741.10095],
        [1651449600000, 341626180826.6119],
        [1651536000000, 345080135066.0433],
        [1651622400000, 336655132684.7217],
        [1651708800000, 355229761723.3485],
        [1651795200000, 332103738939.991],
        [1651881600000, 325971900416.40796],
        [1651968000000, 319030900103.94275],
        [1652054400000, 304412453306.29724],
        [1652140800000, 271594796946.79037],
        [1652227200000, 283082341315.2594],
        [1652313600000, 250066823474.8895],
        [1652400000000, 238137944005.85858],
        [1652486400000, 243391440608.9294],
        [1652572800000, 249319115268.20465],
        [1652659200000, 259350256988.01163],
        [1652745600000, 244741893933.73114],
        [1652832000000, 253640825085.77072],
        [1652918400000, 232056327519.7834],
        [1653004800000, 244359004842.60373],
        [1653091200000, 237366807798.8157],
        [1653177600000, 239102942424.56775],
        [1653264000000, 247730054115.44598],
        [1653350400000, 238722810744.1854],
        [1653436800000, 239375431792.86758],
        [1653523200000, 235347664835.4594],
        [1653609600000, 219247293980.15558],
        [1653696000000, 208622909553.50162],
        [1653782400000, 217791356992.8858],
        [1653868800000, 219535934457.98206],
        [1653955200000, 241204871683.00946],
        [1654041600000, 235235428449.11987],
        [1654128000000, 221596716649.26758],
        [1654214400000, 222487488380.58923],
        [1654300800000, 214879733566.94196],
        [1654387200000, 217832477905.49496],
        [1654473600000, 218561175507.6209],
        [1654560000000, 225224111085.68793],
        [1654646400000, 220727955347.00992],
        [1654732800000, 217320792647.69748],
        [1654819200000, 216720355679.05618],
        [1654905600000, 201384347491.6296],
        [1654992000000, 185302828001.8518],
        [1655078400000, 176649606143.59042],
        [1655164800000, 146072737222.6309],
        [1655251200000, 146866764082.71503],
        [1655337600000, 148791271623.67947],
        [1655424000000, 129200159744.2964],
        [1655510400000, 132057112459.09528],
        [1655596800000, 120148911164.20312],
        [1655683200000, 136214925416.2125],
        [1655769600000, 136360308696.36774],
        [1655856000000, 136466936749.80061],
        [1655942400000, 127762640725.99548],
        [1656028800000, 138845063633.00702],
        [1656115200000, 150370804175.61023],
        [1656201600000, 150822450290.39703],
        [1656288000000, 145727702946.91232],
        [1656374400000, 144903396178.83267],
        [1656460800000, 138578887988.78128],
        [1656547200000, 133275228451.34706],
        [1656633600000, 125647331913.1351],
        [1656720000000, 128274306967.60861],
        [1656806400000, 127772810160.63632],
        [1656892800000, 128552380046.80464],
        [1656979200000, 137709680960.0187],
        [1657065600000, 135655130079.77824],
        [1657152000000, 142216914175.49112],
        [1657238400000, 148622605231.4957],
        [1657324800000, 148022842649.44733],
        [1657411200000, 145667751203.8659],
        [1657497600000, 139844141994.57858],
        [1657584000000, 131363492852.48433],
        [1657670400000, 124831295049.54146],
        [1657756800000, 132986401488.71631],
        [1657843200000, 142767764050.56015],
        [1657929600000, 147677565536.3019],
        [1658016000000, 161839496479.48514],
        [1658102400000, 161551223982.244],
        [1658188800000, 187518878564.43118],
        [1658275200000, 184299116474.0005],
        [1658361600000, 183383314168.63928],
        [1658448000000, 188807814065.77225],
        [1658534400000, 183933879174.97644],
        [1658620800000, 185766674503.14],
        [1658707200000, 192833875418.8718],
        [1658793600000, 176371406952.78534],
        [1658880000000, 172489862867.98703],
        [1658966400000, 195761014376.11777],
        [1659052800000, 206347207632.4567],
        [1659139200000, 210767555013.8352],
        [1659225600000, 203433082401.51196],
        [1659312000000, 201684547325.2247],
        [1659398400000, 195962280445.4563],
        [1659484800000, 197496591505.0525],
        [1659571200000, 194280005714.29996],
        [1659657600000, 192923352504.82288],
        [1659744000000, 206550599256.62897],
        [1659830400000, 203417937064.14215],
        [1659916800000, 203847481552.73074],
        [1660003200000, 213209087779.66412],
        [1660089600000, 204260348576.8655],
        [1660176000000, 222103417229.72577],
        [1660262400000, 225973452853.74725],
        [1660348800000, 235254593664.57745],
        [1660435200000, 237972056306.98938],
        [1660521600000, 232593263080.43414],
        [1660608000000, 229440415801.9887],
        [1660694400000, 225581585656.9299],
        [1660780800000, 220665870251.26813],
        [1660867200000, 222652366350.8803],
        [1660953600000, 194817731984.87317],
        [1661040000000, 189621068133.33334],
        [1661126400000, 195324471844.68927],
        [1661212800000, 193708156170.03223],
        [1661299200000, 199889229686.9934],
        [1661385600000, 199117634174.20898],
        [1661472000000, 204240810436.6186],
        [1661558400000, 181238520850.37888],
        [1661644800000, 179887478326.98642],
        [1661731200000, 173645963258.54285],
        [1661817600000, 187437853967.29883],
        [1661904000000, 184447549243.54855],
        [1661990400000, 186670000430.8127],
        [1662076800000, 191078487039.94174],
        [1662163200000, 189678877652.6803],
        [1662249600000, 187266246294.68723],
        [1662336000000, 190082046955.22556],
        [1662422400000, 194714097035.8242],
        [1662508800000, 188733785486.0839],
        [1662595200000, 196797197824.30237],
        [1662681600000, 196882691710.49237],
        [1662768000000, 206873029263.2678],
        [1662854400000, 214315849498.72748],
        [1662940800000, 211895058510.3476],
        [1663027200000, 205793007270.87155],
        [1663113600000, 189778580452.97882],
        [1663200000000, 196550513818.88647],
        [1663286400000, 177918273632.48117],
        [1663372800000, 172471655154.62585],
        [1663459200000, 177299639477.4967],
        [1663545600000, 161378107849.57123],
        [1663632000000, 166370229681.14825],
        [1663718400000, 159844149938.74484],
        [1663804800000, 151084586604.48166],
        [1663891200000, 160415097114.1742],
        [1663977600000, 160823914264.80762],
        [1664064000000, 159030822515.26553],
        [1664150400000, 156146367767.27133],
        [1664236800000, 160767471777.8733],
        [1664323200000, 160800122124.8998],
        [1664409600000, 161716193676.29504],
        [1664496000000, 161267455734.36823],
        [1664582400000, 160462955554.32846],
        [1664668800000, 158410068981.48102],
        [1664755200000, 154934842225.90906],
        [1664841600000, 159951067075.9052],
        [1664928000000, 164781680358.15567],
        [1665014400000, 163475522281.134],
        [1665100800000, 163307579801.84253],
        [1665187200000, 160084472602.33664],
        [1665273600000, 159083423929.71472],
        [1665360000000, 159586330117.54977],
        [1665446400000, 156180434676.1384],
        [1665532800000, 154657511483.1829],
        [1665619200000, 156488571589.45505],
        [1665705600000, 155703285133.85605],
        [1665792000000, 156801885520.45496],
        [1665878400000, 154496140301.9263],
        [1665964800000, 157219790372.87076],
        [1666051200000, 160690333948.33112],
        [1666137600000, 158082173402.60727],
        [1666224000000, 154808369275.81442],
        [1666310400000, 154512893268.0924],
        [1666396800000, 156673724237.28812],
        [1666483200000, 158365108378.3375],
        [1666569600000, 164218113409.75653],
        [1666656000000, 161858058865.06808],
        [1666742400000, 176051206695.99817],
        [1666828800000, 189173389239.5451],
        [1666915200000, 182723289741.9848],
        [1667001600000, 187324850268.18915],
        [1667088000000, 195052502985.67032],
        [1667174400000, 191500820025.9781],
        [1667260800000, 189478578997.59592],
        [1667347200000, 190428736966.3317],
        [1667433600000, 183368006193.10986],
        [1667520000000, 184398997533.25903],
        [1667606400000, 198053929735.65234],
        [1667692800000, 196264485041.5907],
        [1667779200000, 189763621397.94974],
        [1667865600000, 188875473098.02014],
        [1667952000000, 160813058133.9897],
        [1668038400000, 131794377265.61275],
        [1668124800000, 156380990539.85645],
        [1668211200000, 155222424523.53937],
        [1668297600000, 151059842003.22357],
        [1668384000000, 147426038251.7689],
        [1668470400000, 149987178583.21732],
        [1668556800000, 151062413577.56232],
        [1668643200000, 146462319554.31644],
        [1668729600000, 144894942760.29672],
        [1668816000000, 146145817586.6032],
        [1668902400000, 146971097131.67468],
        [1668988800000, 137964810712.74976],
        [1669075200000, 133733639793.52232],
        [1669161600000, 137050514642.06917],
        [1669248000000, 142854533237.69275],
        [1669334400000, 145091739838.44122],
        [1669420800000, 144810246844.91675],
        [1669507200000, 145222050633.46432],
        [1669593600000, 144507830172.60718],
        [1669680000000, 141048501904.6694],
        [1669766400000, 146749281622.5983],
        [1669852800000, 156777674228.40683],
        [1669939200000, 153787135286.89285],
        [1670025600000, 155766037022.93884],
        [1670112000000, 150322759022.75156],
        [1670198400000, 154484879835.4558],
        [1670284800000, 151960138515.5798],
        [1670371200000, 153112232020.5009],
        [1670457600000, 148778387852.61035],
        [1670544000000, 154438830699.8818],
        [1670630400000, 152405570526.73016],
        [1670716800000, 152480155389.30975],
        [1670803200000, 152290250844.83786],
        [1670889600000, 153743004467.3702],
        [1670976000000, 159205793114.4748],
        [1671062400000, 157633274799.26862],
        [1671148800000, 152444818382.92126],
        [1671235200000, 140289330990.1754],
        [1671321600000, 143321475845.90793],
        [1671408000000, 143142028259.18048],
        [1671494400000, 140848456817.4131],
        [1671580800000, 146802833364.30045],
        [1671667200000, 146039250786.06482],
        [1671753600000, 146741009624.09518],
        [1671840000000, 147069822795.65387],
        [1671926400000, 147173116811.52798],
        [1672012800000, 146840669268.71204],
        [1672099200000, 147697269147.94653],
        [1672185600000, 146030514727.00162],
        [1672272000000, 143241827136.73636],
        [1672358400000, 144831301452.10547],
        [1672444800000, 144611517916.40677],
        [1672531200000, 144188788703.97467],
        [1672617600000, 144712215098.15164],
        [1672704000000, 146443084804.90094],
        [1672790400000, 146422424046.4976],
        [1672876800000, 151322902029.0838],
        [1672963200000, 150805231900.39493],
        [1673049600000, 153115414332.1746],
        [1673136000000, 152351777734.96948],
        [1673222400000, 154857941332.0086],
        [1673308800000, 159397504473.27673],
        [1673395200000, 160999220384.61914],
        [1673481600000, 167839113151.03107],
        [1673568000000, 170890542806.26074],
        [1673654400000, 174798760594.58777],
        [1673740800000, 187276048380.94897],
        [1673827200000, 186901700956.48312],
        [1673913600000, 190122961411.69482],
        [1674000000000, 188982728685.09735],
        [1674086400000, 183517931282.58932],
        [1674172800000, 186967330908.32364],
        [1674259200000, 200011161010.51965],
        [1674345600000, 195908718395.08084],
        [1674432000000, 196512572594.40756],
        [1674518400000, 196541663696.43738],
        [1674604800000, 187270576772.9432],
        [1674691200000, 194402223992.62006],
        [1674777600000, 193276131177.59738],
        [1674864000000, 192677648328.43668],
        [1674950400000, 189301295262.2017],
        [1675036800000, 198339307428.10373],
        [1675123200000, 188787565951.33054],
        [1675209600000, 191042852121.47128],
        [1675296000000, 197724834027.55963],
        [1675382400000, 198613736403.15057],
        [1675468800000, 200784153460.47113],
        [1675555200000, 201099652782.7474],
        [1675641600000, 196817854756.22067],
        [1675728000000, 194725338782.41467],
        [1675814400000, 201779050385.86017],
        [1675900800000, 199107556553.15936],
        [1675987200000, 186306817307.09564],
        [1676073600000, 182839491763.3784],
        [1676160000000, 185826666441.3843],
        [1676246400000, 182722050130.99646],
        [1676332800000, 181589830341.34802],
        [1676419200000, 187619568130.73468],
        [1676505600000, 201595282287.4858],
        [1676592000000, 198370400841.4434],
        [1676678400000, 204534589183.7981],
        [1676764800000, 203613145994.36063],
        [1676851200000, 202999098993.98264],
        [1676937600000, 204900256888.84143],
        [1677024000000, 199387951962.05505],
        [1677110400000, 198153971276.35593],
        [1677196800000, 198619189860.05594],
        [1677283200000, 193773223390.3348],
        [1677369600000, 192056375721.21072],
        [1677456000000, 197696596339.3676],
        [1677542400000, 197018105507.06073],
        [1677628800000, 193276888983.6779],
        [1677715200000, 200476156508.34192],
        [1677801600000, 198520753673.54544],
        [1677888000000, 189178795460.26093],
        [1677974400000, 189002414068.6852],
        [1678060800000, 188337456156.2138],
        [1678147200000, 188803304807.84476],
        [1678233600000, 187945419858.86072],
        [1678320000000, 185138878803.2572],
        [1678406400000, 173232609854.28854],
        [1678492800000, 172474466355.71472],
        [1678579200000, 176915178456.41873],
        [1678665600000, 190440681263.21286],
        [1678752000000, 202294154847.0903],
        [1678838400000, 205738833552.65433],
        [1678924800000, 199607487558.33243],
        [1679011200000, 202411234958.15692],
        [1679097600000, 215660560704.77524],
        [1679184000000, 213123927233.32803],
        [1679270400000, 217844457987.9951],
        [1679356800000, 209763828559.92316],
        [1679443200000, 218008486913.68356],
        [1679529600000, 210285658211.08432],
        [1679616000000, 219518418514.19247],
        [1679702400000, 212072713258.261],
        [1679788800000, 211355889911.33264],
        [1679875200000, 214528709136.8291],
        [1679961600000, 206685090340.53693],
        [1679966032000, 205793527761.5422],
      ],
      total_volumes: [
        [1438905600000, 90622.0],
        [1438992000000, 368070.0],
        [1439164800000, 400464.0745435489],
        [1439251200000, 1518998.2053373822],
        [1439337600000, 2073893.3450314845],
        [1439424000000, 4380142.704000345],
        [1439510400000, 4355618.378552116],
        [1439596800000, 2519633.2708181436],
        [1439683200000, 3032657.656405736],
        [1439769600000, 1880092.2147175823],
        [1439856000000, 1696013.472335737],
        [1439942400000, 1537200.8409894055],
        [1440028800000, 2967730.5022106003],
        [1440115200000, 1742881.8081502889],
        [1440201600000, 914588.6539745613],
        [1440288000000, 1610695.8727506467],
        [1440374400000, 696476.5553267623],
        [1440460800000, 1053169.3862050485],
        [1440547200000, 1151700.041593045],
        [1440633600000, 681460.2394411361],
        [1440720000000, 715178.7899308845],
        [1440806400000, 607200.891453558],
        [1440892800000, 1045589.321256734],
        [1440979200000, 1197102.0492865555],
        [1441065600000, 610368.2430900692],
        [1441152000000, 629746.119316548],
        [1441238400000, 583923.7452450913],
        [1441324800000, 333502.66115673864],
        [1441411200000, 350815.4668948872],
        [1441497600000, 317470.0943419727],
        [1441584000000, 487005.2028033498],
        [1441670400000, 419148.8018252036],
        [1441756800000, 367774.3318949896],
        [1441843200000, 639527.9174929534],
        [1441929600000, 1901710.694902119],
        [1442016000000, 800623.2998453851],
        [1442102400000, 658895.1959938455],
        [1442188800000, 739213.7259180878],
        [1442275200000, 725816.471124354],
        [1442361600000, 629370.0849072988],
        [1442448000000, 910704.9512256783],
        [1442534400000, 478606.3969218407],
        [1442620800000, 387803.6167124918],
        [1442707200000, 514836.10862474947],
        [1442793600000, 429253.50431215833],
        [1442880000000, 485738.9553869821],
        [1442966400000, 204065.40590088838],
        [1443052800000, 818263.068224302],
        [1443139200000, 901151.6190115963],
        [1443225600000, 379124.1859007531],
        [1443312000000, 408380.6390382605],
        [1443398400000, 1214056.2117823916],
        [1443484800000, 936630.3296074625],
        [1443571200000, 640217.6226458604],
        [1443657600000, 588839.7815035215],
        [1443744000000, 220969.48495190177],
        [1443830400000, 163820.85347975112],
        [1443916800000, 104370.45073472841],
        [1444003200000, 221647.20706785333],
        [1444089600000, 268523.03787593375],
        [1444176000000, 216715.50684861522],
        [1444262400000, 310876.1441075317],
        [1444348800000, 379233.1220398259],
        [1444435200000, 145554.93808006204],
        [1444521600000, 87074.79961764446],
        [1444608000000, 108895.52921643597],
        [1444694400000, 297117.07710031554],
        [1444780800000, 944847.8047126097],
        [1444867200000, 376563.0715091993],
        [1444953600000, 247071.24723507115],
        [1445040000000, 317377.7617794195],
        [1445126400000, 133661.67045159836],
        [1445212800000, 240250.37656809334],
        [1445299200000, 352361.0618641571],
        [1445385600000, 599041.0131522977],
        [1445472000000, 979304.0724230009],
        [1445558400000, 866798.4888542511],
        [1445644800000, 259157.66241063716],
        [1445731200000, 476617.738601437],
        [1445817600000, 1174026.819064362],
        [1445904000000, 1887568.9059290001],
        [1445990400000, 2447633.7976275166],
        [1446076800000, 2236842.210445422],
        [1446163200000, 2384549.998119618],
        [1446249600000, 652271.5873221146],
        [1446336000000, 603996.1990296076],
        [1446422400000, 970665.6783058537],
        [1446508800000, 1878273.0109212757],
        [1446595200000, 3218064.880847628],
        [1446681600000, 1202885.3800460405],
        [1446768000000, 913629.1696859167],
        [1446854400000, 904631.8699629814],
        [1446940800000, 1040086.7545033679],
        [1447027200000, 1972521.3874033373],
        [1447113600000, 865031.5412400892],
        [1447200000000, 1243322.7527190507],
        [1447286400000, 826829.6689207307],
        [1447372800000, 545086.2658886773],
        [1447459200000, 361586.11600976856],
        [1447545600000, 432023.407831642],
        [1447632000000, 616346.9298519328],
        [1447718400000, 1128249.5445129166],
        [1447804800000, 687203.3331021931],
        [1447891200000, 434385.481510446],
        [1447977600000, 606560.338362119],
        [1448064000000, 454926.9190034523],
        [1448150400000, 371962.8833336563],
        [1448236800000, 437126.39426833094],
        [1448323200000, 354914.08621214016],
        [1448409600000, 708867.1776788787],
        [1448496000000, 959696.4719438321],
        [1448582400000, 398595.4996438545],
        [1448668800000, 465064.93868912815],
        [1448755200000, 436939.13020833576],
        [1448841600000, 775372.5405749859],
        [1448928000000, 643109.8579217136],
        [1449014400000, 494205.89284799533],
        [1449100800000, 541681.1368684795],
        [1449187200000, 241947.49444270384],
        [1449273600000, 226084.41071520853],
        [1449360000000, 429681.2894745185],
        [1449446400000, 494657.61802976346],
        [1449532800000, 435966.80884823814],
        [1449619200000, 627128.9260492469],
        [1449705600000, 374543.00586710183],
        [1449792000000, 765839.8738111159],
        [1449878400000, 1385279.0437324098],
        [1449964800000, 279257.6104401555],
        [1450051200000, 562723.38601539],
        [1450137600000, 573171.6204861925],
        [1450224000000, 207316.98728030306],
        [1450310400000, 641112.3505143464],
        [1450396800000, 297866.39712775947],
        [1450483200000, 333313.4238328429],
        [1450569600000, 467489.5933259762],
        [1450656000000, 543846.3978062818],
        [1450742400000, 468144.5604338489],
        [1450828800000, 333657.8603050268],
        [1450915200000, 353090.34035883],
        [1451001600000, 169208.7604895867],
        [1451088000000, 424123.09670471714],
        [1451174400000, 208247.21865890338],
        [1451260800000, 228592.5875954824],
        [1451347200000, 211396.56750766764],
        [1451433600000, 584414.4705682999],
        [1451520000000, 668846.07044826],
        [1451606400000, 202032.13336863558],
        [1451692800000, 252880.2353165073],
        [1451779200000, 402755.9084078687],
        [1451865600000, 341506.82136802946],
        [1451952000000, 217443.23809763335],
        [1452038400000, 328507.1523817127],
        [1452124800000, 642269.5176489778],
        [1452211200000, 554403.4018238547],
        [1452297600000, 254045.62911324683],
        [1452384000000, 404315.93496207293],
        [1452470400000, 1025253.0383450065],
        [1452556800000, 2962550.3945903103],
        [1452643200000, 1003321.3604582713],
        [1452729600000, 731050.8483768429],
        [1452816000000, 4049516.2124520643],
        [1452902400000, 2377737.1425659666],
        [1452988800000, 1176009.00668505],
        [1453075200000, 4073710.1202780646],
        [1453161600000, 1684557.2914079635],
        [1453248000000, 2899771.051018662],
        [1453334400000, 1670627.3218903034],
        [1453420800000, 1585027.991812104],
        [1453507200000, 2212028.7971573095],
        [1453593600000, 9578400.618786106],
        [1453680000000, 10884776.210849365],
        [1453766400000, 11669540.231742326],
        [1453852800000, 7048626.2187527],
        [1453939200000, 5043090.697552952],
        [1454025600000, 6527897.661629626],
        [1454112000000, 3674090.3975050296],
        [1454198400000, 5834817.706592737],
        [1454284800000, 3991411.3414352452],
        [1454371200000, 3602402.308067327],
        [1454457600000, 4030697.3392438097],
        [1454544000000, 3532651.020271136],
        [1454630400000, 2640353.6147680376],
        [1454716800000, 1924574.7180617019],
        [1454803200000, 7099203.5519039435],
        [1454889600000, 9781404.736808997],
        [1454976000000, 16355217.945349474],
        [1455062400000, 29316397.613274623],
        [1455148800000, 28752968.923665255],
        [1455235200000, 9902598.244079141],
        [1455321600000, 25637385.479897276],
        [1455408000000, 11552061.707776096],
        [1455494400000, 13786358.783861183],
        [1455580800000, 15444552.58629855],
        [1455667200000, 14847989.04999859],
        [1455753600000, 17707643.869024027],
        [1455840000000, 7215680.13205692],
        [1455926400000, 8703306.94050525],
        [1456012800000, 6458218.23306439],
        [1456099200000, 15140246.890369605],
        [1456185600000, 13534870.66434678],
        [1456272000000, 11396482.659191374],
        [1456358400000, 20614025.807056963],
        [1456444800000, 9515283.257397331],
        [1456531200000, 8967228.051799431],
        [1456617600000, 9683081.005927766],
        [1456704000000, 7546069.554011689],
        [1456790400000, 20945587.168326743],
        [1456876800000, 29743237.911609612],
        [1456963200000, 40441406.846499346],
        [1457049600000, 35604151.84878607],
        [1457136000000, 54303350.00956653],
        [1457222400000, 41487265.46159489],
        [1457308800000, 31891736.915083926],
        [1457395200000, 26473661.601431258],
        [1457481600000, 28637679.638494223],
        [1457568000000, 26154096.467449],
        [1457654400000, 21083465.444312476],
        [1457740800000, 49863322.38648788],
        [1457827200000, 41625885.68412731],
        [1457913600000, 56738456.43221119],
        [1458000000000, 30654519.10842079],
        [1458086400000, 23016882.43185901],
        [1458172800000, 41222397.150388725],
        [1458259200000, 56331932.49799413],
        [1458345600000, 26241552.22233686],
        [1458432000000, 17365910.955602583],
        [1458518400000, 28692538.03850463],
        [1458604800000, 19752147.756938808],
        [1458691200000, 19194415.657153998],
        [1458777600000, 25440037.393962998],
        [1458864000000, 13369781.43339712],
        [1458950400000, 9099232.99988865],
        [1459036800000, 16296116.833151689],
        [1459123200000, 23146182.980729047],
        [1459209600000, 17227685.66894123],
        [1459296000000, 18966041.620488863],
        [1459382400000, 14483801.493105622],
        [1459468800000, 11085851.666571325],
        [1459555200000, 8250251.46099724],
        [1459641600000, 6190954.954391588],
        [1459728000000, 8612716.039295983],
        [1459814400000, 19160809.10508679],
        [1459900800000, 16326497.505610662],
        [1459987200000, 12647730.142575275],
        [1460073600000, 14041839.904038262],
        [1460160000000, 12961939.644313943],
        [1460246400000, 42148631.996420056],
        [1460332800000, 14850316.572718466],
        [1460419200000, 22286939.435105387],
        [1460505600000, 30653780.43975513],
        [1460592000000, 13178565.115089094],
        [1460678400000, 11510142.230195371],
        [1460764800000, 9164981.891612116],
        [1460851200000, 23406128.933429755],
        [1460937600000, 20650475.766265374],
        [1461024000000, 11931697.805021903],
        [1461110400000, 14929127.402227022],
        [1461196800000, 12241737.003326477],
        [1461283200000, 12838519.618996318],
        [1461369600000, 14263735.838354647],
        [1461456000000, 10251449.903605143],
        [1461542400000, 9849771.90571352],
        [1461628800000, 17588527.338440873],
        [1461715200000, 18898653.05760324],
        [1461801600000, 10503191.403122593],
        [1461888000000, 8232651.035704131],
        [1461974400000, 36023474.61132939],
        [1462060800000, 16329848.834566616],
        [1462147200000, 40531647.2594358],
        [1462233600000, 20068196.22374318],
        [1462320000000, 16758119.374866204],
        [1462406400000, 17630163.279848542],
        [1462492800000, 20206557.47556278],
        [1462579200000, 10242971.602412278],
        [1462665600000, 14850766.415806817],
        [1462752000000, 10349090.132607356],
        [1462838400000, 8837274.15169184],
        [1462924800000, 14650764.255450204],
        [1463011200000, 22804335.07313709],
        [1463097600000, 27489270.96990509],
        [1463184000000, 18055946.18486381],
        [1463270400000, 8453161.311741682],
        [1463356800000, 27963225.28498735],
        [1463443200000, 36364157.81238995],
        [1463529600000, 62651459.72818858],
        [1463616000000, 53477909.077545226],
        [1463702400000, 48337340.57185815],
        [1463788800000, 24426995.172922097],
        [1463875200000, 17413663.620256484],
        [1463961600000, 21271913.57732469],
        [1464048000000, 41166028.01368196],
        [1464134400000, 25364775.557130534],
        [1464220800000, 19032556.07440177],
        [1464307200000, 53491048.64137498],
        [1464393600000, 44051409.767962486],
        [1464480000000, 26254518.55363852],
        [1464566400000, 13289827.482192438],
        [1464652800000, 39491270.92023739],
        [1464739200000, 20161807.321217276],
        [1464825600000, 9543717.946969878],
        [1464912000000, 21310804.97545615],
        [1464998400000, 14233023.489212893],
        [1465084800000, 9908484.139421096],
        [1465171200000, 7633717.194766483],
        [1465257600000, 19688483.52449011],
        [1465344000000, 14566862.117567817],
        [1465430400000, 6538415.617239344],
        [1465516800000, 9751904.43375697],
        [1465603200000, 9986576.468801256],
        [1465689600000, 34155133.25971257],
        [1465776000000, 33190471.728637412],
        [1465862400000, 57195562.16615153],
        [1465948800000, 16794245.386186022],
        [1466035200000, 33609803.05751324],
        [1466121600000, 176008430.131423],
        [1466208000000, 121410542.50029658],
        [1466294400000, 68987538.97211507],
        [1466380800000, 79924563.1953664],
        [1466467200000, 73815982.10368486],
        [1466553600000, 157698817.03531438],
        [1466640000000, 35210083.60811146],
        [1466726400000, 42975326.33415148],
        [1466812800000, 13474760.65320482],
        [1466899200000, 12716765.335899664],
        [1466985600000, 9026418.664550114],
        [1467072000000, 38649828.67116688],
        [1467158400000, 24102058.67142671],
        [1467244800000, 25109656.26842844],
        [1467331200000, 16090108.050608601],
        [1467417600000, 12572639.309558652],
        [1467504000000, 8919743.27120069],
        [1467590400000, 10816581.462869536],
        [1467676800000, 40761274.601703286],
        [1467763200000, 16042852.433356386],
        [1467849600000, 13787778.16557356],
        [1467936000000, 17821594.496114336],
        [1468022400000, 14035737.806230836],
        [1468108800000, 4559501.88558977],
        [1468195200000, 11938095.540816711],
        [1468281600000, 8944484.246364418],
        [1468368000000, 6087112.236380934],
        [1468454400000, 13698700.023209212],
        [1468540800000, 13798764.3887509],
        [1468627200000, 10138776.97248824],
        [1468713600000, 20447413.599733796],
        [1468800000000, 13898138.245649716],
        [1468886400000, 20242192.241273765],
        [1468972800000, 29894784.28435568],
        [1469059200000, 24473811.51309662],
        [1469145600000, 72391413.22249371],
        [1469232000000, 72744516.35031606],
        [1469318400000, 71639748.40237246],
        [1469404800000, 33694267.533380985],
        [1469491200000, 76432694.99620306],
        [1469577600000, 48754958.64137967],
        [1469664000000, 19481593.529681668],
        [1469750400000, 10125341.279974585],
        [1469836800000, 8150653.892600032],
        [1469923200000, 20238765.447837573],
        [1470009600000, 17147499.527635228],
        [1470096000000, 60708724.33736284],
        [1470182400000, 44330639.67738887],
        [1470268800000, 29307740.66438063],
        [1470355200000, 10956639.34702048],
        [1470441600000, 11692994.41648213],
        [1470528000000, 11883004.411650596],
        [1470614400000, 10967961.760577636],
        [1470700800000, 29672733.520185594],
        [1470787200000, 23499542.868399795],
        [1470873600000, 24164966.873272106],
        [1470960000000, 24135899.78447133],
        [1471046400000, 6655535.198454872],
        [1471132800000, 7543085.399330939],
        [1471219200000, 5064027.898267308],
        [1471305600000, 5249879.8049723925],
        [1471392000000, 5256928.972461218],
        [1471478400000, 4567012.374311765],
        [1471564800000, 3192133.226690042],
        [1471651200000, 5076175.103616041],
        [1471737600000, 3197471.3310449845],
        [1471824000000, 4831490.757852666],
        [1471910400000, 4721007.823312022],
        [1471996800000, 3221362.082246617],
        [1472083200000, 6231804.810184036],
        [1472169600000, 4403460.384900961],
        [1472256000000, 1903199.5609802417],
        [1472342400000, 4055631.700066124],
        [1472428800000, 5810173.643565886],
        [1472515200000, 5550355.265152284],
        [1472601600000, 7446354.073382742],
        [1472688000000, 19546439.786958296],
        [1472774400000, 7683911.541524928],
        [1472860800000, 10099601.667450976],
        [1472947200000, 5035842.952199199],
        [1473033600000, 3788155.072558386],
        [1473120000000, 4815696.850474364],
        [1473206400000, 4664621.634283545],
        [1473292800000, 7944145.032547882],
        [1473379200000, 3826059.408074749],
        [1473465600000, 4731455.712652934],
        [1473552000000, 6472336.880957989],
        [1473638400000, 4554653.825275872],
        [1473724800000, 6637362.777464354],
        [1473811200000, 3846492.7555122753],
        [1473897600000, 4500681.300284119],
        [1473984000000, 14494427.653257197],
        [1474070400000, 6237001.347482052],
        [1474156800000, 18914756.790781923],
        [1474243200000, 11627825.017810639],
        [1474329600000, 28747263.944959663],
        [1474416000000, 25166170.46583509],
        [1474502400000, 23902556.17228226],
        [1474588800000, 7256064.666833526],
        [1474675200000, 7988975.03646825],
        [1474761600000, 4488914.611269901],
        [1474848000000, 5602575.391137662],
        [1474934400000, 5399539.834538619],
        [1475020800000, 6516563.112363243],
        [1475107200000, 4241372.3389502475],
        [1475193600000, 4568583.2756650625],
        [1475280000000, 4727545.880665653],
        [1475366400000, 2764266.958777093],
        [1475452800000, 5821790.035410628],
        [1475539200000, 6702196.6980442945],
        [1475625600000, 6285719.065320643],
        [1475712000000, 9120081.098095493],
        [1475798400000, 6504030.816998282],
        [1475884800000, 8853947.552639049],
        [1475971200000, 5440893.661712],
        [1476057600000, 9479462.92900281],
        [1476144000000, 8989864.764158806],
        [1476230400000, 7565516.582482849],
        [1476316800000, 6550532.31361221],
        [1476403200000, 3393733.0814907444],
        [1476489600000, 3047472.2344031925],
        [1476576000000, 2051473.3981454277],
        [1476662400000, 4329663.894079154],
        [1476748800000, 14699505.269326214],
        [1476835200000, 7727576.680042059],
        [1476921600000, 4811381.15158085],
        [1477008000000, 2496841.1791091952],
        [1477094400000, 3995386.223650696],
        [1477180800000, 2843471.0792325726],
        [1477267200000, 2417942.610433313],
        [1477353600000, 13528339.239714485],
        [1477440000000, 6113958.130893516],
        [1477526400000, 8682323.137549385],
        [1477612800000, 8907341.45947652],
        [1477699200000, 21618613.51080939],
        [1477785600000, 12714282.56566218],
        [1477872000000, 9254943.69991251],
        [1477958400000, 17471506.378140815],
        [1478044800000, 7632829.9444044735],
        [1478131200000, 11623516.370152857],
        [1478217600000, 5924309.345130352],
        [1478304000000, 3076392.2948442292],
        [1478390400000, 3218494.7703560493],
        [1478476800000, 2949427.1027813354],
        [1478563200000, 4085085.3360599373],
        [1478649600000, 12761164.948392091],
        [1478736000000, 2902119.1234792797],
        [1478822400000, 4630773.4709864445],
        [1478908800000, 7585168.214197377],
        [1478995200000, 7517444.6862510005],
        [1479081600000, 2576156.680039528],
        [1479168000000, 4844767.740034414],
        [1479254400000, 7762025.46717357],
        [1479340800000, 5552737.539381997],
        [1479427200000, 8802335.674523208],
        [1479513600000, 3851758.816579985],
        [1479600000000, 4110666.001854235],
        [1479686400000, 2465979.6564902803],
        [1479772800000, 9216379.377587905],
        [1479859200000, 3470748.2472650427],
        [1479945600000, 13786747.083724402],
        [1480032000000, 6006996.215784014],
        [1480118400000, 2161817.2787581105],
        [1480204800000, 6689742.924678162],
        [1480291200000, 4477356.374190036],
        [1480377600000, 13917166.749888523],
        [1480464000000, 9134285.15725555],
        [1480550400000, 4918051.799250141],
        [1480636800000, 15380798.551991535],
        [1480723200000, 10486224.197755983],
        [1480809600000, 6571191.489582562],
        [1480896000000, 15221226.681976186],
        [1480982400000, 56439748.77481539],
        [1481068800000, 25080911.317097366],
        [1481155200000, 12722352.036778716],
        [1481241600000, 10533790.230199138],
        [1481328000000, 5983385.689231349],
        [1481414400000, 4100522.2593546156],
        [1481500800000, 6987403.012327321],
        [1481587200000, 2905152.217437659],
        [1481673600000, 3686497.057651392],
        [1481760000000, 8295155.05874539],
        [1481846400000, 2694314.912774315],
        [1481932800000, 5469007.7856543865],
        [1482019200000, 3489317.4199424963],
        [1482105600000, 3326290.3901573],
        [1482192000000, 3764440.2234887206],
        [1482278400000, 6468951.581053176],
        [1482364800000, 12618111.15631556],
        [1482451200000, 14660852.226076419],
        [1482537600000, 5396882.270456221],
        [1482624000000, 6089953.448754245],
        [1482710400000, 4090569.3333637784],
        [1482796800000, 6649186.1155613605],
        [1482883200000, 13844753.339093078],
        [1482969600000, 20692130.792193126],
        [1483056000000, 7751538.43158162],
        [1483142400000, 3754739.209030752],
        [1483228800000, 7811699.12614274],
        [1483315200000, 8192075.241699923],
        [1483401600000, 24617090.479933955],
        [1483488000000, 30283873.09261184],
        [1483574400000, 31832239.059713636],
        [1483660800000, 23015612.49187432],
        [1483747200000, 15178458.281250305],
        [1483833600000, 9726315.158380691],
        [1483920000000, 18147872.43406206],
        [1484006400000, 8483903.665315015],
        [1484092800000, 15650725.63529802],
        [1484179200000, 10113120.406065958],
        [1484265600000, 7919565.412407908],
        [1484352000000, 4879177.658292587],
        [1484438400000, 4332221.110583241],
        [1484524800000, 5886593.4906641105],
        [1484611200000, 14660002.940091308],
        [1484697600000, 8053530.980182525],
        [1484784000000, 6357101.908723087],
        [1484870400000, 7935034.033635709],
        [1484956800000, 8840650.535766678],
        [1485043200000, 5158451.375139222],
        [1485129600000, 5620391.166760423],
        [1485216000000, 5709572.008183221],
        [1485302400000, 5749423.917130453],
        [1485388800000, 6557326.8506697165],
        [1485475200000, 5497367.838993973],
        [1485561600000, 5053699.38083557],
        [1485648000000, 3291646.7339461427],
        [1485734400000, 5394044.15637189],
        [1485820800000, 8686038.921618612],
        [1485907200000, 9511590.154111855],
        [1485993600000, 6770261.660819854],
        [1486080000000, 8348606.141529283],
        [1486166400000, 10294011.746300206],
        [1486252800000, 3675287.6308482876],
        [1486339200000, 4068555.061902807],
        [1486425600000, 4749524.532223364],
        [1486512000000, 6195998.7672163835],
        [1486598400000, 13014114.532155838],
        [1486684800000, 8465560.862563102],
        [1486771200000, 5365544.876506018],
        [1486857600000, 4159375.2547445847],
        [1486944000000, 4407682.629750895],
        [1487030400000, 31343305.129852153],
        [1487116800000, 12601119.248384409],
        [1487203200000, 10436344.691817228],
        [1487289600000, 7412007.524208957],
        [1487376000000, 5979769.378102808],
        [1487462400000, 5083526.063930682],
        [1487548800000, 6982638.937362893],
        [1487635200000, 12724890.253553009],
        [1487721600000, 6874100.081802634],
        [1487808000000, 8909149.88275321],
        [1487894400000, 13610109.543739341],
        [1487980800000, 12468242.903672196],
        [1488067200000, 13782004.348183315],
        [1488153600000, 20501520.187154144],
        [1488240000000, 43441932.36968612],
        [1488326400000, 26801192.633228768],
        [1488412800000, 63520413.50856188],
        [1488499200000, 63619640.043579124],
        [1488585600000, 17012295.23543211],
        [1488672000000, 16685230.67871547],
        [1488758400000, 19157704.333576992],
        [1488844800000, 22162502.616289888],
        [1488931200000, 25853719.80005299],
        [1489017600000, 19983777.62089817],
        [1489104000000, 57671666.8607414],
        [1489190400000, 49677899.622201264],
        [1489276800000, 49928738.77542156],
        [1489363200000, 171741714.0352735],
        [1489449600000, 70373909.66103722],
        [1489536000000, 116848018.75719893],
        [1489622400000, 278693085.2513411],
        [1489708800000, 334923528.91465944],
        [1489795200000, 151727339.68589038],
        [1489881600000, 151889492.84525648],
        [1489968000000, 100344408.21407366],
        [1490054400000, 59182511.95934919],
        [1490140800000, 66568697.13198635],
        [1490227200000, 64280704.82629739],
        [1490313600000, 186199954.72870487],
        [1490400000000, 161617494.53457117],
        [1490486400000, 85306682.68239024],
        [1490572800000, 72421320.02870055],
        [1490659200000, 63445924.96171639],
        [1490745600000, 94092094.51316193],
        [1490832000000, 51703487.39219208],
        [1490918400000, 93559373.29078574],
        [1491004800000, 57319943.03719086],
        [1491091200000, 86493796.3560341],
        [1491177600000, 107658222.89744815],
        [1491264000000, 94009509.91013359],
        [1491350400000, 77394936.33704934],
        [1491436800000, 83596063.24955817],
        [1491523200000, 45710556.43334019],
        [1491609600000, 47830509.431582466],
        [1491696000000, 23843828.235415436],
        [1491782400000, 30011146.706036303],
        [1491868800000, 24362779.834156487],
        [1491955200000, 64060330.38766039],
        [1492041600000, 105834573.1245954],
        [1492128000000, 57312861.70789763],
        [1492214400000, 44031479.42837937],
        [1492300800000, 18940687.571405288],
        [1492387200000, 29334200.22162203],
        [1492473600000, 46490458.14501291],
        [1492560000000, 49801919.05302366],
        [1492646400000, 51865276.227512024],
        [1492732800000, 29790338.033409435],
        [1492819200000, 25473890.075759377],
        [1492905600000, 29345869.98540767],
        [1492992000000, 41775081.579924054],
        [1493078400000, 29265025.365697548],
        [1493164800000, 90529456.77094631],
        [1493251200000, 186806133.133835],
        [1493337600000, 270510184.7218242],
        [1493424000000, 126594019.26335718],
        [1493510400000, 173218958.1717786],
        [1493596800000, 290126273.0476898],
        [1493683200000, 138794895.4622068],
        [1493769600000, 115183975.0640192],
        [1493856000000, 269928948.78367954],
        [1493942400000, 233798440.24945286],
        [1494028800000, 95042529.8296724],
        [1494115200000, 109489063.89598095],
        [1494201600000, 166178760.5428186],
        [1494288000000, 258922158.28469768],
        [1494374400000, 109313275.86282022],
        [1494460800000, 94641972.77458185],
        [1494547200000, 90420897.82119603],
        [1494633600000, 59897616.43618652],
        [1494720000000, 48280690.88076472],
        [1494806400000, 118176219.01917155],
        [1494892800000, 96955301.31501137],
        [1494979200000, 116563051.53420986],
        [1495065600000, 148398962.68830538],
        [1495152000000, 380753404.2666354],
        [1495238400000, 211606740.98925158],
        [1495324800000, 362117265.2049605],
        [1495411200000, 733476989.0001667],
        [1495497600000, 314107904.8358022],
        [1495584000000, 465231566.895268],
        [1495670400000, 455310601.9269435],
        [1495756800000, 320845434.46142286],
        [1495843200000, 524489553.9146857],
        [1495929600000, 275566686.9159719],
        [1496016000000, 317142762.9142854],
        [1496102400000, 820279582.1697654],
        [1496188800000, 649753307.2629591],
        [1496275200000, 438784642.5121543],
        [1496361600000, 226780300.74337018],
        [1496448000000, 199543169.93195236],
        [1496534400000, 405540899.41456276],
        [1496620800000, 233840017.29033926],
        [1496707200000, 421823672.7720283],
        [1496793600000, 231988781.8625898],
        [1496880000000, 181079369.60149828],
        [1496966400000, 268066729.96297646],
        [1497052800000, 801852630.5281926],
        [1497139200000, 636143278.2379793],
        [1497225600000, 1421483466.375218],
        [1497312000000, 735420495.2051558],
        [1497398400000, 564686366.697392],
        [1497484800000, 1019438927.4375795],
        [1497571200000, 404203523.11443627],
        [1497657600000, 299739757.59496593],
        [1497744000000, 315402069.35223436],
        [1497830400000, 255793325.43759757],
        [1497916800000, 517663237.25955987],
        [1498003200000, 719062539.4411728],
        [1498089600000, 373363641.5631702],
        [1498176000000, 217363813.30567747],
        [1498262400000, 216259454.09568173],
        [1498348800000, 492617160.2802557],
        [1498435200000, 892409899.091193],
        [1498521600000, 739337521.914465],
        [1498608000000, 735209015.2786213],
        [1498694400000, 467820573.9428333],
        [1498780800000, 359285362.4912947],
        [1498867200000, 364629591.9530462],
        [1498953600000, 352969409.70496273],
        [1499040000000, 244798911.4174002],
        [1499126400000, 239212708.08591032],
        [1499212800000, 269182917.884202],
        [1499299200000, 182999371.28307688],
        [1499385600000, 257098223.16767168],
        [1499472000000, 237052454.07736254],
        [1499558400000, 126590931.37364098],
        [1499644800000, 411832452.472758],
        [1499731200000, 628400853.3093524],
        [1499817600000, 480090644.3759754],
        [1499904000000, 311100836.347462],
        [1499990400000, 282809496.9510771],
        [1500076800000, 245487302.92215428],
        [1500163200000, 594995539.910624],
        [1500249600000, 527360935.2359769],
        [1500336000000, 979531260.0237657],
        [1500422400000, 769335320.255077],
        [1500508800000, 731711069.5424181],
        [1500595200000, 390393974.2217934],
        [1500681600000, 229970082.7943966],
        [1500768000000, 204467773.72653127],
        [1500854400000, 144284203.8135455],
        [1500940800000, 358021108.5784135],
        [1501027200000, 291270680.5567596],
        [1501113600000, 155763232.69062254],
        [1501200000000, 312701503.6657654],
        [1501286400000, 420378363.7250074],
        [1501372800000, 278403491.47329795],
        [1501459200000, 256040954.6827099],
        [1501545600000, 794803694.1987914],
        [1501632000000, 310747315.777984],
        [1501718400000, 229219665.889338],
        [1501804800000, 203505640.27122915],
        [1501891200000, 615120367.3016751],
        [1501977600000, 640103600.4602818],
        [1502064000000, 435385633.8349678],
        [1502150400000, 678573131.3986272],
        [1502236800000, 1197172634.0328116],
        [1502323200000, 448574816.64381045],
        [1502409600000, 411643039.51414186],
        [1502496000000, 485474525.24875605],
        [1502582400000, 689079789.7596157],
        [1502668800000, 384478726.7916482],
        [1502755200000, 526951176.2194243],
        [1502841600000, 640592816.9629201],
        [1502928000000, 641280744.7518665],
        [1503014400000, 590470398.7743214],
        [1503100800000, 553702157.2699106],
        [1503187200000, 342822961.8711704],
        [1503273600000, 1743909692.665895],
        [1503360000000, 898344262.6106703],
        [1503446400000, 466474643.49241275],
        [1503532800000, 397752658.9354108],
        [1503619200000, 451950699.05019414],
        [1503705600000, 333839327.838868],
        [1503792000000, 370052131.98739076],
        [1503878400000, 519874393.4238931],
        [1503964800000, 767166476.1357825],
        [1504051200000, 852991842.1703743],
        [1504137600000, 414360266.00919527],
        [1504224000000, 505643412.793286],
        [1504310400000, 958653961.776176],
        [1504396800000, 671189521.5578377],
        [1504483200000, 1278523027.0732577],
        [1504569600000, 1099567125.3565156],
        [1504656000000, 672471698.8217244],
        [1504742400000, 431394249.7466129],
        [1504828800000, 660392679.7301207],
        [1504915200000, 315515992.3564483],
        [1505001600000, 456818455.0948669],
        [1505088000000, 363007787.84414524],
        [1505174400000, 583871859.8283461],
        [1505260800000, 721738885.8360373],
        [1505347200000, 929861557.1062983],
        [1505433600000, 1617179859.467345],
        [1505520000000, 586258300.894896],
        [1505606400000, 336444511.1569665],
        [1505692800000, 935880977.9429078],
        [1505779200000, 518505103.8522604],
        [1505865600000, 322301352.00916153],
        [1505952000000, 470196974.05520844],
        [1506038400000, 343728639.336796],
        [1506124800000, 365565238.54476005],
        [1506211200000, 473679365.7914858],
        [1506297600000, 367147869.49683565],
        [1506384000000, 240690671.2951268],
        [1506470400000, 394839374.31574607],
        [1506556800000, 344032408.65444744],
        [1506643200000, 401420308.2527799],
        [1506729600000, 234494285.9120729],
        [1506816000000, 208656622.10220405],
        [1506902400000, 228753338.77109602],
        [1506988800000, 213674545.194013],
        [1507075200000, 167895948.31847614],
        [1507161600000, 166289551.89366713],
        [1507248000000, 223872523.77550793],
        [1507334400000, 259031771.53758535],
        [1507420800000, 195574551.40328008],
        [1507507200000, 422803467.0821769],
        [1507593600000, 262661724.03535348],
        [1507680000000, 179994094.52122995],
        [1507766400000, 389123581.441959],
        [1507852800000, 1062936136.5475445],
        [1507939200000, 384671082.3691576],
        [1508025600000, 513925811.79312485],
        [1508112000000, 536987705.5520873],
        [1508198400000, 368660409.98234016],
        [1508284800000, 459286354.2531358],
        [1508371200000, 251750069.63289016],
        [1508457600000, 270984512.97597694],
        [1508544000000, 291385148.2978586],
        [1508630400000, 227830530.53531405],
        [1508716800000, 357572834.9115806],
        [1508803200000, 522634263.28484994],
        [1508889600000, 224745395.63136017],
        [1508976000000, 175211825.16558704],
        [1509062400000, 165062612.7639997],
        [1509148800000, 178675171.42583302],
        [1509235200000, 400151389.4858402],
        [1509321600000, 245646777.54787138],
        [1509408000000, 275058601.9442181],
        [1509494400000, 343876003.8559511],
        [1509580800000, 727168619.8327091],
        [1509667200000, 493531953.8114265],
        [1509753600000, 317726072.07408786],
        [1509840000000, 250477635.77464697],
        [1509926400000, 472946830.30819535],
        [1510012800000, 415882643.8192555],
        [1510099200000, 755752328.1239891],
        [1510185600000, 691815366.2495632],
        [1510272000000, 650505791.3247348],
        [1510358400000, 618676053.5502169],
        [1510444800000, 1172943695.0944433],
        [1510531200000, 783366133.855764],
        [1510617600000, 507718197.434228],
        [1510704000000, 518053273.608587],
        [1510790400000, 540577805.468288],
        [1510876800000, 408873409.223705],
        [1510963200000, 437969175.189351],
        [1511049600000, 892315491.355752],
        [1511136000000, 511630930.906117],
        [1511222400000, 667087478.419292],
        [1511308800000, 502690893.802197],
        [1511395200000, 1357607759.02526],
        [1511481600000, 1710984791.93773],
        [1511568000000, 1062451148.98556],
        [1511654400000, 848847109.547722],
        [1511740800000, 982703877.999528],
        [1511827200000, 640921145.689493],
        [1511913600000, 1864835257.85043],
        [1512000000000, 1302713009.05289],
        [1512086400000, 731926955.703011],
        [1512172800000, 571377802.810273],
        [1512259200000, 513723192.525671],
        [1512345600000, 559902818.597381],
        [1512432000000, 590010796.38345],
        [1512518400000, 5911434.14179457],
        [1512604800000, 968306459.942749],
        [1512691200000, 1578944197.85945],
        [1512777600000, 1109058208.5207],
        [1512864000000, 900203443.334746],
        [1512950400000, 1035160071.24411],
        [1513036800000, 3691983511.74634],
        [1513123200000, 2717113879.86409],
        [1513209600000, 2551109082.71176],
        [1513296000000, 1942292719.83027],
        [1513382400000, 1323743950.88971],
        [1513468800000, 1297439773.48386],
        [1513555200000, 2104028876.27651],
        [1513641600000, 2383800980.37899],
        [1513728000000, 2174389927.16457],
        [1513814400000, 1886649146.02117],
        [1513900800000, 2635463381.90133],
        [1513987200000, 1292257943.85486],
        [1514073600000, 1224218821.95787],
        [1514160000000, 1242520658.38317],
        [1514246400000, 1046697072.25852],
        [1514332800000, 910830156.598811],
        [1514419200000, 1012380621.45529],
        [1514505600000, 1172554374.08768],
        [1514592000000, 1306193221.81905],
        [1514678400000, 1127368142.17048],
        [1514764800000, 1101627635.65143],
        [1514851200000, 2720973272.07018],
        [1514937600000, 2308657205.9772067],
        [1515024000000, 2756635496.077742],
        [1515110400000, 2894788528.9368753],
        [1515196800000, 1824841952.503006],
        [1515283200000, 2286423001.2638764],
        [1515369600000, 4813539670.332469],
        [1515456000000, 4248463429.84743],
        [1515542400000, 5901388488.269492],
        [1515628800000, 3701485302.9898286],
        [1515715200000, 2569674473.5809693],
        [1515801600000, 3149029707.9682436],
        [1515888000000, 2771837301.4899707],
        [1515974400000, 2438212396.6501827],
        [1516060800000, 5197858118.489889],
        [1516147200000, 5505023202.5208645],
        [1516233600000, 3220457431.1306267],
        [1516320000000, 2067139497.7426836],
        [1516406400000, 1927757662.931302],
        [1516492800000, 1834167990.4392762],
        [1516579200000, 2128266201.553245],
        [1516665600000, 2162095341.3385787],
        [1516752000000, 1871168506.347743],
        [1516838400000, 2098100041.2531192],
        [1516924800000, 2126139932.407397],
        [1517011200000, 1592501816.2673755],
        [1517097600000, 3105991887.223209],
        [1517184000000, 2005499364.9335327],
        [1517270711070, 2006770995.7220535],
        [1517356800000, 2570454739.6687236],
        [1517443200000, 2148302756.922482],
        [1517529600000, 3414246407.713859],
        [1517616000000, 4640850229.358906],
        [1517702400000, 1878004123.5207362],
        [1517788800000, 1882681773.9790668],
        [1517875200000, 2941430688.7490797],
        [1517961600000, 5021975963.611412],
        [1518048323681, 2635662719.4442654],
        [1518134400000, 2256661883.3019075],
        [1518220800000, 1609823406.4155498],
        [1518307200000, 1811750053.1438994],
        [1518393600000, 1606757570.924482],
        [1518480000000, 1342080234.6450908],
        [1518566400000, 1215799275.6549883],
        [1518652800000, 1659598861.037234],
        [1518739200000, 1674187629.7427762],
        [1518825600000, 1274489447.9994729],
        [1518912000000, 1331103327.3327596],
        [1518998400000, 1443742228.731157],
        [1519084800000, 1113969168.9102838],
        [1519171200000, 1376504167.75521],
        [1519257600000, 1249190216.7073753],
        [1519344000000, 1255677307.494043],
        [1519430400000, 1553348119.733452],
        [1519516800000, 1133793473.7387242],
        [1519603200000, 971919367.4852569],
        [1519689600000, 1273600054.5671887],
        [1519776000000, 1185779363.0730155],
        [1519862400000, 1088578648.6068828],
        [1519948800000, 1046744161.7080027],
        [1520035200000, 1065182064.2918291],
        [1520121600000, 968839520.3151623],
        [1520208000000, 921438207.058072],
        [1520294400000, 996644678.1014967],
        [1520380800000, 1163651036.9598706],
        [1520467200000, 1517892302.80913],
        [1520553600000, 1253573581.5186775],
        [1520640000000, 1559125766.2324708],
        [1520726400000, 1019304765.7600274],
        [1520812800000, 1044019470.3085319],
        [1520899200000, 1041451579.0783635],
        [1520985600000, 914239003.1825721],
        [1521072000000, 1222860953.9396286],
        [1521158400000, 1314501765.2298925],
        [1521244800000, 939394446.556439],
        [1521331200000, 922526847.9439002],
        [1521417600000, 2515158736.529607],
        [1521504000000, 1668238269.8238573],
        [1521590400000, 1363997499.3256834],
        [1521676800000, 1242490235.347],
        [1521763200000, 1029637021.1311402],
        [1521849600000, 1024506177.6381531],
        [1521936000000, 722258331.0537022],
        [1522022400000, 654302263.778145],
        [1522108800000, 1130010306.3489618],
        [1522195200000, 1205007525.3029315],
        [1522281600000, 899161524.3411528],
        [1522368000000, 1501135895.8124254],
        [1522454400000, 1552652584.610018],
        [1522540800000, 970454263.2346877],
        [1522627200000, 928418706.5875922],
        [1522713600000, 725811930.9112238],
        [1522800000000, 938277656.037438],
        [1522886400000, 888566484.4804863],
        [1522972800000, 767721856.0504649],
        [1523059200000, 590720199.8519591],
        [1523145600000, 549082514.9320759],
        [1523232000000, 565597670.5997823],
        [1523318400000, 1095742137.015535],
        [1523404800000, 687260482.4501439],
        [1523491200000, 816313720.2347212],
        [1523577600000, 1871680188.8730233],
        [1523664000000, 1771917382.798235],
        [1523750400000, 960941082.4787031],
        [1523836800000, 1113471501.5870776],
        [1523923200000, 952394918.7009709],
        [1524009600000, 834810907.6434356],
        [1524096000000, 827471474.463687],
        [1524182400000, 1223410365.7024539],
        [1524268800000, 1477100076.3424082],
        [1524355200000, 1412079787.2669172],
        [1524441600000, 1226887273.8293471],
        [1524528000000, 1175262573.641298],
        [1524614400000, 2013232706.0861015],
        [1524700800000, 2772144667.1931686],
        [1524787200000, 1616604527.0569918],
        [1524873600000, 1327872538.0128767],
        [1524960000000, 1170961463.496074],
        [1525046400000, 1180427164.5645165],
        [1525132800000, 1271088003.6155422],
        [1525219200000, 1342793449.0474029],
        [1525305600000, 1153897333.4141612],
        [1525392000000, 1005515083.8603067],
        [1525478400000, 1967182623.5957444],
        [1525564800000, 1617671981.240539],
        [1525651200000, 1742487117.419987],
        [1525737600000, 2808727749.1170845],
        [1525824000000, 1562833107.5547128],
        [1525910400000, 1517875906.0296729],
        [1525996800000, 1329169267.9922028],
        [1526083200000, 1867453050.653334],
        [1526169600000, 1574237121.080983],
        [1526256000000, 1358354339.8603525],
        [1526342400000, 1747256249.2920547],
        [1526428800000, 1318045201.5495617],
        [1526515200000, 1283393781.5952938],
        [1526601600000, 1148132528.5857544],
        [1526688000000, 1140013811.1318896],
        [1526774400000, 955540974.5773942],
        [1526860800000, 964430375.9997903],
        [1526947200000, 914750523.9094018],
        [1527033600000, 1134342772.6066892],
        [1527120000000, 1636502158.2149532],
        [1527206400000, 1578836653.594426],
        [1527292800000, 1130488777.7931898],
        [1527379200000, 793211715.4555622],
        [1527465600000, 936703218.9329598],
        [1527552000000, 1349222685.5995286],
        [1527638400000, 1304128810.668677],
        [1527724800000, 1076970536.7091825],
        [1527811200000, 1016848280.1956758],
        [1527897600000, 988913896.7405567],
        [1527984000000, 917573393.7371987],
        [1528070400000, 960786811.8699535],
        [1528156800000, 998788030.4591345],
        [1528243200000, 981693246.7757119],
        [1528329600000, 883508366.7942146],
        [1528416000000, 987621968.4491141],
        [1528502400000, 774931924.148761],
        [1528588800000, 704024660.4544114],
        [1528675200000, 1323637042.9511685],
        [1528761600000, 1163129332.5094237],
        [1528848000000, 1183551767.9992313],
        [1528934400000, 1330197064.145758],
        [1529020800000, 1634517037.5387144],
        [1529107200000, 1129671619.800608],
        [1529193600000, 732126705.7704039],
        [1529280000000, 675191594.9936471],
        [1529366400000, 861532499.7213074],
        [1529452800000, 1047558826.1450363],
        [1529539200000, 983081480.6999408],
        [1529625600000, 901042405.2653681],
        [1529712000000, 1473054828.325441],
        [1529798400000, 1088716168.438927],
        [1529884800000, 1813906376.293752],
        [1529971200000, 3935188498.9796495],
        [1530057600000, 3145279252.081028],
        [1530144000000, 1459598903.6298532],
        [1530230400000, 1247515217.4657764],
        [1530316800000, 1274641927.2798553],
        [1530403200000, 1242421813.7124994],
        [1530489600000, 1389903375.1050892],
        [1530576000000, 1756088768.4057357],
        [1530662400000, 1747988600.5163236],
        [1530748800000, 1569050880.151065],
        [1530835200000, 1727252653.8580823],
        [1530921600000, 1573448525.1336815],
        [1531008000000, 1498055824.3153505],
        [1531094400000, 1475654563.4116213],
        [1531180800000, 1637687610.915009],
        [1531267200000, 1785960365.2391424],
        [1531353600000, 1290310142.3535857],
        [1531440000000, 1431811643.4801674],
        [1531526400000, 1446717925.4764767],
        [1531612800000, 1123965513.5737858],
        [1531699200000, 1144805112.5639584],
        [1531785600000, 2202047226.057058],
        [1531872000000, 1948063360.783312],
        [1531958400000, 1914258994.0043561],
        [1532044800000, 1540257887.5019171],
        [1532131200000, 1657375888.3844986],
        [1532217600000, 1274976611.9245467],
        [1532304000000, 1171779230.1386185],
        [1532390400000, 1376984398.4663281],
        [1532476800000, 1761639225.2861466],
        [1532563200000, 1567839914.2626874],
        [1532649600000, 1425778759.9011617],
        [1532736000000, 1318960361.3308747],
        [1532822400000, 15896613818.38507],
        [1532908800000, 8096703017.25451],
        [1532995200000, 1658111274.2681992],
        [1533081600000, 1576813468.9990995],
        [1533168000000, 5779965392.271022],
        [1533254400000, 2616667154.5040126],
        [1533340800000, 2104388036.4109633],
        [1533427200000, 1809713052.7134383],
        [1533513600000, 2106705188.799999],
        [1533600000000, 2315454592.965536],
        [1533686400000, 1851482817.3237607],
        [1533772800000, 1935897432.9447079],
        [1533859200000, 2317855524.1517854],
        [1533945600000, 2027472927.1744547],
        [1534032000000, 2371854614.6728854],
        [1534118400000, 2055561310.2074306],
        [1534204800000, 2305624514.7211037],
        [1534291200000, 3134794913.6870646],
        [1534377600000, 2818580241.5689964],
        [1534464000000, 2409323174.6488853],
        [1534550400000, 2930409554.8592534],
        [1534636800000, 2806352498.6709337],
        [1534723200000, 2503896967.514467],
        [1534809600000, 2209797060.591189],
        [1534896000000, 2099627671.6781328],
        [1534982400000, 2312267790.5229416],
        [1535068800000, 2099144009.1521382],
        [1535155200000, 2215723321.9649477],
        [1535241600000, 1727946125.1412983],
        [1535328000000, 1905358200.9155493],
        [1535414400000, 2157563439.765662],
        [1535500800000, 2548394710.003952],
        [1535587200000, 2536785879.3731794],
        [1535673600000, 2491159403.542024],
        [1535760000000, 2200676475.6614757],
        [1535846400000, 2591135538.1810236],
        [1535932800000, 2297225428.9853034],
        [1536019200000, 2057722570.6458614],
        [1536105600000, 2979570499.016402],
        [1536192000000, 4099897499.876869],
        [1536278400000, 2701061396.205367],
        [1536364800000, 2789431368.228549],
        [1536451200000, 2540332938.5796466],
        [1536537600000, 3175761389.4267607],
        [1536624000000, 2491788678.425953],
        [1536710400000, 2596452765.8883944],
        [1536796800000, 2604494814.9428654],
        [1536883200000, 3501498582.9411836],
        [1536969600000, 3131560545.2123165],
        [1537056000000, 2852106466.4745975],
        [1537142400000, 2369163812.379939],
        [1537228800000, 2973745225.343125],
        [1537315200000, 2669510295.5159802],
        [1537401600000, 2532964971.008765],
        [1537488000000, 2506415802.825286],
        [1537574400000, 3224898332.902585],
        [1537660800000, 2253360127.7800627],
        [1537747200000, 2228170858.2763815],
        [1537833600000, 2153893196.3090963],
        [1537920000000, 4690848657.831447],
        [1538006400000, 2266901693.2743306],
        [1538092800000, 2324850663.3816543],
        [1538179200000, 1941656837.4249225],
        [1538265600000, 2157048771.9024014],
        [1538352000000, 1696510081.8875268],
        [1538438400000, 1380169412.0950716],
        [1538524800000, 1289588248.9376829],
        [1538611200000, 1484341832.0366879],
        [1538697600000, 1299403541.2219515],
        [1538784000000, 1343329878.055222],
        [1538870400000, 1289880374.6702132],
        [1538956800000, 1219257804.378523],
        [1539043200000, 1307100279.2014499],
        [1539129600000, 1144035692.1601665],
        [1539216000000, 1221609498.330973],
        [1539302400000, 1985897938.5728974],
        [1539388800000, 1432531941.2853491],
        [1539475200000, 1075912682.4856188],
        [1539561600000, 1120326805.1559036],
        [1539648000000, 3003242429.5916],
        [1539734400000, 1278234751.758753],
        [1539820800000, 1230898825.0612144],
        [1539907200000, 1267340948.4957962],
        [1539993600000, 1230724742.5840461],
        [1540080000000, 1154390188.6976511],
        [1540166400000, 1070945888.3231828],
        [1540252800000, 1242296100.6213374],
        [1540339200000, 1232758658.0737],
        [1540425600000, 1220612744.1480913],
        [1540512000000, 1165395713.6574717],
        [1540598400000, 1294283606.8483193],
        [1540684800000, 1055337328.7702934],
        [1540771200000, 1039726472.1950811],
        [1540857600000, 1525796041.9084504],
        [1540944000000, 977416380.6935779],
        [1541030400000, 1270047763.0015414],
        [1541116800000, 1320296639.6714208],
        [1541203200000, 1261558842.1750631],
        [1541289600000, 1031402141.4720232],
        [1541376000000, 1796786475.6827538],
        [1541462400000, 1497004600.6516602],
        [1541548800000, 1767914756.9357538],
        [1541635200000, 1482170343.362153],
        [1541721600000, 1330922735.7940972],
        [1541808000000, 1088745373.4360032],
        [1541894400000, 1068409319.3349147],
        [1541980800000, 1300514887.7948813],
        [1542067200000, 1282532597.6221092],
        [1542153600000, 1395382434.391138],
        [1542240000000, 2658768934.172415],
        [1542326400000, 2461869578.310328],
        [1542412800000, 1724796640.6795118],
        [1542499200000, 1412767953.2110136],
        [1542585600000, 1419370369.0170794],
        [1542672000000, 2804845847.3432374],
        [1542758400000, 3210253186.2157793],
        [1542844800000, 2864570364.233047],
        [1542931200000, 1749233437.3121877],
        [1543017600000, 1739142608.9564946],
        [1543104000000, 1678866535.7412324],
        [1543190400000, 2438195895.932367],
        [1543276800000, 1897018311.0723217],
        [1543363200000, 1997220602.5212777],
        [1543449600000, 2140930653.1883004],
        [1543536000000, 1607356549.8743916],
        [1543622400000, 1719140725.5823185],
        [1543708800000, 1471310196.1165853],
        [1543795200000, 1311180616.0078957],
        [1543881600000, 1272979823.5054636],
        [1543968000000, 1334764925.9641466],
        [1544054400000, 1338392608.1239889],
        [1544140800000, 1619374013.0384092],
        [1544227200000, 2504222326.1841803],
        [1544313600000, 1807021801.6527622],
        [1544400000000, 1591278001.7639914],
        [1544486400000, 1295907311.516668],
        [1544572800000, 1392270657.2968814],
        [1544659200000, 1232429721.5102735],
        [1544745600000, 1269694470.9247181],
        [1544832000000, 1325123179.3098264],
        [1544918400000, 1109693017.2554052],
        [1545004800000, 1198587935.4117327],
        [1545091200000, 1696264039.588314],
        [1545177600000, 1751770011.1099293],
        [1545264000000, 1971206305.0304084],
        [1545350400000, 2413040598.1809735],
        [1545436800000, 2146527658.029341],
        [1545523200000, 1843031240.6757457],
        [1545609600000, 3038557582.0165663],
        [1545696000000, 4190149358.5431848],
        [1545782400000, 2981293112.474187],
        [1545868800000, 2263075668.319348],
        [1545955200000, 1964406471.6911712],
        [1546041600000, 2611941307.2773275],
        [1546128000000, 2754610335.045736],
        [1546214400000, 2326472259.1586623],
        [1546300800000, 1822123555.3749323],
        [1546387200000, 1831181165.2598188],
        [1546473600000, 2970382678.100767],
        [1546560000000, 2147721864.232185],
        [1546646400000, 2802750465.265354],
        [1546732800000, 2751810037.277045],
        [1546819200000, 2604841786.5535765],
        [1546905600000, 2288173707.0961676],
        [1546992000000, 2024950462.4209144],
        [1547078400000, 1895694764.7753518],
        [1547164800000, 2728309575.30694],
        [1547251200000, 2081742756.9099514],
        [1547337600000, 1504750867.1261935],
        [1547424000000, 1642681383.2372842],
        [1547510400000, 2141032095.0550327],
        [1547596800000, 2179445232.6582036],
        [1547683200000, 2113608536.6644716],
        [1547769600000, 1953391667.2246928],
        [1547856000000, 1689783955.342064],
        [1547942400000, 1843518995.3753238],
        [1548028800000, 1968418396.7324762],
        [1548115200000, 1719454581.2400157],
        [1548201600000, 1765861558.4971473],
        [1548288000000, 1599707091.7203567],
        [1548374400000, 1605735349.503223],
        [1548460800000, 1612015659.44269],
        [1548547200000, 1433112570.853001],
        [1548633600000, 1753825291.7971783],
        [1548720000000, 2332780149.277323],
        [1548806400000, 2555950268.3740425],
        [1548892800000, 2493952726.425111],
        [1548979200000, 1980504563.8608692],
        [1549065600000, 2189489172.098924],
        [1549152000000, 1859087081.855614],
        [1549238400000, 1970373516.398768],
        [1549324800000, 1910734433.7085884],
        [1549411200000, 1850805735.245364],
        [1549497600000, 2028390258.2091599],
        [1549584000000, 1809028295.0201623],
        [1549670400000, 3337030323.848282],
        [1549756800000, 2281124257.2366414],
        [1549843200000, 2720772395.722468],
        [1549929600000, 2802017940.5234113],
        [1550016000000, 2573128709.502421],
        [1550102400000, 2635011194.1226845],
        [1550188800000, 2745287445.7246704],
        [1550275200000, 2374950565.9874945],
        [1550361600000, 2286482856.840245],
        [1550448000000, 4003447879.040646],
        [1550534400000, 5528376735.104817],
        [1550620800000, 4958547530.735177],
        [1550707200000, 4674193073.576877],
        [1550793600000, 4028897436.931248],
        [1550880000000, 3866449781.9766116],
        [1550966400000, 5041062545.201769],
        [1551052800000, 6907323014.255601],
        [1551139200000, 5199003327.451232],
        [1551225600000, 4013821729.4520674],
        [1551312000000, 4639489690.788092],
        [1551398400000, 4363574713.481887],
        [1551484800000, 3152662372.3438797],
        [1551571200000, 3386932026.236029],
        [1551657600000, 3658774171.8825684],
        [1551744000000, 4360833026.630624],
        [1551830400000, 5188731980.705806],
        [1551916800000, 4644218449.362553],
        [1552003200000, 4578357693.783855],
        [1552089600000, 4522056070.475344],
        [1552176000000, 4296123041.029174],
        [1552262400000, 4499641386.635785],
        [1552348800000, 3785480350.370964],
        [1552435200000, 4188517679.4991765],
        [1552521600000, 3621660451.668175],
        [1552608000000, 3916095404.0189743],
        [1552694400000, 4756481151.442661],
        [1552780800000, 4786242985.088408],
        [1552867200000, 3163231174.800602],
        [1552953600000, 4436947537.81063],
        [1553040000000, 4016707231.683015],
        [1553126400000, 4301026829.020336],
        [1553212800000, 5329540237.069148],
        [1553299200000, 4676895061.111653],
        [1553385600000, 4329648785.084198],
        [1553472000000, 4828779391.134742],
        [1553558400000, 5128191497.587813],
        [1553644800000, 6365187624.764251],
        [1553731200000, 6058290711.3394575],
        [1553817600000, 6421741403.9849205],
        [1553904000000, 6309182468.745683],
        [1553990400000, 6431145135.476545],
        [1554076800000, 5681255417.619743],
        [1554163200000, 5806385520.250954],
        [1554249600000, 11972928895.801353],
        [1554336000000, 14345018962.49493],
        [1554422400000, 18960578265.68538],
        [1554508800000, 8154465149.927939],
        [1554595200000, 8041146224.6182995],
        [1554681600000, 8774486908.148108],
        [1554768000000, 9174797057.457947],
        [1554854400000, 7853653932.961174],
        [1554940800000, 8535409687.957658],
        [1555027200000, 9492297742.469435],
        [1555113600000, 6864550131.632152],
        [1555200000000, 4895342267.034281],
        [1555286400000, 5453899413.191777],
        [1555372800000, 6208683988.248793],
        [1555459200000, 5341068833.609714],
        [1555545600000, 5326967582.034817],
        [1555632000000, 7052905652.127466],
        [1555718400000, 7221388008.444495],
        [1555804800000, 6156525100.7694435],
        [1555891200000, 6664047714.540679],
        [1555977600000, 6402115847.390519],
        [1556064000000, 7113521465.307921],
        [1556150400000, 8048012710.936328],
        [1556236800000, 7178205516.338611],
        [1556323200000, 7692660385.390277],
        [1556409600000, 5979883531.17244],
        [1556496000000, 5768664295.690624],
        [1556582400000, 6411778889.018952],
        [1556668800000, 7295538037.156365],
        [1556755200000, 5827474260.897809],
        [1556841600000, 6382424498.642877],
        [1556928000000, 7370814310.015348],
        [1557014400000, 7462922637.386197],
        [1557100800000, 6585100748.204202],
        [1557187200000, 8296752441.893426],
        [1557273600000, 9534335324.779873],
        [1557360000000, 6667762034.758085],
        [1557446400000, 6902719143.315466],
        [1557532800000, 8161017266.9209385],
        [1557619200000, 15122302157.846966],
        [1557705600000, 11582482850.21803],
        [1557792000000, 11346705276.931213],
        [1557878400000, 13905655019.126186],
        [1557964800000, 13672541332.505766],
        [1558051200000, 18005081606.855213],
        [1558137600000, 16370442950.862896],
        [1558224000000, 9809558171.875607],
        [1558310400000, 10660302136.0779],
        [1558396800000, 11264583865.095984],
        [1558483200000, 10173591139.714605],
        [1558569600000, 9779773115.360765],
        [1558656000000, 11366080280.52015],
        [1558742400000, 9874907867.701994],
        [1558828800000, 9323604229.835455],
        [1558915200000, 11071430353.366852],
        [1559001600000, 12655030646.34605],
        [1559088000000, 9175086732.606298],
        [1559174400000, 10947486779.437864],
        [1559260800000, 13789629797.840866],
        [1559347200000, 13065602415.97646],
        [1559433600000, 9939944521.868128],
        [1559520000000, 9394858671.397644],
        [1559606400000, 9490495448.140297],
        [1559692800000, 9485736882.825798],
        [1559779200000, 9425766068.31484],
        [1559865600000, 8404331947.2354],
        [1559952000000, 8150833842.114468],
        [1560038400000, 7354875776.242826],
        [1560124800000, 8029562832.321171],
        [1560211200000, 8199127073.858642],
        [1560297600000, 8182713237.348147],
        [1560384000000, 12143678886.284647],
        [1560470400000, 8992183283.907227],
        [1560556800000, 9249836453.371191],
        [1560643200000, 8326314916.480807],
        [1560729600000, 11214016847.926468],
        [1560816000000, 10113175117.058073],
        [1560902400000, 9119385783.21149],
        [1560988800000, 8141550514.028044],
        [1561075200000, 9013711196.951782],
        [1561161600000, 13984421402.12714],
        [1561248000000, 14958216407.156822],
        [1561334400000, 11379261835.846235],
        [1561420800000, 10801735316.460266],
        [1561507200000, 11894108469.494308],
        [1561593600000, 20783128904.669895],
        [1561680000000, 17565136833.06944],
        [1561766400000, 13481294400.562168],
        [1561852800000, 15410762009.028484],
        [1561939200000, 14452227264.203045],
        [1562025600000, 14541502314.74893],
        [1562112000000, 13634093714.668394],
        [1562198400000, 13852646285.058842],
        [1562284800000, 11865088600.655111],
        [1562371200000, 12482686164.985281],
        [1562457600000, 11073979598.076387],
        [1562544000000, 9431071377.576225],
        [1562630400000, 11412190952.131702],
        [1562716800000, 12539512334.617245],
        [1562803200000, 14363666681.915071],
        [1562889600000, 11259727245.112816],
        [1562976000000, 9759415139.820307],
        [1563062400000, 8942739616.455101],
        [1563148800000, 10112411751.87944],
        [1563235200000, 12728221879.918568],
        [1563321600000, 11546792446.85422],
        [1563408000000, 11495306974.325901],
        [1563494400000, 12224826620.798044],
        [1563580800000, 9394172336.260527],
        [1563667200000, 9443236308.126741],
        [1563753600000, 8668234008.782793],
        [1563840000000, 9159417328.812357],
        [1563926400000, 8343544802.577731],
        [1564012800000, 8036309076.5856],
        [1564099200000, 8431628740.248658],
        [1564185600000, 6261416237.370375],
        [1564272000000, 6935857584.275074],
        [1564358400000, 6820635279.878914],
        [1564444800000, 7155796188.164937],
        [1564531200000, 6861118022.849301],
        [1564617600000, 7854507842.961148],
        [1564704000000, 7106350020.545052],
        [1564790400000, 7476612908.300348],
        [1564876800000, 7118505874.499466],
        [1564963200000, 7801234062.836402],
        [1565049600000, 10565615079.886549],
        [1565136000000, 9971872218.817013],
        [1565222400000, 9705696057.326073],
        [1565308800000, 8972857706.734287],
        [1565395200000, 9017297468.618519],
        [1565481600000, 7866305403.719613],
        [1565568000000, 8817555226.301624],
        [1565654400000, 6858803450.515639],
        [1565740800000, 7468627796.213287],
        [1565827200000, 9066370572.253965],
        [1565913600000, 9569868633.162851],
        [1566000000000, 9104078191.86573],
        [1566086400000, 7074381392.731569],
        [1566172800000, 7608103574.120334],
        [1566259200000, 7984979710.387964],
        [1566345600000, 7427661565.776037],
        [1566432000000, 7533577003.439183],
        [1566518400000, 8373678956.808694],
        [1566604800000, 8310335766.427433],
        [1566691200000, 6816227733.539867],
        [1566777600000, 6925363690.298179],
        [1566864000000, 7808973221.715419],
        [1566950400000, 6715792817.800851],
        [1567036800000, 6630138918.5056305],
        [1567123200000, 7553876741.007874],
        [1567209600000, 6149887965.938229],
        [1567296000000, 6422265680.054329],
        [1567382400000, 5582323205.083545],
        [1567468800000, 6929999813.24363],
        [1567555200000, 6839270834.390837],
        [1567641600000, 6753228074.432234],
        [1567728000000, 6972203243.611649],
        [1567814400000, 7990465595.274032],
        [1567900800000, 7289035615.537996],
        [1567987200000, 7427353150.500337],
        [1568073600000, 8230569258.881535],
        [1568160000000, 6808322892.822258],
        [1568246400000, 7572468713.673471],
        [1568332800000, 6514468640.712693],
        [1568419200000, 6577694623.403602],
        [1568505600000, 7236471987.494058],
        [1568592000000, 6872781110.7933855],
        [1568678400000, 8404868032.960358],
        [1568764800000, 10534577182.03602],
        [1568851200000, 10026926701.837385],
        [1568937600000, 11660216013.817453],
        [1569024000000, 8563835819.843909],
        [1569110400000, 8291876955.773469],
        [1569196800000, 8770978542.694231],
        [1569283200000, 8336808955.502666],
        [1569369600000, 11738404290.834139],
        [1569456000000, 10971536373.46625],
        [1569542400000, 8887474208.578917],
        [1569628800000, 7741411781.909645],
        [1569715200000, 8420640752.534281],
        [1569801600000, 6622811335.857158],
        [1569888000000, 8531793896.531498],
        [1569974400000, 7030264696.061253],
        [1570060800000, 7011073715.725833],
        [1570147200000, 6571609557.977975],
        [1570233600000, 6788268823.555099],
        [1570320000000, 6878002599.189638],
        [1570406400000, 6183872973.064911],
        [1570492800000, 8651483187.380627],
        [1570579200000, 7965287586.524669],
        [1570665600000, 9901588880.945726],
        [1570752000000, 8489850464.079381],
        [1570838400000, 9464732026.7697],
        [1570924800000, 7092415247.875073],
        [1571011200000, 7218077682.306935],
        [1571097600000, 6948746949.167441],
        [1571184000000, 7255387196.496509],
        [1571270400000, 7574783773.934067],
        [1571356800000, 6589528029.318176],
        [1571443200000, 8019508916.141182],
        [1571529600000, 6653033262.426412],
        [1571616000000, 7186266521.867684],
        [1571702400000, 6738537534.893596],
        [1571788800000, 6863039381.640138],
        [1571875200000, 8423014823.046386],
        [1571961600000, 7385746457.861195],
        [1572048000000, 11568719162.372278],
        [1572134400000, 13413299279.150604],
        [1572220800000, 11927463512.844177],
        [1572307200000, 10802432065.022448],
        [1572393600000, 10858105798.943794],
        [1572480000000, 10475563029.033878],
        [1572566400000, 10677408737.976376],
        [1572652800000, 8966993687.026836],
        [1572739200000, 17556115439.989822],
        [1572825600000, 15978664590.342894],
        [1572912000000, 10711288571.709585],
        [1572998400000, 10976995833.162132],
        [1573084800000, 10229062756.046371],
        [1573171200000, 8952840250.325546],
        [1573257600000, 9317711239.968254],
        [1573344000000, 7371484625.797002],
        [1573430400000, 9145731474.140394],
        [1573516800000, 8436450179.250551],
        [1573603200000, 7224692515.135974],
        [1573689600000, 7174004374.33889],
        [1573776000000, 8007041886.640145],
        [1573862400000, 9104427564.529264],
        [1573948800000, 7196313424.600481],
        [1574035200000, 6738152838.897733],
        [1574121600000, 8026165759.571411],
        [1574208000000, 7790094393.890497],
        [1574294400000, 6713792276.519455],
        [1574380800000, 8718872247.235916],
        [1574467200000, 12883154422.38589],
        [1574553600000, 7399048990.785586],
        [1574640000000, 7849310718.476399],
        [1574726400000, 9382188064.705387],
        [1574812800000, 7520914600.00864],
        [1574899200000, 8634745624.644365],
        [1574985600000, 6528542635.067198],
        [1575072000000, 7145810322.087986],
        [1575158400000, 5436245310.980454],
        [1575244800000, 6544172474.357911],
        [1575331200000, 5011809194.132204],
        [1575417600000, 5245524511.511721],
        [1575504000000, 7074240395.510329],
        [1575590400000, 6421963684.296958],
        [1575676800000, 5806189343.207808],
        [1575763200000, 4749924969.069943],
        [1575849600000, 6027777721.176993],
        [1575936000000, 5789441263.561269],
        [1576022400000, 6047605226.725084],
        [1576108800000, 5883082276.821148],
        [1576195200000, 6766489316.62679],
        [1576281600000, 5946736085.342134],
        [1576368000000, 6322939248.375952],
        [1576454400000, 6837194965.911324],
        [1576540800000, 8956132139.815428],
        [1576627200000, 9871891691.072153],
        [1576713600000, 11532439096.28435],
        [1576800000000, 7865020994.018279],
        [1576886400000, 5979828257.7039175],
        [1576972800000, 4862554350.74467],
        [1577059200000, 7466406124.29696],
        [1577145600000, 8703094374.30469],
        [1577232000000, 7694981359.909612],
        [1577318400000, 6446543347.919613],
        [1577404800000, 7594710628.052688],
        [1577491200000, 7290590722.529487],
        [1577577600000, 6709535782.463888],
        [1577664000000, 6514129764.108342],
        [1577750400000, 8424570388.279623],
        [1577836800000, 7103187150.687103],
        [1577923200000, 6623732040.964472],
        [1578009600000, 6497297884.22486],
        [1578096000000, 9655245099.720835],
        [1578182400000, 6958393690.018681],
        [1578268800000, 7260645128.406949],
        [1578355200000, 9514201882.396584],
        [1578441600000, 9440938787.519835],
        [1578528000000, 10095847759.294762],
        [1578614400000, 7280640218.292043],
        [1578700800000, 10273370788.312328],
        [1578787200000, 9737605323.282524],
        [1578873600000, 8488793477.5950365],
        [1578960000000, 8019794567.486078],
        [1579046400000, 18970204899.197784],
        [1579132800000, 17862385531.73745],
        [1579219200000, 14605493158.53319],
        [1579305600000, 15915322019.78247],
        [1579392000000, 15808136411.02366],
        [1579478400000, 14003433869.000366],
        [1579564800000, 10983216126.222286],
        [1579651200000, 8267420452.9121065],
        [1579737600000, 8809189828.884594],
        [1579824000000, 9017537604.34573],
        [1579910400000, 11043498594.604164],
        [1579996800000, 8673816085.484137],
        [1580083200000, 10518901915.39258],
        [1580169600000, 12435457692.695446],
        [1580256000000, 14300512538.139254],
        [1580342400000, 15565369994.796808],
        [1580428800000, 18170945326.39071],
        [1580515200000, 18506953476.934044],
        [1580601600000, 19083817630.29935],
        [1580688000000, 21543158277.49522],
        [1580774400000, 23519056716.26084],
        [1580860800000, 21734656893.64477],
        [1580947200000, 27708721578.171207],
        [1581033600000, 30730370695.67516],
        [1581120000000, 34073387340.7219],
        [1581206400000, 35852075948.97941],
        [1581292800000, 37065973224.819984],
        [1581379200000, 40180881296.506096],
        [1581465600000, 17260236147.946793],
        [1581552000000, 25083848405.774593],
        [1581638400000, 31919298278.23887],
        [1581724800000, 32486783383.59592],
        [1581811200000, 31021139510.840595],
        [1581897600000, 26307305450.095947],
        [1581984000000, 28621805406.267815],
        [1582070400000, 33038477053.26572],
        [1582156800000, 24634102799.07547],
        [1582243200000, 34355959852.746918],
        [1582329600000, 18672669187.760765],
        [1582416000000, 14860160863.37393],
        [1582502400000, 15734092874.96997],
        [1582588800000, 21019871769.54907],
        [1582675200000, 15216321566.877127],
        [1582761600000, 20470479538.054836],
        [1582848000000, 23994578699.806026],
        [1582934400000, 19715536659.914295],
        [1583020800000, 13967572974.821035],
        [1583107200000, 16557363028.554333],
        [1583193600000, 18301098060.91034],
        [1583280000000, 19133763158.937866],
        [1583366400000, 14844945103.395332],
        [1583452800000, 16056678004.768778],
        [1583539200000, 16938887970.316448],
        [1583625600000, 15033855133.023136],
        [1583712000000, 18839637103.648037],
        [1583798400000, 17900321700.644154],
        [1583884800000, 10622282127.512653],
        [1583971200000, 14699687559.439268],
        [1584057600000, 17357843633.111378],
        [1584144000000, 24412190319.85676],
        [1584230400000, 8678897518.712492],
        [1584316800000, 10971643911.795376],
        [1584403200000, 11161656313.89003],
        [1584489600000, 10514054745.732649],
        [1584576000000, 9829287702.00789],
        [1584662400000, 13910341816.437582],
        [1584748800000, 16267979993.668526],
        [1584835200000, 12633534610.010733],
        [1584921600000, 10177508398.873247],
        [1585008000000, 11596650460.730272],
        [1585094400000, 12119249137.978954],
        [1585180800000, 10616935307.838089],
        [1585267200000, 7749490793.658535],
        [1585353600000, 10183491264.54011],
        [1585440000000, 11067855577.856102],
        [1585526400000, 7718875104.828544],
        [1585612800000, 8469234427.790874],
        [1585699200000, 7869741296.803818],
        [1585785600000, 7848601147.336895],
        [1585872000000, 13645052823.766476],
        [1585958400000, 11621521038.709623],
        [1586044800000, 9863619646.24454],
        [1586131200000, 8271697077.94318],
        [1586217600000, 15337221519.354977],
        [1586304000000, 16711178690.848326],
        [1586390400000, 12116309211.63937],
        [1586476800000, 11971791025.372421],
        [1586563200000, 14135915530.589357],
        [1586649600000, 11128989594.360977],
        [1586736000000, 13002801329.50846],
        [1586822400000, 13286483792.048328],
        [1586908800000, 11468431211.774742],
        [1586995200000, 8578826590.313573],
        [1587081600000, 17430629608.3789],
        [1587168000000, 15220772945.895311],
        [1587254400000, 14284812698.801844],
        [1587340800000, 13763176713.456295],
        [1587427200000, 15901061387.704433],
        [1587513600000, 12178214643.078756],
        [1587600000000, 14853382137.991045],
        [1587686400000, 6903118239.477871],
        [1587772800000, 13419651870.125168],
        [1587859200000, 14452583265.899439],
        [1587945600000, 13530756223.951258],
        [1588032000000, 17659706480.60195],
        [1588118400000, 14644384537.090769],
        [1588204800000, 20966892392.04868],
        [1588291200000, 23630952297.4432],
        [1588377600000, 17137878522.355497],
        [1588464000000, 14287655444.044804],
        [1588550400000, 16158461349.767452],
        [1588636800000, 17901489283.101368],
        [1588723200000, 14345915703.711891],
        [1588809600000, 18128961143.16994],
        [1588896000000, 15142506242.294548],
        [1588982400000, 18337241719.88773],
        [1589068800000, 15514402336.324596],
        [1589155200000, 19756507796.247448],
        [1589241600000, 18768379406.38769],
        [1589328000000, 13706747451.019007],
        [1589414400000, 13403589148.76194],
        [1589500800000, 18153298514.018234],
        [1589587200000, 13130818660.241241],
        [1589673600000, 11676763398.063229],
        [1589760000000, 13449821514.26736],
        [1589846400000, 13342260442.297512],
        [1589932800000, 10220660537.90651],
        [1590019200000, 10429639020.80288],
        [1590105600000, 8110302356.370864],
        [1590192000000, 10636959193.321962],
        [1590278400000, 8524288901.148943],
        [1590364800000, 10782742329.00735],
        [1590451200000, 9317131818.625378],
        [1590537600000, 8593529312.403248],
        [1590624000000, 8773050350.183073],
        [1590710400000, 12329460719.291054],
        [1590796800000, 10335270102.499737],
        [1590883200000, 13546265992.193748],
        [1590969600000, 10887047331.601736],
        [1591056000000, 12532381844.259752],
        [1591142400000, 11228877888.97363],
        [1591228800000, 9023955445.45879],
        [1591315200000, 10063518956.568722],
        [1591401600000, 8222827212.494165],
        [1591488000000, 7581565794.828065],
        [1591574400000, 9398819027.550787],
        [1591660800000, 8129322897.065843],
        [1591747200000, 8592468949.874516],
        [1591833600000, 7584308101.602023],
        [1591920000000, 12109538778.82486],
        [1592006400000, 9191505535.416174],
        [1592092800000, 6588386730.111501],
        [1592179200000, 6517043494.317247],
        [1592265600000, 9539612843.334753],
        [1592352000000, 6857067974.879582],
        [1592438400000, 6079869078.290233],
        [1592524800000, 6063250096.3478],
        [1592611200000, 6840801770.229276],
        [1592697600000, 5400222130.457475],
        [1592784000000, 4995955268.45639],
        [1592870400000, 5973345536.2072935],
        [1592956800000, 6291513184.395153],
        [1593043200000, 8149316604.925404],
        [1593129600000, 6321009243.432307],
        [1593216000000, 6513998360.988546],
        [1593302400000, 6164981016.058191],
        [1593388800000, 5641785424.366106],
        [1593475200000, 5792207632.621344],
        [1593561600000, 5282884955.518924],
        [1593648000000, 5307320170.136238],
        [1593734400000, 4427853855.208807],
        [1593820800000, 4553181088.144414],
        [1593907200000, 4910504255.511211],
        [1593993600000, 5250547694.457692],
        [1594080000000, 7214622724.058187],
        [1594166400000, 6390063707.992301],
        [1594252800000, 6855809513.431496],
        [1594339200000, 5454877568.354481],
        [1594425600000, 4948468762.536561],
        [1594512000000, 4350900773.68197],
        [1594598400000, 5293860444.738397],
        [1594684800000, 5773853723.2969055],
        [1594771200000, 4794731175.010703],
        [1594857600000, 4622070139.084404],
        [1594944000000, 4836661397.807636],
        [1595030400000, 4344879745.807146],
        [1595116800000, 4349365185.3359585],
        [1595203200000, 4452847140.177009],
        [1595289600000, 5152984578.126705],
        [1595376000000, 5121955556.196016],
        [1595462400000, 6556501920.467992],
        [1595548800000, 7634745874.600546],
        [1595635200000, 7547527176.1284075],
        [1595721600000, 10358352414.143063],
        [1595808000000, 10227817625.90999],
        [1595894400000, 15060479190.224041],
        [1595980800000, 12462060871.173037],
        [1596067200000, 10343143710.27412],
        [1596153600000, 10534706857.54779],
        [1596240000000, 11638423647.390888],
        [1596326400000, 15379516251.5158],
        [1596412800000, 15202899455.056835],
        [1596499200000, 11174150665.410692],
        [1596585600000, 12113993540.45853],
        [1596672000000, 10578439440.459143],
        [1596758400000, 11175559421.02824],
        [1596844800000, 12469778424.949451],
        [1596931200000, 7446547697.815684],
        [1597017600000, 5940323179.041791],
        [1597104000000, 12717549263.402819],
        [1597190400000, 13124713653.81531],
        [1597276800000, 12808587803.77308],
        [1597363200000, 17422050835.673008],
        [1597449600000, 15546677422.815374],
        [1597536000000, 10265309385.857273],
        [1597622400000, 12680404379.026093],
        [1597708800000, 11697848303.8365],
        [1597795200000, 12062999738.137259],
        [1597881600000, 12854733824.945929],
        [1597968000000, 10564357237.821798],
        [1598054400000, 12518435194.162006],
        [1598140800000, 10612983427.483643],
        [1598227200000, 9775318835.217514],
        [1598313600000, 11007565809.594412],
        [1598400000000, 12857413913.19107],
        [1598486400000, 10454041361.377256],
        [1598572800000, 10466849103.532475],
        [1598659200000, 10196208352.394411],
        [1598745600000, 9631959320.390598],
        [1598832000000, 12945668314.960146],
        [1598918400000, 14228693735.746506],
        [1599004800000, 18804560687.28111],
        [1599091200000, 19315593282.634296],
        [1599177600000, 17364384113.17322],
        [1599264000000, 10260946593.268202],
        [1599350400000, 22254663654.420143],
        [1599436800000, 21920007828.96099],
        [1599523200000, 14769191933.857542],
        [1599609600000, 14799132842.838894],
        [1599696000000, 13120851004.434223],
        [1599782400000, 74747424441.3974],
        [1599868800000, 12937594458.734825],
        [1599955200000, 11465259432.878637],
        [1600041600000, 13172306578.937866],
        [1600128000000, 14124838468.354868],
        [1600214400000, 12209389923.086823],
        [1600300800000, 13298202038.469078],
        [1600387200000, 16748693289.537083],
        [1600473600000, 9883257470.927006],
        [1600560000000, 11046298234.18736],
        [1600646400000, 11369621273.059267],
        [1600732800000, 14837815989.716738],
        [1600819200000, 11967106210.545612],
        [1600905600000, 9839917613.109154],
        [1600992000000, 12209550797.233208],
        [1601078400000, 10740036294.329079],
        [1601164800000, 10859942552.210592],
        [1601251200000, 9973839924.612421],
        [1601337600000, 9818467156.628036],
        [1601424000000, 9708794838.84551],
        [1601510400000, 8037484324.980594],
        [1601596800000, 11835729247.851833],
        [1601683200000, 12194440257.52427],
        [1601769600000, 8933121301.40709],
        [1601856000000, 8905022839.294907],
        [1601942400000, 9285381014.80383],
        [1602028800000, 10280978397.300406],
        [1602115200000, 8044228155.819704],
        [1602201600000, 9330978624.154116],
        [1602288000000, 11301702589.430536],
        [1602374400000, 10673988207.878847],
        [1602460800000, 8000631027.644073],
        [1602547200000, 12458841248.929943],
        [1602633600000, 9741612768.02954],
        [1602720000000, 10673398919.618017],
        [1602806400000, 10155629419.116392],
        [1602892800000, 9375570544.203642],
        [1602979200000, 8087278469.473757],
        [1603065600000, 7527761590.194419],
        [1603152000000, 8995049004.81075],
        [1603238400000, 7492023938.775781],
        [1603324800000, 12728437763.916903],
        [1603411200000, 12229478488.701916],
        [1603497600000, 11171063368.78023],
        [1603584000000, 9333976948.222593],
        [1603670400000, 8489563012.362298],
        [1603756800000, 14982520071.004465],
        [1603843200000, 10384433736.852089],
        [1603929600000, 11312414332.858248],
        [1604016000000, 10294153382.842941],
        [1604102400000, 10598788554.534933],
        [1604188800000, 9009356073.612816],
        [1604275200000, 8655139278.000864],
        [1604361600000, 8492071098.35507],
        [1604448000000, 9513314551.14123],
        [1604534400000, 11648560618.489246],
        [1604620800000, 13606373470.76514],
        [1604707200000, 16264349016.82086],
        [1604793600000, 15146746571.79792],
        [1604880000000, 11155033976.455217],
        [1604966400000, 12036099592.129189],
        [1605052800000, 7873172432.585956],
        [1605139200000, 11456937853.526192],
        [1605225600000, 11096201853.108873],
        [1605312000000, 10814357418.247974],
        [1605398400000, 9774490045.968235],
        [1605484800000, 8946780761.302334],
        [1605571200000, 9874800384.414816],
        [1605657600000, 12534266981.990389],
        [1605744000000, 15390748756.847664],
        [1605830400000, 10960245344.173328],
        [1605916800000, 15567496836.779345],
        [1606003200000, 17270632325.076218],
        [1606089600000, 20803203330.719395],
        [1606176000000, 22868894446.116894],
        [1606262400000, 19964356486.3813],
        [1606348800000, 15178975818.650188],
        [1606435200000, 25993581649.401203],
        [1606521600000, 14714678172.404446],
        [1606608000000, 9637463879.915295],
        [1606694400000, 13623124682.204208],
        [1606780800000, 18811269166.61186],
        [1606867200000, 23557071520.39523],
        [1606953600000, 14839044995.297548],
        [1607040000000, 12249197831.015257],
        [1607126400000, 14326184164.36095],
        [1607212800000, 11764206188.844515],
        [1607299200000, 11453823478.195438],
        [1607385600000, 10010900862.848228],
        [1607472000000, 13501656447.613894],
        [1607558400000, 14024278560.088686],
        [1607644800000, 11590043392.322668],
        [1607731200000, 11334198380.977062],
        [1607817600000, 9511330147.764025],
        [1607904000000, 9082894202.250328],
        [1607990400000, 7794572085.232029],
        [1608076800000, 10160486192.82214],
        [1608163200000, 14781462845.092134],
        [1608249600000, 19729196033.853626],
        [1608336000000, 16512361338.574877],
        [1608422400000, 14703775003.28234],
        [1608508800000, 12845420666.191206],
        [1608595200000, 14563648896.243877],
        [1608681600000, 11548425748.838194],
        [1608768000000, 16795838161.84157],
        [1608854400000, 15132872519.529186],
        [1608940800000, 13125875205.90394],
        [1609027200000, 14640527408.48169],
        [1609113600000, 24721301579.88044],
        [1609200000000, 22707049400.143177],
        [1609286400000, 17170428247.761232],
        [1609372800000, 13293869466.928808],
        [1609459200000, 18166112097.655663],
        [1609545600000, 13843183079.15519],
        [1609632000000, 20443662605.68775],
        [1609718400000, 140906534190.58786],
        [1609804800000, 62285144708.39812],
        [1609891200000, 47148245967.40434],
        [1609977600000, 47886847354.00142],
        [1610064000000, 43042878463.81648],
        [1610150400000, 48132624602.86217],
        [1610236800000, 34851118090.66404],
        [1610323200000, 44541377224.12463],
        [1610409600000, 67107873698.86543],
        [1610496000000, 37495863245.02223],
        [1610582400000, 32166268470.073753],
        [1610668800000, 34339866026.50668],
        [1610755200000, 39499008134.408424],
        [1610841600000, 38277998837.56892],
        [1610928000000, 35508914212.36928],
        [1611014400000, 30802895108.229195],
        [1611100800000, 52274850962.437416],
        [1611187200000, 52021227614.32531],
        [1611273600000, 49658518028.423325],
        [1611360000000, 48657448380.08583],
        [1611446400000, 30774910632.149376],
        [1611532800000, 39913977039.25946],
        [1611619200000, 44124188232.248085],
        [1611705600000, 40367492548.543144],
        [1611792000000, 38752412145.036766],
        [1611878400000, 32668893126.715786],
        [1611964800000, 49454503567.54182],
        [1612051200000, 29581914651.14533],
        [1612137600000, 24723054987.874157],
        [1612224000000, 28544048877.677177],
        [1612310400000, 42952410181.632454],
        [1612396800000, 42202356538.206825],
        [1612483200000, 42668487953.81155],
        [1612569600000, 39147540640.174576],
        [1612656000000, 36776919163.51647],
        [1612742400000, 38849634841.44165],
        [1612828800000, 45540524354.89187],
        [1612915200000, 42127693213.60732],
        [1613001600000, 40563120176.97226],
        [1613088000000, 33868453070.396435],
        [1613174400000, 36105354379.40371],
        [1613260800000, 33305061601.17683],
        [1613347200000, 30218599726.89195],
        [1613433600000, 37652347195.44906],
        [1613520000000, 33252538989.620327],
        [1613606400000, 33582379213.069286],
        [1613692800000, 34799956643.698685],
        [1613779200000, 34796767875.96328],
        [1613865600000, 44957939435.53464],
        [1613952000000, 33064349740.90669],
        [1614038400000, 53799590366.036766],
        [1614124800000, 62714754623.34122],
        [1614211200000, 40313400449.155266],
        [1614297600000, 31736119376.25847],
        [1614384000000, 36682073651.905846],
        [1614470400000, 28221473460.214203],
        [1614556800000, 35420463945.47327],
        [1614643200000, 31604939381.863796],
        [1614729600000, 29049758215.725],
        [1614816000000, 30352405292.089714],
        [1614902400000, 29848246930.850037],
        [1614988800000, 28537887161.018692],
        [1615075200000, 31094969328.98433],
        [1615161600000, 29416647567.957836],
        [1615248000000, 35353973627.380775],
        [1615334400000, 31147565432.965343],
        [1615420800000, 31746963272.74199],
        [1615507200000, 31257932436.53519],
        [1615593600000, 28436574756.548508],
        [1615680000000, 32785949401.529545],
        [1615766400000, 26379565439.25233],
        [1615852800000, 32950345872.852455],
        [1615939200000, 30686971237.08834],
        [1616025600000, 30744899541.009495],
        [1616112000000, 29651777827.921936],
        [1616198400000, 27345983317.046707],
        [1616284800000, 29495472699.399334],
        [1616371200000, 29228757709.8678],
        [1616457600000, 31376731220.175137],
        [1616544000000, 28758919428.479744],
        [1616630400000, 38413713634.36278],
        [1616716800000, 26325124557.630264],
        [1616803200000, 24804294373.644676],
        [1616889600000, 21118379279.876865],
        [1616976000000, 19859272131.74501],
        [1617062400000, 32618518751.6075],
        [1617148800000, 28496809577.711266],
        [1617235200000, 28955571443.672318],
        [1617321600000, 32076778812.4586],
        [1617408000000, 35467665330.67185],
        [1617494400000, 34872897876.651085],
        [1617580800000, 26817722961.480415],
        [1617667200000, 30867393925.630993],
        [1617753600000, 33391498272.783157],
        [1617840000000, 39604003543.54452],
        [1617926400000, 27145394978.191498],
        [1618012800000, 25076796421.07279],
        [1618099200000, 32243242817.777157],
        [1618185600000, 25369863410.044476],
        [1618272000000, 29096213677.73583],
        [1618358400000, 35760747636.05728],
        [1618444800000, 43351853140.30176],
        [1618531200000, 37106765660.11614],
        [1618617600000, 43982805217.511246],
        [1618704000000, 35627176091.57985],
        [1618790400000, 61890227351.97623],
        [1618876800000, 40335730705.29044],
        [1618963200000, 44737302423.76558],
        [1619049600000, 43726884251.94589],
        [1619136000000, 63343308067.3157],
        [1619222400000, 69126869461.51704],
        [1619308800000, 35640594314.43296],
        [1619395200000, 36744402576.60037],
        [1619481600000, 42791797037.03227],
        [1619568000000, 37611597877.629944],
        [1619654400000, 42878743228.1645],
        [1619740800000, 38536865198.32878],
        [1619827200000, 35477884578.62226],
        [1619913600000, 36721647718.00421],
        [1620000000000, 35727432415.51426],
        [1620086400000, 64192650586.42862],
        [1620172800000, 78437235045.70189],
        [1620259200000, 63552342594.19045],
        [1620345600000, 50430169752.9615],
        [1620432000000, 44980768421.369],
        [1620518400000, 60174921874.24125],
        [1620604800000, 61625657297.19764],
        [1620691200000, 80827215917.6633],
        [1620777600000, 67869198116.36811],
        [1620864000000, 78772032254.80873],
        [1620950400000, 97383442023.26581],
        [1621036800000, 63587729060.25979],
        [1621123200000, 58150860876.149025],
        [1621209600000, 67531308123.83328],
        [1621296000000, 75100584775.8453],
        [1621382400000, 59160464538.280525],
        [1621468800000, 117905041184.06494],
        [1621555200000, 92538167827.51727],
        [1621641600000, 115000901891.803],
        [1621728000000, 102524751568.4531],
        [1621814400000, 77839858613.53922],
        [1621900800000, 73496058830.83229],
        [1621987200000, 92468701273.98392],
        [1622073600000, 53115222704.62064],
        [1622160000000, 44070236220.034096],
        [1622246400000, 49493519120.23643],
        [1622332800000, 47141249182.27268],
        [1622419200000, 37392021393.940125],
        [1622505600000, 44445830387.932175],
        [1622592000000, 39898648753.4186],
        [1622678400000, 39148856525.29615],
        [1622764800000, 38104663185.40115],
        [1622851200000, 39996583175.266685],
        [1622937600000, 34731979704.601685],
        [1623024000000, 29050162248.93885],
        [1623110400000, 37385872210.25046],
        [1623196800000, 48344195673.521126],
        [1623283200000, 43973432438.082634],
        [1623369600000, 33075957436.760677],
        [1623456000000, 26751786966.47868],
        [1623542400000, 28235882061.70689],
        [1623628800000, 29382118964.283043],
        [1623715200000, 28128172338.347244],
        [1623801600000, 26803957680.025055],
        [1623888000000, 26150320394.527775],
        [1623974400000, 25708624827.013702],
        [1624060800000, 26204484920.993828],
        [1624147200000, 20456963374.959637],
        [1624233600000, 26235850446.54907],
        [1624320000000, 39499960407.7835],
        [1624406400000, 38755019936.85197],
        [1624492800000, 28098744044.509525],
        [1624579200000, 22447489241.67468],
        [1624665600000, 25981381067.41728],
        [1624752000000, 24249510320.705112],
        [1624838400000, 24316408028.131123],
        [1624924800000, 30629329805.72042],
        [1625011200000, 27149143846.958942],
        [1625097600000, 29046221905.503418],
        [1625184000000, 25874247678.48856],
        [1625270400000, 22869223901.008],
        [1625356800000, 19954299562.699074],
        [1625443200000, 21119209512.99698],
        [1625529600000, 22592756940.415127],
        [1625616000000, 24748897613.709663],
        [1625702400000, 23352988376.91004],
        [1625788800000, 24133566100.864506],
        [1625875200000, 22262234450.01033],
        [1625961600000, 18091111748.440804],
        [1626048000000, 14249909363.131065],
        [1626134400000, 16403901753.797194],
        [1626220800000, 18210922217.703205],
        [1626307200000, 20007437042.5854],
        [1626393600000, 17978198759.76909],
        [1626480000000, 17448403933.724445],
        [1626566400000, 14920352990.216908],
        [1626652800000, 15355055465.803198],
        [1626739200000, 15768554506.425838],
        [1626825600000, 20634025154.91444],
        [1626912000000, 25849497421.028152],
        [1626998400000, 21655718476.41885],
        [1627084800000, 20760571836.05241],
        [1627171200000, 19333415460.111233],
        [1627257600000, 16557827418.503304],
        [1627344000000, 35197538271.146904],
        [1627430400000, 28862032737.553215],
        [1627516800000, 23171592869.594303],
        [1627603200000, 18266719970.961597],
        [1627689600000, 24040211451.365524],
        [1627776000000, 21579519281.279892],
        [1627862400000, 25689002090.279446],
        [1627948800000, 26664958251.58228],
        [1628035200000, 26304380467.43418],
        [1628121600000, 29356647318.25012],
        [1628208000000, 37970347683.56547],
        [1628294400000, 30876444992.275696],
        [1628380800000, 38870720439.186905],
        [1628467200000, 34209269717.32262],
        [1628553600000, 38510599666.551865],
        [1628640000000, 30456934757.02111],
        [1628726400000, 28540625893.874588],
        [1628812800000, 28111908833.730755],
        [1628899200000, 25989682658.6423],
        [1628985600000, 21723717067.76398],
        [1629072000000, 24412292890.901497],
        [1629158400000, 25922917846.046463],
        [1629244800000, 28303010380.704197],
        [1629331200000, 23192419118.647858],
        [1629417600000, 20590444717.44903],
        [1629504000000, 22079051284.799744],
        [1629590400000, 19339185909.36738],
        [1629676800000, 17023426890.400778],
        [1629763200000, 22119621924.56181],
        [1629849600000, 23531754575.679913],
        [1629936000000, 20438475652.044525],
        [1630022400000, 18755498273.80065],
        [1630108800000, 18470749435.075993],
        [1630195200000, 13957284069.585827],
        [1630281600000, 14110206249.483368],
        [1630368000000, 21741061738.593838],
        [1630454400000, 32201644522.644287],
        [1630540800000, 34465100677.998825],
        [1630627200000, 28115776510.367878],
        [1630713600000, 30463347266.426533],
        [1630800000000, 24002848309.4245],
        [1630886400000, 19760598542.300426],
        [1630972800000, 20190616165.855145],
        [1631059200000, 42541561062.44531],
        [1631145600000, 34175479497.51584],
        [1631232000000, 25796050344.025276],
        [1631318400000, 24111446202.204918],
        [1631404800000, 19742335705.039883],
        [1631491200000, 18149841767.144417],
        [1631577600000, 25897933725.98018],
        [1631664000000, 21080767347.82575],
        [1631750400000, 18985547836.191357],
        [1631836800000, 22391476966.877094],
        [1631923200000, 19039612529.444134],
        [1632009600000, 16278153590.132133],
        [1632096000000, 14500103698.494116],
        [1632182400000, 28477674119.56528],
        [1632268800000, 32062935710.168865],
        [1632355200000, 27084409011.05875],
        [1632441600000, 19536554032.084408],
        [1632528000000, 28504692815.75815],
        [1632614400000, 22533679023.57236],
        [1632700800000, 24916718280.69273],
        [1632787200000, 20890327693.72457],
        [1632873600000, 18975426962.499523],
        [1632960000000, 17522543131.581055],
        [1633046400000, 19400423828.622337],
        [1633132800000, 24368105704.246353],
        [1633219200000, 21335051735.331825],
        [1633305600000, 18130363405.685154],
        [1633392000000, 19898468513.475533],
        [1633478400000, 19024921944.49268],
        [1633564800000, 24709364134.235626],
        [1633651200000, 21186801824.571068],
        [1633737600000, 18144469093.779636],
        [1633824000000, 13815631715.44782],
        [1633910400000, 18106607784.26291],
        [1633996800000, 20424407322.58066],
        [1634083200000, 20207646949.24054],
        [1634169600000, 18300885349.637314],
        [1634256000000, 21679175672.91577],
        [1634342400000, 23144188055.382],
        [1634428800000, 18154520779.65643],
        [1634515200000, 17263171427.44574],
        [1634601600000, 18931081929.44796],
        [1634688000000, 17449609317.97657],
        [1634774400000, 21926381355.419468],
        [1634860800000, 50122173541.0596],
        [1634947200000, 21674013294.746418],
        [1635033600000, 16499701301.345774],
        [1635120000000, 16494229912.456104],
        [1635206400000, 17031857290.344103],
        [1635292800000, 18925943956.175625],
        [1635379200000, 28770879596.15931],
        [1635465600000, 29989860066.02069],
        [1635552000000, 25462638679.97447],
        [1635638400000, 15937401968.575453],
        [1635724800000, 18516249996.186913],
        [1635811200000, 19235929520.306248],
        [1635897600000, 22135519614.732147],
        [1635984000000, 22744538221.14315],
        [1636070400000, 19583405367.2671],
        [1636156800000, 16182205113.440348],
        [1636243200000, 17697965751.156593],
        [1636329600000, 14665949273.051767],
        [1636416000000, 21226347241.356174],
        [1636502400000, 20669583046.287785],
        [1636588800000, 25152889711.576366],
        [1636675200000, 19048319256.420074],
        [1636761600000, 20883749031.792934],
        [1636848000000, 13954188613.807226],
        [1636934400000, 13927559432.546179],
        [1637020800000, 17939105212.670113],
        [1637107200000, 30162258590.6919],
        [1637193600000, 23532734478.929985],
        [1637280000000, 23825764061.571724],
        [1637366400000, 21398451638.777546],
        [1637452800000, 16407598047.996004],
        [1637539200000, 15090104727.278082],
        [1637625600000, 21563803786.49851],
        [1637712000000, 23393688031.318764],
        [1637798400000, 19881953842.36485],
        [1637884800000, 20241529505.687588],
        [1637971200000, 29276733766.839596],
        [1638057600000, 16500509901.66011],
        [1638144000000, 17408610005.868984],
        [1638230400000, 20150915710.995937],
        [1638316800000, 31531135168.773865],
        [1638403200000, 27957934803.559887],
        [1638489600000, 22586539837.216267],
        [1638576000000, 27620915094.003757],
        [1638662400000, 40547950440.47308],
        [1638748800000, 25848780907.958004],
        [1638835200000, 30676805365.753136],
        [1638921600000, 21808603057.7599],
        [1639008000000, 19661953642.45195],
        [1639094400000, 22680202938.506203],
        [1639180800000, 26799083668.888447],
        [1639267200000, 19796149221.596004],
        [1639353600000, 14046344322.674688],
        [1639440000000, 24089144863.40074],
        [1639526400000, 20766055169.54314],
        [1639612800000, 26881290260.577652],
        [1639699200000, 19166579837.021267],
        [1639785600000, 23209497898.091805],
        [1639872000000, 18728700443.97014],
        [1639958400000, 15960000522.484169],
        [1640044800000, 21439121593.0351],
        [1640131200000, 16630181836.72027],
        [1640217600000, 14022344430.178228],
        [1640304000000, 18571462177.267685],
        [1640390400000, 13351470832.627863],
        [1640476800000, 11869342790.219467],
        [1640563200000, 11815773894.02084],
        [1640649600000, 11725230819.88359],
        [1640736000000, 18009599978.903225],
        [1640822400000, 15528813680.462475],
        [1640908800000, 13445245167.227766],
        [1640995200000, 14298378834.359474],
        [1641081600000, 9948120591.378103],
        [1641168000000, 10069690529.898535],
        [1641254400000, 12462872357.255108],
        [1641340800000, 13853786397.222265],
        [1641427200000, 18782873941.21831],
        [1641513600000, 24467182063.00624],
        [1641600000000, 24359369448.53183],
        [1641686400000, 15966769090.792395],
        [1641772800000, 11906478540.265388],
        [1641859200000, 19655314714.55994],
        [1641945600000, 14922195360.075489],
        [1642032000000, 15285972614.67417],
        [1642118400000, 13999771252.160748],
        [1642204800000, 12316286854.849726],
        [1642291200000, 8659658467.059889],
        [1642377600000, 9002833937.226437],
        [1642464000000, 11938682376.103683],
        [1642550400000, 12700158860.46528],
        [1642636800000, 12762451059.939085],
        [1642723200000, 13389582889.566511],
        [1642809600000, 29314647992.596836],
        [1642896000000, 28250265353.796116],
        [1642982400000, 17123741957.54585],
        [1643068800000, 28735520619.97796],
        [1643155200000, 16139625164.237488],
        [1643241600000, 21415081805.12725],
        [1643328000000, 16482912982.01206],
        [1643414400000, 14326314172.126577],
        [1643500800000, 10742621684.922014],
        [1643587200000, 8937393969.826124],
        [1643673600000, 13818587053.759966],
        [1643760000000, 13176431108.286674],
        [1643846400000, 13860391591.281128],
        [1643932800000, 21844552552.403343],
        [1644019200000, 18766789788.28106],
        [1644105600000, 13036212065.007109],
        [1644192000000, 9025772577.382162],
        [1644278400000, 14427983727.88122],
        [1644364800000, 16856365272.811357],
        [1644451200000, 14005361496.44425],
        [1644537600000, 18990367974.117947],
        [1644624000000, 16560032290.708168],
        [1644710400000, 11367427285.455873],
        [1644796800000, 9020562479.291342],
        [1644883200000, 12117644856.003006],
        [1644969600000, 14189484930.460047],
        [1645056000000, 13120593628.157278],
        [1645142400000, 16242836366.095669],
        [1645228800000, 16064045339.56507],
        [1645315200000, 9956229564.570753],
        [1645401600000, 12215462928.563446],
        [1645488000000, 19206327576.145523],
        [1645574400000, 16856627508.447924],
        [1645660800000, 13713218952.651205],
        [1645747200000, 30992409365.835472],
        [1645833600000, 17751279835.572117],
        [1645920000000, 11984293384.913609],
        [1646006400000, 17309819254.70233],
        [1646092800000, 19832961026.345818],
        [1646179200000, 19012291817.365406],
        [1646265600000, 17023997910.606855],
        [1646352000000, 13786702924.254955],
        [1646438400000, 15538211354.189482],
        [1646524800000, 8652610729.437431],
        [1646611200000, 9377488374.702219],
        [1646697600000, 15295190068.57191],
        [1646784000000, 14681251925.804962],
        [1646870400000, 15208159162.669767],
        [1646956800000, 13843643777.451271],
        [1647043200000, 12313723771.430836],
        [1647129600000, 6288630630.865574],
        [1647216000000, 8570037700.961715],
        [1647302400000, 10951215810.637651],
        [1647388800000, 12439580087.508198],
        [1647475200000, 19785262485.166782],
        [1647561600000, 12593189074.637096],
        [1647648000000, 14540256794.800894],
        [1647734400000, 10359721811.614828],
        [1647820800000, 11304953073.460001],
        [1647907200000, 13890729058.80007],
        [1647993600000, 15671928047.784338],
        [1648080000000, 14257143986.39263],
        [1648166400000, 16202188701.767122],
        [1648252800000, 15532463157.18736],
        [1648339200000, 7615867102.157496],
        [1648425600000, 11980084883.788645],
        [1648512000000, 18514225350.60662],
        [1648598400000, 17936369661.586555],
        [1648684800000, 14183468637.6598],
        [1648771200000, 16222256121.186861],
        [1648857600000, 19701116865.137077],
        [1648944000000, 22091140764.533768],
        [1649030400000, 14019828281.404171],
        [1649116800000, 16925919013.954468],
        [1649203200000, 15114055501.97898],
        [1649289600000, 26109763334.73009],
        [1649376000000, 16149775914.530878],
        [1649462400000, 17523939555.353264],
        [1649548800000, 9316769843.73387],
        [1649635200000, 10005373137.102016],
        [1649721600000, 21142997359.75918],
        [1649808000000, 18977679663.55036],
        [1649894400000, 15452917855.24062],
        [1649980800000, 15895586504.785799],
        [1650067200000, 12543243551.278517],
        [1650153600000, 9063939048.316748],
        [1650240000000, 9543306566.22193],
        [1650326400000, 18188817400.079117],
        [1650412800000, 13472639207.148006],
        [1650499200000, 15155965878.384497],
        [1650585600000, 20529419340.296413],
        [1650672000000, 16155619718.968117],
        [1650758400000, 8633203957.82989],
        [1650844800000, 9196851418.265764],
        [1650931200000, 22037549638.799118],
        [1651017600000, 17750798288.906208],
        [1651104000000, 17036298768.907473],
        [1651190400000, 18249410920.251778],
        [1651276800000, 17902735173.807156],
        [1651363200000, 12618462103.401344],
        [1651449600000, 14489173444.820097],
        [1651536000000, 17701813870.710365],
        [1651622400000, 12836324477.777216],
        [1651708800000, 17336934449.712643],
        [1651795200000, 20868783132.923206],
        [1651881600000, 20516541977.493565],
        [1651968000000, 12528069434.498928],
        [1652054400000, 20582973186.12796],
        [1652140800000, 34392685561.846504],
        [1652227200000, 36844564808.016556],
        [1652313600000, 46548009611.49195],
        [1652400000000, 41621733327.158905],
        [1652486400000, 23979529161.232746],
        [1652572800000, 14395772634.811949],
        [1652659200000, 14395455889.043333],
        [1652745600000, 20068743681.95205],
        [1652832000000, 17005002529.405003],
        [1652918400000, 15892119501.206236],
        [1653004800000, 17828306698.714794],
        [1653091200000, 14638100162.773735],
        [1653177600000, 7551508571.492984],
        [1653264000000, 9623174527.738039],
        [1653350400000, 14627311157.92938],
        [1653436800000, 12880481567.942186],
        [1653523200000, 12554611854.439222],
        [1653609600000, 22669347246.961388],
        [1653696000000, 24344535948.91357],
        [1653782400000, 12004308559.066864],
        [1653868800000, 9767849361.351788],
        [1653955200000, 18803016950.09315],
        [1654041600000, 17409076011.07384],
        [1654128000000, 19904831726.00975],
        [1654214400000, 16444980823.786707],
        [1654300800000, 15012416794.069233],
        [1654387200000, 8295912402.90654],
        [1654473600000, 8385254999.857006],
        [1654560000000, 16623006597.793545],
        [1654646400000, 23647547692.445885],
        [1654732800000, 17712874976.607395],
        [1654819200000, 10469080004.414614],
        [1654905600000, 17639990086.322327],
        [1654992000000, 20409428647.9328],
        [1655078400000, 22843765038.754616],
        [1655164800000, 47571733027.00858],
        [1655251200000, 32772246927.059067],
        [1655337600000, 39679810344.68712],
        [1655424000000, 18016510161.232018],
        [1655510400000, 14067259810.778109],
        [1655596800000, 21112670623.39463],
        [1655683200000, 21676867429.837547],
        [1655769600000, 19817753726.316406],
        [1655856000000, 14838197887.543182],
        [1655942400000, 14331217471.338781],
        [1656028800000, 14247953151.150192],
        [1656115200000, 16288488163.424995],
        [1656201600000, 11692717741.724768],
        [1656288000000, 11375746747.404722],
        [1656374400000, 12044359298.246725],
        [1656460800000, 13181896477.373173],
        [1656547200000, 14755440934.326208],
        [1656633600000, 15402670572.44401],
        [1656720000000, 17420131787.73736],
        [1656806400000, 9601263305.049065],
        [1656892800000, 7939419238.560307],
        [1656979200000, 13827342516.34925],
        [1657065600000, 15393490017.737787],
        [1657152000000, 14422132961.962864],
        [1657238400000, 13170522513.182203],
        [1657324800000, 15346600702.3086],
        [1657411200000, 7870254817.813988],
        [1657497600000, 10374466605.406368],
        [1657584000000, 11693162178.034164],
        [1657670400000, 11555798332.527388],
        [1657756800000, 17936886119.90067],
        [1657843200000, 16474223860.658737],
        [1657929600000, 16817194904.2499],
        [1658016000000, 19025831554.91271],
        [1658102400000, 15797648344.457474],
        [1658188800000, 26437290054.27533],
        [1658275200000, 27749085857.707005],
        [1658361600000, 21728126383.56131],
        [1658448000000, 19411405082.967407],
        [1658534400000, 18084368731.242767],
        [1658620800000, 14901008279.154797],
        [1658707200000, 16058331978.02615],
        [1658793600000, 19852493229.276333],
        [1658880000000, 17711985937.250435],
        [1658966400000, 22792557484.269226],
        [1659052800000, 27508245872.984703],
        [1659139200000, 21792598232.364918],
        [1659225600000, 14236780342.012003],
        [1659312000000, 12894604186.287697],
        [1659398400000, 15515408282.681604],
        [1659484800000, 19414686138.72405],
        [1659571200000, 15548536682.141968],
        [1659657600000, 14074560038.427649],
        [1659744000000, 17717475728.243652],
        [1659830400000, 11231103244.36643],
        [1659916800000, 9464715954.716835],
        [1660003200000, 15566998953.351175],
        [1660089600000, 24284375402.607098],
        [1660176000000, 21699442049.07331],
        [1660262400000, 21837068069.82725],
        [1660348800000, 15849264839.752771],
        [1660435200000, 14816745160.91679],
        [1660521600000, 12172210305.158232],
        [1660608000000, 18310871317.455875],
        [1660694400000, 14240230761.887741],
        [1660780800000, 18427553152.35174],
        [1660867200000, 13363780756.798216],
        [1660953600000, 22687362580.095345],
        [1661040000000, 16404512934.336613],
        [1661126400000, 14174702673.113022],
        [1661212800000, 16648637311.974203],
        [1661299200000, 17182337491.18613],
        [1661385600000, 15322907185.334568],
        [1661472000000, 12905826689.355816],
        [1661558400000, 23170035981.438354],
        [1661644800000, 15440053448.984152],
        [1661731200000, 10575504871.684057],
        [1661817600000, 16528644851.715965],
        [1661904000000, 19704720195.3797],
        [1661990400000, 18596055350.613735],
        [1662076800000, 15076286516.891344],
        [1662163200000, 15857654357.09863],
        [1662249600000, 8500114333.500416],
        [1662336000000, 7827505125.483585],
        [1662422400000, 11815125728.969868],
        [1662508800000, 20860201644.41259],
        [1662595200000, 18560005397.32841],
        [1662681600000, 16676188643.15121],
        [1662768000000, 19418675070.680107],
        [1662854400000, 12521808520.701172],
        [1662940800000, 11692754175.395372],
        [1663027200000, 16770566236.30973],
        [1663113600000, 22286700927.338142],
        [1663200000000, 17198033718.180206],
        [1663286400000, 24787864366.63655],
        [1663372800000, 17892819034.618076],
        [1663459200000, 10309763882.142286],
        [1663545600000, 14696599277.019012],
        [1663632000000, 17757533838.1638],
        [1663718400000, 13605986462.228132],
        [1663804800000, 18732743241.62821],
        [1663891200000, 17517188721.084026],
        [1663977600000, 17599766731.24086],
        [1664064000000, 11251326609.48999],
        [1664150400000, 10552647869.67358],
        [1664236800000, 15270197375.846754],
        [1664323200000, 16685874856.004007],
        [1664409600000, 16571161476.253872],
        [1664496000000, 11834328252.392088],
        [1664582400000, 11849125783.759304],
        [1664668800000, 5052407840.714767],
        [1664755200000, 6227268235.758528],
        [1664841600000, 8749663554.66095],
        [1664928000000, 8587836597.469869],
        [1665014400000, 8502549881.905833],
        [1665100800000, 10653358724.953413],
        [1665187200000, 9123112355.727316],
        [1665273600000, 4942603337.902456],
        [1665360000000, 4753165267.937541],
        [1665446400000, 7492362032.739503],
        [1665532800000, 8314705467.191551],
        [1665619200000, 7512521119.536691],
        [1665705600000, 15374968702.824959],
        [1665792000000, 11792024290.983032],
        [1665878400000, 5743906844.859241],
        [1665964800000, 6318629607.96271],
        [1666051200000, 8639535764.06259],
        [1666137600000, 9536615488.397163],
        [1666224000000, 8128527355.67816],
        [1666310400000, 8816030159.087742],
        [1666396800000, 8731620089.4909],
        [1666483200000, 4996733236.216461],
        [1666569600000, 7794024007.581724],
        [1666656000000, 10789079575.113602],
        [1666742400000, 19624530679.956947],
        [1666828800000, 24505395072.046234],
        [1666915200000, 17752430680.403706],
        [1667001600000, 15641652314.210123],
        [1667088000000, 17044927217.751923],
        [1667174400000, 10366405350.250761],
        [1667260800000, 14900137160.396046],
        [1667347200000, 19199900507.15055],
        [1667433600000, 27498708818.133373],
        [1667520000000, 22537458293.692245],
        [1667606400000, 36953923774.760704],
        [1667692800000, 21174006976.119827],
        [1667779200000, 22195586345.876583],
        [1667865600000, 30344896841.852493],
        [1667952000000, 32343101742.62143],
        [1668038400000, 29253284762.659542],
        [1668124800000, 24039423467.45047],
        [1668211200000, 17355612789.319725],
        [1668297600000, 8636739779.554464],
        [1668384000000, 7719000328.307081],
        [1668470400000, 12056844339.739658],
        [1668556800000, 8940477028.374247],
        [1668643200000, 8870530650.446701],
        [1668729600000, 7075882101.619532],
        [1668816000000, 5600678512.529781],
        [1668902400000, 3722348151.0022244],
        [1668988800000, 6826180055.62674],
        [1669075200000, 10372965080.612862],
        [1669161600000, 9220536958.839794],
        [1669248000000, 9189536303.575544],
        [1669334400000, 6955523718.221009],
        [1669420800000, 5823202533.487156],
        [1669507200000, 5271100859.564753],
        [1669593600000, 4150923882.274703],
        [1669680000000, 6467273323.741678],
        [1669766400000, 7132972094.453388],
        [1669852800000, 9889408117.518559],
        [1669939200000, 6400063074.120894],
        [1670025600000, 5644934957.241694],
        [1670112000000, 5183647936.284697],
        [1670198400000, 4842789176.374092],
        [1670284800000, 5795682744.772271],
        [1670371200000, 4765089563.175744],
        [1670457600000, 5392077793.333963],
        [1670544000000, 5929103713.4824705],
        [1670630400000, 5743935111.263781],
        [1670716800000, 3136321665.2746277],
        [1670803200000, 3179021750.4472365],
        [1670889600000, 5107100671.713232],
        [1670976000000, 8667920250.973438],
        [1671062400000, 8063349707.439905],
        [1671148800000, 6014960880.483797],
        [1671235200000, 9306680537.671982],
        [1671321600000, 4893469369.238545],
        [1671408000000, 3175705524.420867],
        [1671494400000, 4281620919.9824796],
        [1671580800000, 6463130153.512681],
        [1671667200000, 4086534541.450752],
        [1671753600000, 5190165001.535945],
        [1671840000000, 4777025971.87922],
        [1671926400000, 2443348024.2152143],
        [1672012800000, 3694243212.8174734],
        [1672099200000, 3071221733.631151],
        [1672185600000, 4221450706.7612934],
        [1672272000000, 5177421363.012343],
        [1672358400000, 4174715683.6280565],
        [1672444800000, 4110733112.8102927],
        [1672531200000, 2891351007.4251895],
        [1672617600000, 2179799161.0385365],
        [1672704000000, 3845095582.093461],
        [1672790400000, 3385566691.456905],
        [1672876800000, 6727208484.546551],
        [1672963200000, 4052351544.8201857],
        [1673049600000, 5222706873.557585],
        [1673136000000, 2439388823.0572276],
        [1673222400000, 3401917835.2694077],
        [1673308800000, 8615288094.160769],
        [1673395200000, 6390063040.044838],
        [1673481600000, 6779329581.863201],
        [1673568000000, 13360962683.072271],
        [1673654400000, 8386970426.225097],
        [1673740800000, 15505376250.712307],
        [1673827200000, 7074931051.0836935],
        [1673913600000, 9434570734.551754],
        [1674000000000, 8482482040.670269],
        [1674086400000, 11809477723.65587],
        [1674172800000, 6871702648.394773],
        [1674259200000, 9228892411.460192],
        [1674345600000, 9663018503.707115],
        [1674432000000, 8233555835.053316],
        [1674518400000, 9037743837.862547],
        [1674604800000, 9078639809.982983],
        [1674691200000, 11946053798.490387],
        [1674777600000, 10076436860.255999],
        [1674864000000, 9593062923.130283],
        [1674950400000, 6523949724.808857],
        [1675036800000, 10037370816.308008],
        [1675123200000, 10977781767.502348],
        [1675209600000, 7544090211.946499],
        [1675296000000, 9295116915.9373],
        [1675382400000, 12542560274.342657],
        [1675468800000, 9528083087.520357],
        [1675555200000, 6766208820.07522],
        [1675641600000, 8211226952.330717],
        [1675728000000, 7825100535.008893],
        [1675814400000, 9180247305.88792],
        [1675900800000, 8766845417.320044],
        [1675987200000, 12447757846.416866],
        [1676073600000, 9882779899.96751],
        [1676160000000, 6070233325.97355],
        [1676246400000, 6128794710.192691],
        [1676332800000, 11573812955.970678],
        [1676419200000, 11723184974.304256],
        [1676505600000, 11079231384.245422],
        [1676592000000, 13388835215.08776],
        [1676678400000, 10807323083.841963],
        [1676764800000, 5863446679.020431],
        [1676851200000, 7499517187.119554],
        [1676937600000, 8435810846.264387],
        [1677024000000, 9728814194.484383],
        [1677110400000, 9836391621.850903],
        [1677196800000, 9698749201.902168],
        [1677283200000, 9609840835.076471],
        [1677369600000, 6117521125.045025],
        [1677456000000, 6357994998.206232],
        [1677542400000, 7916573299.24559],
        [1677628800000, 6883711561.9226],
        [1677715200000, 8672222866.055677],
        [1677801600000, 7930395056.206914],
        [1677888000000, 11179316252.20772],
        [1677974400000, 4800994312.370927],
        [1678060800000, 62172854406.22754],
        [1678147200000, 6627693313.532333],
        [1678233600000, 8523808476.527845],
        [1678320000000, 11881879818.725237],
        [1678406400000, 10861201441.135424],
        [1678492800000, 14696381616.840977],
        [1678579200000, 18345198261.395977],
        [1678665600000, 13967891188.499998],
        [1678752000000, 65211714924.508385],
        [1678838400000, 19833762797.583607],
        [1678924800000, 17257648020.36215],
        [1679011200000, 30201930394.76128],
        [1679097600000, 14459727611.041805],
        [1679184000000, 11976182095.296051],
        [1679270400000, 10745813975.10349],
        [1679356800000, 12973595973.359987],
        [1679443200000, 10831022955.199936],
        [1679529600000, 13820716099.501862],
        [1679616000000, 10980967034.937744],
        [1679702400000, 11601017881.786583],
        [1679788800000, 8023849174.389528],
        [1679875200000, 8417258785.455161],
        [1679961600000, 9757415238.165146],
        [1679966032000, 9834170722.84476],
      ],
    };
    for (let item of hisotricalEthPrice.prices) {
      let date = new Date(item[0]);
      item[0] = date.toISOString().split("T")[0];
    }
    const toAddress = address;
    let res;

    try {
      res = alchemy.core.getAssetTransfers({
        fromBlock: "0x0",
        toAddress: toAddress,
        withMetadata: true,
        excludeZeroValue: true,
        category: ["internal"],
      });
    } catch (error) {
      console.log(error);
    }
    const firstPage = await res;
    console.log(firstPage);
    let counts = 0;
    let a = 0;
    let x2y2 = 0;
    let x2y2Count = 0;
    let spent = 0;
    let blur = 0;
    let actualOpenSeaSpent = 0;
    let actualX2Y2Spent = 0;
    for (const events of firstPage.transfers) {
      if (
        events.from === "0x00000000006c3852cbef3e08e8df289169ede581" ||
        events.from === "0x7be8076f4ea4a4ad08075c2508e481d6c946d12b"
      ) {
        let indexOfPrice = hisotricalEthPrice.prices.findIndex(
          (element) =>
            element[0] === events.metadata.blockTimestamp.substring(0, 10)
        );
        actualOpenSeaSpent =
          actualOpenSeaSpent +
          (hisotricalEthPrice.prices[indexOfPrice][1] * events.value) / 35;
        spent = spent + events.value;
        a = a + 1;
        counts = counts + events.value / 35;
      }
      if (events.from === "0x74312363e45dcaba76c59ec49a7aa8a65a67eed3") {
        let indexOfPrice = hisotricalEthPrice.prices.findIndex(
          (element) =>
            element[0] === events.metadata.blockTimestamp.substring(0, 10)
        );
        actualX2Y2Spent =
          actualX2Y2Spent +
          (hisotricalEthPrice.prices[indexOfPrice][1] * events.value) / 199;
        spent = spent + events.value;
        x2y2 = x2y2 + events.value / 199;
        x2y2Count = x2y2Count + 1;
      }
      if (events.from === "0x000000000000ad05ccc4f10045630fb830b95127") {
        blur = blur + 1;
        spent = spent + events.value;
      }
    }
    let pageKey = firstPage.pageKey;

    if (pageKey) {
      let counter = 0;
      while (pageKey) {
        const nextKey = alchemy.core.getAssetTransfers({
          fromBlock: "0x0",
          toAddress: toAddress,
          excludeZeroValue: true,
          withMetadata: true,
          category: ["internal"],
          pageKey: pageKey.toString(),
        });
        const nextPage = await nextKey;
        console.log(nextPage);
        for (const events of nextPage.transfers) {
          if (
            events.from === "0x00000000006c3852cbef3e08e8df289169ede581" ||
            events.from === "0x7be8076f4ea4a4ad08075c2508e481d6c946d12b"
          ) {
            let indexOfPrice = hisotricalEthPrice.prices.findIndex(
              (element) =>
                element[0] === events.metadata.blockTimestamp.substring(0, 10)
            );
            actualOpenSeaSpent =
              actualOpenSeaSpent +
              (hisotricalEthPrice.prices[indexOfPrice][1] * events.value) / 35;
            spent = spent + events.value;
            a = a + 1;
            counts = counts + events.value / 35;
          }
          if (events.from === "0x74312363e45dcaba76c59ec49a7aa8a65a67eed3") {
            let indexOfPrice = hisotricalEthPrice.prices.findIndex(
              (element) =>
                element[0] === events.metadata.blockTimestamp.substring(0, 10)
            );
            actualX2Y2Spent =
              actualX2Y2Spent +
              (hisotricalEthPrice.prices[indexOfPrice][1] * events.value) / 199;
            spent = spent + events.value;
            x2y2 = x2y2 + events.value / 199;
            x2y2Count = x2y2Count + 1;
          }
          if (events.from === "0x000000000000ad05ccc4f10045630fb830b95127") {
            blur = blur + 1;
            spent = spent + events.value;
          }
        }
        pageKey = nextPage.pageKey;
        if (pageKey) {
          counter += 1;

          continue;
        } else {
          let round = counts.toFixed(2);
          if (round === "0.00") {
            setNoData(true);
          }
          if (a > 0) setNumber(a);
          if (round > 0) setNFT(round);
          if (x2y2Count > 0) setX2Y2Count(x2y2Count);
          if (x2y2 > 0) setX2Y2(x2y2);
          if (spent > 0) setTotalSpent(spent);
          if (blur > 0) setBlur(blur);
          if (actualOpenSeaSpent > 0) setOpenSeaTotalSpent(actualOpenSeaSpent);
          if (actualX2Y2Spent > 0) setX2Y2TotalSpent(actualX2Y2Spent);

          setTotal();
          setLoading(false);
          break;
        }
      }
    } else if (pageKey === undefined) {
      let round = counts.toFixed(2);

      if (round === "0.00") {
        setNoData(true);
      }

      if (a > 0) setNumber(a);
      if (round > 0) setNFT(round);
      if (x2y2Count > 0) setX2Y2Count(x2y2Count);
      if (x2y2 > 0) setX2Y2(x2y2);
      if (spent > 0) setTotalSpent(spent);
      if (blur > 0) setBlur(blur);
      if (actualOpenSeaSpent > 0) setOpenSeaTotalSpent(actualOpenSeaSpent);
      if (actualX2Y2Spent > 0) setX2Y2TotalSpent(actualX2Y2Spent);

      setTotal();

      setLoading(false);

      const firstPageLength = firstPage.transfers.length;
    }
  } catch (err) {
    setLoading(false);
    setStart(true);
    setError("The address is wrong. Try Again!");
    console.log("Something went wrong. Try Again!", +err);
  }
};

export const fetchLooksRare = async (
  address,
  setLook,
  setLookCount,
  setTotalSpentTwo,
  setLoading,
  setStart,
  setError,
  setLooksRareTotalSpent
) => {
  try {
    let hisotricalEthPrice = {
      prices: [
        [1438905600000, 2.83162],
        [1438992000000, 1.33075],
        [1439164800000, 0.6875857090838133],
        [1439251200000, 1.067378879429946],
        [1439337600000, 1.256613175101658],
        [1439424000000, 1.825395446319752],
        [1439510400000, 1.825974891355639],
        [1439596800000, 1.670949668937766],
        [1439683200000, 1.476607254205028],
        [1439769600000, 1.20387143115441],
        [1439856000000, 1.281393051659975],
        [1439942400000, 1.252738651679077],
        [1440028800000, 1.484195074171873],
        [1440115200000, 1.40631477379752],
        [1440201600000, 1.380721536476638],
        [1440288000000, 1.355182100454404],
        [1440374400000, 1.24657168269902],
        [1440460800000, 1.160828327907264],
        [1440547200000, 1.119639156482987],
        [1440633600000, 1.133690694008804],
        [1440720000000, 1.188895698722608],
        [1440806400000, 1.174343905252208],
        [1440892800000, 1.322350879918967],
        [1440979200000, 1.353368417391325],
        [1441065600000, 1.350786688734447],
        [1441152000000, 1.278810765308764],
        [1441238400000, 1.262770719520856],
        [1441324800000, 1.269520099878085],
        [1441411200000, 1.334976229386974],
        [1441497600000, 1.29888313413515],
        [1441584000000, 1.256223439965584],
        [1441670400000, 1.236033260936938],
        [1441756800000, 1.206529945086729],
        [1441843200000, 1.167234841402144],
        [1441929600000, 0.9765090926915041],
        [1442016000000, 1.038203585306107],
        [1442102400000, 0.9386521502557871],
        [1442188800000, 0.87402296242848],
        [1442275200000, 0.9418231857274298],
        [1442361600000, 0.9032199195724051],
        [1442448000000, 0.8830787289063511],
        [1442534400000, 0.8460709193872474],
        [1442620800000, 0.8821037996855977],
        [1442707200000, 0.9363277614869444],
        [1442793600000, 0.9010928790890973],
        [1442880000000, 0.8971422650551497],
        [1442966400000, 0.8940475222994914],
        [1443052800000, 0.8160373080893036],
        [1443139200000, 0.7405192998515878],
        [1443225600000, 0.7778014991497727],
        [1443312000000, 0.7213584231130563],
        [1443398400000, 0.5830570046974366],
        [1443484800000, 0.657832489515796],
        [1443571200000, 0.7347630535971914],
        [1443657600000, 0.6858121897138929],
        [1443744000000, 0.6762217338750541],
        [1443830400000, 0.6874757575411915],
        [1443916800000, 0.6675468579375702],
        [1444003200000, 0.6282550742429386],
        [1444089600000, 0.64565776230867],
        [1444176000000, 0.6100126973575233],
        [1444262400000, 0.6234833021554507],
        [1444348800000, 0.650768764684828],
        [1444435200000, 0.6297949417268295],
        [1444521600000, 0.6370439806918999],
        [1444608000000, 0.628787281832734],
        [1444694400000, 0.6026272723327593],
        [1444780800000, 0.517689435598842],
        [1444867200000, 0.5614990042588522],
        [1444953600000, 0.5331480200007419],
        [1445040000000, 0.5490283089973744],
        [1445126400000, 0.5184445473595721],
        [1445212800000, 0.4870672984193326],
        [1445299200000, 0.4329785990312485],
        [1445385600000, 0.4397689744048958],
        [1445472000000, 0.5654615066780281],
        [1445558400000, 0.540737766800056],
        [1445644800000, 0.5685737685118122],
        [1445731200000, 0.6319388092249569],
        [1445817600000, 0.7439579097275092],
        [1445904000000, 0.8544552504832845],
        [1445990400000, 1.010409721259424],
        [1446076800000, 1.163748635576317],
        [1446163200000, 1.041849054896385],
        [1446249600000, 0.9070916284167455],
        [1446336000000, 1.058541941291151],
        [1446422400000, 0.9550458603200441],
        [1446508800000, 1.002344802929553],
        [1446595200000, 0.9018086605486009],
        [1446681600000, 0.906601136813436],
        [1446768000000, 0.9094421987209234],
        [1446854400000, 0.922482091197951],
        [1446940800000, 1.030499514179018],
        [1447027200000, 0.9958029845225516],
        [1447113600000, 0.9348342219016499],
        [1447200000000, 0.7887611772679245],
        [1447286400000, 0.90074206650109],
        [1447372800000, 0.9040815579448993],
        [1447459200000, 0.8842289054037304],
        [1447545600000, 0.9108255481961639],
        [1447632000000, 0.9338407839578461],
        [1447718400000, 0.9952733578828389],
        [1447804800000, 0.9944285799213992],
        [1447891200000, 0.9514712892422663],
        [1447977600000, 0.9268031189591781],
        [1448064000000, 0.9735724342696529],
        [1448150400000, 0.96473853235469],
        [1448236800000, 0.9452203362307195],
        [1448323200000, 0.8993611354663962],
        [1448409600000, 0.8677070917677269],
        [1448496000000, 0.8948747707881739],
        [1448582400000, 0.8707128002545287],
        [1448668800000, 0.9173987754375574],
        [1448755200000, 0.8717449537893333],
        [1448841600000, 0.8736009320890139],
        [1448928000000, 0.8750039976447704],
        [1449014400000, 0.8227337811375524],
        [1449100800000, 0.8247651888810106],
        [1449187200000, 0.8387909512811372],
        [1449273600000, 0.8645837297560469],
        [1449360000000, 0.8349924869606098],
        [1449446400000, 0.8007502839259887],
        [1449532800000, 0.8188527088752511],
        [1449619200000, 0.7918293483107267],
        [1449705600000, 0.8474422420390545],
        [1449792000000, 0.9167389424445134],
        [1449878400000, 0.9712583097472548],
        [1449964800000, 0.9583031735578238],
        [1450051200000, 0.9954898346339888],
        [1450137600000, 1.0170637001075],
        [1450224000000, 0.9889124423815733],
        [1450310400000, 0.9402283251940493],
        [1450396800000, 0.9211717831099321],
        [1450483200000, 0.9096329169325174],
        [1450569600000, 0.9032316429089903],
        [1450656000000, 0.8968062870238118],
        [1450742400000, 0.8789593130596947],
        [1450828800000, 0.8675654552724993],
        [1450915200000, 0.861363016488459],
        [1451001600000, 0.8707183439745884],
        [1451088000000, 0.8530178649009634],
        [1451174400000, 0.8588870868776094],
        [1451260800000, 0.8421696130307879],
        [1451347200000, 0.8694878107781919],
        [1451433600000, 0.9003543017405369],
        [1451520000000, 0.9365399677803522],
        [1451606400000, 0.9463133430749894],
        [1451692800000, 0.9381261621726148],
        [1451779200000, 0.9721969755035614],
        [1451865600000, 0.9592978352147374],
        [1451952000000, 0.9531022411427653],
        [1452038400000, 0.9684340117908705],
        [1452124800000, 0.9589767803876823],
        [1452211200000, 0.9955747811931686],
        [1452297600000, 1.02478096778813],
        [1452384000000, 1.01996585607406],
        [1452470400000, 1.068726621062779],
        [1452556800000, 1.167362900827917],
        [1452643200000, 1.12958834642001],
        [1452729600000, 1.157377553252501],
        [1452816000000, 1.21073546288485],
        [1452902400000, 1.215691295024754],
        [1452988800000, 1.321855711753408],
        [1453075200000, 1.39830112692888],
        [1453161600000, 1.3769900190471],
        [1453248000000, 1.565411904541257],
        [1453334400000, 1.544691862235827],
        [1453420800000, 1.471341822504971],
        [1453507200000, 1.692156284936261],
        [1453593600000, 2.131691495370684],
        [1453680000000, 2.48139608647395],
        [1453766400000, 2.086782726986503],
        [1453852800000, 2.384501158704437],
        [1453939200000, 2.519719734523484],
        [1454025600000, 2.486203232055895],
        [1454112000000, 2.437601273512591],
        [1454198400000, 2.298766557449123],
        [1454284800000, 2.221883059355513],
        [1454371200000, 2.403111855327034],
        [1454457600000, 2.515949933456795],
        [1454544000000, 2.557178944302694],
        [1454630400000, 2.532878565491474],
        [1454716800000, 2.517776369343559],
        [1454803200000, 2.950776113177157],
        [1454889600000, 3.165335693696386],
        [1454976000000, 3.720453286688504],
        [1455062400000, 4.427431437703359],
        [1455148800000, 6.005000308112697],
        [1455235200000, 5.475649283683189],
        [1455321600000, 5.373284921940013],
        [1455408000000, 5.169512436402438],
        [1455494400000, 5.319058286377442],
        [1455580800000, 4.368970122064963],
        [1455667200000, 3.744022470740231],
        [1455753600000, 4.397939042003922],
        [1455840000000, 4.493895382965731],
        [1455926400000, 4.301875677560981],
        [1456012800000, 4.653970273276003],
        [1456099200000, 5.608714257576696],
        [1456185600000, 5.612091439849516],
        [1456272000000, 6.134381259039694],
        [1456358400000, 5.93625743323269],
        [1456444800000, 5.897351626440898],
        [1456531200000, 6.357091762158404],
        [1456617600000, 6.440650848847282],
        [1456704000000, 6.312028458049726],
        [1456790400000, 7.560157049617909],
        [1456876800000, 8.398318163034869],
        [1456963200000, 9.504937042998197],
        [1457049600000, 10.03776475108964],
        [1457136000000, 10.93519773927983],
        [1457222400000, 10.96466178163567],
        [1457308800000, 9.397469868813554],
        [1457395200000, 9.800690787314164],
        [1457481600000, 11.97157975649351],
        [1457568000000, 11.2064229700308],
        [1457654400000, 11.20893669288255],
        [1457740800000, 13.37620667356022],
        [1457827200000, 14.89359978457807],
        [1457913600000, 12.28982460670519],
        [1458000000000, 12.966897673599],
        [1458086400000, 12.52943314528079],
        [1458172800000, 10.956232214701],
        [1458259200000, 10.79765033887468],
        [1458345600000, 10.43953586817164],
        [1458432000000, 10.21749097388716],
        [1458518400000, 11.85112269425594],
        [1458604800000, 11.31742767517068],
        [1458691200000, 12.40319150724304],
        [1458777600000, 11.12762218636002],
        [1458864000000, 10.64069143211582],
        [1458950400000, 10.96552244468764],
        [1459036800000, 10.37751681820161],
        [1459123200000, 11.67625154039094],
        [1459209600000, 11.73817083046575],
        [1459296000000, 11.88228675953419],
        [1459382400000, 11.35463054290018],
        [1459468800000, 11.63607180334684],
        [1459555200000, 11.5639776320382],
        [1459641600000, 11.51238012010477],
        [1459728000000, 11.18688123888369],
        [1459814400000, 10.41948468988903],
        [1459900800000, 10.66622342007389],
        [1459987200000, 10.0295684039377],
        [1460073600000, 9.758944911021473],
        [1460160000000, 9.899719595415823],
        [1460246400000, 8.814617175830122],
        [1460332800000, 8.66829211893249],
        [1460419200000, 7.51681223420342],
        [1460505600000, 8.015604593105122],
        [1460592000000, 8.43365602127963],
        [1460678400000, 8.20613745870167],
        [1460764800000, 8.555981966976974],
        [1460851200000, 9.323170276346234],
        [1460937600000, 8.960482405053712],
        [1461024000000, 8.696773038727313],
        [1461110400000, 8.484150722584234],
        [1461196800000, 8.11104074507033],
        [1461283200000, 7.855106665179344],
        [1461369600000, 8.377192623957283],
        [1461456000000, 7.995361207240304],
        [1461542400000, 7.812751656077315],
        [1461628800000, 7.424663266229029],
        [1461715200000, 7.724694549946334],
        [1461801600000, 7.250216005225782],
        [1461888000000, 7.47581760975781],
        [1461974400000, 8.892970091837213],
        [1462060800000, 8.832454372832895],
        [1462147200000, 10.10782116866719],
        [1462233600000, 9.285335810954495],
        [1462320000000, 9.414136136064808],
        [1462406400000, 9.827130178456441],
        [1462492800000, 9.277042146778028],
        [1462579200000, 9.381202893584621],
        [1462665600000, 9.52920206226682],
        [1462752000000, 9.353276545574158],
        [1462838400000, 9.350283703928604],
        [1462924800000, 9.981302920467385],
        [1463011200000, 10.091492287586432],
        [1463097600000, 10.585661577212786],
        [1463184000000, 10.176098567981153],
        [1463270400000, 9.960446892869543],
        [1463356800000, 11.1336459827202],
        [1463443200000, 12.157137161266911],
        [1463529600000, 13.35361430554958],
        [1463616000000, 14.71832475600216],
        [1463702400000, 13.69368343400459],
        [1463788800000, 13.99924843857703],
        [1463875200000, 14.248986066415835],
        [1463961600000, 13.23503660198636],
        [1464048000000, 12.66434299000911],
        [1464134400000, 12.50426452924122],
        [1464220800000, 12.498353409631168],
        [1464307200000, 11.19174476153149],
        [1464393600000, 11.76324560569715],
        [1464480000000, 12.38905213533701],
        [1464566400000, 12.67843106889236],
        [1464652800000, 14.00927375901662],
        [1464739200000, 13.857937834861247],
        [1464825600000, 13.77433887468257],
        [1464912000000, 13.8427358773047],
        [1464998400000, 13.697394260187224],
        [1465084800000, 13.970529067786634],
        [1465171200000, 13.942005553967727],
        [1465257600000, 14.549216948648322],
        [1465344000000, 14.43177555298705],
        [1465430400000, 14.44062374394472],
        [1465516800000, 13.943733928604544],
        [1465603200000, 13.960460970231287],
        [1465689600000, 15.697007269686255],
        [1465776000000, 17.55275028575095],
        [1465862400000, 18.770374371361417],
        [1465948800000, 18.421853687438038],
        [1466035200000, 20.509400413759888],
        [1466121600000, 15.397397817568129],
        [1466208000000, 11.197136666677007],
        [1466294400000, 12.23058702890821],
        [1466380800000, 11.814834520499266],
        [1466467200000, 12.984597875196858],
        [1466553600000, 12.737756210638716],
        [1466640000000, 13.676478995775716],
        [1466726400000, 13.94189690132606],
        [1466812800000, 14.276287720956573],
        [1466899200000, 13.845029961871877],
        [1466985600000, 13.932543491059052],
        [1467072000000, 12.295660556232374],
        [1467158400000, 12.800496100256193],
        [1467244800000, 13.33562669996461],
        [1467331200000, 12.329645963731734],
        [1467417600000, 12.01798250397972],
        [1467504000000, 11.825161030775883],
        [1467590400000, 11.421738727166776],
        [1467676800000, 10.540860504216047],
        [1467763200000, 10.51055993812399],
        [1467849600000, 10.098931265626275],
        [1467936000000, 11.39547874677082],
        [1468022400000, 10.99572234732321],
        [1468108800000, 10.957294339543301],
        [1468195200000, 10.515451706239658],
        [1468281600000, 10.606193607117202],
        [1468368000000, 10.489054686552203],
        [1468454400000, 11.242970957141127],
        [1468540800000, 11.32509705331988],
        [1468627200000, 11.701716015155743],
        [1468713600000, 11.314787410063753],
        [1468800000000, 11.022556335862294],
        [1468886400000, 11.582501423627564],
        [1468972800000, 12.152788273478947],
        [1469059200000, 12.605917129706976],
        [1469145600000, 14.319877369541702],
        [1469232000000, 14.389725342721574],
        [1469318400000, 12.803851809790078],
        [1469404800000, 13.816630680711038],
        [1469491200000, 11.771641757280554],
        [1469577600000, 12.96633340222508],
        [1469664000000, 12.883149607897323],
        [1469750400000, 12.840037637363167],
        [1469836800000, 12.547081780784822],
        [1469923200000, 11.868408414551208],
        [1470009600000, 10.968397852276404],
        [1470096000000, 8.352592747838974],
        [1470182400000, 10.22174781589873],
        [1470268800000, 11.032160216870203],
        [1470355200000, 10.940014108153814],
        [1470441600000, 10.898636348546457],
        [1470528000000, 10.920308055489057],
        [1470614400000, 11.245805121088916],
        [1470700800000, 12.191218707659688],
        [1470787200000, 12.141222798878122],
        [1470873600000, 11.988140188861896],
        [1470960000000, 11.973720126245913],
        [1471046400000, 11.577472667681942],
        [1471132800000, 11.25990352208696],
        [1471219200000, 11.230807046622242],
        [1471305600000, 10.25082244872534],
        [1471392000000, 10.73534634440297],
        [1471478400000, 10.724216809737694],
        [1471564800000, 10.718344718920152],
        [1471651200000, 11.262754476834717],
        [1471737600000, 11.154044310153234],
        [1471824000000, 11.061377388887204],
        [1471910400000, 11.012543081655863],
        [1471996800000, 11.013811994175523],
        [1472083200000, 11.339127441192723],
        [1472169600000, 11.256812662022648],
        [1472256000000, 11.14950758737516],
        [1472342400000, 10.91304107690759],
        [1472428800000, 10.906290588906247],
        [1472515200000, 11.210877311518441],
        [1472601600000, 11.49662475092663],
        [1472688000000, 12.002877477501157],
        [1472774400000, 12.09104280233147],
        [1472860800000, 11.797585220569804],
        [1472947200000, 11.662579543984544],
        [1473033600000, 11.751897477880805],
        [1473120000000, 11.684755437748173],
        [1473206400000, 11.550025415616256],
        [1473292800000, 11.33639873415642],
        [1473379200000, 11.63011682103449],
        [1473465600000, 12.117830861883727],
        [1473552000000, 11.59605624659764],
        [1473638400000, 11.84714198083278],
        [1473724800000, 11.881729455789054],
        [1473811200000, 11.912008011075965],
        [1473897600000, 11.901478405174705],
        [1473984000000, 12.522369283417852],
        [1474070400000, 12.68454280449742],
        [1474156800000, 12.369052139120052],
        [1474243200000, 13.160168063025644],
        [1474329600000, 14.308046581659253],
        [1474416000000, 13.705179799911743],
        [1474502400000, 13.233802742993412],
        [1474588800000, 13.318993324210759],
        [1474675200000, 12.859605765847848],
        [1474761600000, 13.045598643367276],
        [1474848000000, 12.818746399021148],
        [1474934400000, 13.074726797493888],
        [1475020800000, 13.313989439262565],
        [1475107200000, 13.09665700624727],
        [1475193600000, 13.194818155480155],
        [1475280000000, 13.269827224677638],
        [1475366400000, 13.197909934562759],
        [1475452800000, 13.470600090767412],
        [1475539200000, 13.261962214071206],
        [1475625600000, 13.056650848974803],
        [1475712000000, 12.826128461125474],
        [1475798400000, 12.655679278508105],
        [1475884800000, 12.175874962913426],
        [1475971200000, 12.029519227976937],
        [1476057600000, 11.837832089591664],
        [1476144000000, 11.750267273762638],
        [1476230400000, 11.854687660404304],
        [1476316800000, 11.96064220847878],
        [1476403200000, 11.916089474549377],
        [1476489600000, 11.944605165006873],
        [1476576000000, 11.930313388885365],
        [1476662400000, 11.974984398539725],
        [1476748800000, 12.509511262679014],
        [1476835200000, 11.973806158360992],
        [1476921600000, 12.016522079125743],
        [1477008000000, 12.090575972840435],
        [1477094400000, 12.045038278304451],
        [1477180800000, 11.933800410433603],
        [1477267200000, 11.890535450541087],
        [1477353600000, 11.272711525960538],
        [1477440000000, 11.497257370206905],
        [1477526400000, 11.456673402613983],
        [1477612800000, 11.523584376027783],
        [1477699200000, 10.336819684434698],
        [1477785600000, 11.186557158904845],
        [1477872000000, 10.88070260049341],
        [1477958400000, 10.792187379279612],
        [1478044800000, 10.783585401157607],
        [1478131200000, 10.811630097854405],
        [1478217600000, 11.129995658864795],
        [1478304000000, 11.066058259923683],
        [1478390400000, 10.920762843886132],
        [1478476800000, 10.874200829423744],
        [1478563200000, 10.890105501340495],
        [1478649600000, 10.664918162834825],
        [1478736000000, 10.519281388267027],
        [1478822400000, 10.29308735231344],
        [1478908800000, 9.664325241310054],
        [1478995200000, 10.110719459728225],
        [1479081600000, 9.991652804266907],
        [1479168000000, 10.200083856801202],
        [1479254400000, 10.025367620097992],
        [1479340800000, 9.96397798540216],
        [1479427200000, 9.453697368045109],
        [1479513600000, 9.66730580728121],
        [1479600000000, 9.530496491166998],
        [1479686400000, 9.575907932522316],
        [1479772800000, 9.81249150160966],
        [1479859200000, 9.753493133033384],
        [1479945600000, 9.180997416192705],
        [1480032000000, 9.335116893823114],
        [1480118400000, 9.288306186526965],
        [1480204800000, 8.907402683417718],
        [1480291200000, 8.70845246717361],
        [1480377600000, 8.158494315710797],
        [1480464000000, 8.650154047457612],
        [1480550400000, 8.439785438702646],
        [1480636800000, 7.637917965068979],
        [1480723200000, 7.892622837022096],
        [1480809600000, 7.469132189830329],
        [1480896000000, 6.815609222087059],
        [1480982400000, 7.631916157246792],
        [1481068800000, 8.13507798254848],
        [1481155200000, 8.302596338463099],
        [1481241600000, 8.504900484563588],
        [1481328000000, 8.098585501342257],
        [1481414400000, 8.214985823104497],
        [1481500800000, 8.472511708508414],
        [1481587200000, 8.35100726243699],
        [1481673600000, 8.207853104398847],
        [1481760000000, 7.770357969755126],
        [1481846400000, 7.796626831717168],
        [1481932800000, 7.820282701062171],
        [1482019200000, 7.856185612715422],
        [1482105600000, 7.5922361645492975],
        [1482192000000, 7.623783721340898],
        [1482278400000, 7.856099400557245],
        [1482364800000, 7.575643744053494],
        [1482451200000, 7.201805732308588],
        [1482537600000, 7.2227628476531995],
        [1482624000000, 7.2132341590915585],
        [1482710400000, 7.268475378158216],
        [1482796800000, 7.171806594177698],
        [1482883200000, 7.613307736356936],
        [1482969600000, 8.172329056763505],
        [1483056000000, 8.15378652716307],
        [1483142400000, 8.010852235417396],
        [1483228800000, 8.064789989459687],
        [1483315200000, 8.361286160728705],
        [1483401600000, 9.626792384988086],
        [1483488000000, 10.972252853086058],
        [1483574400000, 10.115254241883068],
        [1483660800000, 10.154658304812745],
        [1483747200000, 9.824278869463066],
        [1483833600000, 10.267836701334787],
        [1483920000000, 10.285955970375603],
        [1484006400000, 10.523650713762722],
        [1484092800000, 9.661693873756436],
        [1484179200000, 9.66715894243992],
        [1484265600000, 9.650805118856487],
        [1484352000000, 9.695262751794939],
        [1484438400000, 9.785923385543578],
        [1484524800000, 9.5212839167112],
        [1484611200000, 10.258458296131915],
        [1484697600000, 10.125537740945934],
        [1484784000000, 10.385306173596472],
        [1484870400000, 10.549960559535752],
        [1484956800000, 10.872633993948424],
        [1485043200000, 10.745356049118469],
        [1485129600000, 10.751024402450716],
        [1485216000000, 10.399087248402381],
        [1485302400000, 10.471671231503688],
        [1485388800000, 10.555255877288236],
        [1485475200000, 10.509979092454431],
        [1485561600000, 10.554962953815345],
        [1485648000000, 10.430442595335876],
        [1485734400000, 10.570411298387052],
        [1485820800000, 10.677298023363218],
        [1485907200000, 10.700989418222473],
        [1485993600000, 10.81046108694813],
        [1486080000000, 10.937685579154774],
        [1486166400000, 11.33331586102519],
        [1486252800000, 11.242238837962214],
        [1486339200000, 11.294016130984236],
        [1486425600000, 11.433167440255266],
        [1486512000000, 11.335153011135779],
        [1486598400000, 10.914963949831499],
        [1486684800000, 11.275640644361795],
        [1486771200000, 11.399125371500645],
        [1486857600000, 11.354164564644952],
        [1486944000000, 11.281947015245585],
        [1487030400000, 12.972145045966517],
        [1487116800000, 12.934716009243267],
        [1487203200000, 12.835968798993127],
        [1487289600000, 12.7034838481457],
        [1487376000000, 12.816285332013342],
        [1487462400000, 12.775445603327524],
        [1487548800000, 12.750223774537398],
        [1487635200000, 12.73614094049859],
        [1487721600000, 12.600296405431427],
        [1487808000000, 13.15516545737877],
        [1487894400000, 13.071871231178674],
        [1487980800000, 13.546938608109645],
        [1488067200000, 14.547676188997867],
        [1488153600000, 15.358690360839047],
        [1488240000000, 15.702781231900772],
        [1488326400000, 17.168029711638418],
        [1488412800000, 18.993581792674195],
        [1488499200000, 19.58574948053123],
        [1488585600000, 18.63867345258356],
        [1488672000000, 19.247364659986754],
        [1488758400000, 19.670705614004156],
        [1488844800000, 18.82733332066123],
        [1488931200000, 16.7497435780077],
        [1489017600000, 17.632128818481693],
        [1489104000000, 19.26359042821057],
        [1489190400000, 21.396413225585537],
        [1489276800000, 23.09884795827443],
        [1489363200000, 28.4460651994335],
        [1489449600000, 28.53666459127697],
        [1489536000000, 34.19736420741617],
        [1489622400000, 39.86185607085268],
        [1489708800000, 44.06729598863206],
        [1489795200000, 32.26798526618718],
        [1489881600000, 44.08879749579081],
        [1489968000000, 41.86369288259116],
        [1490054400000, 42.47845801399063],
        [1490140800000, 41.262612537182946],
        [1490227200000, 43.274929979555615],
        [1490313600000, 50.894791438930966],
        [1490400000000, 50.678966487888445],
        [1490486400000, 50.049454214237386],
        [1490572800000, 49.017113803037155],
        [1490659200000, 50.259542864330534],
        [1490745600000, 52.84379426461343],
        [1490832000000, 52.313175209019796],
        [1490918400000, 49.9646672803426],
        [1491004800000, 50.42578760723248],
        [1491091200000, 48.37846727683558],
        [1491177600000, 43.57923995912754],
        [1491264000000, 41.79779504583054],
        [1491350400000, 45.590295052351756],
        [1491436800000, 42.978525212227865],
        [1491523200000, 42.40137665621101],
        [1491609600000, 44.501284387189116],
        [1491696000000, 44.1907610730727],
        [1491782400000, 43.90534128958704],
        [1491868800000, 43.85889483882994],
        [1491955200000, 46.197883489516954],
        [1492041600000, 49.7503375730287],
        [1492128000000, 47.56205172159332],
        [1492214400000, 49.122793411699824],
        [1492300800000, 48.70871927867354],
        [1492387200000, 48.34641781155764],
        [1492473600000, 50.759170818800456],
        [1492560000000, 48.1594963219902],
        [1492646400000, 50.065253649182445],
        [1492732800000, 48.80173571247641],
        [1492819200000, 49.0798706465032],
        [1492905600000, 50.60871348408776],
        [1492992000000, 51.09741863901202],
        [1493078400000, 50.97375547481341],
        [1493164800000, 54.87817856677162],
        [1493251200000, 63.95735376256868],
        [1493337600000, 73.0109629151518],
        [1493424000000, 70.45153824766224],
        [1493510400000, 79.8025539393512],
        [1493596800000, 76.84617780144701],
        [1493683200000, 77.34604386935915],
        [1493769600000, 80.9294915785314],
        [1493856000000, 94.11226519917764],
        [1493942400000, 91.16038713753753],
        [1494028800000, 96.42767965195394],
        [1494115200000, 89.73967947914684],
        [1494201600000, 87.94812005196805],
        [1494288000000, 87.50244390780135],
        [1494374400000, 88.41058886851773],
        [1494460800000, 88.45664874349296],
        [1494547200000, 88.18109149325619],
        [1494633600000, 89.13893167771396],
        [1494720000000, 90.05504056395782],
        [1494806400000, 90.50413159922428],
        [1494892800000, 87.09148558444505],
        [1494979200000, 88.21316751381906],
        [1495065600000, 96.62584622982801],
        [1495152000000, 122.16278299258484],
        [1495238400000, 123.02820305804211],
        [1495324800000, 148.65732047983943],
        [1495411200000, 153.99006380727735],
        [1495497600000, 171.08002832171815],
        [1495584000000, 190.42581317146121],
        [1495670400000, 176.81781507886726],
        [1495756800000, 162.94448638549966],
        [1495843200000, 159.05524764233746],
        [1495929600000, 172.3406699356334],
        [1496016000000, 196.0785933386944],
        [1496102400000, 230.79837139121355],
        [1496188800000, 231.37212671271118],
        [1496275200000, 221.82807122254022],
        [1496361600000, 224.31670548594502],
        [1496448000000, 225.39759443485244],
        [1496534400000, 247.4275410848339],
        [1496620800000, 248.93012523209245],
        [1496707200000, 266.13970806704395],
        [1496793600000, 256.21892337063565],
        [1496880000000, 260.3415097477362],
        [1496966400000, 280.333547997722],
        [1497052800000, 337.7921754587072],
        [1497139200000, 341.0796489185503],
        [1497225600000, 398.4307850845948],
        [1497312000000, 389.9448221775502],
        [1497398400000, 347.78135772227546],
        [1497484800000, 346.36906994261665],
        [1497571200000, 358.2845168676378],
        [1497657600000, 372.3570110185702],
        [1497744000000, 359.4387121135216],
        [1497830400000, 362.0442891370599],
        [1497916800000, 358.0532310647092],
        [1498003200000, 327.8909356942679],
        [1498089600000, 326.9598139741636],
        [1498176000000, 331.9138051489657],
        [1498262400000, 310.05462840596994],
        [1498348800000, 288.04702626534885],
        [1498435200000, 258.91946273454556],
        [1498521600000, 280.8130146788337],
        [1498608000000, 317.2936774724324],
        [1498694400000, 300.18215256198584],
        [1498780800000, 284.69108328848546],
        [1498867200000, 262.47807335859795],
        [1498953600000, 286.1336070099866],
        [1499040000000, 278.5971953381099],
        [1499126400000, 269.7366305781692],
        [1499212800000, 265.9369454655199],
        [1499299200000, 267.4697236476019],
        [1499385600000, 241.4766205298395],
        [1499472000000, 246.21497097327446],
        [1499558400000, 238.75431849081662],
        [1499644800000, 210.52819727984127],
        [1499731200000, 194.33493649003975],
        [1499817600000, 222.13885617231483],
        [1499904000000, 206.41325042813503],
        [1499990400000, 197.96945236247697],
        [1500076800000, 171.77744094493255],
        [1500163200000, 156.46899162898947],
        [1500249600000, 188.3527773486568],
        [1500336000000, 228.50328003667275],
        [1500422400000, 199.59051553264698],
        [1500508800000, 223.4613483872583],
        [1500595200000, 217.08647395834424],
        [1500681600000, 231.14352894295098],
        [1500768000000, 226.7185992848529],
        [1500854400000, 224.91340709342114],
        [1500940800000, 206.81050478775018],
        [1501027200000, 200.3285558009793],
        [1501113600000, 203.18932178158303],
        [1501200000000, 192.58568390577597],
        [1501286400000, 205.51111919711502],
        [1501372800000, 197.19896347978235],
        [1501459200000, 203.28719634179097],
        [1501545600000, 227.08056705038587],
        [1501632000000, 219.05792926762416],
        [1501718400000, 224.86795657659746],
        [1501804800000, 221.67495953018923],
        [1501891200000, 253.15566612330053],
        [1501977600000, 263.2220861638244],
        [1502064000000, 268.49662903477497],
        [1502150400000, 295.14709831672786],
        [1502236800000, 297.0696048197405],
        [1502323200000, 296.7767240757822],
        [1502409600000, 306.826433219032],
        [1502496000000, 308.625531335255],
        [1502582400000, 297.15980274719976],
        [1502668800000, 296.3972895244633],
        [1502755200000, 288.4507671723357],
        [1502841600000, 300.0393294082391],
        [1502928000000, 300.5703437604035],
        [1503014400000, 296.114635373622],
        [1503100800000, 296.6220895183796],
        [1503187200000, 295.17157732771335],
        [1503273600000, 322.2012201364436],
        [1503360000000, 312.1744705574116],
        [1503446400000, 316.7889202107443],
        [1503532800000, 321.7852984049241],
        [1503619200000, 329.0252809929819],
        [1503705600000, 329.86578262442157],
        [1503792000000, 343.34133712814116],
        [1503878400000, 344.2011314209417],
        [1503964800000, 366.809589912788],
        [1504051200000, 378.90633073469184],
        [1504137600000, 382.9344507545728],
        [1504224000000, 386.3430570009356],
        [1504310400000, 346.3987754868897],
        [1504396800000, 349.92924044114534],
        [1504483200000, 299.7173318855491],
        [1504569600000, 318.9728041158723],
        [1504656000000, 333.20632999273016],
        [1504742400000, 329.4156625176296],
        [1504828800000, 304.47510826802426],
        [1504915200000, 299.6944444705654],
        [1505001600000, 292.7796567856866],
        [1505088000000, 296.4753988432914],
        [1505174400000, 290.58653195906925],
        [1505260800000, 277.33365793127047],
        [1505347200000, 214.3117136334975],
        [1505433600000, 246.9218572871298],
        [1505520000000, 244.80561175288247],
        [1505606400000, 249.66654597502836],
        [1505692800000, 287.96109568877347],
        [1505779200000, 280.4456323420793],
        [1505865600000, 279.4176492108516],
        [1505952000000, 257.465074465559],
        [1506038400000, 263.46977368373445],
        [1506124800000, 283.22215899335055],
        [1506211200000, 280.68104473460187],
        [1506297600000, 288.0857009417575],
        [1506384000000, 283.9294530726591],
        [1506470400000, 302.1518453516756],
        [1506556800000, 294.3138380117381],
        [1506643200000, 288.11459689868053],
        [1506729600000, 297.2689355700251],
        [1506816000000, 299.6748890236153],
        [1506902400000, 295.8400297394862],
        [1506988800000, 292.2107206992577],
        [1507075200000, 293.0357027858311],
        [1507161600000, 293.71934612048636],
        [1507248000000, 307.7089805559562],
        [1507334400000, 309.5982491240284],
        [1507420800000, 307.05813687389934],
        [1507507200000, 295.901045084102],
        [1507593600000, 299.9170113647283],
        [1507680000000, 303.2474665353709],
        [1507766400000, 302.0199976805083],
        [1507852800000, 337.9526134340301],
        [1507939200000, 335.33824482981413],
        [1508025600000, 335.3913549863649],
        [1508112000000, 331.967214731027],
        [1508198400000, 317.898212514702],
        [1508284800000, 314.456554240651],
        [1508371200000, 308.4746839282341],
        [1508457600000, 302.35649692557024],
        [1508544000000, 300.69384596228315],
        [1508630400000, 295.4882479105146],
        [1508716800000, 289.4140251237576],
        [1508803200000, 298.75573430773346],
        [1508889600000, 298.4839668215046],
        [1508976000000, 295.7078546189935],
        [1509062400000, 297.9291296930045],
        [1509148800000, 296.4649529071602],
        [1509235200000, 303.74113040048763],
        [1509321600000, 306.2494615338919],
        [1509408000000, 303.54211702851427],
        [1509494400000, 292.33053872865435],
        [1509580800000, 287.0696687458828],
        [1509667200000, 305.8432726789198],
        [1509753600000, 299.9549103522952],
        [1509840000000, 297.5173231052644],
        [1509926400000, 301.370794552855],
        [1510012800000, 295.23511338265723],
        [1510099200000, 308.9339866357258],
        [1510185600000, 322.32952048358885],
        [1510272000000, 301.3549333527746],
        [1510358400000, 316.2583419604271],
        [1510444800000, 314.1661277072687],
        [1510531200000, 315.34395637188766],
        [1510617600000, 338.937627406501],
        [1510704000000, 334.286932546348],
        [1510790400000, 330.021315646911],
        [1510876800000, 333.351255037181],
        [1510963200000, 348.74721545667],
        [1511049600000, 353.43572964676],
        [1511136000000, 365.786815977961],
        [1511222400000, 361.055822571048],
        [1511308800000, 379.409057274538],
        [1511395200000, 412.557611501513],
        [1511481600000, 475.002014978137],
        [1511568000000, 467.446390045639],
        [1511654400000, 468.998099373249],
        [1511740800000, 484.362624150733],
        [1511827200000, 474.182894748523],
        [1511913600000, 428.345443336342],
        [1512000000000, 454.160981519821],
        [1512086400000, 469.863479570669],
        [1512172800000, 469.857050536754],
        [1512259200000, 461.880481908051],
        [1512345600000, 472.252735495896],
        [1512432000000, 467.016662982671],
        [1512518400000, 448.328222164605],
        [1512604800000, 451.825581860168],
        [1512691200000, 467.881662612901],
        [1512777600000, 482.035518858509],
        [1512864000000, 446.691058078548],
        [1512950400000, 509.000876237775],
        [1513036800000, 661.841332287634],
        [1513123200000, 709.883193572531],
        [1513209600000, 704.621843737326],
        [1513296000000, 693.09706413276],
        [1513382400000, 702.244612799479],
        [1513468800000, 728.700471211578],
        [1513555200000, 798.115404033608],
        [1513641600000, 829.989201766042],
        [1513728000000, 813.403602277568],
        [1513814400000, 812.885159103535],
        [1513900800000, 669.276492289395],
        [1513987200000, 715.719068265809],
        [1514073600000, 686.208308919806],
        [1514160000000, 733.902545910265],
        [1514246400000, 774.110198124999],
        [1514332800000, 764.967062014162],
        [1514419200000, 736.840047017461],
        [1514505600000, 755.343641318154],
        [1514592000000, 736.909191519636],
        [1514678400000, 774.696255457107],
        [1514764800000, 776.080330355968],
        [1514851200000, 876.890258814416],
        [1514937600000, 963.0557506903511],
        [1515024000000, 978.416104183522],
        [1515110400000, 1004.8949722625625],
        [1515196800000, 1052.02336010703],
        [1515283200000, 1137.6549188901813],
        [1515369600000, 1184.92393027798],
        [1515456000000, 1361.4396682787146],
        [1515542400000, 1345.5876017375906],
        [1515628800000, 1181.6514100305303],
        [1515715200000, 1315.6187580839762],
        [1515801600000, 1448.1800861342022],
        [1515888000000, 1410.0002145085193],
        [1515974400000, 1329.6713965043375],
        [1516060800000, 1068.6004106894156],
        [1516147200000, 1022.3652131699663],
        [1516233600000, 1036.072312788025],
        [1516320000000, 1046.8985973958734],
        [1516406400000, 1172.9172062239459],
        [1516492800000, 1053.6332024800395],
        [1516579200000, 1000.3871327788332],
        [1516665600000, 991.665320638703],
        [1516752000000, 1056.8792325682198],
        [1516838400000, 1059.3154960264808],
        [1516924800000, 1048.7989058434846],
        [1517011200000, 1095.7825142892807],
        [1517097600000, 1237.3241758467964],
        [1517184000000, 1169.6342569193446],
        [1517270711070, 1161.9086430994253],
        [1517356800000, 1058.0960529662955],
        [1517443200000, 1106.1381723552724],
        [1517529600000, 1021.2693786534147],
        [1517616000000, 914.6388425021536],
        [1517702400000, 941.8032295185238],
        [1517788800000, 834.8891703333355],
        [1517875200000, 684.3133397266745],
        [1517961600000, 785.7389155049871],
        [1518048323681, 751.8878893661607],
        [1518134400000, 807.8227505154956],
        [1518220800000, 871.3268829432668],
        [1518307200000, 854.3498784217315],
        [1518393600000, 817.2905243571003],
        [1518480000000, 862.7487725902944],
        [1518566400000, 833.7791837295838],
        [1518652800000, 915.0765430188819],
        [1518739200000, 928.2935214045194],
        [1518825600000, 934.6767104527537],
        [1518912000000, 964.2765079107337],
        [1518998400000, 915.0920469360246],
        [1519084800000, 928.2902425457683],
        [1519171200000, 902.9690554385094],
        [1519257600000, 809.1296597083212],
        [1519344000000, 812.4468626177468],
        [1519430400000, 864.6697536212578],
        [1519516800000, 828.7233989886703],
        [1519603200000, 842.6280247519165],
        [1519689600000, 863.6502752378826],
        [1519776000000, 869.2632438249697],
        [1519862400000, 846.3652117960792],
        [1519948800000, 855.6787797305122],
        [1520035200000, 850.4429895810156],
        [1520121600000, 844.2552535175925],
        [1520208000000, 843.7565763098702],
        [1520294400000, 840.1509954642488],
        [1520380800000, 808.4491332246333],
        [1520467200000, 752.5931712474129],
        [1520553600000, 699.1898984866136],
        [1520640000000, 710.8916532666208],
        [1520726400000, 684.934340074043],
        [1520812800000, 713.1953300017516],
        [1520899200000, 695.3142030222126],
        [1520985600000, 684.6415843660401],
        [1521072000000, 608.3931488499501],
        [1521158400000, 612.4435397197692],
        [1521244800000, 607.879487479319],
        [1521331200000, 560.1094330840288],
        [1521417600000, 538.6905900543026],
        [1521504000000, 555.0775482373977],
        [1521590400000, 554.2098259834895],
        [1521676800000, 561.1465342247278],
        [1521763200000, 537.7059724380829],
        [1521849600000, 538.109029163154],
        [1521936000000, 519.4855998398031],
        [1522022400000, 519.3780445915389],
        [1522108800000, 487.5396120553992],
        [1522195200000, 449.05225249207564],
        [1522281600000, 443.9491009578748],
        [1522368000000, 385.32709566886405],
        [1522454400000, 395.32626329061816],
        [1522540800000, 395.79638095782116],
        [1522627200000, 379.1235134110076],
        [1522713600000, 384.8259822881638],
        [1522800000000, 415.7162339703384],
        [1522886400000, 380.28195959120734],
        [1522972800000, 384.46448544618835],
        [1523059200000, 369.9138976693199],
        [1523145600000, 383.44636088273523],
        [1523232000000, 396.98175550697044],
        [1523318400000, 398.77335502107474],
        [1523404800000, 412.6893456266917],
        [1523491200000, 427.3849460078481],
        [1523577600000, 488.60548934434905],
        [1523664000000, 488.99218126708564],
        [1523750400000, 495.11291363236046],
        [1523836800000, 525.5274445398754],
        [1523923200000, 507.11031309582995],
        [1524009600000, 498.46700764950907],
        [1524096000000, 517.3740431959737],
        [1524182400000, 566.3920838512778],
        [1524268800000, 590.27192246768],
        [1524355200000, 600.2596767733208],
        [1524441600000, 617.2024078855728],
        [1524528000000, 636.3671638965957],
        [1524614400000, 705.0276448443027],
        [1524700800000, 613.7267252906634],
        [1524787200000, 657.0577934851548],
        [1524873600000, 641.9936056225328],
        [1524960000000, 672.5969368274542],
        [1525046400000, 680.1204953886593],
        [1525132800000, 662.732735837356],
        [1525219200000, 665.2257861413076],
        [1525305600000, 676.5525639388837],
        [1525392000000, 781.4257804747286],
        [1525478400000, 777.9076470179848],
        [1525564800000, 807.0673291637275],
        [1525651200000, 785.89274351052],
        [1525737600000, 748.8131413938073],
        [1525824000000, 744.3248257193555],
        [1525910400000, 743.6398157399032],
        [1525996800000, 717.4128743633592],
        [1526083200000, 675.2917525022057],
        [1526169600000, 683.1702676131072],
        [1526256000000, 726.8719105255797],
        [1526342400000, 727.8026816427881],
        [1526428800000, 703.1556814584169],
        [1526515200000, 702.4531187046043],
        [1526601600000, 665.6275313370153],
        [1526688000000, 686.2132687257347],
        [1526774400000, 685.8467539789947],
        [1526860800000, 703.8146666492306],
        [1526947200000, 688.9132212295281],
        [1527033600000, 641.1003511423173],
        [1527120000000, 581.2411880572671],
        [1527206400000, 597.848771866914],
        [1527292800000, 582.2793862832264],
        [1527379200000, 580.4834597869499],
        [1527465600000, 565.9260648024518],
        [1527552000000, 511.61588041076885],
        [1527638400000, 561.7406614509548],
        [1527724800000, 555.3491509790109],
        [1527811200000, 571.7271134994469],
        [1527897600000, 573.8335504842785],
        [1527984000000, 583.1316392150303],
        [1528070400000, 609.2946747342367],
        [1528156800000, 586.5759760467295],
        [1528243200000, 600.6782790386933],
        [1528329600000, 598.2160984640701],
        [1528416000000, 595.1261406729993],
        [1528502400000, 590.4981451663734],
        [1528588800000, 581.1212496803587],
        [1528675200000, 522.9980386707371],
        [1528761600000, 529.1308297528115],
        [1528848000000, 495.0763558611063],
        [1528934400000, 474.85911303649493],
        [1529020800000, 516.6441703707438],
        [1529107200000, 485.4328365159148],
        [1529193600000, 493.0678580653148],
        [1529280000000, 491.40027597923427],
        [1529366400000, 512.4288524669856],
        [1529452800000, 532.0039133610575],
        [1529539200000, 529.7630465685196],
        [1529625600000, 520.6671645141439],
        [1529712000000, 463.94082865220423],
        [1529798400000, 472.9772647615346],
        [1529884800000, 455.71843063066746],
        [1529971200000, 458.9258012406738],
        [1530057600000, 430.60178441047594],
        [1530144000000, 437.8765374730084],
        [1530230400000, 420.86212416397933],
        [1530316800000, 432.50722049091496],
        [1530403200000, 449.43004507185094],
        [1530489600000, 447.6805505735615],
        [1530576000000, 472.31165843777023],
        [1530662400000, 459.6987235407423],
        [1530748800000, 463.22220830490755],
        [1530835200000, 466.87948010075564],
        [1530921600000, 467.5901144927255],
        [1531008000000, 481.523823511904],
        [1531094400000, 483.8040307751051],
        [1531180800000, 469.7022293732207],
        [1531267200000, 431.76445446277353],
        [1531353600000, 443.0254304264658],
        [1531440000000, 427.79224436391297],
        [1531526400000, 431.266939712935],
        [1531612800000, 431.78456841825795],
        [1531699200000, 446.60952086822346],
        [1531785600000, 478.20850696097756],
        [1531872000000, 497.1775460849197],
        [1531958400000, 475.9901579076109],
        [1532044800000, 464.78994267592014],
        [1532131200000, 447.2032909319223],
        [1532217600000, 458.0790283587359],
        [1532304000000, 455.5332019326154],
        [1532390400000, 447.92137912561867],
        [1532476800000, 477.2092716797165],
        [1532563200000, 468.73749232171497],
        [1532649600000, 460.8693339260698],
        [1532736000000, 467.9579265520383],
        [1532822400000, 465.8991552657435],
        [1532908800000, 466.41922911559277],
        [1532995200000, 458.080921209812],
        [1533081600000, 432.3501179219696],
        [1533168000000, 417.27540676628155],
        [1533254400000, 410.9251331069581],
        [1533340800000, 417.35539322792266],
        [1533427200000, 406.8839911959127],
        [1533513600000, 409.16493540460897],
        [1533600000000, 404.81719789129863],
        [1533686400000, 377.75090114785144],
        [1533772800000, 353.6461229266765],
        [1533859200000, 363.6851798621928],
        [1533945600000, 332.49243311549486],
        [1534032000000, 317.3144774285816],
        [1534118400000, 317.9561130787788],
        [1534204800000, 284.9586646577589],
        [1534291200000, 277.7460166184284],
        [1534377600000, 281.1411931356025],
        [1534464000000, 286.3202214369417],
        [1534550400000, 314.79325272659554],
        [1534636800000, 293.17699177790365],
        [1534723200000, 299.4351225630472],
        [1534809600000, 271.06155217805997],
        [1534896000000, 280.3747171450871],
        [1534982400000, 269.2483994291051],
        [1535068800000, 275.80838507029057],
        [1535155200000, 281.7346420014112],
        [1535241600000, 277.96671056981506],
        [1535328000000, 273.50341418810274],
        [1535414400000, 286.9611037603499],
        [1535500800000, 296.29612763685776],
        [1535587200000, 288.5053605724647],
        [1535673600000, 285.1028726269231],
        [1535760000000, 282.22317870221497],
        [1535846400000, 295.6461617616964],
        [1535932800000, 294.5856884801363],
        [1536019200000, 288.85282563770716],
        [1536105600000, 285.4857566471215],
        [1536192000000, 232.59576871683151],
        [1536278400000, 229.37495804862965],
        [1536364800000, 215.43223745188737],
        [1536451200000, 196.8653868522827],
        [1536537600000, 195.87733831990175],
        [1536624000000, 196.3615326706261],
        [1536710400000, 184.08223182704117],
        [1536796800000, 182.94557625329006],
        [1536883200000, 211.82672748652598],
        [1536969600000, 211.3835617204549],
        [1537056000000, 223.05308230390335],
        [1537142400000, 220.1415794346937],
        [1537228800000, 196.5766097002653],
        [1537315200000, 210.15069647518163],
        [1537401600000, 209.1425267776257],
        [1537488000000, 224.38097767293752],
        [1537574400000, 247.41001789452824],
        [1537660800000, 241.8437317592413],
        [1537747200000, 244.7847954629194],
        [1537833600000, 228.34091150907454],
        [1537920000000, 219.50434375691938],
        [1538006400000, 214.9793802543281],
        [1538092800000, 229.22195926025518],
        [1538179200000, 222.229239761749],
        [1538265600000, 231.73587329828854],
        [1538352000000, 232.78138524562493],
        [1538438400000, 231.1436846227436],
        [1538524800000, 225.79485382934473],
        [1538611200000, 220.47446029228064],
        [1538697600000, 222.19584779064576],
        [1538784000000, 228.03607024893918],
        [1538870400000, 225.06880731767052],
        [1538956800000, 225.65834864130284],
        [1539043200000, 229.52461502629828],
        [1539129600000, 227.9689077917784],
        [1539216000000, 225.66201617746165],
        [1539302400000, 192.0010841477226],
        [1539388800000, 197.06801594741614],
        [1539475200000, 201.05349968429928],
        [1539561600000, 196.3186984116144],
        [1539648000000, 212.2030694387269],
        [1539734400000, 212.4442865474299],
        [1539820800000, 211.08269302502703],
        [1539907200000, 204.31304892929978],
        [1539993600000, 203.97626253061992],
        [1540080000000, 206.88209866230514],
        [1540166400000, 206.30982679591034],
        [1540252800000, 205.03775490876436],
        [1540339200000, 204.08394558056767],
        [1540425600000, 203.52219877380867],
        [1540512000000, 202.35302275695466],
        [1540598400000, 203.38496856526396],
        [1540684800000, 203.18739632422128],
        [1540771200000, 204.7223407022085],
        [1540857600000, 195.7514045211852],
        [1540944000000, 197.44844958499],
        [1541030400000, 198.6390488336609],
        [1541116800000, 199.1226070207313],
        [1541203200000, 202.28275716666218],
        [1541289600000, 200.13622776255878],
        [1541376000000, 206.8144295995958],
        [1541462400000, 209.10887661978714],
        [1541548800000, 219.16088708430863],
        [1541635200000, 219.0157747507676],
        [1541721600000, 213.40763439234544],
        [1541808000000, 210.79061959752252],
        [1541894400000, 212.9658278769949],
        [1541980800000, 212.4733247441558],
        [1542067200000, 211.65690924875202],
        [1542153600000, 208.58229647385883],
        [1542240000000, 184.02255937246562],
        [1542326400000, 182.448242594831],
        [1542412800000, 176.02240566453227],
        [1542499200000, 175.6674163561008],
        [1542585600000, 177.72868960741215],
        [1542672000000, 150.39116276572497],
        [1542758400000, 131.476679735108],
        [1542844800000, 137.1730431934016],
        [1542931200000, 126.06638760845392],
        [1543017600000, 124.15029635415753],
        [1543104000000, 114.28793125942474],
        [1543190400000, 117.52527750634898],
        [1543276800000, 109.43098854688948],
        [1543363200000, 110.43494358866081],
        [1543449600000, 122.44642499081692],
        [1543536000000, 117.70538590366436],
        [1543622400000, 113.4028831522645],
        [1543708800000, 118.10075903673732],
        [1543795200000, 115.94428273243844],
        [1543881600000, 108.67684762425776],
        [1543968000000, 109.85191500097886],
        [1544054400000, 102.51104175852288],
        [1544140800000, 91.10115845588969],
        [1544227200000, 92.96795017189604],
        [1544313600000, 90.67825609691094],
        [1544400000000, 93.53012682199429],
        [1544486400000, 89.85371730794834],
        [1544572800000, 87.85041070425926],
        [1544659200000, 89.37790161622951],
        [1544745600000, 85.95872706359158],
        [1544832000000, 83.78596235654511],
        [1544918400000, 83.8335821320138],
        [1545004800000, 84.46584639727314],
        [1545091200000, 94.07306838255042],
        [1545177600000, 100.05002539321067],
        [1545264000000, 99.49155365807758],
        [1545350400000, 114.42918370038834],
        [1545436800000, 107.85318110517642],
        [1545523200000, 115.04582181530175],
        [1545609600000, 129.20650341412852],
        [1545696000000, 138.95781833191495],
        [1545782400000, 127.63707252348217],
        [1545868800000, 129.51137399263007],
        [1545955200000, 114.1013635506553],
        [1546041600000, 134.01993952035144],
        [1546128000000, 138.75426661197633],
        [1546214400000, 137.6316929791457],
        [1546300800000, 131.45872465603802],
        [1546387200000, 138.14480220535194],
        [1546473600000, 152.86045271321305],
        [1546560000000, 146.7305994341217],
        [1546646400000, 153.0565669307744],
        [1546732800000, 153.65187149457],
        [1546819200000, 154.69930839905126],
        [1546905600000, 149.89869762072908],
        [1546992000000, 148.14786553107623],
        [1547078400000, 148.7388798515161],
        [1547164800000, 126.62475249770274],
        [1547251200000, 124.6267970725248],
        [1547337600000, 123.75936261029351],
        [1547424000000, 114.76604584662175],
        [1547510400000, 127.40527329081972],
        [1547596800000, 119.81437324652917],
        [1547683200000, 122.24553850486993],
        [1547769600000, 121.92726666997898],
        [1547856000000, 119.1707391686563],
        [1547942400000, 122.92817578942176],
        [1548028800000, 117.6078729013454],
        [1548115200000, 115.82686433322588],
        [1548201600000, 117.81718880676009],
        [1548288000000, 116.41601969858114],
        [1548374400000, 116.24543567436463],
        [1548460800000, 115.14990605895021],
        [1548547200000, 115.33716687142687],
        [1548633600000, 112.51348924314217],
        [1548720000000, 105.35239189764611],
        [1548806400000, 104.59784628074063],
        [1548892800000, 108.27309466982854],
        [1548979200000, 106.33599581366398],
        [1549065600000, 107.00955827242754],
        [1549152000000, 108.77817692653159],
        [1549238400000, 107.19999697185268],
        [1549324800000, 107.61824539435413],
        [1549411200000, 107.31703853339418],
        [1549497600000, 104.62362623241391],
        [1549584000000, 104.26536439234141],
        [1549670400000, 119.33360463375858],
        [1549756800000, 119.48955517293045],
        [1549843200000, 124.88963479029995],
        [1549929600000, 120.97026174727318],
        [1550016000000, 122.54208894762355],
        [1550102400000, 122.09412965721424],
        [1550188800000, 120.59217399552682],
        [1550275200000, 121.57227847601175],
        [1550361600000, 122.93860835926709],
        [1550448000000, 133.88146961685993],
        [1550534400000, 145.32602670603126],
        [1550620800000, 143.65523417640586],
        [1550707200000, 148.8684346762975],
        [1550793600000, 145.2958232437471],
        [1550880000000, 148.04165031619752],
        [1550966400000, 157.67559560191677],
        [1551052800000, 134.31602869596247],
        [1551139200000, 137.8218665264574],
        [1551225600000, 135.99783785109707],
        [1551312000000, 134.84981713392082],
        [1551398400000, 135.4906837644075],
        [1551484800000, 134.95470877409352],
        [1551571200000, 132.40742658761687],
        [1551657600000, 130.5403477406027],
        [1551744000000, 125.6240824924571],
        [1551830400000, 136.5502476885833],
        [1551916800000, 137.32665152792868],
        [1552003200000, 136.78614228385894],
        [1552089600000, 133.5416931933968],
        [1552176000000, 137.1960003042231],
        [1552262400000, 135.62080210154824],
        [1552348800000, 132.38753404384025],
        [1552435200000, 133.28742563523164],
        [1552521600000, 131.6683444532404],
        [1552608000000, 132.14623792639355],
        [1552694400000, 136.4478648131974],
        [1552780800000, 141.08804416286097],
        [1552867200000, 138.55063028189403],
        [1552953600000, 137.65794893090973],
        [1553040000000, 138.5225166550466],
        [1553126400000, 139.0759731796369],
        [1553212800000, 135.05601072736965],
        [1553299200000, 136.0995360201361],
        [1553385600000, 137.19806866408575],
        [1553472000000, 136.05748300079634],
        [1553558400000, 133.8175199792062],
        [1553644800000, 134.02990438257248],
        [1553731200000, 139.63660664431762],
        [1553817600000, 137.81198852309657],
        [1553904000000, 143.14644309796884],
        [1553990400000, 142.28965262894044],
        [1554076800000, 141.586389044902],
        [1554163200000, 141.51455520152766],
        [1554249600000, 164.05054289558294],
        [1554336000000, 160.43283831978377],
        [1554422400000, 157.41222580794496],
        [1554508800000, 165.1385234713877],
        [1554595200000, 165.32515186458005],
        [1554681600000, 174.09052297795662],
        [1554768000000, 180.01192355336678],
        [1554854400000, 175.2941224274854],
        [1554940800000, 177.3253178087243],
        [1555027200000, 165.03710682659573],
        [1555113600000, 164.57194837218958],
        [1555200000000, 163.97160033492332],
        [1555286400000, 167.61800117879454],
        [1555372800000, 160.53249078494966],
        [1555459200000, 166.4845378681717],
        [1555545600000, 166.3272646030099],
        [1555632000000, 173.41362781077277],
        [1555718400000, 173.2282621688014],
        [1555804800000, 173.16628767058037],
        [1555891200000, 169.98788930945395],
        [1555977600000, 171.0434707895899],
        [1556064000000, 170.91296418483168],
        [1556150400000, 165.66966005027155],
        [1556236800000, 153.9304406219976],
        [1556323200000, 157.3757012457437],
        [1556409600000, 158.28919434200932],
        [1556496000000, 156.5951891487867],
        [1556582400000, 154.08028243009934],
        [1556668800000, 160.74469184721738],
        [1556755200000, 159.48931440238658],
        [1556841600000, 160.95718796104512],
        [1556928000000, 166.63211526803485],
        [1557014400000, 162.83631690744346],
        [1557100800000, 162.37013497277283],
        [1557187200000, 172.8283284518378],
        [1557273600000, 170.09589748059332],
        [1557360000000, 170.11063311538186],
        [1557446400000, 170.57549417946805],
        [1557532800000, 172.68209089135948],
        [1557619200000, 196.6901677286205],
        [1557705600000, 187.2720183708742],
        [1557792000000, 196.0761130134244],
        [1557878400000, 218.28460968764938],
        [1557964800000, 248.74500426984366],
        [1558051200000, 264.90012138907406],
        [1558137600000, 243.57049917993308],
        [1558224000000, 235.32720134101993],
        [1558310400000, 260.59698403060327],
        [1558396800000, 251.9614994995826],
        [1558483200000, 256.0830401834705],
        [1558569600000, 244.54946301361036],
        [1558656000000, 244.6460957973966],
        [1558742400000, 248.54526290535333],
        [1558828800000, 250.82036469745617],
        [1558915200000, 266.12218207843597],
        [1559001600000, 273.40508817754096],
        [1559088000000, 271.227867157993],
        [1559174400000, 268.7857564563058],
        [1559260800000, 255.37736314606394],
        [1559347200000, 268.12603920482644],
        [1559433600000, 264.72732428744166],
        [1559520000000, 269.9388294795906],
        [1559606400000, 251.75257532903564],
        [1559692800000, 240.23943365820054],
        [1559779200000, 246.82656909017393],
        [1559865600000, 249.9679117723965],
        [1559952000000, 250.63689323005363],
        [1560038400000, 244.62980571926562],
        [1560124800000, 232.04158416760484],
        [1560211200000, 247.25227622797598],
        [1560297600000, 245.08937853941526],
        [1560384000000, 260.5666340627198],
        [1560470400000, 255.1204266417473],
        [1560556800000, 263.92853843827874],
        [1560643200000, 268.1121928964469],
        [1560729600000, 269.0716935274996],
        [1560816000000, 273.12368845247914],
        [1560902400000, 264.78793107969506],
        [1560988800000, 269.1527997781388],
        [1561075200000, 272.334690986303],
        [1561161600000, 294.47562296593696],
        [1561248000000, 310.65499867231955],
        [1561334400000, 307.9472102635873],
        [1561420800000, 311.06822902414945],
        [1561507200000, 318.635944657736],
        [1561593600000, 337.3409578098138],
        [1561680000000, 295.8416528876357],
        [1561766400000, 309.949031472406],
        [1561852800000, 321.43279903776084],
        [1561939200000, 292.83887601724456],
        [1562025600000, 295.2558980709613],
        [1562112000000, 290.9596721071729],
        [1562198400000, 304.1705424011823],
        [1562284800000, 285.2194708797813],
        [1562371200000, 287.98591058443674],
        [1562457600000, 289.57713834106636],
        [1562544000000, 305.33625290070216],
        [1562630400000, 312.4663688290414],
        [1562716800000, 307.7796837520068],
        [1562803200000, 290.009967157574],
        [1562889600000, 268.6352130477999],
        [1562976000000, 275.80920843487644],
        [1563062400000, 270.314761247692],
        [1563148800000, 227.12207268042678],
        [1563235200000, 230.18019411198864],
        [1563321600000, 199.94052185387875],
        [1563408000000, 210.83853712737297],
        [1563494400000, 226.3035342868679],
        [1563580800000, 221.11374095039795],
        [1563667200000, 228.96480408491627],
        [1563753600000, 226.15340772656594],
        [1563840000000, 217.65256165832335],
        [1563926400000, 213.5021516080946],
        [1564012800000, 217.19777774847861],
        [1564099200000, 219.48779097276912],
        [1564185600000, 219.58243262777626],
        [1564272000000, 206.531772895918],
        [1564358400000, 211.229753661743],
        [1564444800000, 210.82351650905207],
        [1564531200000, 209.64626074112806],
        [1564617600000, 218.61308553620958],
        [1564704000000, 216.630420657716],
        [1564790400000, 217.79546207706508],
        [1564876800000, 221.41611875255478],
        [1564963200000, 221.98815294300547],
        [1565049600000, 232.82368350463972],
        [1565136000000, 227.02071107575],
        [1565222400000, 226.4011931773696],
        [1565308800000, 221.99588620712112],
        [1565395200000, 210.15361895773407],
        [1565481600000, 206.50462546838185],
        [1565568000000, 216.4855805517722],
        [1565654400000, 211.11314126304725],
        [1565740800000, 209.26573714761406],
        [1565827200000, 185.65530058955875],
        [1565913600000, 188.15278116773473],
        [1566000000000, 185.15324355177958],
        [1566086400000, 185.76004109383433],
        [1566172800000, 194.35736761658467],
        [1566259200000, 202.95975075968417],
        [1566345600000, 196.27274728566928],
        [1566432000000, 187.01618910896025],
        [1566518400000, 191.52119596772542],
        [1566604800000, 194.2093873568753],
        [1566691200000, 190.55575089343063],
        [1566777600000, 186.32236897942659],
        [1566864000000, 188.37725745413954],
        [1566950400000, 186.97068487781738],
        [1567036800000, 173.02703573027782],
        [1567123200000, 168.79916502828434],
        [1567209600000, 168.6708883739598],
        [1567296000000, 172.07296929356409],
        [1567382400000, 170.72754514461607],
        [1567468800000, 177.71647895327314],
        [1567555200000, 178.82647784328285],
        [1567641600000, 174.77627554812665],
        [1567728000000, 174.1938100520476],
        [1567814400000, 169.42286694793444],
        [1567900800000, 177.85258889110943],
        [1567987200000, 181.4839320522675],
        [1568073600000, 181.01465623196765],
        [1568160000000, 180.1493915298145],
        [1568246400000, 178.08294980618456],
        [1568332800000, 181.010836085851],
        [1568419200000, 181.2119450035023],
        [1568505600000, 188.30568158376607],
        [1568592000000, 189.25504633546134],
        [1568678400000, 197.60691638393752],
        [1568764800000, 209.21116304181604],
        [1568851200000, 210.24907372866744],
        [1568937600000, 221.74292599489385],
        [1569024000000, 217.95344382394939],
        [1569110400000, 215.34523960456264],
        [1569196800000, 211.61753840452246],
        [1569283200000, 201.00337752581868],
        [1569369600000, 168.67357964603033],
        [1569456000000, 170.28886769224317],
        [1569542400000, 166.21680322909975],
        [1569628800000, 173.90494900595044],
        [1569715200000, 174.07970541243265],
        [1569801600000, 169.6894747522977],
        [1569888000000, 179.75716197925505],
        [1569974400000, 180.63244082835516],
        [1570060800000, 180.57893058773016],
        [1570147200000, 176.85852555342686],
        [1570233600000, 175.86315365350248],
        [1570320000000, 176.2772565733896],
        [1570406400000, 170.44710628243018],
        [1570492800000, 180.14424760253544],
        [1570579200000, 180.78979326727546],
        [1570665600000, 193.33993781390976],
        [1570752000000, 191.04108656991255],
        [1570838400000, 180.55244905183102],
        [1570924800000, 179.94653567610214],
        [1571011200000, 181.2872472944888],
        [1571097600000, 186.61606753146776],
        [1571184000000, 180.74563079187547],
        [1571270400000, 175.05406935469742],
        [1571356800000, 177.4769186344818],
        [1571443200000, 172.88626058117322],
        [1571529600000, 171.85241043187662],
        [1571616000000, 175.21210857661558],
        [1571702400000, 174.35449608806442],
        [1571788800000, 171.4265472158501],
        [1571875200000, 161.5840435929357],
        [1571961600000, 160.67839136074096],
        [1572048000000, 181.45319156222183],
        [1572134400000, 179.53527008259897],
        [1572220800000, 183.58089983827585],
        [1572307200000, 181.86179987269554],
        [1572393600000, 190.50596091786502],
        [1572480000000, 183.13375148673262],
        [1572566400000, 182.75570124695298],
        [1572652800000, 183.064191451962],
        [1572739200000, 183.21568174708486],
        [1572825600000, 181.8244399622803],
        [1572912000000, 185.76433086001398],
        [1572998400000, 189.16575518025138],
        [1573084800000, 191.3004004540056],
        [1573171200000, 186.81653195658674],
        [1573257600000, 183.6736227613274],
        [1573344000000, 184.95814543107952],
        [1573430400000, 189.04498222352015],
        [1573516800000, 184.839873654298],
        [1573603200000, 186.8659398462863],
        [1573689600000, 187.84288849379362],
        [1573776000000, 184.78830689468228],
        [1573862400000, 180.16871140792335],
        [1573948800000, 182.63065416042576],
        [1574035200000, 183.9879638750186],
        [1574121600000, 178.3855993731378],
        [1574208000000, 175.8204845763924],
        [1574294400000, 174.80445727797053],
        [1574380800000, 161.15147727923085],
        [1574467200000, 149.92569206419535],
        [1574553600000, 151.96608493452732],
        [1574640000000, 140.24681084120735],
        [1574726400000, 146.34618694453036],
        [1574812800000, 147.57506848968612],
        [1574899200000, 152.615068348219],
        [1574985600000, 150.91034572010813],
        [1575072000000, 154.25530586508248],
        [1575158400000, 151.73585404476006],
        [1575244800000, 151.02315688624967],
        [1575331200000, 148.86726798663648],
        [1575417600000, 147.12795176955626],
        [1575504000000, 145.40170953026762],
        [1575590400000, 147.79749210977155],
        [1575676800000, 148.60569815279229],
        [1575763200000, 147.1457864691915],
        [1575849600000, 150.2924488050236],
        [1575936000000, 147.42926132729735],
        [1576022400000, 145.56867918491014],
        [1576108800000, 143.12297867633916],
        [1576195200000, 145.02592545573296],
        [1576281600000, 144.6187808332106],
        [1576368000000, 141.84486515951662],
        [1576454400000, 142.21086918955265],
        [1576540800000, 132.72274321129302],
        [1576627200000, 121.770107290006],
        [1576713600000, 132.74136111736684],
        [1576800000000, 128.0791538700726],
        [1576886400000, 128.2701941791178],
        [1576972800000, 126.93287325611249],
        [1577059200000, 132.17746965077103],
        [1577145600000, 128.16997170630987],
        [1577232000000, 127.72559890360142],
        [1577318400000, 125.01870585042732],
        [1577404800000, 125.61458431453704],
        [1577491200000, 126.32718018933488],
        [1577577600000, 128.0669874384903],
        [1577664000000, 134.3236471998861],
        [1577750400000, 131.4621363838881],
        [1577836800000, 129.18638529253852],
        [1577923200000, 130.4846850311141],
        [1578009600000, 127.04525801179804],
        [1578096000000, 133.70264861844188],
        [1578182400000, 134.1368826978575],
        [1578268800000, 135.00571364233375],
        [1578355200000, 143.80639795566535],
        [1578441600000, 143.019432264986],
        [1578528000000, 140.27393527388705],
        [1578614400000, 137.86055928182662],
        [1578700800000, 144.6047743464792],
        [1578787200000, 142.18233327497873],
        [1578873600000, 145.42287633530452],
        [1578960000000, 143.58796905036286],
        [1579046400000, 165.99143854583525],
        [1579132800000, 166.2530969048888],
        [1579219200000, 163.80590111423027],
        [1579305600000, 171.15621787671842],
        [1579392000000, 174.23837318258896],
        [1579478400000, 166.6271059505621],
        [1579564800000, 166.93852805776982],
        [1579651200000, 169.27993585839258],
        [1579737600000, 167.8265296405669],
        [1579824000000, 162.51937110634034],
        [1579910400000, 162.40947975163584],
        [1579996800000, 160.6736108617405],
        [1580083200000, 167.6474095857562],
        [1580169600000, 169.7364357354222],
        [1580256000000, 175.19000305345378],
        [1580342400000, 173.70688823620796],
        [1580428800000, 184.72621843141044],
        [1580515200000, 179.22910385240368],
        [1580601600000, 183.33693755210257],
        [1580688000000, 188.55064179431113],
        [1580774400000, 189.86176346298166],
        [1580860800000, 188.84220736948276],
        [1580947200000, 203.8593183747095],
        [1581033600000, 212.73341421352782],
        [1581120000000, 223.27631974791998],
        [1581206400000, 223.30077697426225],
        [1581292800000, 228.29226083217347],
        [1581379200000, 224.14699652028605],
        [1581465600000, 236.78534852972567],
        [1581552000000, 264.03276824299627],
        [1581638400000, 267.6704445042514],
        [1581724800000, 284.2318914741183],
        [1581811200000, 263.8999463915962],
        [1581897600000, 262.1562443168135],
        [1581984000000, 267.9356656159883],
        [1582070400000, 281.9457403055278],
        [1582156800000, 259.1838037480287],
        [1582243200000, 257.9883953048601],
        [1582329600000, 265.1644591338591],
        [1582416000000, 260.99654013152536],
        [1582502400000, 274.6312157785517],
        [1582588800000, 265.2421912203732],
        [1582675200000, 248.31738139395102],
        [1582761600000, 224.81050829744677],
        [1582848000000, 225.42971511683754],
        [1582934400000, 227.70528448376498],
        [1583020800000, 218.34835692985286],
        [1583107200000, 218.93979697447656],
        [1583193600000, 231.67944604592213],
        [1583280000000, 223.96011601810258],
        [1583366400000, 224.13487737180202],
        [1583452800000, 228.0809351016884],
        [1583539200000, 244.23846951978683],
        [1583625600000, 237.38079020191347],
        [1583712000000, 198.81964228768578],
        [1583798400000, 200.84687148188746],
        [1583884800000, 200.74903854851766],
        [1583971200000, 194.21794124025206],
        [1584057600000, 110.5978978308351],
        [1584144000000, 132.57285770180832],
        [1584230400000, 123.030844108903],
        [1584316800000, 124.60342553062202],
        [1584403200000, 110.99159845334059],
        [1584489600000, 117.21704138518466],
        [1584576000000, 117.7004246469663],
        [1584662400000, 136.81187578973578],
        [1584748800000, 131.96469397290025],
        [1584835200000, 131.94321845316688],
        [1584921600000, 122.5205987665882],
        [1585008000000, 135.58865798037067],
        [1585094400000, 138.77657127268222],
        [1585180800000, 136.23392142176775],
        [1585267200000, 138.76693759902926],
        [1585353600000, 130.2865618875773],
        [1585440000000, 131.42405214081145],
        [1585526400000, 125.31887451568916],
        [1585612800000, 132.36373895414985],
        [1585699200000, 133.2364468438201],
        [1585785600000, 136.21638557312852],
        [1585872000000, 141.4537053523866],
        [1585958400000, 141.26154326644877],
        [1586044800000, 144.200802681986],
        [1586131200000, 142.8508834648482],
        [1586217600000, 169.85504979336824],
        [1586304000000, 164.5160197107307],
        [1586390400000, 172.80356826747519],
        [1586476800000, 170.09510352387568],
        [1586563200000, 157.74015800780134],
        [1586649600000, 158.32787794181905],
        [1586736000000, 158.8638257195104],
        [1586822400000, 156.70135879140688],
        [1586908800000, 158.26715118298955],
        [1586995200000, 153.2228636800284],
        [1587081600000, 171.7759907508837],
        [1587168000000, 170.445890477055],
        [1587254400000, 187.14354411560592],
        [1587340800000, 180.05859654051866],
        [1587427200000, 170.70405868653958],
        [1587513600000, 170.45154288047368],
        [1587600000000, 182.2767576620704],
        [1587686400000, 184.59370248021838],
        [1587772800000, 187.34336861419638],
        [1587859200000, 194.11423682570447],
        [1587945600000, 197.22990444623989],
        [1588032000000, 196.46262535084685],
        [1588118400000, 197.15473156634306],
        [1588204800000, 215.54817266567386],
        [1588291200000, 205.55600541063674],
        [1588377600000, 211.96829534904975],
        [1588464000000, 213.94301121407548],
        [1588550400000, 210.02470947464775],
        [1588636800000, 206.8325342346069],
        [1588723200000, 205.22517973985205],
        [1588809600000, 200.25479450009442],
        [1588896000000, 212.29247620086525],
        [1588982400000, 211.68904835113835],
        [1589068800000, 210.4318181376063],
        [1589155200000, 188.13722615896586],
        [1589241600000, 185.67471809740294],
        [1589328000000, 189.30578181851274],
        [1589414400000, 199.36937254304945],
        [1589500800000, 203.21124699378416],
        [1589587200000, 194.27309778153102],
        [1589673600000, 200.58339090310048],
        [1589760000000, 206.76889403683904],
        [1589846400000, 214.35764164526782],
        [1589932800000, 213.68672021176667],
        [1590019200000, 209.9207882888633],
        [1590105600000, 198.9175157507187],
        [1590192000000, 206.22772963994552],
        [1590278400000, 206.4112069583191],
        [1590364800000, 200.69072858230618],
        [1590451200000, 203.81011834234977],
        [1590537600000, 200.96361540675866],
        [1590624000000, 207.84589389085414],
        [1590710400000, 219.59156405880168],
        [1590796800000, 220.49116734603788],
        [1590883200000, 242.70977026782677],
        [1590969600000, 232.33100303896677],
        [1591056000000, 248.04456658448655],
        [1591142400000, 237.26532012867918],
        [1591228800000, 244.1253366100756],
        [1591315200000, 243.70782131595863],
        [1591401600000, 240.24696898765103],
        [1591488000000, 242.02164036721499],
        [1591574400000, 244.22674439497985],
        [1591660800000, 246.21278326044262],
        [1591747200000, 243.8495266518988],
        [1591833600000, 247.5419715295243],
        [1591920000000, 232.00078536182022],
        [1592006400000, 237.59341087744238],
        [1592092800000, 238.29362910089247],
        [1592179200000, 232.11252047744935],
        [1592265600000, 231.0170115525279],
        [1592352000000, 235.28847948297147],
        [1592438400000, 233.8636184621818],
        [1592524800000, 231.68485732135008],
        [1592611200000, 228.9592128032193],
        [1592697600000, 228.8691487972198],
        [1592784000000, 227.79190590968685],
        [1592870400000, 243.36470999245498],
        [1592956800000, 243.07948223903702],
        [1593043200000, 234.5649846444734],
        [1593129600000, 232.64870349961575],
        [1593216000000, 229.6365260272679],
        [1593302400000, 221.0260852973422],
        [1593388800000, 225.12294616525017],
        [1593475200000, 228.20576377381224],
        [1593561600000, 225.59281496891896],
        [1593648000000, 230.66194359094902],
        [1593734400000, 226.57442434030435],
        [1593820800000, 225.1764054804081],
        [1593907200000, 229.09471646218861],
        [1593993600000, 227.8993750381917],
        [1594080000000, 241.52685187431663],
        [1594166400000, 238.96448054067793],
        [1594252800000, 246.85900786802029],
        [1594339200000, 241.97512857897888],
        [1594425600000, 240.95495378293265],
        [1594512000000, 239.10202554671358],
        [1594598400000, 241.7715226007061],
        [1594684800000, 239.63878681174097],
        [1594771200000, 240.25147066847245],
        [1594857600000, 238.66359720631723],
        [1594944000000, 233.837074562722],
        [1595030400000, 232.78147743826054],
        [1595116800000, 235.6811447764246],
        [1595203200000, 238.89523167867932],
        [1595289600000, 236.06009844538684],
        [1595376000000, 245.23373502634252],
        [1595462400000, 263.7624639660021],
        [1595548800000, 274.7236202591814],
        [1595635200000, 279.42705624758224],
        [1595721600000, 305.0381394111965],
        [1595808000000, 310.51799095173624],
        [1595894400000, 320.47619130968184],
        [1595980800000, 316.4067660520556],
        [1596067200000, 317.5390729161191],
        [1596153600000, 334.7419220365616],
        [1596240000000, 346.00350607288686],
        [1596326400000, 387.94242117124685],
        [1596412800000, 370.7323024312388],
        [1596499200000, 386.24744771780877],
        [1596585600000, 389.3830083498991],
        [1596672000000, 400.18436250317995],
        [1596758400000, 394.5417454611831],
        [1596844800000, 379.1351558498163],
        [1596931200000, 391.47843864211467],
        [1597017600000, 389.62293111291143],
        [1597104000000, 395.20991365718925],
        [1597190400000, 379.17476395275435],
        [1597276800000, 387.50053082397886],
        [1597363200000, 427.49026536796873],
        [1597449600000, 438.7625874702974],
        [1597536000000, 432.6546652389318],
        [1597622400000, 433.43186093211244],
        [1597708800000, 430.03250508972275],
        [1597795200000, 422.10665286599425],
        [1597881600000, 406.5744691821068],
        [1597968000000, 416.46977582279794],
        [1598054400000, 386.8238914189483],
        [1598140800000, 395.88501828908585],
        [1598227200000, 390.7891350477376],
        [1598313600000, 408.2691456317229],
        [1598400000000, 383.66214311829924],
        [1598486400000, 385.75249987492117],
        [1598572800000, 381.8376513210842],
        [1598659200000, 395.1382132386204],
        [1598745600000, 399.374543834064],
        [1598832000000, 428.2956791635218],
        [1598918400000, 435.6930232991007],
        [1599004800000, 475.6846642626859],
        [1599091200000, 439.35376021531494],
        [1599177600000, 383.6915311779978],
        [1599264000000, 387.9040290939166],
        [1599350400000, 334.46859301354317],
        [1599436800000, 352.99300214607035],
        [1599523200000, 351.8778602923998],
        [1599609600000, 337.84505193506857],
        [1599696000000, 351.2322670832359],
        [1599782400000, 367.63892881000675],
        [1599868800000, 373.9149059897517],
        [1599955200000, 387.72351629002264],
        [1600041600000, 365.695506306262],
        [1600128000000, 376.394561113407],
        [1600214400000, 364.6900450860686],
        [1600300800000, 365.13055081164816],
        [1600387200000, 388.7974562319938],
        [1600473600000, 383.17286599152135],
        [1600560000000, 385.4721234936851],
        [1600646400000, 370.9678951624775],
        [1600732800000, 341.27713087183076],
        [1600819200000, 343.9175471009491],
        [1600905600000, 321.0774351739628],
        [1600992000000, 348.8210199785493],
        [1601078400000, 351.3315670221176],
        [1601164800000, 354.2615819143104],
        [1601251200000, 357.0664364067552],
        [1601337600000, 354.4295835375506],
        [1601424000000, 359.4260895383702],
        [1601510400000, 359.59898475085777],
        [1601596800000, 353.1978481381707],
        [1601683200000, 345.9125682279143],
        [1601769600000, 345.99246319342933],
        [1601856000000, 352.4588065039611],
        [1601942400000, 353.6044273109067],
        [1602028800000, 340.6160218128092],
        [1602115200000, 341.4437964782809],
        [1602201600000, 350.0556765681245],
        [1602288000000, 365.33944481939363],
        [1602374400000, 370.4718109734285],
        [1602460800000, 374.4970150925736],
        [1602547200000, 387.25555835657167],
        [1602633600000, 381.16877389892255],
        [1602720000000, 379.21025376092774],
        [1602806400000, 377.15868107868175],
        [1602892800000, 365.9906423831468],
        [1602979200000, 368.6504703636898],
        [1603065600000, 378.0493653237596],
        [1603152000000, 379.6394773321801],
        [1603238400000, 368.2270142350454],
        [1603324800000, 391.16674217757225],
        [1603411200000, 413.2018903361906],
        [1603497600000, 408.88948864371923],
        [1603584000000, 411.95921775305027],
        [1603670400000, 406.157094148215],
        [1603756800000, 393.38290839224464],
        [1603843200000, 403.529837833562],
        [1603929600000, 388.87733988207765],
        [1604016000000, 386.4460129872924],
        [1604102400000, 382.9007710380906],
        [1604188800000, 385.8445887975079],
        [1604275200000, 394.9363420491719],
        [1604361600000, 383.8498034220404],
        [1604448000000, 387.616373722075],
        [1604534400000, 401.7330968324722],
        [1604620800000, 415.9277704309719],
        [1604707200000, 454.6515430984697],
        [1604793600000, 435.4183540833094],
        [1604880000000, 455.3583669721691],
        [1604966400000, 445.0512933420698],
        [1605052800000, 449.8161845012962],
        [1605139200000, 463.18087172032307],
        [1605225600000, 462.2056195794902],
        [1605312000000, 475.9690936576015],
        [1605398400000, 462.7183970607933],
        [1605484800000, 449.2068016397434],
        [1605571200000, 461.3711967052673],
        [1605657600000, 482.1983847235396],
        [1605744000000, 479.43766136247405],
        [1605830400000, 471.31795839441156],
        [1605916800000, 508.79221458524455],
        [1606003200000, 548.842116249849],
        [1606089600000, 560.5088874583059],
        [1606176000000, 608.2733109189421],
        [1606262400000, 602.6662623938871],
        [1606348800000, 568.2751530404493],
        [1606435200000, 518.4670452162002],
        [1606521600000, 517.5530264056014],
        [1606608000000, 537.3926120944484],
        [1606694400000, 574.7511196548439],
        [1606780800000, 612.2637856122055],
        [1606867200000, 589.5818443540976],
        [1606953600000, 598.760610095824],
        [1607040000000, 616.506661565682],
        [1607126400000, 571.1904316349736],
        [1607212800000, 595.9194139680376],
        [1607299200000, 601.9689349244918],
        [1607385600000, 592.3865066820175],
        [1607472000000, 554.329674302381],
        [1607558400000, 573.8953091099941],
        [1607644800000, 560.4542654076048],
        [1607731200000, 545.9828352633687],
        [1607817600000, 568.3587534073821],
        [1607904000000, 590.3244403029342],
        [1607990400000, 585.5418949360015],
        [1608076800000, 589.0668316450424],
        [1608163200000, 635.9618893796135],
        [1608249600000, 644.0652701929779],
        [1608336000000, 654.4204162965752],
        [1608422400000, 659.3188076372094],
        [1608508800000, 639.5154368778184],
        [1608595200000, 610.4270278980672],
        [1608681600000, 634.9797234431858],
        [1608768000000, 587.9588954992439],
        [1608854400000, 612.8796570505974],
        [1608940800000, 626.4567387784558],
        [1609027200000, 636.7423171944997],
        [1609113600000, 689.6598573269875],
        [1609200000000, 732.9570292975275],
        [1609286400000, 735.5908981625738],
        [1609372800000, 752.8559324490188],
        [1609459200000, 738.6169381520413],
        [1609545600000, 730.1473402196374],
        [1609632000000, 777.6960653039432],
        [1609718400000, 967.0005967288458],
        [1609804800000, 1025.6547675669035],
        [1609891200000, 1103.3582516225742],
        [1609977600000, 1208.5750928728885],
        [1610064000000, 1229.4713150147782],
        [1610150400000, 1223.7296883535275],
        [1610236800000, 1282.979575527323],
        [1610323200000, 1267.7310031512136],
        [1610409600000, 1092.9143378806064],
        [1610496000000, 1045.406815230412],
        [1610582400000, 1132.0155910164729],
        [1610668800000, 1216.9147884634556],
        [1610755200000, 1171.8600177046465],
        [1610841600000, 1234.6318700828049],
        [1610928000000, 1229.379698072007],
        [1611014400000, 1255.976691513213],
        [1611100800000, 1383.4840902277115],
        [1611187200000, 1385.8529958752583],
        [1611273600000, 1122.912432537305],
        [1611360000000, 1236.6834427536564],
        [1611446400000, 1231.176379704471],
        [1611532800000, 1392.5397634449737],
        [1611619200000, 1323.4295031020106],
        [1611705600000, 1355.233724240999],
        [1611792000000, 1253.141671558609],
        [1611878400000, 1328.773619066556],
        [1611964800000, 1380.2842591352105],
        [1612051200000, 1372.427228964234],
        [1612137600000, 1317.0474362291777],
        [1612224000000, 1368.6644548123775],
        [1612310400000, 1514.2251957516528],
        [1612396800000, 1661.000824367477],
        [1612483200000, 1587.8008991859367],
        [1612569600000, 1724.8569083393102],
        [1612656000000, 1683.941297865053],
        [1612742400000, 1608.6405479857196],
        [1612828800000, 1750.997553719215],
        [1612915200000, 1769.053533934599],
        [1613001600000, 1739.164209004836],
        [1613088000000, 1782.5088691218523],
        [1613174400000, 1841.1977507032907],
        [1613260800000, 1810.8425457323726],
        [1613347200000, 1804.9841464366498],
        [1613433600000, 1775.758351701864],
        [1613520000000, 1782.5753090505934],
        [1613606400000, 1845.5739221567521],
        [1613692800000, 1938.5694368074617],
        [1613779200000, 1969.9797177628186],
        [1613865600000, 1929.367692879665],
        [1613952000000, 1941.4267676797988],
        [1614038400000, 1788.6152102806425],
        [1614124800000, 1563.9246059011416],
        [1614211200000, 1628.391533792655],
        [1614297600000, 1468.8601037286776],
        [1614384000000, 1450.988746804119],
        [1614470400000, 1480.1295768477528],
        [1614556800000, 1416.661552837565],
        [1614643200000, 1570.3996896340539],
        [1614729600000, 1497.0891042633873],
        [1614816000000, 1579.4271687603093],
        [1614902400000, 1546.4996211167372],
        [1614988800000, 1539.0484290947375],
        [1615075200000, 1661.9279698998157],
        [1615161600000, 1727.463096224567],
        [1615248000000, 1837.5330305439452],
        [1615334400000, 1869.3311016255],
        [1615420800000, 1802.3110916881178],
        [1615507200000, 1826.0574768119818],
        [1615593600000, 1770.9361793697751],
        [1615680000000, 1927.7207203999014],
        [1615766400000, 1866.0715447008808],
        [1615852800000, 1791.047851830107],
        [1615939200000, 1808.5512173614395],
        [1616025600000, 1828.7548332342565],
        [1616112000000, 1780.1596459528505],
        [1616198400000, 1817.1329628084297],
        [1616284800000, 1817.8601431294944],
        [1616371200000, 1790.3780747526928],
        [1616457600000, 1686.8911971580417],
        [1616544000000, 1673.8591835627929],
        [1616630400000, 1581.631055931366],
        [1616716800000, 1587.2979641585232],
        [1616803200000, 1700.3668693581185],
        [1616889600000, 1713.8378756834459],
        [1616976000000, 1689.0367982059097],
        [1617062400000, 1817.626388088868],
        [1617148800000, 1840.294951985396],
        [1617235200000, 1915.8325358462234],
        [1617321600000, 1970.4711995232753],
        [1617408000000, 2134.101788297294],
        [1617494400000, 2016.6672472930366],
        [1617580800000, 2077.755212422407],
        [1617667200000, 2097.7963827792596],
        [1617753600000, 2115.0554517461082],
        [1617840000000, 1989.1480619041722],
        [1617926400000, 2081.3540625398964],
        [1618012800000, 2069.6677960855295],
        [1618099200000, 2142.7960653929085],
        [1618185600000, 2150.2651427927744],
        [1618272000000, 2142.476275865736],
        [1618358400000, 2304.3435163110134],
        [1618444800000, 2429.6615277515734],
        [1618531200000, 2514.1684155812304],
        [1618617600000, 2424.5538447081194],
        [1618704000000, 2345.266962776132],
        [1618790400000, 2245.7607533508544],
        [1618876800000, 2168.032726735651],
        [1618963200000, 2324.2846872281943],
        [1619049600000, 2373.501344298211],
        [1619136000000, 2426.0711497906045],
        [1619222400000, 2364.2312036020908],
        [1619308800000, 2212.8437976342047],
        [1619395200000, 2307.35532084471],
        [1619481600000, 2532.3868028851084],
        [1619568000000, 2647.158189660288],
        [1619654400000, 2748.7845851218613],
        [1619740800000, 2757.497552304977],
        [1619827200000, 2776.70371169874],
        [1619913600000, 2944.9169473185057],
        [1620000000000, 2953.2973480804735],
        [1620086400000, 3439.8550695192603],
        [1620172800000, 3245.663148933579],
        [1620259200000, 3524.562728476055],
        [1620345600000, 3495.0758690360576],
        [1620432000000, 3493.534500000208],
        [1620518400000, 3912.7429165968124],
        [1620604800000, 3932.754068170969],
        [1620691200000, 3979.6086519212827],
        [1620777600000, 4182.790285752286],
        [1620864000000, 3906.108903329409],
        [1620950400000, 3750.3415950592753],
        [1621036800000, 4088.73170832043],
        [1621123200000, 3659.9218442136184],
        [1621209600000, 3602.0048969299023],
        [1621296000000, 3288.2298872378637],
        [1621382400000, 3399.0492796569383],
        [1621468800000, 2505.014945945662],
        [1621555200000, 2778.279660560299],
        [1621641600000, 2419.1032171150196],
        [1621728000000, 2306.371266867117],
        [1621814400000, 2120.0373745099428],
        [1621900800000, 2640.1596323488234],
        [1621987200000, 2695.4778071496326],
        [1622073600000, 2882.483407624011],
        [1622160000000, 2742.990862697615],
        [1622246400000, 2433.328865728611],
        [1622332800000, 2294.626285512794],
        [1622419200000, 2395.8532280027384],
        [1622505600000, 2708.429865793973],
        [1622592000000, 2632.6565998696506],
        [1622678400000, 2717.1540367097723],
        [1622764800000, 2858.27670209791],
        [1622851200000, 2694.4976666790503],
        [1622937600000, 2624.7689150594238],
        [1623024000000, 2711.5479390111605],
        [1623110400000, 2580.5355800598445],
        [1623196800000, 2528.022300878691],
        [1623283200000, 2620.6253893038547],
        [1623369600000, 2486.600068788676],
        [1623456000000, 2356.6347741896757],
        [1623542400000, 2379.991747950219],
        [1623628800000, 2517.771695525423],
        [1623715200000, 2587.381610979154],
        [1623801600000, 2561.1883309320033],
        [1623888000000, 2365.872786190649],
        [1623974400000, 2380.744519633922],
        [1624060800000, 2231.554315497782],
        [1624147200000, 2176.308536317709],
        [1624233600000, 2251.5605593660407],
        [1624320000000, 1900.1222998935382],
        [1624406400000, 1875.3576944712033],
        [1624492800000, 1971.1059779809414],
        [1624579200000, 1990.0761511910587],
        [1624665600000, 1833.4631609981645],
        [1624752000000, 1817.047665540675],
        [1624838400000, 1973.9268648383472],
        [1624924800000, 2087.5187237874175],
        [1625011200000, 2169.400067865984],
        [1625097600000, 2279.354161419647],
        [1625184000000, 2121.657900633364],
        [1625270400000, 2157.880584866453],
        [1625356800000, 2228.532398568573],
        [1625443200000, 2329.004750998825],
        [1625529600000, 2217.3018530598792],
        [1625616000000, 2320.6549288317],
        [1625702400000, 2317.23694270242],
        [1625788800000, 2126.4424673902267],
        [1625875200000, 2156.5809569525845],
        [1625961600000, 2123.0585974934124],
        [1626048000000, 2144.0142572722566],
        [1626134400000, 2042.4991866143628],
        [1626220800000, 1944.3950166372085],
        [1626307200000, 1997.6633136705987],
        [1626393600000, 1910.7269516309964],
        [1626480000000, 1874.2002496808186],
        [1626566400000, 1899.8427500663568],
        [1626652800000, 1905.723382843169],
        [1626739200000, 1824.929392091902],
        [1626825600000, 1794.973425186835],
        [1626912000000, 2003.7239214521073],
        [1626998400000, 2027.5334479167045],
        [1627084800000, 2117.154607794504],
        [1627171200000, 2183.627414557696],
        [1627257600000, 2209.4993601030114],
        [1627344000000, 2230.212067673471],
        [1627430400000, 2292.579636803809],
        [1627516800000, 2299.689405936291],
        [1627603200000, 2383.443259961735],
        [1627689600000, 2462.3999826480103],
        [1627776000000, 2541.674599365905],
        [1627862400000, 2555.408178513038],
        [1627948800000, 2611.6730699481845],
        [1628035200000, 2521.268474889094],
        [1628121600000, 2724.5322427136302],
        [1628208000000, 2821.649692746136],
        [1628294400000, 2888.7322742752317],
        [1628380800000, 3151.2175169176753],
        [1628467200000, 3012.3085592702105],
        [1628553600000, 3163.0646551948857],
        [1628640000000, 3147.842995401753],
        [1628726400000, 3166.6472179679286],
        [1628812800000, 3048.4126816864195],
        [1628899200000, 3323.19799054098],
        [1628985600000, 3268.5481770314927],
        [1629072000000, 3309.7549104132368],
        [1629158400000, 3153.583683763619],
        [1629244800000, 3007.1440271712713],
        [1629331200000, 3037.230251196439],
        [1629417600000, 3144.8184367851927],
        [1629504000000, 3276.9698366433354],
        [1629590400000, 3224.0004601521537],
        [1629676800000, 3243.4863583537394],
        [1629763200000, 3320.4091697810586],
        [1629849600000, 3177.6637963046905],
        [1629936000000, 3231.4414517287996],
        [1630022400000, 3122.971797237849],
        [1630108800000, 3267.539434750998],
        [1630195200000, 3245.430222387629],
        [1630281600000, 3233.383151693551],
        [1630368000000, 3232.7338632854617],
        [1630454400000, 3440.5623358869034],
        [1630540800000, 3790.6139962215557],
        [1630627200000, 3793.3007433464186],
        [1630713600000, 3936.1633916244464],
        [1630800000000, 3894.937511605067],
        [1630886400000, 3950.270344526561],
        [1630972800000, 3943.256785301908],
        [1631059200000, 3440.3417568365676],
        [1631145600000, 3496.859022996073],
        [1631232000000, 3435.97993265133],
        [1631318400000, 3209.915696090501],
        [1631404800000, 3268.1041621348804],
        [1631491200000, 3417.839366766938],
        [1631577600000, 3301.186776828727],
        [1631664000000, 3425.2508733748823],
        [1631750400000, 3595.9625714292315],
        [1631836800000, 3573.3075160249905],
        [1631923200000, 3412.177182421673],
        [1632009600000, 3427.58426153173],
        [1632096000000, 3335.884886514119],
        [1632182400000, 2977.3226793404747],
        [1632268800000, 2744.1109995297],
        [1632355200000, 3074.119761245824],
        [1632441600000, 3159.269865581771],
        [1632528000000, 2930.7427060738946],
        [1632614400000, 2946.9708461401983],
        [1632700800000, 3063.31634471348],
        [1632787200000, 2939.742282853775],
        [1632873600000, 2798.9844172601042],
        [1632960000000, 2855.6117305265157],
        [1633046400000, 3013.4932320320772],
        [1633132800000, 3305.107041443724],
        [1633219200000, 3393.9242492696653],
        [1633305600000, 3426.387080851377],
        [1633392000000, 3390.310407759615],
        [1633478400000, 3520.3422566393974],
        [1633564800000, 3592.1761295000256],
        [1633651200000, 3594.918357967647],
        [1633737600000, 3558.549577083409],
        [1633824000000, 3588.0809215501645],
        [1633910400000, 3431.0193069150096],
        [1633996800000, 3537.840087124068],
        [1634083200000, 3498.1052921206597],
        [1634169600000, 3605.6503342981764],
        [1634256000000, 3794.516888224821],
        [1634342400000, 3885.641764529699],
        [1634428800000, 3854.498461449342],
        [1634515200000, 3854.223686940845],
        [1634601600000, 3752.61872738495],
        [1634688000000, 3884.5872952429017],
        [1634774400000, 4170.107932886265],
        [1634860800000, 4074.8601575202883],
        [1634947200000, 3990.711976433473],
        [1635033600000, 4179.44229816945],
        [1635120000000, 4094.9389934187225],
        [1635206400000, 4230.208371594039],
        [1635292800000, 4152.570288731245],
        [1635379200000, 3944.090861781322],
        [1635465600000, 4288.09721878663],
        [1635552000000, 4422.940535593754],
        [1635638400000, 4324.6099257118785],
        [1635724800000, 4292.04047236198],
        [1635811200000, 4330.553151595248],
        [1635897600000, 4596.59309058429],
        [1635984000000, 4607.699273078144],
        [1636070400000, 4550.014435142697],
        [1636156800000, 4494.80276090967],
        [1636243200000, 4527.104154150622],
        [1636329600000, 4626.6292884486975],
        [1636416000000, 4815.004634322234],
        [1636502400000, 4742.08091060724],
        [1636588800000, 4641.528764849704],
        [1636675200000, 4732.9244503493555],
        [1636761600000, 4685.10635551733],
        [1636848000000, 4666.498498194288],
        [1636934400000, 4652.947394238871],
        [1637020800000, 4583.211418759011],
        [1637107200000, 4243.352906802173],
        [1637193600000, 4302.804958962834],
        [1637280000000, 3993.846595161859],
        [1637366400000, 4317.603195646166],
        [1637452800000, 4436.192766930451],
        [1637539200000, 4319.361566856902],
        [1637625600000, 4101.062876490145],
        [1637712000000, 4355.420957796475],
        [1637798400000, 4269.437219728043],
        [1637884800000, 4515.843300142739],
        [1637971200000, 4048.3128440761725],
        [1638057600000, 4084.0884856959556],
        [1638144000000, 4290.091862536401],
        [1638230400000, 4444.528279821561],
        [1638316800000, 4637.121616831405],
        [1638403200000, 4589.610617539151],
        [1638489600000, 4519.441028028784],
        [1638576000000, 4240.155516808957],
        [1638662400000, 4101.656791810778],
        [1638748800000, 4198.572874562462],
        [1638835200000, 4347.615630598069],
        [1638921600000, 4310.566646424531],
        [1639008000000, 4431.540647139759],
        [1639094400000, 4153.333310724186],
        [1639180800000, 3918.200743402953],
        [1639267200000, 4079.814930569866],
        [1639353600000, 4135.841510137412],
        [1639440000000, 3782.8952622347147],
        [1639526400000, 3858.1644681806974],
        [1639612800000, 4015.72254331389],
        [1639699200000, 3971.5597663421136],
        [1639785600000, 3886.7473617182886],
        [1639872000000, 3966.4253516705508],
        [1639958400000, 3928.8417238716206],
        [1640044800000, 3950.4823919860964],
        [1640131200000, 4036.549717813428],
        [1640217600000, 3992.5945766373156],
        [1640304000000, 4113.529931874606],
        [1640390400000, 4055.1173669313243],
        [1640476800000, 4110.571870853741],
        [1640563200000, 4075.03161894955],
        [1640649600000, 4045.051350119327],
        [1640736000000, 3807.3603674991996],
        [1640822400000, 3644.405516878227],
        [1640908800000, 3714.945456115339],
        [1640995200000, 3686.4025423019352],
        [1641081600000, 3780.315874327848],
        [1641168000000, 3835.3959914939574],
        [1641254400000, 3769.4049401148714],
        [1641340800000, 3794.908130100449],
        [1641427200000, 3556.9062274078738],
        [1641513600000, 3416.8260086159135],
        [1641600000000, 3201.79457434796],
        [1641686400000, 3101.0409016141402],
        [1641772800000, 3156.967262159481],
        [1641859200000, 3086.7290024387257],
        [1641945600000, 3246.413723028553],
        [1642032000000, 3378.0358951715943],
        [1642118400000, 3256.7588661330324],
        [1642204800000, 3311.986061274383],
        [1642291200000, 3331.246655159985],
        [1642377600000, 3356.2954438590405],
        [1642464000000, 3216.592980619574],
        [1642550400000, 3166.4539115046164],
        [1642636800000, 3097.0214660626802],
        [1642723200000, 3015.588777543836],
        [1642809600000, 2564.3433423740635],
        [1642896000000, 2407.377851608285],
        [1642982400000, 2537.836727861043],
        [1643068800000, 2447.831512095295],
        [1643155200000, 2465.21922984942],
        [1643241600000, 2470.4304120157067],
        [1643328000000, 2411.863855325421],
        [1643414400000, 2551.1110735194716],
        [1643500800000, 2603.603872339056],
        [1643587200000, 2610.1824055910874],
        [1643673600000, 2689.2208672204492],
        [1643760000000, 2797.434855273681],
        [1643846400000, 2690.319739476765],
        [1643932800000, 2667.256728215686],
        [1644019200000, 2993.0994395135976],
        [1644105600000, 3018.6733981571506],
        [1644192000000, 3062.8058084507857],
        [1644278400000, 3149.953285509783],
        [1644364800000, 3127.438106740214],
        [1644451200000, 3240.9210444877285],
        [1644537600000, 3081.911501840844],
        [1644624000000, 2930.5631692063735],
        [1644710400000, 2918.0853674291184],
        [1644796800000, 2889.235678281073],
        [1644883200000, 2935.6454998606846],
        [1644969600000, 3179.3002065350443],
        [1645056000000, 3128.640780542462],
        [1645142400000, 2881.613122162859],
        [1645228800000, 2792.3038477186237],
        [1645315200000, 2768.9716520348597],
        [1645401600000, 2632.49139911745],
        [1645488000000, 2574.513383778016],
        [1645574400000, 2644.153655546016],
        [1645660800000, 2594.700959110155],
        [1645747200000, 2599.9335696870216],
        [1645833600000, 2771.890509646429],
        [1645920000000, 2785.3389569017427],
        [1646006400000, 2629.48312082149],
        [1646092800000, 2919.294962425658],
        [1646179200000, 2977.276039756805],
        [1646265600000, 2953.315776286441],
        [1646352000000, 2836.9063366005576],
        [1646438400000, 2619.356137634489],
        [1646524800000, 2668.70597865687],
        [1646611200000, 2558.359874049847],
        [1646697600000, 2498.658864965217],
        [1646784000000, 2576.6271539947625],
        [1646870400000, 2731.037685293996],
        [1646956800000, 2611.4647225047097],
        [1647043200000, 2562.8323535359655],
        [1647129600000, 2579.4581356883637],
        [1647216000000, 2518.492845270615],
        [1647302400000, 2591.5420409591425],
        [1647388800000, 2620.363852420936],
        [1647475200000, 2771.759457292368],
        [1647561600000, 2817.401074966818],
        [1647648000000, 2945.7459101862714],
        [1647734400000, 2947.226158224087],
        [1647820800000, 2860.641960901919],
        [1647907200000, 2896.5869324317464],
        [1647993600000, 2969.78498365825],
        [1648080000000, 3028.8352577107285],
        [1648166400000, 3106.657023584939],
        [1648252800000, 3106.072054158695],
        [1648339200000, 3140.8757105064406],
        [1648425600000, 3285.1730967305425],
        [1648512000000, 3328.9341246867148],
        [1648598400000, 3401.184431040135],
        [1648684800000, 3383.7887617814154],
        [1648771200000, 3283.3028425717257],
        [1648857600000, 3451.1960908672754],
        [1648944000000, 3440.3362384891684],
        [1649030400000, 3521.5847247785246],
        [1649116800000, 3520.9671175043636],
        [1649203200000, 3422.9698837547116],
        [1649289600000, 3171.371104498954],
        [1649376000000, 3232.834988999582],
        [1649462400000, 3192.0250912893603],
        [1649548800000, 3265.938067729151],
        [1649635200000, 3219.159553453764],
        [1649721600000, 2992.7021064252435],
        [1649808000000, 3038.209421996366],
        [1649894400000, 3121.399823462041],
        [1649980800000, 3023.417211885316],
        [1650067200000, 3045.42807480128],
        [1650153600000, 3066.3580141449797],
        [1650240000000, 2995.719421019825],
        [1650326400000, 3061.8905711469047],
        [1650412800000, 3104.688447526111],
        [1650499200000, 3079.6764776018495],
        [1650585600000, 2987.4888760341605],
        [1650672000000, 2967.085285238213],
        [1650758400000, 2940.6879777714407],
        [1650844800000, 2922.901865199808],
        [1650931200000, 3008.3363470853],
        [1651017600000, 2806.748835957046],
        [1651104000000, 2889.5922230230817],
        [1651190400000, 2932.455083612903],
        [1651276800000, 2817.4898821916195],
        [1651363200000, 2738.1741359527714],
        [1651449600000, 2832.513106895354],
        [1651536000000, 2861.3723755560436],
        [1651622400000, 2786.0472478011084],
        [1651708800000, 2942.052313122658],
        [1651795200000, 2753.936566546688],
        [1651881600000, 2699.7072470893745],
        [1651968000000, 2641.229106940243],
        [1652054400000, 2517.8299224886478],
        [1652140800000, 2249.8909622207443],
        [1652227200000, 2344.797715643986],
        [1652313600000, 2080.910243657776],
        [1652400000000, 1966.6991711336661],
        [1652486400000, 2010.214051125259],
        [1652572800000, 2064.229357512243],
        [1652659200000, 2147.047447880575],
        [1652745600000, 2025.8886983912162],
        [1652832000000, 2095.178884796724],
        [1652918400000, 1915.1771232664505],
        [1653004800000, 2023.8482593608173],
        [1653091200000, 1963.9909395294865],
        [1653177600000, 1978.1471325624789],
        [1653264000000, 2046.6463496545232],
        [1653350400000, 1974.5811944790914],
        [1653436800000, 1979.770545006472],
        [1653523200000, 1944.8428445072448],
        [1653609600000, 1807.9694742881136],
        [1653696000000, 1724.8757340323443],
        [1653782400000, 1798.6948545788846],
        [1653868800000, 1814.9831598025937],
        [1653955200000, 1995.9364841552344],
        [1654041600000, 1944.078766836179],
        [1654128000000, 1828.8926546074292],
        [1654214400000, 1833.089840845753],
        [1654300800000, 1776.979907458187],
        [1654387200000, 1804.2616695112486],
        [1654473600000, 1805.3313949894373],
        [1654560000000, 1860.1813068416047],
        [1654646400000, 1818.3877119829308],
        [1654732800000, 1794.539625671828],
        [1654819200000, 1788.4182866616045],
        [1654905600000, 1663.844367412088],
        [1654992000000, 1530.0386174317211],
        [1655078400000, 1454.6867601728409],
        [1655164800000, 1205.5952855404787],
        [1655251200000, 1214.8662649132034],
        [1655337600000, 1230.3643353556051],
        [1655424000000, 1068.6035748646073],
        [1655510400000, 1087.8139244584365],
        [1655596800000, 995.2525692707909],
        [1655683200000, 1125.7540917942745],
        [1655769600000, 1132.0543935828048],
        [1655856000000, 1124.5613920165545],
        [1655942400000, 1051.0740681589236],
        [1656028800000, 1144.4807534808065],
        [1656115200000, 1229.3040854466553],
        [1656201600000, 1244.2862044953927],
        [1656288000000, 1201.237790641909],
        [1656374400000, 1194.2245084746116],
        [1656460800000, 1143.5335357209958],
        [1656547200000, 1098.9055176105344],
        [1656633600000, 1057.6309990608017],
        [1656720000000, 1068.8194401301964],
        [1656806400000, 1068.5415025999594],
        [1656892800000, 1074.6073175787587],
        [1656979200000, 1153.3483147880897],
        [1657065600000, 1134.017069659217],
        [1657152000000, 1188.72564292538],
        [1657238400000, 1240.3784056617153],
        [1657324800000, 1233.5146792602989],
        [1657411200000, 1216.8497068484603],
        [1657497600000, 1169.012707661018],
        [1657584000000, 1097.4494380477286],
        [1657670400000, 1040.7971455029597],
        [1657756800000, 1112.9207827012565],
        [1657843200000, 1191.1308371103116],
        [1657929600000, 1234.0991389763224],
        [1658016000000, 1355.04564021949],
        [1658102400000, 1344.7202840019017],
        [1658188800000, 1570.6589588642053],
        [1658275200000, 1542.6298214332235],
        [1658361600000, 1527.4139307593093],
        [1658448000000, 1576.8252394976823],
        [1658534400000, 1536.124922238913],
        [1658620800000, 1552.4964891908746],
        [1658707200000, 1601.2079670127046],
        [1658793600000, 1450.4822852139405],
        [1658880000000, 1438.9880382230651],
        [1658966400000, 1636.9491814677851],
        [1659052800000, 1723.5485206790597],
        [1659139200000, 1739.0421771356112],
        [1659225600000, 1696.7850982136515],
        [1659312000000, 1682.011373614746],
        [1659398400000, 1636.0185563573557],
        [1659484800000, 1638.3786826572205],
        [1659571200000, 1621.3413236364065],
        [1659657600000, 1612.4189067269824],
        [1659744000000, 1725.3826270211505],
        [1659830400000, 1693.2965918360276],
        [1659916800000, 1699.0064178886953],
        [1660003200000, 1775.7013555270828],
        [1660089600000, 1698.9661290126492],
        [1660176000000, 1852.8785552656473],
        [1660262400000, 1881.4274054352998],
        [1660348800000, 1959.3309247187408],
        [1660435200000, 1982.4118280514233],
        [1660521600000, 1936.7011636052605],
        [1660608000000, 1908.2776419059185],
        [1660694400000, 1880.6001012594395],
        [1660780800000, 1834.8264526426372],
        [1660867200000, 1850.1112910473696],
        [1660953600000, 1618.5325042633535],
        [1661040000000, 1579.4157230953704],
        [1661126400000, 1624.149982884847],
        [1661212800000, 1622.30703457785],
        [1661299200000, 1665.7919073919315],
        [1661385600000, 1657.7936424663385],
        [1661472000000, 1697.0769719636442],
        [1661558400000, 1510.1891114840514],
        [1661644800000, 1496.0554659420902],
        [1661731200000, 1438.7470503278116],
        [1661817600000, 1555.1774560415913],
        [1661904000000, 1525.400116232047],
        [1661990400000, 1552.5629662281337],
        [1662076800000, 1588.9803510869067],
        [1662163200000, 1575.1766307276075],
        [1662249600000, 1555.6740772078124],
        [1662336000000, 1578.9493013433012],
        [1662422400000, 1617.9354554854337],
        [1662508800000, 1564.5589540302462],
        [1662595200000, 1629.8832311636493],
        [1662681600000, 1635.9321760130483],
        [1662768000000, 1717.0769569840472],
        [1662854400000, 1779.5190288833046],
        [1662940800000, 1761.317355880691],
        [1663027200000, 1710.1642908515214],
        [1663113600000, 1573.2052136951713],
        [1663200000000, 1635.3682061921256],
        [1663286400000, 1471.999592516298],
        [1663372800000, 1432.215807478607],
        [1663459200000, 1471.2039553630448],
        [1663545600000, 1335.8525523850524],
        [1663632000000, 1379.920704526258],
        [1663718400000, 1324.1905646155858],
        [1663804800000, 1252.7745621685783],
        [1663891200000, 1330.7927929218301],
        [1663977600000, 1329.558806730321],
        [1664064000000, 1318.099347021849],
        [1664150400000, 1294.0750952797787],
        [1664236800000, 1335.1212051991713],
        [1664323200000, 1331.1501769338627],
        [1664409600000, 1340.0230594816517],
        [1664496000000, 1335.7389706335669],
        [1664582400000, 1329.1460320707963],
        [1664668800000, 1311.2633244162857],
        [1664755200000, 1277.901345279672],
        [1664841600000, 1323.5040179722914],
        [1664928000000, 1363.2371821945037],
        [1665014400000, 1352.5865243024869],
        [1665100800000, 1351.4273691979538],
        [1665187200000, 1324.8947380290601],
        [1665273600000, 1315.857799905668],
        [1665360000000, 1322.2697552193713],
        [1665446400000, 1291.0816596695552],
        [1665532800000, 1279.8833075472612],
        [1665619200000, 1294.2710513882173],
        [1665705600000, 1288.1640147020812],
        [1665792000000, 1297.6276053223842],
        [1665878400000, 1275.6937773947975],
        [1665964800000, 1306.943118828839],
        [1666051200000, 1333.4408776301655],
        [1666137600000, 1311.8067255158232],
        [1666224000000, 1284.654933702257],
        [1666310400000, 1282.0426467759612],
        [1666396800000, 1299.8019052506386],
        [1666483200000, 1313.767778723105],
        [1666569600000, 1364.4868713063736],
        [1666656000000, 1343.334491985092],
        [1666742400000, 1462.0489375629863],
        [1666828800000, 1568.7475855708392],
        [1666915200000, 1514.8635252533977],
        [1667001600000, 1555.0292135813938],
        [1667088000000, 1619.4295836277443],
        [1667174400000, 1591.0517734961002],
        [1667260800000, 1572.979514885975],
        [1667347200000, 1580.3787334402593],
        [1667433600000, 1520.6383643789845],
        [1667520000000, 1531.112630741574],
        [1667606400000, 1644.1928578089398],
        [1667692800000, 1626.8064304933644],
        [1667779200000, 1572.9477769721586],
        [1667865600000, 1569.9886360033397],
        [1667952000000, 1334.2879331082395],
        [1668038400000, 1095.1839771132936],
        [1668124800000, 1301.5538803074423],
        [1668211200000, 1293.9115948871518],
        [1668297600000, 1255.6010507578437],
        [1668384000000, 1223.0064433283683],
        [1668470400000, 1241.997092728311],
        [1668556800000, 1252.7067308366848],
        [1668643200000, 1217.7020892163014],
        [1668729600000, 1201.7898419641292],
        [1668816000000, 1213.49631006335],
        [1668902400000, 1218.8936571112463],
        [1668988800000, 1144.2531525085535],
        [1669075200000, 1110.2882130124592],
        [1669161600000, 1135.175811894246],
        [1669248000000, 1184.4617581029652],
        [1669334400000, 1204.2078028331202],
        [1669420800000, 1198.9755884889066],
        [1669507200000, 1205.6579602157142],
        [1669593600000, 1196.1962195659255],
        [1669680000000, 1170.115741471507],
        [1669766400000, 1216.936420693739],
        [1669852800000, 1298.94077037352],
        [1669939200000, 1276.6393714030964],
        [1670025600000, 1293.9480231782143],
        [1670112000000, 1243.0408423289737],
        [1670198400000, 1282.4164481394064],
        [1670284800000, 1259.7575748565346],
        [1670371200000, 1271.9354761263946],
        [1670457600000, 1233.6250483990402],
        [1670544000000, 1281.210637067264],
        [1670630400000, 1264.4773467215684],
        [1670716800000, 1264.7922793495136],
        [1670803200000, 1264.317985551174],
        [1670889600000, 1275.332421155143],
        [1670976000000, 1319.413007743119],
        [1671062400000, 1308.4001523403851],
        [1671148800000, 1263.7464240856877],
        [1671235200000, 1165.9737772823325],
        [1671321600000, 1189.4275215641987],
        [1671408000000, 1186.7779210362507],
        [1671494400000, 1166.9895905156618],
        [1671580800000, 1217.8888916641765],
        [1671667200000, 1213.3431612255224],
        [1671753600000, 1217.8525497108176],
        [1671840000000, 1220.701744919786],
        [1671926400000, 1221.3595303484499],
        [1672012800000, 1219.286343216304],
        [1672099200000, 1226.2534148310056],
        [1672185600000, 1211.819562063861],
        [1672272000000, 1188.7286638821608],
        [1672358400000, 1201.5431060594274],
        [1672444800000, 1199.1104971711857],
        [1672531200000, 1196.6130648084502],
        [1672617600000, 1200.65921690107],
        [1672704000000, 1214.3789459253508],
        [1672790400000, 1214.760969419338],
        [1672876800000, 1256.4366037834864],
        [1672963200000, 1250.7922522355914],
        [1673049600000, 1270.028610178057],
        [1673136000000, 1264.8369433837202],
        [1673222400000, 1284.5754041342987],
        [1673308800000, 1321.676571173056],
        [1673395200000, 1336.017071771777],
        [1673481600000, 1389.7716620941067],
        [1673568000000, 1417.134908743914],
        [1673654400000, 1453.3769102089225],
        [1673740800000, 1549.111474081304],
        [1673827200000, 1551.324517175301],
        [1673913600000, 1577.725890482971],
        [1674000000000, 1569.5308332272923],
        [1674086400000, 1516.555475473704],
        [1674172800000, 1550.5088875585636],
        [1674259200000, 1661.3349193405402],
        [1674345600000, 1625.9093109886562],
        [1674432000000, 1630.8448549407967],
        [1674518400000, 1632.9617267135266],
        [1674604800000, 1557.0597608721732],
        [1674691200000, 1614.6771850629295],
        [1674777600000, 1602.8469492523193],
        [1674864000000, 1598.4701935450053],
        [1674950400000, 1573.0634394330648],
        [1675036800000, 1646.5159900930198],
        [1675123200000, 1568.64771349631],
        [1675209600000, 1586.5395765836909],
        [1675296000000, 1642.857168158598],
        [1675382400000, 1648.6796840768716],
        [1675468800000, 1665.4278712238624],
        [1675555200000, 1667.2716925637008],
        [1675641600000, 1631.3725233636533],
        [1675728000000, 1617.144163289206],
        [1675814400000, 1672.8226777758136],
        [1675900800000, 1651.4083786023796],
        [1675987200000, 1546.383768658398],
        [1676073600000, 1515.5340268615535],
        [1676160000000, 1541.9688769412712],
        [1676246400000, 1515.3346311806397],
        [1676332800000, 1506.9162010135053],
        [1676419200000, 1556.963484345393],
        [1676505600000, 1674.858207473528],
        [1676592000000, 1646.143501886728],
        [1676678400000, 1697.0848665737892],
        [1676764800000, 1692.518962831222],
        [1676851200000, 1680.3786857000732],
        [1676937600000, 1700.1986814992663],
        [1677024000000, 1656.582369571119],
        [1677110400000, 1643.1733937453491],
        [1677196800000, 1651.850006691427],
        [1677283200000, 1608.1034936538365],
        [1677369600000, 1593.9519737908456],
        [1677456000000, 1640.5827101943062],
        [1677542400000, 1633.9766289833817],
        [1677628800000, 1606.632639454843],
        [1677715200000, 1663.925777294461],
        [1677801600000, 1647.6646669077718],
        [1677888000000, 1570.668210599776],
        [1677974400000, 1568.4956010562191],
        [1678060800000, 1563.2256620056303],
        [1678147200000, 1567.3501465252189],
        [1678233600000, 1563.8131822475007],
        [1678320000000, 1535.2602520971097],
        [1678406400000, 1440.1676618801841],
        [1678492800000, 1429.6031691041849],
        [1678579200000, 1474.40324440156],
        [1678665600000, 1588.1968020530471],
        [1678752000000, 1678.915634393308],
        [1678838400000, 1708.300897366666],
        [1678924800000, 1658.0701085663115],
        [1679011200000, 1679.7924762080895],
        [1679097600000, 1793.2988017342332],
        [1679184000000, 1769.4253269123851],
        [1679270400000, 1801.3674190114587],
        [1679356800000, 1744.4414599600184],
        [1679443200000, 1811.4112436682735],
        [1679529600000, 1747.6400281141143],
        [1679616000000, 1824.9566578949327],
        [1679702400000, 1751.2194078261725],
        [1679788800000, 1753.982636507409],
        [1679875200000, 1776.867690456723],
        [1679961600000, 1719.1709726748606],
        [1679966032000, 1709.1271834872894],
      ],
      market_caps: [
        [1438905600000, 0.0],
        [1438992000000, 80339475.0],
        [1439164800000, 41556309.21112176],
        [1439251200000, 64539006.306272194],
        [1439337600000, 76013261.05415402],
        [1439424000000, 110468836.22388984],
        [1439510400000, 110555313.26025319],
        [1439596800000, 101215246.3781064],
        [1439683200000, 89480939.24025029],
        [1439769600000, 87313387.94547918],
        [1439856000000, 92957858.0620156],
        [1439942400000, 90920695.86547753],
        [1440028800000, 107749185.84108424],
        [1440115200000, 102131022.8278611],
        [1440201600000, 100308763.78229792],
        [1440288000000, 98488001.44464436],
        [1440374400000, 90625948.31797116],
        [1440460800000, 84423109.08066371],
        [1440547200000, 81454879.46968535],
        [1440633600000, 82504022.8230964],
        [1440720000000, 86549490.63266213],
        [1440806400000, 85515599.87435573],
        [1440892800000, 96321049.6917207],
        [1440979200000, 98608290.53954794],
        [1441065600000, 98446826.49425755],
        [1441152000000, 93224371.25915022],
        [1441238400000, 92075482.63297981],
        [1441324800000, 92596631.55333723],
        [1441411200000, 97402782.61913483],
        [1441497600000, 94801706.70501994],
        [1441584000000, 91718481.31789045],
        [1441670400000, 90274690.39947063],
        [1441756800000, 88150718.66876175],
        [1441843200000, 85308945.82326],
        [1441929600000, 71394234.94958797],
        [1442016000000, 75931677.01254052],
        [1442102400000, 68673818.80135794],
        [1442188800000, 63965969.21105757],
        [1442275200000, 68951445.2301325],
        [1442361600000, 66147947.57992838],
        [1442448000000, 64693831.07862287],
        [1442534400000, 62003275.12077277],
        [1442620800000, 64666306.22983543],
        [1442707200000, 68666556.12460059],
        [1442793600000, 66105164.81214318],
        [1442880000000, 65835322.779180124],
        [1442966400000, 65633959.754653834],
        [1443052800000, 59927327.71396765],
        [1443139200000, 54400658.247102216],
        [1443225600000, 57158415.438244216],
        [1443312000000, 53028193.82255717],
        [1443398400000, 42874816.973063745],
        [1443484800000, 48389042.902712226],
        [1443571200000, 54065419.507539704],
        [1443657600000, 50481342.528862044],
        [1443744000000, 49792779.28891763],
        [1443830400000, 50638684.01549935],
        [1443916800000, 49187272.3502002],
        [1444003200000, 46307572.65224096],
        [1444089600000, 47605896.39364778],
        [1444176000000, 44984157.19104538],
        [1444262400000, 45977519.79859987],
        [1444348800000, 47989631.2526218],
        [1444435200000, 46490996.55001768],
        [1444521600000, 47026115.24213034],
        [1444608000000, 46416611.842303865],
        [1444694400000, 44485499.29942276],
        [1444780800000, 38272635.02078042],
        [1444867200000, 41527413.54435173],
        [1444953600000, 39443831.31743269],
        [1445040000000, 40632190.61699083],
        [1445126400000, 38382124.41691709],
        [1445212800000, 36070935.31062339],
        [1445299200000, 32076052.631905656],
        [1445385600000, 32590298.008333907],
        [1445472000000, 41918540.78268511],
        [1445558400000, 40100107.0136459],
        [1445644800000, 42179070.75754259],
        [1445731200000, 46895926.257060364],
        [1445817600000, 55226993.78944921],
        [1445904000000, 63451496.574236006],
        [1445990400000, 75057962.81264551],
        [1446076800000, 86478981.5524642],
        [1446163200000, 77447839.42741764],
        [1446249600000, 67453442.47710526],
        [1446336000000, 78742733.24541084],
        [1446422400000, 71066352.05814125],
        [1446508800000, 74613449.56251672],
        [1446595200000, 67152505.55125615],
        [1446681600000, 67532501.09696001],
        [1446768000000, 67767390.10082617],
        [1446854400000, 68761146.27837914],
        [1446940800000, 76839299.32470989],
        [1447027200000, 74277547.07634276],
        [1447113600000, 69753492.14382021],
        [1447200000000, 58873875.70678452],
        [1447286400000, 67254888.20415637],
        [1447372800000, 67527257.40972713],
        [1447459200000, 66066714.4889687],
        [1447545600000, 68078325.7946219],
        [1447632000000, 69812830.40735959],
        [1447718400000, 74440734.3005621],
        [1447804800000, 74402718.74542972],
        [1447891200000, 71212297.76056388],
        [1447977600000, 69388368.57982641],
        [1448064000000, 72914368.80252853],
        [1448150400000, 72276339.25126061],
        [1448236800000, 70839197.81949022],
        [1448323200000, 67424299.39769948],
        [1448409600000, 65072847.332706206],
        [1448496000000, 67132055.60740013],
        [1448582400000, 65341845.392888874],
        [1448668800000, 68868768.28657502],
        [1448755200000, 65463342.96555324],
        [1448841600000, 65624853.97047546],
        [1448928000000, 65752486.02943543],
        [1449014400000, 61844779.03171155],
        [1449100800000, 62018808.085017644],
        [1449187200000, 63094325.07829986],
        [1449273600000, 65056337.64166404],
        [1449360000000, 62850264.41510667],
        [1449446400000, 60292476.61320164],
        [1449532800000, 61675859.110314034],
        [1449619200000, 59661067.18085799],
        [1449705600000, 63872552.27588074],
        [1449792000000, 69119370.62718369],
        [1449878400000, 73250296.07272834],
        [1449964800000, 72302484.28350292],
        [1450051200000, 75133022.39506158],
        [1450137600000, 76787012.60189861],
        [1450224000000, 74686005.22814946],
        [1450310400000, 71033582.40629953],
        [1450396800000, 69616990.9878865],
        [1450483200000, 68767429.85047308],
        [1450569600000, 68305705.24538197],
        [1450656000000, 67842615.39188164],
        [1450742400000, 66514758.19337365],
        [1450828800000, 65674405.654046126],
        [1450915200000, 65226512.003279686],
        [1451001600000, 65957619.83793369],
        [1451088000000, 64637552.400460914],
        [1451174400000, 65104495.77797423],
        [1451260800000, 63858382.55360898],
        [1451347200000, 65951485.1558242],
        [1451433600000, 68315404.54669571],
        [1451520000000, 71083912.69961053],
        [1451606400000, 71849843.66511223],
        [1451692800000, 71252107.9687766],
        [1451779200000, 73864483.22222728],
        [1451865600000, 72909192.00505088],
        [1451952000000, 72462361.87937805],
        [1452038400000, 73650960.93328463],
        [1452124800000, 72931723.30621576],
        [1452211200000, 75767382.4398899],
        [1452297600000, 78016544.33428131],
        [1452384000000, 77676004.65241879],
        [1452470400000, 81416101.6377075],
        [1452556800000, 88958959.89936547],
        [1452643200000, 86108971.48293594],
        [1452729600000, 88257219.96322113],
        [1452816000000, 92329493.82516772],
        [1452902400000, 92765190.35300171],
        [1452988800000, 100866229.13386133],
        [1453075200000, 106770204.99600992],
        [1453161600000, 105177492.60810892],
        [1453248000000, 119608403.90910526],
        [1453334400000, 118064297.75775222],
        [1453420800000, 112494896.69290045],
        [1453507200000, 129390552.65124127],
        [1453593600000, 163090024.6945184],
        [1453680000000, 189898537.77611715],
        [1453766400000, 159744115.0673894],
        [1453852800000, 182534589.0343229],
        [1453939200000, 193030853.2041578],
        [1454025600000, 190524391.83507282],
        [1454112000000, 186862759.72151402],
        [1454198400000, 176275775.71473008],
        [1454284800000, 170434539.30474818],
        [1454371200000, 184396131.5986807],
        [1454457600000, 193120920.3597334],
        [1454544000000, 196350197.91323307],
        [1454630400000, 194548161.01786965],
        [1454716800000, 193450250.47357807],
        [1454803200000, 226795324.20954534],
        [1454889600000, 243366581.58299115],
        [1454976000000, 286139280.9840226],
        [1455062400000, 340624194.15097886],
        [1455148800000, 462140079.76210976],
        [1455235200000, 421542668.4566254],
        [1455321600000, 413798928.6182676],
        [1455408000000, 398234915.54443055],
        [1455494400000, 409885035.83075976],
        [1455580800000, 336781865.25572217],
        [1455667200000, 288700599.2729368],
        [1455753600000, 339233532.70569825],
        [1455840000000, 346746765.74089813],
        [1455926400000, 332040532.28508496],
        [1456012800000, 359334417.7208708],
        [1456099200000, 433193897.2217689],
        [1456185600000, 433593972.8044955],
        [1456272000000, 474102955.51858103],
        [1456358400000, 458942242.84471726],
        [1456444800000, 456082096.8564275],
        [1456531200000, 491795616.48932266],
        [1456617600000, 498424354.01221955],
        [1456704000000, 488620056.2443799],
        [1456790400000, 585403826.3853717],
        [1456876800000, 650547162.2177418],
        [1456963200000, 736507630.9102448],
        [1457049600000, 778040245.1399581],
        [1457136000000, 847886363.7817264],
        [1457222400000, 850440219.9342833],
        [1457308800000, 729122199.5218774],
        [1457395200000, 760648959.2261459],
        [1457481600000, 929438076.2759757],
        [1457568000000, 870327228.7272445],
        [1457654400000, 870802676.0308328],
        [1457740800000, 1039511436.0395219],
        [1457827200000, 1157819808.965224],
        [1457913600000, 955721344.4457039],
        [1458000000000, 1008754617.7585647],
        [1458086400000, 975104638.0634549],
        [1458172800000, 856001603.1676563],
        [1458259200000, 843946887.9342747],
        [1458345600000, 816289545.7437397],
        [1458432000000, 799245003.4284257],
        [1458518400000, 927407651.003754],
        [1458604800000, 886765230.0935057],
        [1458691200000, 972225642.275035],
        [1458777600000, 872598276.4440267],
        [1458864000000, 834753145.0583116],
        [1458950400000, 860590847.122157],
        [1459036800000, 814770808.1488085],
        [1459123200000, 917099302.4921898],
        [1459209600000, 922332031.7043784],
        [1459296000000, 934038009.0318996],
        [1459382400000, 892916961.5825855],
        [1459468800000, 915415691.5298438],
        [1459555200000, 910108352.6203126],
        [1459641600000, 906254482.4679867],
        [1459728000000, 881135506.2361231],
        [1459814400000, 821026031.4553995],
        [1459900800000, 840796803.7682226],
        [1459987200000, 790923840.975488],
        [1460073600000, 769898368.4666554],
        [1460160000000, 781106339.0553539],
        [1460246400000, 695943784.1793737],
        [1460332800000, 684660770.877576],
        [1460419200000, 593949307.4480366],
        [1460505600000, 633611436.0474921],
        [1460592000000, 666925784.5002216],
        [1460678400000, 649194329.0397049],
        [1460764800000, 677141041.3390801],
        [1460851200000, 738147546.3926145],
        [1460937600000, 709720474.7865463],
        [1461024000000, 689106961.8888905],
        [1461110400000, 672532357.9918683],
        [1461196800000, 643204549.648147],
        [1461283200000, 623163882.7267805],
        [1461369600000, 664843804.3114134],
        [1461456000000, 634792414.6359913],
        [1461542400000, 620380381.4729422],
        [1461628800000, 589953549.0718942],
        [1461715200000, 614038719.7664939],
        [1461801600000, 576551379.9087085],
        [1461888000000, 594731702.4822702],
        [1461974400000, 707751631.3173722],
        [1462060800000, 703213705.2833785],
        [1462147200000, 805073117.8028661],
        [1462233600000, 739862094.2932651],
        [1462320000000, 750420702.3036536],
        [1462406400000, 783653892.6075463],
        [1462492800000, 740079043.0208727],
        [1462579200000, 748684467.9155403],
        [1462665600000, 760794311.5000647],
        [1462752000000, 747045608.4385856],
        [1462838400000, 747107780.4041426],
        [1462924800000, 797843002.2628187],
        [1463011200000, 806976736.4777818],
        [1463097600000, 846829870.6061078],
        [1463184000000, 814388813.0253446],
        [1463270400000, 797447113.6994009],
        [1463356800000, 891725938.6611807],
        [1463443200000, 974091672.7174443],
        [1463529600000, 1070392165.1001045],
        [1463616000000, 1180254102.1753519],
        [1463702400000, 1098527825.600999],
        [1463788800000, 1123480271.01703],
        [1463875200000, 1143985930.6592457],
        [1463961600000, 1063005899.322382],
        [1464048000000, 1017513902.0341961],
        [1464134400000, 1005116816.0568345],
        [1464220800000, 1005043803.4223615],
        [1464307200000, 900334792.3619336],
        [1464393600000, 946689008.4566064],
        [1464480000000, 997114649.2059603],
        [1464566400000, 1021153612.0329038],
        [1464652800000, 1128785731.519008],
        [1464739200000, 1117038106.1276379],
        [1464825600000, 1110747962.0861454],
        [1464912000000, 1116709699.2231467],
        [1464998400000, 1105420836.3746781],
        [1465084800000, 1127904559.166551],
        [1465171200000, 1126059079.2933497],
        [1465257600000, 1175566661.3926558],
        [1465344000000, 1166543234.5850925],
        [1465430400000, 1167724056.8805668],
        [1465516800000, 1127971788.6557844],
        [1465603200000, 1129771063.2214296],
        [1465689600000, 1270814827.7909074],
        [1465776000000, 1421627594.3482137],
        [1465862400000, 1520848128.9016523],
        [1465948800000, 1493198806.5705338],
        [1466035200000, 1663058590.2352068],
        [1466121600000, 1249053416.9715872],
        [1466208000000, 908703581.3956258],
        [1466294400000, 992977657.9993956],
        [1466380800000, 959600009.086865],
        [1466467200000, 1055028896.3957832],
        [1466553600000, 1035382450.4098117],
        [1466640000000, 1112128273.0865185],
        [1466726400000, 1134160918.5765297],
        [1466812800000, 1161808477.683748],
        [1466899200000, 1127161866.113175],
        [1466985600000, 1134738651.812568],
        [1467072000000, 1001816707.4205686],
        [1467158400000, 1043352935.7311049],
        [1467244800000, 1087390815.1043506],
        [1467331200000, 1005775062.8852906],
        [1467417600000, 980549295.6466436],
        [1467504000000, 965373887.5132511],
        [1467590400000, 932812328.2042702],
        [1467676800000, 861199508.1291671],
        [1467763200000, 859068161.5290377],
        [1467849600000, 825747093.0896322],
        [1467936000000, 932118254.1453621],
        [1468022400000, 899784687.8420677],
        [1468108800000, 896985032.1099088],
        [1468195200000, 861156597.6960213],
        [1468281600000, 868920981.0675113],
        [1468368000000, 859665552.2352576],
        [1468454400000, 921808085.7160168],
        [1468540800000, 928901844.3372735],
        [1468627200000, 960169025.1824887],
        [1468713600000, 928781022.9092299],
        [1468800000000, 905150788.2259313],
        [1468886400000, 951503951.3461838],
        [1468972800000, 998549464.8233292],
        [1469059200000, 1036388808.2886974],
        [1469145600000, 1177755541.070433],
        [1469232000000, 1183972887.7908795],
        [1469318400000, 1053890906.6279503],
        [1469404800000, 1137688271.2296822],
        [1469491200000, 969670072.7276816],
        [1469577600000, 1068505615.2937703],
        [1469664000000, 1062067696.4315883],
        [1469750400000, 1058924002.9712181],
        [1469836800000, 1035160533.0510787],
        [1469923200000, 979546056.9825448],
        [1470009600000, 905616046.061989],
        [1470096000000, 689901283.219963],
        [1470182400000, 844612892.2756267],
        [1470268800000, 911931922.0513974],
        [1470355200000, 904674489.3809131],
        [1470441600000, 901592852.5380467],
        [1470528000000, 903742755.7733909],
        [1470614400000, 931042241.4656782],
        [1470700800000, 1009705614.3654938],
        [1470787200000, 1005960650.7643628],
        [1470873600000, 993657810.9651861],
        [1470960000000, 992852191.9212197],
        [1471046400000, 960366408.498685],
        [1471132800000, 934377927.8960145],
        [1471219200000, 932326143.2853824],
        [1471305600000, 851307063.4618484],
        [1471392000000, 891887550.1509097],
        [1471478400000, 891315247.0386077],
        [1471564800000, 891164434.8398446],
        [1471651200000, 936793411.7477161],
        [1471737600000, 928118646.4965856],
        [1471824000000, 920760944.831664],
        [1471910400000, 917054933.5053089],
        [1471996800000, 917509231.7189381],
        [1472083200000, 944980815.0070009],
        [1472169600000, 938479976.3330711],
        [1472256000000, 929893628.9463943],
        [1472342400000, 910522226.8620988],
        [1472428800000, 910306587.9266042],
        [1472515200000, 936081383.0388741],
        [1472601600000, 960315422.2529753],
        [1472688000000, 1002979070.4858418],
        [1472774400000, 1010736310.5378789],
        [1472860800000, 986576697.7263979],
        [1472947200000, 975664137.5387731],
        [1473033600000, 983510024.4105914],
        [1473120000000, 978261092.6084977],
        [1473206400000, 967355053.646816],
        [1473292800000, 949823761.5796945],
        [1473379200000, 974812003.5841372],
        [1473465600000, 1016072616.831647],
        [1473552000000, 972702164.2737088],
        [1473638400000, 994145301.609289],
        [1473724800000, 997422104.3891094],
        [1473811200000, 1000350301.4766209],
        [1473897600000, 999855875.8932285],
        [1473984000000, 1052421113.1733788],
        [1474070400000, 1066458048.1588708],
        [1474156800000, 1040236010.887626],
        [1474243200000, 1107285960.0478551],
        [1474329600000, 1204332733.2298675],
        [1474416000000, 1154027780.2700548],
        [1474502400000, 1114753105.9884236],
        [1474588800000, 1122387371.9483435],
        [1474675200000, 1084102058.9176946],
        [1474761600000, 1100233097.311396],
        [1474848000000, 1081499008.0468965],
        [1474934400000, 1103562692.3435485],
        [1475020800000, 1124190330.6353893],
        [1475107200000, 1106268658.338769],
        [1475193600000, 1114978390.1540408],
        [1475280000000, 1121767153.3880613],
        [1475366400000, 1116158962.9099944],
        [1475452800000, 1139675567.6165462],
        [1475539200000, 1122448916.2531135],
        [1475625600000, 1105495725.0792377],
        [1475712000000, 1086400169.4406376],
        [1475798400000, 1072424202.4064465],
        [1475884800000, 1032213087.4672256],
        [1475971200000, 1020207839.2316508],
        [1476057600000, 1004236335.549423],
        [1476144000000, 997294452.394914],
        [1476230400000, 1006549794.2460625],
        [1476316800000, 1015964123.7672851],
        [1476403200000, 1012567915.6864122],
        [1476489600000, 1015379673.8239383],
        [1476576000000, 1014551965.6012961],
        [1476662400000, 1018744183.3917441],
        [1476748800000, 1064628048.1422825],
        [1476835200000, 1019440133.5922542],
        [1476921600000, 1023487873.4228995],
        [1477008000000, 1030182192.7281948],
        [1477094400000, 1026687902.3286453],
        [1477180800000, 1017581341.656798],
        [1477267200000, 1014274172.0789354],
        [1477353600000, 961902672.6889603],
        [1477440000000, 981270911.3193351],
        [1477526400000, 978335224.2052029],
        [1477612800000, 984269328.3943177],
        [1477699200000, 883346994.5449576],
        [1477785600000, 956201948.7341646],
        [1477872000000, 930359100.1537943],
        [1477958400000, 923284106.0659813],
        [1478044800000, 922879134.3461332],
        [1478131200000, 925622888.0829407],
        [1478217600000, 953223436.315442],
        [1478304000000, 948094434.4750459],
        [1478390400000, 935993552.9610422],
        [1478476800000, 932357915.5754921],
        [1478563200000, 934033643.8762665],
        [1478649600000, 915091038.306155],
        [1478736000000, 902927283.1963692],
        [1478822400000, 883831055.7586268],
        [1478908800000, 830154646.5339867],
        [1478995200000, 868826185.2627618],
        [1479081600000, 858907304.312002],
        [1479168000000, 877146449.7853684],
        [1479254400000, 862443066.8905919],
        [1479340800000, 857469644.9666562],
        [1479427200000, 813868305.3690429],
        [1479513600000, 832570726.9340843],
        [1479600000000, 821098057.2191805],
        [1479686400000, 825310560.8512948],
        [1479772800000, 846010291.5384672],
        [1479859200000, 841238275.5424864],
        [1479945600000, 792161484.4680631],
        [1480032000000, 805772134.224589],
        [1480118400000, 802029049.6889203],
        [1480204800000, 769442732.486036],
        [1480291200000, 752553451.4139076],
        [1480377600000, 705297428.006268],
        [1480464000000, 748071852.89044],
        [1480550400000, 730151014.1821295],
        [1480636800000, 661018149.1755499],
        [1480723200000, 683319039.0920178],
        [1480809600000, 646883448.723041],
        [1480896000000, 590496504.1573621],
        [1480982400000, 661467705.6118599],
        [1481068800000, 705334665.7063091],
        [1481155200000, 720121915.7352629],
        [1481241600000, 737938568.3108796],
        [1481328000000, 702944255.6811203],
        [1481414400000, 713308213.0334481],
        [1481500800000, 735934880.9008343],
        [1481587200000, 725643347.5173056],
        [1481673600000, 713465176.9517916],
        [1481760000000, 675681120.2742975],
        [1481846400000, 678214580.9425403],
        [1481932800000, 680519827.6040249],
        [1482019200000, 683897439.864293],
        [1482105600000, 661159386.32716],
        [1482192000000, 664148518.5948613],
        [1482278400000, 684634858.1163107],
        [1482364800000, 660427962.7659905],
        [1482451200000, 628063010.6930844],
        [1482537600000, 630117081.0725466],
        [1482624000000, 629516509.2890977],
        [1482710400000, 634564176.0517044],
        [1482796800000, 626352253.3784304],
        [1482883200000, 665151773.2559114],
        [1482969600000, 714246947.4367769],
        [1483056000000, 712888825.7382647],
        [1483142400000, 700638437.1090508],
        [1483228800000, 705615194.8270631],
        [1483315200000, 731817457.4649888],
        [1483401600000, 842886495.3799952],
        [1483488000000, 961038889.8439155],
        [1483574400000, 886296492.9314206],
        [1483660800000, 890067630.5937623],
        [1483747200000, 861427362.560421],
        [1483833600000, 900645822.1454092],
        [1483920000000, 902556769.2719904],
        [1484006400000, 923748369.397242],
        [1484092800000, 848391822.1686144],
        [1484179200000, 849172695.9853683],
        [1484265600000, 848042631.0951145],
        [1484352000000, 852254946.1784115],
        [1484438400000, 860534322.7461399],
        [1484524800000, 837562733.6341596],
        [1484611200000, 902735897.6068891],
        [1484697600000, 891354114.8712552],
        [1484784000000, 914555495.2617246],
        [1484870400000, 929384722.9448366],
        [1484956800000, 958152131.9422481],
        [1485043200000, 947279384.7763175],
        [1485129600000, 948113651.4735702],
        [1485216000000, 917408215.3892527],
        [1485302400000, 924140033.97176],
        [1485388800000, 931851533.4501834],
        [1485475200000, 928178829.5799544],
        [1485561600000, 932486646.1251798],
        [1485648000000, 921810453.3952467],
        [1485734400000, 934513900.731524],
        [1485820800000, 944300621.5689718],
        [1485907200000, 946733901.535968],
        [1485993600000, 956759156.4673628],
        [1486080000000, 968360894.192527],
        [1486166400000, 1003747585.8791431],
        [1486252800000, 996031546.0667974],
        [1486339200000, 1000976621.5619533],
        [1486425600000, 1013517743.3509269],
        [1486512000000, 1005343885.9063299],
        [1486598400000, 968413072.7002903],
        [1486684800000, 1000774841.3196372],
        [1486771200000, 1012095683.2413529],
        [1486857600000, 1008457060.9062703],
        [1486944000000, 1002404862.0123965],
        [1487030400000, 1152983307.7665765],
        [1487116800000, 1150065646.2625835],
        [1487203200000, 1141695360.3501627],
        [1487289600000, 1130310857.329892],
        [1487376000000, 1140747285.3365533],
        [1487462400000, 1137511955.6917048],
        [1487548800000, 1135537849.161545],
        [1487635200000, 1134283628.7370799],
        [1487721600000, 1122185283.3355997],
        [1487808000000, 1171602048.167043],
        [1487894400000, 1165941515.2567716],
        [1487980800000, 1208749236.7164543],
        [1488067200000, 1298504264.2843328],
        [1488153600000, 1371373069.7338753],
        [1488240000000, 1402597198.622161],
        [1488326400000, 1534004366.1496954],
        [1488412800000, 1697730102.3249254],
        [1488499200000, 1751276476.8602574],
        [1488585600000, 1667171877.3033454],
        [1488672000000, 1722225376.4707189],
        [1488758400000, 1760717955.0918417],
        [1488844800000, 1685811625.6439984],
        [1488931200000, 1500309874.2410994],
        [1489017600000, 1579882452.1833007],
        [1489104000000, 1726669640.058529],
        [1489190400000, 1918511338.945731],
        [1489276800000, 2071872695.7437222],
        [1489363200000, 2552384188.382959],
        [1489449600000, 2561402786.7498617],
        [1489536000000, 3070584067.9730344],
        [1489622400000, 3580085215.1633782],
        [1489708800000, 3959577512.5822687],
        [1489795200000, 2900403757.617106],
        [1489881600000, 3964282980.887029],
        [1489968000000, 3765537571.6064487],
        [1490054400000, 3822148614.067184],
        [1490140800000, 3714048002.0475125],
        [1490227200000, 3896515676.7178783],
        [1490313600000, 4584161758.556292],
        [1490400000000, 4566322356.351229],
        [1490486400000, 4511139438.293101],
        [1490572800000, 4419631301.180356],
        [1490659200000, 4533191634.755726],
        [1490745600000, 4767901725.458708],
        [1490832000000, 4721666246.305043],
        [1490918400000, 4511239008.808598],
        [1491004800000, 4554446900.250353],
        [1491091200000, null],
        [1491177600000, 3938736158.3307056],
        [1491264000000, 3779040762.106836],
        [1491350400000, 4123329711.6029277],
        [1491436800000, 3888436554.318116],
        [1491523200000, 3837553639.6237936],
        [1491609600000, 4028965746.7053437],
        [1491696000000, 4001279550.297905],
        [1491782400000, 3977660680.7776713],
        [1491868800000, 3974803439.6935434],
        [1491955200000, 4188162262.675274],
        [1492041600000, 4511719460.307157],
        [1492128000000, 4314764267.296191],
        [1492214400000, 4457826105.639198],
        [1492300800000, 4421737497.550737],
        [1492387200000, 4390331578.008309],
        [1492473600000, 4610962772.624808],
        [1492560000000, 4376229928.945044],
        [1492646400000, 4550946376.025764],
        [1492732800000, 4437559543.365543],
        [1492819200000, 4464325598.471732],
        [1492905600000, 4604950657.5048485],
        [1492992000000, 4650945908.7491455],
        [1493078400000, 4641256266.642054],
        [1493164800000, 4998350382.018841],
        [1493251200000, 5827188142.875711],
        [1493337600000, 6654236244.652257],
        [1493424000000, 6423030318.594092],
        [1493510400000, 7277893964.101945],
        [1493596800000, 7010408300.280772],
        [1493683200000, 7058413636.898035],
        [1493769600000, 7387794149.972096],
        [1493856000000, 8594013017.409878],
        [1493942400000, 8327157234.5526085],
        [1494028800000, 8811175463.021584],
        [1494115200000, 8202729258.547628],
        [1494201600000, 8041549109.429107],
        [1494288000000, 8003377431.08859],
        [1494374400000, 8089058133.497479],
        [1494460800000, 8095903577.443339],
        [1494547200000, 8073353519.484268],
        [1494633600000, 8163626646.569916],
        [1494720000000, 8250106526.458163],
        [1494806400000, 8293823327.380839],
        [1494892800000, 7983587476.027811],
        [1494979200000, 8088870648.097549],
        [1495065600000, 8862576243.544483],
        [1495152000000, 11208881844.541496],
        [1495238400000, 11291783022.019232],
        [1495324800000, 13648265757.584177],
        [1495411200000, 14142415430.127079],
        [1495497600000, 15715507206.448889],
        [1495584000000, 17498667475.10237],
        [1495670400000, 16254143683.05323],
        [1495756800000, 14983523257.18021],
        [1495843200000, 14630471573.094501],
        [1495929600000, 15857470558.37399],
        [1496016000000, 18047458437.093563],
        [1496102400000, 21249999565.212254],
        [1496188800000, 21309492258.258083],
        [1496275200000, 20436692118.01529],
        [1496361600000, 20671991234.40535],
        [1496448000000, 20777803231.835773],
        [1496534400000, 22815254964.736126],
        [1496620800000, 22960811912.55524],
        [1496707200000, 24555497266.27736],
        [1496793600000, 23647279711.566154],
        [1496880000000, 24034963007.9528],
        [1496966400000, 25888383498.268276],
        [1497052800000, 31204109902.87976],
        [1497139200000, 31517336371.290997],
        [1497225600000, 36827986612.086975],
        [1497312000000, 36054646089.31767],
        [1497398400000, 32166013290.582638],
        [1497484800000, 32045187612.760445],
        [1497571200000, 33157744080.501328],
        [1497657600000, 34470617407.87629],
        [1497744000000, 33285012838.7317],
        [1497830400000, 33536649090.290455],
        [1497916800000, 33172832683.333572],
        [1498003200000, 30390293651.200687],
        [1498089600000, 30312664724.45932],
        [1498176000000, 30780636114.235588],
        [1498262400000, 28761584162.47397],
        [1498348800000, 26727797729.147892],
        [1498435200000, 24031469340.43858],
        [1498521600000, 26071528057.27424],
        [1498608000000, 29466974350.047752],
        [1498694400000, 27885580759.15084],
        [1498780800000, 26454457715.02758],
        [1498867200000, 24397386527.037548],
        [1498953600000, 26603794019.452686],
        [1499040000000, 25910676121.598396],
        [1499126400000, 25093983117.385654],
        [1499212800000, 24747605746.348026],
        [1499299200000, 24892807780.231613],
        [1499385600000, 22473685750.315968],
        [1499472000000, 22932342735.97489],
        [1499558400000, 22243889410.906918],
        [1499644800000, 19619308390.378407],
        [1499731200000, 18114890100.247456],
        [1499817600000, 20711938391.132465],
        [1499904000000, 19250616800.120193],
        [1499990400000, 18467839703.61626],
        [1500076800000, 16028622412.657797],
        [1500163200000, 14603949935.719194],
        [1500249600000, 17584242919.82978],
        [1500336000000, 21338075701.63201],
        [1500422400000, 18643050491.147614],
        [1500508800000, 20878190202.346775],
        [1500595200000, 20287767362.233944],
        [1500681600000, 21607085358.114548],
        [1500768000000, 21198900249.379704],
        [1500854400000, 21035610498.530434],
        [1500940800000, 19347591148.68737],
        [1501027200000, 18746083965.650166],
        [1501113600000, 19018740572.79166],
        [1501200000000, 18030982561.483517],
        [1501286400000, 19246182898.41244],
        [1501372800000, 18472631835.069103],
        [1501459200000, 19047827948.6107],
        [1501545600000, 21282137210.92791],
        [1501632000000, 20534872983.74952],
        [1501718400000, 21084238670.106236],
        [1501804800000, 20789568173.053577],
        [1501891200000, 23747316372.895435],
        [1501977600000, 24697194959.234104],
        [1502064000000, 25197894463.869026],
        [1502150400000, 27705377543.833405],
        [1502236800000, 27892280499.832973],
        [1502323200000, 27871169339.54446],
        [1502409600000, 28821677184.531666],
        [1502496000000, 28997352513.86263],
        [1502582400000, 27926704137.990173],
        [1502668800000, 27861579961.952854],
        [1502755200000, 27120920523.5159],
        [1502841600000, 28217360455.396397],
        [1502928000000, 28267299986.362434],
        [1503014400000, 27861556924.972927],
        [1503100800000, 27916001533.15132],
        [1503187200000, 27786029857.799793],
        [1503273600000, 30337762878.381535],
        [1503360000000, 29400686225.9616],
        [1503446400000, 29842462532.356785],
        [1503532800000, 30320399298.400482],
        [1503619200000, 31008765515.118908],
        [1503705600000, 31031409011.107296],
        [1503792000000, 32370755160.220387],
        [1503878400000, 32458167725.598194],
        [1503964800000, 34597128016.55826],
        [1504051200000, 35745271112.44432],
        [1504137600000, 36132449087.43318],
        [1504224000000, 36461379831.851746],
        [1504310400000, 32698140251.89353],
        [1504396800000, 33037915918.358833],
        [1504483200000, 28302865423.90704],
        [1504569600000, 30127332537.80147],
        [1504656000000, 31478005326.47652],
        [1504742400000, 31126200677.32708],
        [1504828800000, 28775477244.445538],
        [1504915200000, 28329354911.608036],
        [1505001600000, 27681278352.42371],
        [1505088000000, 28036287702.51409],
        [1505174400000, 27485022514.19706],
        [1505260800000, 26236732489.431683],
        [1505347200000, 20278768201.760014],
        [1505433600000, 23369190516.539547],
        [1505520000000, 23173710726.76121],
        [1505606400000, 23638636295.481937],
        [1505692800000, 27269907986.777264],
        [1505779200000, 26563613422.607845],
        [1505865600000, 26471592194.703312],
        [1505952000000, 24396830727.284477],
        [1506038400000, 24969981050.950962],
        [1506124800000, 26846246384.287216],
        [1506211200000, 26609714675.1668],
        [1506297600000, 27316005855.910435],
        [1506384000000, 26926232192.184574],
        [1506470400000, 28658972908.46477],
        [1506556800000, 27919976584.315685],
        [1506643200000, 27336170625.13594],
        [1506729600000, 28209139740.688995],
        [1506816000000, 28442001971.008823],
        [1506902400000, 28082506249.729813],
        [1506988800000, 27742435562.94357],
        [1507075200000, 27825239443.20582],
        [1507161600000, 27894664349.155266],
        [1507248000000, 29227935374.982],
        [1507334400000, 29412044193.592102],
        [1507420800000, 29175396936.827038],
        [1507507200000, 28119791744.856277],
        [1507593600000, 28506074217.358105],
        [1507680000000, 28827311270.280365],
        [1507766400000, 28715264952.728848],
        [1507852800000, 32136884949.300323],
        [1507939200000, 31893398413.31946],
        [1508025600000, 31903683068.492874],
        [1508112000000, 31583306698.85391],
        [1508198400000, 30251186909.138367],
        [1508284800000, 29930081747.853603],
        [1508371200000, 29366946306.63264],
        [1508457600000, 28790749976.831596],
        [1508544000000, 28638437008.879925],
        [1508630400000, 28148745034.196087],
        [1508716800000, 27575920805.165585],
        [1508803200000, 28472255904.29292],
        [1508889600000, 28452391049.617855],
        [1508976000000, 28192911056.673782],
        [1509062400000, 28411617292.93479],
        [1509148800000, 28278036785.292797],
        [1509235200000, 28978234226.357674],
        [1509321600000, 29223725935.84833],
        [1509408000000, 28971625622.98315],
        [1509494400000, 27907457658.55725],
        [1509580800000, 27411044537.063046],
        [1509667200000, 29209850779.092915],
        [1509753600000, 28653533860.136066],
        [1509840000000, 28426818646.719738],
        [1509926400000, 28801102723.032696],
        [1510012800000, 28220768686.35795],
        [1510099200000, 29536454012.04308],
        [1510185600000, 30823742534.2921],
        [1510272000000, 28824163917.314198],
        [1510358400000, 30256030132.159668],
        [1510444800000, 30062176933.46014],
        [1510531200000, 30181430682.324654],
        [1510617600000, 32446360785.0723],
        [1510704000000, 32007998528.5458],
        [1510790400000, 31606240405.8994],
        [1510876800000, 31931961399.8328],
        [1510963200000, 33413835153.7437],
        [1511049600000, 33870265876.0073],
        [1511136000000, 35061352161.7675],
        [1511222400000, 34615252499.3341],
        [1511308800000, 36382569909.3727],
        [1511395200000, 39569679447.7058],
        [1511481600000, 45568623853.9817],
        [1511568000000, 44853255083.9293],
        [1511654400000, 45011721609.851],
        [1511740800000, 46496118377.9175],
        [1511827200000, 45528555887.0189],
        [1511913600000, 41136242461.6215],
        [1512000000000, 43624717838.8218],
        [1512086400000, 45142640782.4465],
        [1512172800000, 45151510929.4977],
        [1512259200000, 44394413397.1167],
        [1512345600000, 45400977761.2147],
        [1512432000000, 44907252844.2554],
        [1512518400000, 43118585011.2992],
        [1512604800000, 43465282557.5874],
        [1512691200000, 45019411221.6764],
        [1512777600000, 46391200044.4373],
        [1512864000000, 42998769813.0645],
        [1512950400000, 49007073662.9809],
        [1513036800000, 63736319679.7109],
        [1513123200000, 68377310760.8715],
        [1513209600000, 67885165267.6573],
        [1513296000000, 66789398252.0671],
        [1513382400000, 67685238860.8431],
        [1513468800000, 70249967413.1925],
        [1513555200000, 76958112624.052],
        [1513641600000, 80048859677.1559],
        [1513728000000, 78466047306.039],
        [1513814400000, 78432622738.5783],
        [1513900800000, 64585322053.99],
        [1513987200000, 69087031853.6697],
        [1514073600000, 66252429575.9089],
        [1514160000000, 70871970751.0918],
        [1514246400000, 74770905370.1416],
        [1514332800000, 73903417373.3621],
        [1514419200000, 71200955427.2237],
        [1514505600000, 73004412362.8661],
        [1514592000000, 71238101432.8975],
        [1514678400000, 74906560027.8485],
        [1514764800000, 75056629369.4547],
        [1514851200000, 84822222588.5437],
        [1514937600000, 93178531120.43204],
        [1515024000000, 94681149308.52457],
        [1515110400000, 97269228886.25314],
        [1515196800000, 101852997666.87328],
        [1515283200000, 110167827718.61086],
        [1515369600000, 114769005376.76157],
        [1515456000000, 131894965357.881],
        [1515542400000, 130387621608.28636],
        [1515628800000, 114526940296.8931],
        [1515715200000, 127538307119.98058],
        [1515801600000, 140419500475.92773],
        [1515888000000, 136747164703.8492],
        [1515974400000, 128980953671.9811],
        [1516060800000, 103677753320.31013],
        [1516147200000, 99216267384.45854],
        [1516233600000, 100567876886.70789],
        [1516320000000, 101640355096.62448],
        [1516406400000, 113899479853.58607],
        [1516492800000, 102337202453.45827],
        [1516579200000, 97186332455.0951],
        [1516665600000, 96359326865.87158],
        [1516752000000, 102717867498.02875],
        [1516838400000, 102976145173.28937],
        [1516924800000, 101975238868.2182],
        [1517011200000, 106559849459.01985],
        [1517097600000, 120356395893.4201],
        [1517184000000, 113796120454.081],
        [1517270711070, 113043826616.74722],
        [1517356800000, 102965815556.0668],
        [1517443200000, 107663538775.87933],
        [1517529600000, 99423764457.44884],
        [1517616000000, 89061856063.48083],
        [1517702400000, 91725607014.4353],
        [1517788800000, 81329819333.7154],
        [1517875200000, 66675677460.41152],
        [1517961600000, 76574516020.88303],
        [1518048323681, 73290929917.86972],
        [1518134400000, 78759712266.80615],
        [1518220800000, 84968804616.78447],
        [1518307200000, 83330877020.24867],
        [1518393600000, 79732678121.41658],
        [1518480000000, 84185155652.77072],
        [1518566400000, 81375375877.96892],
        [1518652800000, 89328231140.60481],
        [1518739200000, 90637629785.66489],
        [1518825600000, 91279135809.19406],
        [1518912000000, 94190008583.4062],
        [1518998400000, 89404389580.2483],
        [1519084800000, 90713108252.71552],
        [1519171200000, 88256609941.35635],
        [1519257600000, 79118626282.25874],
        [1519344000000, 79442544254.30933],
        [1519430400000, 84567348658.27484],
        [1519516800000, 81068136131.60957],
        [1519603200000, 82445384433.1456],
        [1519689600000, 84519903484.51317],
        [1519776000000, 85086932065.08655],
        [1519862400000, 82862945950.985],
        [1519948800000, 83792455743.35178],
        [1520035200000, 83297136123.66147],
        [1520121600000, 82708301723.55193],
        [1520208000000, 82676499793.85762],
        [1520294400000, 82340571913.28821],
        [1520380800000, 79250313700.00667],
        [1520467200000, 73790376421.96692],
        [1520553600000, 68568491974.3163],
        [1520640000000, 69730146644.19576],
        [1520726400000, 67198010840.67886],
        [1520812800000, 69985307146.27713],
        [1520899200000, 68245214878.500916],
        [1520985600000, 67211282137.89535],
        [1521072000000, 59738408621.9634],
        [1521158400000, 60148621435.96764],
        [1521244800000, 59712695037.709236],
        [1521331200000, 55031620530.236534],
        [1521417600000, 52938616457.57076],
        [1521504000000, 54560292401.575806],
        [1521590400000, 54486305509.98849],
        [1521676800000, 55179662649.949524],
        [1521763200000, 52885690223.31916],
        [1521849600000, 52936241666.49759],
        [1521936000000, 51114768972.69823],
        [1522022400000, 51114766847.01147],
        [1522108800000, 47991343456.18946],
        [1522195200000, 44211929435.11478],
        [1522281600000, 43718505250.02615],
        [1522368000000, 37953505528.35885],
        [1522454400000, 38946452754.23128],
        [1522540800000, 39000868214.27854],
        [1522627200000, 37365640069.82155],
        [1522713600000, 37935548084.04267],
        [1522800000000, 40989129292.88682],
        [1522886400000, 37503095784.24193],
        [1522972800000, 37923466118.640205],
        [1523059200000, 36495759521.398056],
        [1523145600000, 37838661360.00252],
        [1523232000000, 39182417250.92415],
        [1523318400000, 39367328706.210175],
        [1523404800000, 40749602575.92843],
        [1523491200000, 42209337332.354004],
        [1523577600000, 48265439663.55072],
        [1523664000000, 48313044128.328636],
        [1523750400000, 48928578121.79471],
        [1523836800000, 51944970387.794846],
        [1523923200000, 50134949937.02363],
        [1524009600000, 49290625426.76034],
        [1524096000000, 51170694522.39032],
        [1524182400000, 56030501148.69197],
        [1524268800000, 58403874540.99289],
        [1524355200000, 59405441433.091866],
        [1524441600000, 61094825885.43238],
        [1524528000000, 63004894796.41469],
        [1524614400000, 69817174451.75433],
        [1524700800000, 60788471582.80268],
        [1524787200000, 65093958669.996185],
        [1524873600000, 63614677083.81106],
        [1524960000000, 66660769651.39336],
        [1525046400000, 67420561666.31582],
        [1525132800000, 65710538602.21053],
        [1525219200000, 65971279396.12401],
        [1525305600000, 67108704081.6629],
        [1525392000000, 77526578541.87308],
        [1525478400000, 77194381804.21214],
        [1525564800000, 80104681672.56717],
        [1525651200000, 78019321356.67586],
        [1525737600000, 74353698902.32837],
        [1525824000000, 73923190963.45912],
        [1525910400000, 73870430059.20319],
        [1525996800000, 71279821648.68193],
        [1526083200000, 67108755487.4065],
        [1526169600000, 67905759626.548164],
        [1526256000000, 72264507767.86824],
        [1526342400000, 72371889345.97685],
        [1526428800000, 69935482264.3191],
        [1526515200000, 69879914021.89139],
        [1526601600000, 66230317444.47082],
        [1526688000000, 68292780311.34642],
        [1526774400000, 68270520548.85238],
        [1526860800000, 70073661062.42535],
        [1526947200000, 68604235449.74915],
        [1527033600000, 63856048464.81863],
        [1527120000000, 57906139337.23845],
        [1527206400000, 59572815257.15423],
        [1527292800000, 58033414970.12175],
        [1527379200000, 57866488201.56909],
        [1527465600000, 56426783298.552086],
        [1527552000000, 51022311776.744835],
        [1527638400000, 56032720818.86429],
        [1527724800000, 55406697681.99651],
        [1527811200000, 57052511798.36029],
        [1527897600000, 57274516594.26361],
        [1527984000000, 58214660996.41215],
        [1528070400000, 60839012156.67801],
        [1528156800000, 58582699275.82673],
        [1528243200000, 60003382388.410774],
        [1528329600000, 59769818240.26592],
        [1528416000000, 59473431631.17932],
        [1528502400000, 59023188216.61767],
        [1528588800000, 58097979895.17439],
        [1528675200000, 52297773130.593605],
        [1528761600000, 52922017100.80877],
        [1528848000000, 49526210494.67609],
        [1528934400000, 47513487722.27301],
        [1529020800000, 51705041671.85295],
        [1529107200000, 48591417578.37274],
        [1529193600000, 49365820389.2502],
        [1529280000000, 49208886719.57955],
        [1529366400000, 51325180407.15406],
        [1529452800000, 53296770078.92655],
        [1529539200000, 53083203703.900185],
        [1529625600000, 52182383816.74685],
        [1529712000000, 46506729655.2268],
        [1529798400000, 47422371814.92663],
        [1529884800000, 45701228403.58992],
        [1529971200000, 46032293630.19799],
        [1530057600000, 43200150825.554535],
        [1530144000000, 43938995892.49492],
        [1530230400000, 42240235077.69152],
        [1530316800000, 43417851340.14835],
        [1530403200000, 45125931776.53015],
        [1530489600000, 44959418542.5589],
        [1530576000000, 47442809332.14308],
        [1530662400000, 46185301814.019936],
        [1530748800000, 46548745985.99023],
        [1530835200000, 46925838686.869354],
        [1530921600000, 47006819801.91246],
        [1531008000000, 48417457589.09996],
        [1531094400000, 48656609005.5521],
        [1531180800000, 47248080178.500404],
        [1531267200000, 43440645610.1465],
        [1531353600000, 44582775234.01637],
        [1531440000000, 43058583252.44312],
        [1531526400000, 43417089532.37335],
        [1531612800000, 43478040808.58002],
        [1531699200000, 44980010137.45454],
        [1531785600000, 48172237051.237144],
        [1531872000000, 50093375646.64564],
        [1531958400000, 47968300051.31038],
        [1532044800000, 46849108851.47914],
        [1532131200000, 45085461873.32423],
        [1532217600000, 46191354520.09791],
        [1532304000000, 45944085755.28768],
        [1532390400000, 45185546979.493904],
        [1532476800000, 48149869331.10582],
        [1532563200000, 47304793330.43528],
        [1532649600000, 46520159725.86072],
        [1532736000000, 47245239452.64461],
        [1532822400000, 47046915978.43651],
        [1532908800000, 47108994325.494484],
        [1532995200000, 46276223652.12072],
        [1533081600000, 43685748084.88833],
        [1533168000000, 42171077988.81626],
        [1533254400000, 41537743616.40968],
        [1533340800000, 42196294616.106125],
        [1533427200000, 41145986285.06191],
        [1533513600000, 41385054072.208534],
        [1533600000000, 40953537749.75725],
        [1533686400000, 38223074883.08225],
        [1533772800000, 35791291191.22547],
        [1533859200000, 36814735141.44988],
        [1533945600000, 33664024161.54582],
        [1534032000000, 32133841450.27239],
        [1534118400000, 32205233601.695885],
        [1534204800000, 28868747042.36861],
        [1534291200000, 28143782821.250084],
        [1534377600000, 28457713331.487896],
        [1534464000000, 29013771356.624912],
        [1534550400000, 31900584937.308926],
        [1534636800000, 29731648689.35023],
        [1534723200000, 30373462667.100254],
        [1534809600000, 27502092651.06376],
        [1534896000000, 28451639052.689304],
        [1534982400000, 27322879481.120026],
        [1535068800000, 27993194187.78576],
        [1535155200000, 28606495007.326126],
        [1535241600000, 28227624888.171623],
        [1535328000000, 27764964383.52451],
        [1535414400000, 29048911774.353527],
        [1535500800000, 30108250592.80309],
        [1535587200000, 29316888089.791355],
        [1535673600000, 28983027590.772324],
        [1535760000000, 28697700432.171036],
        [1535846400000, 30072245775.768417],
        [1535932800000, 29960438166.83158],
        [1536019200000, 29383711204.563396],
        [1536105600000, 29050036562.782276],
        [1536192000000, 23699297868.898872],
        [1536278400000, 23333059272.415604],
        [1536364800000, 21937448595.359604],
        [1536451200000, 20053781147.975986],
        [1536537600000, 19952064720.055923],
        [1536624000000, 19995395751.499054],
        [1536710400000, 18758112117.52692],
        [1536796800000, 18646346738.68822],
        [1536883200000, 21593950253.338764],
        [1536969600000, 21564238470.858032],
        [1537056000000, 22752839429.228737],
        [1537142400000, 22455303413.059746],
        [1537228800000, 20051344766.00986],
        [1537315200000, 21444053203.54171],
        [1537401600000, 21345953864.748203],
        [1537488000000, 22905841034.88813],
        [1537574400000, 25265891712.53375],
        [1537660800000, 24698456681.437977],
        [1537747200000, 25008142946.45999],
        [1537833600000, 23326983438.531864],
        [1537920000000, 22430430093.888412],
        [1538006400000, 21996083267.123955],
        [1538092800000, 23427304227.532307],
        [1538179200000, 22728786275.759216],
        [1538265600000, 23704835590.94445],
        [1538352000000, 23810956054.875587],
        [1538438400000, 23660128684.62694],
        [1538524800000, 23102075847.08329],
        [1538611200000, 22578266460.225845],
        [1538697600000, 22746296668.385403],
        [1538784000000, 23346587563.266563],
        [1538870400000, 23047878586.785908],
        [1538956800000, 23108155611.30217],
        [1539043200000, 23515310563.890266],
        [1539129600000, 23360773268.500908],
        [1539216000000, 23136932206.46423],
        [1539302400000, 19696329362.922806],
        [1539388800000, 20205253420.305264],
        [1539475200000, 20618468437.571827],
        [1539561600000, 20154043106.326366],
        [1539648000000, 21758441436.75235],
        [1539734400000, 21797843239.523815],
        [1539820800000, 21665771379.758472],
        [1539907200000, 20978898040.00994],
        [1539993600000, 20944981899.996338],
        [1540080000000, 21245961049.679523],
        [1540166400000, 21194118735.35679],
        [1540252800000, 21065455939.758324],
        [1540339200000, 20971271070.992756],
        [1540425600000, 20915202600.824116],
        [1540512000000, 20806627800.42047],
        [1540598400000, 20912618603.71542],
        [1540684800000, 20895701368.92201],
        [1540771200000, 21050011679.152504],
        [1540857600000, 20141044164.60023],
        [1540944000000, 20319037427.370758],
        [1541030400000, 20443374890.788353],
        [1541116800000, 20504797883.626476],
        [1541203200000, 20827898393.480133],
        [1541289600000, 20607885132.10309],
        [1541376000000, 21305283379.90364],
        [1541462400000, 21542826214.062332],
        [1541548800000, 22564364808.51298],
        [1541635200000, 22526589761.074043],
        [1541721600000, 22014228200.633472],
        [1541808000000, 21743426340.7329],
        [1541894400000, 21899054776.88674],
        [1541980800000, 21915333222.812687],
        [1542067200000, 21835416808.274967],
        [1542153600000, 21526572966.38387],
        [1542240000000, 18991988237.013927],
        [1542326400000, 18833157524.209366],
        [1542412800000, 18173414086.234],
        [1542499200000, 18140877471.665012],
        [1542585600000, 18355720231.296604],
        [1542672000000, 15568477359.93989],
        [1542758400000, 13480873430.564436],
        [1542844800000, 14199281074.8261],
        [1542931200000, 13145643371.545767],
        [1543017600000, 12835445196.81356],
        [1543104000000, 11817983686.122585],
        [1543190400000, 12164898237.895905],
        [1543276800000, 11351812370.701126],
        [1543363200000, 11441393467.752829],
        [1543449600000, 12659773332.697258],
        [1543536000000, 12182154959.33421],
        [1543622400000, 11708062196.90978],
        [1543708800000, 12257640966.96176],
        [1543795200000, 11998542053.561508],
        [1543881600000, 11252886409.15223],
        [1543968000000, 11368927707.526571],
        [1544054400000, 10626501697.030668],
        [1544140800000, 9411545128.787203],
        [1544227200000, 9610584223.413237],
        [1544313600000, 9437707619.370005],
        [1544400000000, 9708220956.92728],
        [1544486400000, 9323959135.591385],
        [1544572800000, 9111391456.283594],
        [1544659200000, 9261188928.81479],
        [1544745600000, 8913719805.958307],
        [1544832000000, 8696897485.61424],
        [1544918400000, 8697750641.337017],
        [1545004800000, 8803144021.88444],
        [1545091200000, 9769901072.309902],
        [1545177600000, 10392925527.200373],
        [1545264000000, 10336785710.638008],
        [1545350400000, 11865192793.01515],
        [1545436800000, 11228235724.642712],
        [1545523200000, 11930676648.438414],
        [1545609600000, 13420144843.953455],
        [1545696000000, 14528137553.21583],
        [1545782400000, 13232839336.104912],
        [1545868800000, 13473635804.054379],
        [1545955200000, 11924339225.89629],
        [1546041600000, 13935614204.209122],
        [1546128000000, 14676638050.826061],
        [1546214400000, 14310166049.405546],
        [1546300800000, 13677300455.463268],
        [1546387200000, 14392313036.760887],
        [1546473600000, 15922214147.12651],
        [1546560000000, 15277722818.958939],
        [1546646400000, 15940848169.781616],
        [1546732800000, 16018302447.242657],
        [1546819200000, 16115376656.500986],
        [1546905600000, 15614190928.600574],
        [1546992000000, 15452130779.24307],
        [1547078400000, 15516781022.69595],
        [1547164800000, 13229569871.910849],
        [1547251200000, 12975985683.226751],
        [1547337600000, 12909473758.745958],
        [1547424000000, 11970884149.01689],
        [1547510400000, 13312835355.826275],
        [1547596800000, 12511274618.87375],
        [1547683200000, 12790017531.356869],
        [1547769600000, 12732832650.46048],
        [1547856000000, 12441677743.686483],
        [1547942400000, 12841756778.277468],
        [1548028800000, 12288060353.539341],
        [1548115200000, 12103985769.178844],
        [1548201600000, 12313725371.510918],
        [1548288000000, 12169120160.834082],
        [1548374400000, 12153158417.51909],
        [1548460800000, 12040473330.175814],
        [1548547200000, 12061900817.036028],
        [1548633600000, 11774157608.884583],
        [1548720000000, 11010938397.321363],
        [1548806400000, 10955187408.251522],
        [1548892800000, 11329885765.71566],
        [1548979200000, 11125649845.205639],
        [1549065600000, 11200980015.417768],
        [1549152000000, 11387807226.075611],
        [1549238400000, 11229439070.409464],
        [1549324800000, 11267355154.846409],
        [1549411200000, 11245947689.319769],
        [1549497600000, 10959290841.797949],
        [1549584000000, 10923364917.939417],
        [1549670400000, 12490351594.278725],
        [1549756800000, 12520670865.692413],
        [1549843200000, 13087985837.282696],
        [1549929600000, 12680390197.310715],
        [1550016000000, 12845310573.797472],
        [1550102400000, 12793730452.297075],
        [1550188800000, 12668201016.366795],
        [1550275200000, 12742765419.399738],
        [1550361600000, 12903776985.469166],
        [1550448000000, 14098327882.16335],
        [1550534400000, 15243928177.166456],
        [1550620800000, 15072819715.874296],
        [1550707200000, 15611440200.106087],
        [1550793600000, 15225793598.680445],
        [1550880000000, 15537602228.061953],
        [1550966400000, 16550258314.845478],
        [1551052800000, 14284713525.163023],
        [1551139200000, 14472468022.306929],
        [1551225600000, 14307393197.668602],
        [1551312000000, 14178777736.552544],
        [1551398400000, 14233212113.400938],
        [1551484800000, 14187938674.158926],
        [1551571200000, 13922485507.386408],
        [1551657600000, 13708633834.78851],
        [1551744000000, 13206850625.680403],
        [1551830400000, 14358338788.931305],
        [1551916800000, 14452766138.766956],
        [1552003200000, 14369621725.628769],
        [1552089600000, 14031460882.113087],
        [1552176000000, 14428962251.829548],
        [1552262400000, 14309692872.294827],
        [1552348800000, 13919712301.577475],
        [1552435200000, 14031839989.199167],
        [1552521600000, 13852442749.06418],
        [1552608000000, 13903058196.646849],
        [1552694400000, 14361253091.20203],
        [1552780800000, 14864545411.806246],
        [1552867200000, 14586546977.699165],
        [1552953600000, 14504816696.08246],
        [1553040000000, 14588139258.964716],
        [1553126400000, 14646332154.545843],
        [1553212800000, 14226418657.384989],
        [1553299200000, 14349872413.090286],
        [1553385600000, 14458951512.820686],
        [1553472000000, 14349083379.676003],
        [1553558400000, 14114508110.83552],
        [1553644800000, 14127347666.266151],
        [1553731200000, 14728909325.816017],
        [1553817600000, 14528588960.681654],
        [1553904000000, 15091573435.250275],
        [1553990400000, 15005382590.00451],
        [1554076800000, 14930496588.280657],
        [1554163200000, 14928001079.066639],
        [1554249600000, 17376379496.109585],
        [1554336000000, 17073994347.06151],
        [1554422400000, 16631238666.397068],
        [1554508800000, 17415767438.96335],
        [1554595200000, 17431813826.27367],
        [1554681600000, 18330615695.15078],
        [1554768000000, 19005528101.207676],
        [1554854400000, 18514941900.58221],
        [1554940800000, 18738487750.9329],
        [1555027200000, 17442825237.19966],
        [1555113600000, 17386399385.122326],
        [1555200000000, 17327865226.321712],
        [1555286400000, 17700075886.552334],
        [1555372800000, 16995977336.81406],
        [1555459200000, 17609171351.003345],
        [1555545600000, 17588516336.63911],
        [1555632000000, 18314771488.822433],
        [1555718400000, 18342659764.36273],
        [1555804800000, 18306343766.281067],
        [1555891200000, 17972610400.17828],
        [1555977600000, 18087557354.45475],
        [1556064000000, 18058590378.524723],
        [1556150400000, 17513743539.566673],
        [1556236800000, 16278218288.123909],
        [1556323200000, 16557928986.13092],
        [1556409600000, 16729345051.253407],
        [1556496000000, 16559733009.547436],
        [1556582400000, 16314567668.36402],
        [1556668800000, 17017994744.389439],
        [1556755200000, 16881275146.405905],
        [1556841600000, 17057211134.916904],
        [1556928000000, 17632782365.111347],
        [1557014400000, 17251245248.674953],
        [1557100800000, 17213549032.231903],
        [1557187200000, 18328559385.30721],
        [1557273600000, 18164993797.052807],
        [1557360000000, 18006362667.522446],
        [1557446400000, 18116042476.197884],
        [1557532800000, 18289505929.628407],
        [1557619200000, 21127886686.212933],
        [1557705600000, 19825564759.39219],
        [1557792000000, 20785018542.369095],
        [1557878400000, 23191421813.968952],
        [1557964800000, 26321593931.32679],
        [1558051200000, 27977152978.337208],
        [1558137600000, 25882741084.84837],
        [1558224000000, 24992104363.05817],
        [1558310400000, 27696392705.66153],
        [1558396800000, 26710731424.55328],
        [1558483200000, 27208222408.161823],
        [1558569600000, 25986179169.035725],
        [1558656000000, 26015066752.046535],
        [1558742400000, 26403380130.094868],
        [1558828800000, 26638407046.872597],
        [1558915200000, 28248518480.178585],
        [1559001600000, 29061104048.029087],
        [1559088000000, 28849462681.914993],
        [1559174400000, 28545915354.884007],
        [1559260800000, 27163085813.469257],
        [1559347200000, 28499454382.30483],
        [1559433600000, 28173804952.878986],
        [1559520000000, 28695353819.42512],
        [1559606400000, 26744262117.273018],
        [1559692800000, 25505094563.584915],
        [1559779200000, 26217390413.075325],
        [1559865600000, 26581663259.716743],
        [1559952000000, 26701545587.49556],
        [1560038400000, 26034305601.98276],
        [1560124800000, 24672828577.74306],
        [1560211200000, 26297325019.25777],
        [1560297600000, 26090193634.664776],
        [1560384000000, 27568882097.168007],
        [1560470400000, 27144233034.692554],
        [1560556800000, 28177766390.07106],
        [1560643200000, 28555069614.98048],
        [1560729600000, 28730739929.02647],
        [1560816000000, 29075955330.534096],
        [1560902400000, 28216787936.482452],
        [1560988800000, 28662304286.474934],
        [1561075200000, 29062957235.75888],
        [1561161600000, 31419443062.588604],
        [1561248000000, 33084459417.56612],
        [1561334400000, 32863219243.396492],
        [1561420800000, 33154422291.905163],
        [1561507200000, 33909642984.874393],
        [1561593600000, 36033928259.050896],
        [1561680000000, 31578278682.118446],
        [1561766400000, 33044592666.437965],
        [1561852800000, 34306768610.59132],
        [1561939200000, 31176379854.59102],
        [1562025600000, 31498970007.217487],
        [1562112000000, 30959350149.490692],
        [1562198400000, 32463482348.950417],
        [1562284800000, 30439535096.682316],
        [1562371200000, 30740130568.29118],
        [1562457600000, 30937887121.98499],
        [1562544000000, 32585483973.188705],
        [1562630400000, 33325496553.950596],
        [1562716800000, 32850299631.681156],
        [1562803200000, 30956973577.480614],
        [1562889600000, 28636795382.556053],
        [1562976000000, 29477046976.610035],
        [1563062400000, 28810921295.770554],
        [1563148800000, 24246464997.184734],
        [1563235200000, 24761194859.108467],
        [1563321600000, 21404242629.096542],
        [1563408000000, 22555777896.46238],
        [1563494400000, 24469199432.985012],
        [1563580800000, 23638366539.887043],
        [1563667200000, 24370718738.349724],
        [1563753600000, 24197616725.96213],
        [1563840000000, 23300408113.461063],
        [1563926400000, 22833250656.49764],
        [1564012800000, 23289112074.867935],
        [1564099200000, 23512755315.51417],
        [1564185600000, 23510568057.420704],
        [1564272000000, 22113111388.41074],
        [1564358400000, 22676505895.192112],
        [1564444800000, 22532332968.39789],
        [1564531200000, 22459680355.763912],
        [1564617600000, 23395542240.652126],
        [1564704000000, 23212512549.342392],
        [1564790400000, 23340842011.61525],
        [1564876800000, 23728815859.720734],
        [1564963200000, 23801827044.905396],
        [1565049600000, 24942936105.273483],
        [1565136000000, 24246876267.774906],
        [1565222400000, 24246033283.292213],
        [1565308800000, 23748361769.302696],
        [1565395200000, 22496236375.06767],
        [1565481600000, 22164896130.494186],
        [1565568000000, 23199201016.471577],
        [1565654400000, 22642683362.924953],
        [1565740800000, 22436740682.994324],
        [1565827200000, 19905411203.78774],
        [1565913600000, 20166571469.133984],
        [1566000000000, 19875538705.637814],
        [1566086400000, 19947175522.901073],
        [1566172800000, 20870523158.477386],
        [1566259200000, 21805354686.68139],
        [1566345600000, 21099092813.884506],
        [1566432000000, 20090814029.40346],
        [1566518400000, 20579733353.237606],
        [1566604800000, 20886288389.848602],
        [1566691200000, 20497351303.834663],
        [1566777600000, 20024597154.008755],
        [1566864000000, 20251623745.88952],
        [1566950400000, 20099329728.45356],
        [1567036800000, 18598885730.967613],
        [1567123200000, 18182244936.177788],
        [1567209600000, 18137815999.552658],
        [1567296000000, 18507223778.142094],
        [1567382400000, 18351207184.536457],
        [1567468800000, 19113080684.062847],
        [1567555200000, 19324838795.229195],
        [1567641600000, 18790062457.39357],
        [1567728000000, 18756294625.6701],
        [1567814400000, 18244685096.248436],
        [1567900800000, 19145726416.543644],
        [1567987200000, 19532846873.76019],
        [1568073600000, 19501252636.159073],
        [1568160000000, 19398770840.28421],
        [1568246400000, 19174005533.27935],
        [1568332800000, 19496200878.17006],
        [1568419200000, 19522068957.29138],
        [1568505600000, 20297648872.897217],
        [1568592000000, 20389680178.128475],
        [1568678400000, 21223483200.061485],
        [1568764800000, 22573128316.388668],
        [1568851200000, 22689784462.139828],
        [1568937600000, 23838258551.56916],
        [1569024000000, 23468933218.644382],
        [1569110400000, 23241002834.289497],
        [1569196800000, 22812625864.180454],
        [1569283200000, 21659224040.642677],
        [1569369600000, 18418924820.330776],
        [1569456000000, 18387961477.667904],
        [1569542400000, 18012013650.053577],
        [1569628800000, 18764435751.517056],
        [1569715200000, 18778305331.488625],
        [1569801600000, 18340364798.35873],
        [1569888000000, 19371744530.934284],
        [1569974400000, 19506079956.197968],
        [1570060800000, 19515449012.870743],
        [1570147200000, 19083597997.072533],
        [1570233600000, 19013321261.257965],
        [1570320000000, 19009613625.563313],
        [1570406400000, 18414715905.660275],
        [1570492800000, 19492030354.883118],
        [1570579200000, 19537703569.993225],
        [1570665600000, 20896473661.552616],
        [1570752000000, 20655168596.9981],
        [1570838400000, 19490975562.25558],
        [1570924800000, 19456204702.653095],
        [1571011200000, 19636637034.909355],
        [1571097600000, 20182332549.589005],
        [1571184000000, 19551821811.16607],
        [1571270400000, 18931847830.822693],
        [1571356800000, 19185722528.28324],
        [1571443200000, 18701016660.84209],
        [1571529600000, 18584020382.348053],
        [1571616000000, 18941808902.195774],
        [1571702400000, 18867384241.158245],
        [1571788800000, 18558740241.107227],
        [1571875200000, 17459645841.196804],
        [1571961600000, 17399743469.054173],
        [1572048000000, 19637705780.19648],
        [1572134400000, 19404848951.545723],
        [1572220800000, 19901421621.580063],
        [1572307200000, 19665873705.505238],
        [1572393600000, 20630859876.73889],
        [1572480000000, 19844553056.696297],
        [1572566400000, 19802989402.16403],
        [1572652800000, 19841838289.17925],
        [1572739200000, 19859708138.2688],
        [1572825600000, 19716789940.491096],
        [1572912000000, 20141787853.298763],
        [1572998400000, 20492280668.62314],
        [1573084800000, 20737428494.66662],
        [1573171200000, 20266199492.292927],
        [1573257600000, 19922829399.444794],
        [1573344000000, 20084569101.090305],
        [1573430400000, 20498434183.96548],
        [1573516800000, 20053149255.624203],
        [1573603200000, 20253767836.417084],
        [1573689600000, 20432066850.22492],
        [1573776000000, 20069162255.474976],
        [1573862400000, 19545090767.174282],
        [1573948800000, 19844844655.043144],
        [1574035200000, 19958777557.908268],
        [1574121600000, 19368856364.95644],
        [1574208000000, 19065767350.091602],
        [1574294400000, 18988847253.54836],
        [1574380800000, 17491549308.65305],
        [1574467200000, 16287556192.270582],
        [1574553600000, 16542855738.706274],
        [1574640000000, 15475834863.359888],
        [1574726400000, 15886464708.930641],
        [1574812800000, 16079208209.774965],
        [1574899200000, 16624532572.655481],
        [1574985600000, 16410189698.68012],
        [1575072000000, 16758887510.141882],
        [1575158400000, 16526371128.750948],
        [1575244800000, 16406763648.56546],
        [1575331200000, 16190436587.112755],
        [1575417600000, 16020038545.991947],
        [1575504000000, 15854160155.08143],
        [1575590400000, 16080828336.473515],
        [1575676800000, 16139573040.723936],
        [1575763200000, 16002334420.498518],
        [1575849600000, 16377855974.169815],
        [1575936000000, 16043706474.69232],
        [1576022400000, 15849875150.02611],
        [1576108800000, 15578842749.139772],
        [1576195200000, 15788957248.11528],
        [1576281600000, 15750794124.489826],
        [1576368000000, 15443355857.400137],
        [1576454400000, 15508762801.226492],
        [1576540800000, 14450939875.930876],
        [1576627200000, 13287532088.063696],
        [1576713600000, 14431239759.79371],
        [1576800000000, 13910360785.04459],
        [1576886400000, 13960011409.004557],
        [1576972800000, 13835225941.813122],
        [1577059200000, 14364983064.322144],
        [1577145600000, 13932981482.574308],
        [1577232000000, 13937886620.894697],
        [1577318400000, 13638505587.030851],
        [1577404800000, 13697038140.554945],
        [1577491200000, 13773176348.366127],
        [1577577600000, 13985982758.17354],
        [1577664000000, 14666626034.39065],
        [1577750400000, 14330460495.39055],
        [1577836800000, 14097451631.783957],
        [1577923200000, 14230588104.281237],
        [1578009600000, 13859793329.814827],
        [1578096000000, 14613194775.854177],
        [1578182400000, 14636213160.069315],
        [1578268800000, 14738834254.266087],
        [1578355200000, 15692578635.119993],
        [1578441600000, 15656491188.064734],
        [1578528000000, 15364547561.67077],
        [1578614400000, 14998595868.023438],
        [1578700800000, 15647071448.436348],
        [1578787200000, 15564837467.520994],
        [1578873600000, 15859031094.44091],
        [1578960000000, 15715287657.358463],
        [1579046400000, 18075462340.568443],
        [1579132800000, 18104742352.015366],
        [1579219200000, 17869543294.566414],
        [1579305600000, 18727969032.79025],
        [1579392000000, 19138755594.52358],
        [1579478400000, 18220366702.244606],
        [1579564800000, 18243871990.6739],
        [1579651200000, 18460475135.804626],
        [1579737600000, 18358377435.95901],
        [1579824000000, 17722969842.693172],
        [1579910400000, 17777723072.283943],
        [1579996800000, 17587666193.195988],
        [1580083200000, 18279197138.315884],
        [1580169600000, 18576520021.386745],
        [1580256000000, 19212409346.36024],
        [1580342400000, 19140249467.28951],
        [1580428800000, 20090242968.224346],
        [1580515200000, 19751392940.997242],
        [1580601600000, 20079997942.89275],
        [1580688000000, 20619310835.276108],
        [1580774400000, 20791014743.301926],
        [1580860800000, 20665245888.10715],
        [1580947200000, 22414850796.683693],
        [1581033600000, 23314707733.86607],
        [1581120000000, 24407934017.312393],
        [1581206400000, 24478412143.01876],
        [1581292800000, 25011126326.73704],
        [1581379200000, 24577280465.17777],
        [1581465600000, 25966283262.662064],
        [1581552000000, 29001954205.996136],
        [1581638400000, 29312880235.867893],
        [1581724800000, 31059453816.69378],
        [1581811200000, 28909877146.05339],
        [1581897600000, 28746450516.039288],
        [1581984000000, 29075052114.63163],
        [1582070400000, 31034929114.66128],
        [1582156800000, 28662558273.135616],
        [1582243200000, 28258309183.25315],
        [1582329600000, 29114570356.366127],
        [1582416000000, 28657670954.134422],
        [1582502400000, 30135977087.940575],
        [1582588800000, 28974292556.854683],
        [1582675200000, 27274600634.783813],
        [1582761600000, 24823014146.598347],
        [1582848000000, 24879553838.25944],
        [1582934400000, 25137364865.97101],
        [1583020800000, 24105658618.96741],
        [1583107200000, 24049874527.90718],
        [1583193600000, 25392608994.495888],
        [1583280000000, 24709361638.74632],
        [1583366400000, 24635535324.34428],
        [1583452800000, 25072941968.371387],
        [1583539200000, 26861442576.626507],
        [1583625600000, 26199142579.509506],
        [1583712000000, 22145350491.45419],
        [1583798400000, 22001125399.41239],
        [1583884800000, 22178131746.591427],
        [1583971200000, 21375073649.951973],
        [1584057600000, 11956631650.303354],
        [1584144000000, 14567054564.19822],
        [1584230400000, 13505998666.239887],
        [1584316800000, 13756072458.523424],
        [1584403200000, 12178760413.015514],
        [1584489600000, 12991193734.51619],
        [1584576000000, 12900856057.87349],
        [1584662400000, 15068113354.89011],
        [1584748800000, 14451395504.502293],
        [1584835200000, 14575207631.098148],
        [1584921600000, 13499834314.773163],
        [1585008000000, 14944699607.313162],
        [1585094400000, 15385255695.77982],
        [1585180800000, 15020580578.564753],
        [1585267200000, 15277363230.972204],
        [1585353600000, 14958737456.74538],
        [1585440000000, 14468066254.835968],
        [1585526400000, 13819843925.360764],
        [1585612800000, 14615131244.892355],
        [1585699200000, 14699970260.177477],
        [1585785600000, 14896572551.520117],
        [1585872000000, 15522802609.007648],
        [1585958400000, 15597757554.270603],
        [1586044800000, 15898519073.894499],
        [1586131200000, 15770466064.128725],
        [1586217600000, 18679968361.111393],
        [1586304000000, 18166748814.85624],
        [1586390400000, 19089126835.76443],
        [1586476800000, 18787453383.948494],
        [1586563200000, 17375118634.991734],
        [1586649600000, 17489565908.771362],
        [1586736000000, 17790409219.818943],
        [1586822400000, 17316641314.145325],
        [1586908800000, 17559917949.258163],
        [1586995200000, 16933905371.155535],
        [1587081600000, 18989526634.23506],
        [1587168000000, 18844786940.26037],
        [1587254400000, 20686869606.075146],
        [1587340800000, 19894212517.443478],
        [1587427200000, 18894679854.84961],
        [1587513600000, 18883297426.60788],
        [1587600000000, 20165266729.19022],
        [1587686400000, 20371607313.859978],
        [1587772800000, 20756708748.58927],
        [1587859200000, 21449928671.504494],
        [1587945600000, 21798344583.464844],
        [1588032000000, 21715118585.744934],
        [1588118400000, 21824818237.698734],
        [1588204800000, 23796586177.48911],
        [1588291200000, 22894189506.373463],
        [1588377600000, 23540629181.114624],
        [1588464000000, 23700169401.049606],
        [1588550400000, 23254687127.813503],
        [1588636800000, 22819858077.111965],
        [1588723200000, 22740460359.466698],
        [1588809600000, 22529347281.111214],
        [1588896000000, 23678773321.927563],
        [1588982400000, 23476076684.66088],
        [1589068800000, 23328821609.133904],
        [1589155200000, 20859785783.852856],
        [1589241600000, 20616754314.258286],
        [1589328000000, 20993946147.950317],
        [1589414400000, 22169692740.7452],
        [1589500800000, 22562017222.46954],
        [1589587200000, 21656663418.09443],
        [1589673600000, 22256509489.004852],
        [1589760000000, 22938430778.9383],
        [1589846400000, 23793534838.638046],
        [1589932800000, 23656360817.218487],
        [1590019200000, 23303624068.60854],
        [1590105600000, 22089212414.442772],
        [1590192000000, 22960218146.427925],
        [1590278400000, 22943289960.014706],
        [1590364800000, 22417907964.48714],
        [1590451200000, 22639118588.39778],
        [1590537600000, 22325672542.330963],
        [1590624000000, 23085068545.910442],
        [1590710400000, 24328143646.44253],
        [1590796800000, 24504031642.684784],
        [1590883200000, 27012940828.466957],
        [1590969600000, 25671662155.409367],
        [1591056000000, 27559451838.43636],
        [1591142400000, 26414164713.757725],
        [1591228800000, 27137533088.4328],
        [1591315200000, 27105225697.6403],
        [1591401600000, 26722521046.76495],
        [1591488000000, 26923196533.750423],
        [1591574400000, 27129407338.340355],
        [1591660800000, 27441367803.298782],
        [1591747200000, 27188436885.46926],
        [1591833600000, 27480866985.686268],
        [1591920000000, 25655172292.438347],
        [1592006400000, 26414947184.76374],
        [1592092800000, 26503078976.085632],
        [1592179200000, 25856836267.17579],
        [1592265600000, 25743924409.197376],
        [1592352000000, 26206196967.82947],
        [1592438400000, 26079437153.80169],
        [1592524800000, 25847377552.221436],
        [1592611200000, 25534271650.26011],
        [1592697600000, 25501270877.342506],
        [1592784000000, 25381090910.620564],
        [1592870400000, 27083046206.889904],
        [1592956800000, 27097142032.576195],
        [1593043200000, 26239099771.426495],
        [1593129600000, 25963410301.573772],
        [1593216000000, 25643178437.809326],
        [1593302400000, 24650756780.212147],
        [1593388800000, 25059706534.523754],
        [1593475200000, 25465535919.172665],
        [1593561600000, 25169333234.47631],
        [1593648000000, 25738012877.807854],
        [1593734400000, 25284990219.958416],
        [1593820800000, 25132071680.625286],
        [1593907200000, 25572481115.808292],
        [1593993600000, 25442148685.628662],
        [1594080000000, 26966777214.595856],
        [1594166400000, 26667827703.348965],
        [1594252800000, 27538955079.081337],
        [1594339200000, 27017961197.637337],
        [1594425600000, 26917385878.316235],
        [1594512000000, 26712364780.153114],
        [1594598400000, 26889793823.339703],
        [1594684800000, 26757968884.421734],
        [1594771200000, 26870705032.694412],
        [1594857600000, 26686755678.99533],
        [1594944000000, 26142878196.66504],
        [1595030400000, 26015174195.367386],
        [1595116800000, 26352655621.666836],
        [1595203200000, 26715274452.48371],
        [1595289600000, 26401433618.61543],
        [1595376000000, 27404691587.94453],
        [1595462400000, 29528105819.948067],
        [1595548800000, 30714049723.16856],
        [1595635200000, 31236557399.18939],
        [1595721600000, 34136825749.960796],
        [1595808000000, 34754323492.16516],
        [1595894400000, 35873239729.40686],
        [1595980800000, 35422047874.02414],
        [1596067200000, 35553131948.946304],
        [1596153600000, 37564052087.124535],
        [1596240000000, 38794882275.39032],
        [1596326400000, 43411673380.48453],
        [1596412800000, 41469942047.79405],
        [1596499200000, 43272376856.193855],
        [1596585600000, 43787281284.318275],
        [1596672000000, 44844619093.01642],
        [1596758400000, 44217734699.095276],
        [1596844800000, 42541561650.30221],
        [1596931200000, 43821378072.89572],
        [1597017600000, 43682388559.8162],
        [1597104000000, 44298575284.664795],
        [1597190400000, 42639702440.41078],
        [1597276800000, 43436076387.69582],
        [1597363200000, 47693538253.1255],
        [1597449600000, 49022330904.64755],
        [1597536000000, 48462771178.61529],
        [1597622400000, 48635333662.57019],
        [1597708800000, 48259667903.08671],
        [1597795200000, 47400903599.29812],
        [1597881600000, 45642757811.590775],
        [1597968000000, 46754739209.41609],
        [1598054400000, 43434501400.18814],
        [1598140800000, 44454504740.72998],
        [1598227200000, 44021875258.788315],
        [1598313600000, 45914782850.88361],
        [1598400000000, 43097616381.47341],
        [1598486400000, 43337732292.52325],
        [1598572800000, 42903096293.90094],
        [1598659200000, 44402928843.0099],
        [1598745600000, 44884412904.61814],
        [1598832000000, 48092923342.6494],
        [1598918400000, 49098776608.15353],
        [1599004800000, 54146717970.58548],
        [1599091200000, 49401551509.00041],
        [1599177600000, 43135808902.68998],
        [1599264000000, 43627087090.328964],
        [1599350400000, 37655618254.050865],
        [1599436800000, 39710363028.2121],
        [1599523200000, 39639961273.34071],
        [1599609600000, 37835807856.34224],
        [1599696000000, 39580368571.35996],
        [1599782400000, 41300327191.463684],
        [1599868800000, 42135842526.32081],
        [1599955200000, 43393172173.54062],
        [1600041600000, 41170490544.06533],
        [1600128000000, 42353184458.585205],
        [1600214400000, 41019180031.929504],
        [1600300800000, 41136175614.140686],
        [1600387200000, 43828530075.491394],
        [1600473600000, 43009572687.415535],
        [1600560000000, 43455110863.06883],
        [1600646400000, 41929980576.65271],
        [1600732800000, 38695806846.97656],
        [1600819200000, 38679912764.2938],
        [1600905600000, 36239170986.342445],
        [1600992000000, 39314719146.84341],
        [1601078400000, 39598389061.56603],
        [1601164800000, 39946698187.61045],
        [1601251200000, 40273903426.08249],
        [1601337600000, 39794887269.461235],
        [1601424000000, 40574256067.82458],
        [1601510400000, 40586538615.954575],
        [1601596800000, 39782583382.89109],
        [1601683200000, 39005631807.605095],
        [1601769600000, 39098719406.98244],
        [1601856000000, 39758373727.61445],
        [1601942400000, 39890007481.293],
        [1602028800000, 38419834420.75508],
        [1602115200000, 38556379197.91778],
        [1602201600000, 39539898845.29083],
        [1602288000000, 41261693685.49925],
        [1602374400000, 41919449520.12781],
        [1602460800000, 42273071872.950066],
        [1602547200000, 43787622889.79302],
        [1602633600000, 43068111158.10915],
        [1602720000000, 42827891085.95138],
        [1602806400000, 42625398559.363594],
        [1602892800000, 41424753265.12099],
        [1602979200000, 41654901940.043465],
        [1603065600000, 42730815492.78595],
        [1603152000000, 42919073937.74135],
        [1603238400000, 41623179886.79757],
        [1603324800000, 44137912522.42472],
        [1603411200000, 46776265907.292244],
        [1603497600000, 46233128841.054825],
        [1603584000000, 46625376044.79527],
        [1603670400000, 45985592948.88831],
        [1603756800000, 44537523611.830605],
        [1603843200000, 45687568527.06391],
        [1603929600000, 43943405500.51123],
        [1604016000000, 43798925859.1192],
        [1604102400000, 43341298725.501526],
        [1604188800000, 43692767478.3082],
        [1604275200000, 44687833549.98935],
        [1604361600000, 43437224863.28803],
        [1604448000000, 43903834507.362854],
        [1604534400000, 45360338717.11154],
        [1604620800000, 47077208350.069885],
        [1604707200000, 51546745852.677986],
        [1604793600000, 49463138492.48698],
        [1604880000000, 51682115129.3641],
        [1604966400000, 50377035452.20988],
        [1605052800000, 51000796202.07293],
        [1605139200000, 52569790942.69943],
        [1605225600000, 52548309254.49251],
        [1605312000000, 53963588841.127716],
        [1605398400000, 52476765553.11609],
        [1605484800000, 50978678612.60896],
        [1605571200000, 52356872782.26836],
        [1605657600000, 54716609289.542305],
        [1605744000000, 54435713128.05311],
        [1605830400000, 53447508889.231575],
        [1605916800000, 57875755337.83428],
        [1606003200000, 62062910159.27977],
        [1606089600000, 64219272617.02369],
        [1606176000000, 68705033455.82259],
        [1606262400000, 68461935305.63655],
        [1606348800000, 64369854808.743355],
        [1606435200000, 58947811751.581924],
        [1606521600000, 58773961397.899704],
        [1606608000000, 61290956964.36442],
        [1606694400000, 65121266717.863914],
        [1606780800000, 69576210026.93625],
        [1606867200000, 66950738084.67838],
        [1606953600000, 68153602806.21818],
        [1607040000000, 70109767421.9594],
        [1607126400000, 64944263291.91259],
        [1607212800000, 67764042948.9772],
        [1607299200000, 68483582708.01654],
        [1607385600000, 67474119685.86511],
        [1607472000000, 63305670197.47529],
        [1607558400000, 65276424697.152855],
        [1607644800000, 63759073874.73225],
        [1607731200000, 62124158560.85701],
        [1607817600000, 64655118180.3175],
        [1607904000000, 67134794851.681],
        [1607990400000, 66629545532.422066],
        [1608076800000, 67041487308.61978],
        [1608163200000, 72301103108.2225],
        [1608249600000, 72955533475.1786],
        [1608336000000, 74135277407.04251],
        [1608422400000, 75092677592.1047],
        [1608508800000, 72618785345.5407],
        [1608595200000, 69545799024.42459],
        [1608681600000, 72327947935.45154],
        [1608768000000, 67468061368.13851],
        [1608854400000, 69867514814.01097],
        [1608940800000, 71422858091.1277],
        [1609027200000, 72391403990.8806],
        [1609113600000, 78833072066.0927],
        [1609200000000, 83575563402.66385],
        [1609286400000, 83885243123.81543],
        [1609372800000, 85790178023.44371],
        [1609459200000, 84037694047.35515],
        [1609545600000, 83294083323.20639],
        [1609632000000, 88595346035.55156],
        [1609718400000, 112525376104.65454],
        [1609804800000, 116693162132.74934],
        [1609891200000, 125112930227.72487],
        [1609977600000, 137306782958.13135],
        [1610064000000, 139084939665.71393],
        [1610150400000, 139145700786.27826],
        [1610236800000, 147397891905.5662],
        [1610323200000, 144775179901.584],
        [1610409600000, 124728988376.03725],
        [1610496000000, 119330774034.78914],
        [1610582400000, 128582223216.03041],
        [1610668800000, 138240285220.32953],
        [1610755200000, 133962341262.37857],
        [1610841600000, 141369013157.282],
        [1610928000000, 142087383372.707],
        [1611014400000, 143378258827.21744],
        [1611100800000, 157929393133.71045],
        [1611187200000, 156457140894.28006],
        [1611273600000, 130713677392.4996],
        [1611360000000, 140271927461.8329],
        [1611446400000, 140773242456.76056],
        [1611532800000, 158167202909.14236],
        [1611619200000, 151940348541.692],
        [1611705600000, 155446593758.92108],
        [1611792000000, 141774137750.6659],
        [1611878400000, 152877389472.9714],
        [1611964800000, 158354114909.26474],
        [1612051200000, 157410718624.21973],
        [1612137600000, 150322722005.1637],
        [1612224000000, 155894407853.50046],
        [1612310400000, 174557101587.54166],
        [1612396800000, 189023794263.7008],
        [1612483200000, 183982328605.4526],
        [1612569600000, 196424150368.47876],
        [1612656000000, 192164042318.95844],
        [1612742400000, 184109385158.1494],
        [1612828800000, 198719063426.16904],
        [1612915200000, 203102939465.473],
        [1613001600000, 199919369275.37125],
        [1613088000000, 203774834938.25632],
        [1613174400000, 210344318124.73236],
        [1613260800000, 207890872554.91016],
        [1613347200000, 207057026271.03055],
        [1613433600000, 203972439068.60135],
        [1613520000000, 204528599567.24704],
        [1613606400000, 211713928319.03534],
        [1613692800000, 221863277318.4042],
        [1613779200000, 226038410300.14252],
        [1613865600000, 221404545098.43417],
        [1613952000000, 223004310602.46854],
        [1614038400000, 204731064486.9056],
        [1614124800000, 179691686731.1511],
        [1614211200000, 185843232352.39114],
        [1614297600000, 168658293547.98462],
        [1614384000000, 167151176870.9801],
        [1614470400000, 167833687528.28204],
        [1614556800000, 163289758568.53607],
        [1614643200000, 179885012253.05722],
        [1614729600000, 172017777678.2973],
        [1614816000000, 182133382817.63593],
        [1614902400000, 177647949967.0106],
        [1614988800000, 176268279969.45792],
        [1615075200000, 191505024384.2956],
        [1615161600000, 198695961501.5439],
        [1615248000000, 211132676701.59906],
        [1615334400000, 214412034435.93906],
        [1615420800000, 207916309215.61444],
        [1615507200000, 210018342198.09778],
        [1615593600000, 203771682506.1516],
        [1615680000000, 222405459430.76758],
        [1615766400000, 215367930757.887],
        [1615852800000, 207264868908.9888],
        [1615939200000, 207059072434.72623],
        [1616025600000, 210150824167.56415],
        [1616112000000, 205022936620.98633],
        [1616198400000, 209188785173.5874],
        [1616284800000, 209455699202.5177],
        [1616371200000, 206013222508.0891],
        [1616457600000, 195434685366.63266],
        [1616544000000, 192388701720.41965],
        [1616630400000, 183005042939.6772],
        [1616716800000, 182810172113.94278],
        [1616803200000, 195511706252.9431],
        [1616889600000, 197943394049.89084],
        [1616976000000, 194647792269.71985],
        [1617062400000, 209088522651.80023],
        [1617148800000, 212080597430.12268],
        [1617235200000, 221210690537.73203],
        [1617321600000, 227187483748.8278],
        [1617408000000, 246446284273.69153],
        [1617494400000, 233519912234.63],
        [1617580800000, 239721240194.1402],
        [1617667200000, 242118495324.83636],
        [1617753600000, 244306254505.299],
        [1617840000000, 229324263726.26553],
        [1617926400000, 240116470865.16214],
        [1618012800000, 238825167325.68292],
        [1618099200000, 246336623343.47812],
        [1618185600000, 247431817683.18906],
        [1618272000000, 247289165411.20078],
        [1618358400000, 265314823430.1463],
        [1618444800000, 281118122172.12756],
        [1618531200000, 290591950682.4659],
        [1618617600000, 280617563098.8275],
        [1618704000000, 271060261616.9552],
        [1618790400000, 259329079995.44214],
        [1618876800000, 251090482814.8267],
        [1618963200000, 269456185695.19864],
        [1619049600000, 274296843910.20834],
        [1619136000000, 280712855517.3182],
        [1619222400000, 273323480799.64493],
        [1619308800000, 258340689899.58817],
        [1619395200000, 266776799157.93356],
        [1619481600000, 292829223558.3116],
        [1619568000000, 306136280873.3804],
        [1619654400000, 317353332381.2044],
        [1619740800000, 318971242360.58826],
        [1619827200000, 321155845972.33514],
        [1619913600000, 340730211563.77893],
        [1620000000000, 341953594507.36664],
        [1620086400000, 398258027094.341],
        [1620172800000, 380565712269.779],
        [1620259200000, 409191426695.66266],
        [1620345600000, 405355930465.5294],
        [1620432000000, 404284565609.95483],
        [1620518400000, 450716254202.56744],
        [1620604800000, 455448294000.9345],
        [1620691200000, 461040789541.05756],
        [1620777600000, 481380994613.09344],
        [1620864000000, 465647015108.638],
        [1620950400000, 431105320829.47766],
        [1621036800000, 473724804234.6842],
        [1621123200000, 425715131137.06134],
        [1621209600000, 414265118099.5364],
        [1621296000000, 381161976477.9278],
        [1621382400000, 390227141901.1161],
        [1621468800000, 295232973801.7795],
        [1621555200000, 322162621074.79614],
        [1621641600000, 279623071718.589],
        [1621728000000, 268503273693.00333],
        [1621814400000, 244070264760.92874],
        [1621900800000, 306046123107.58295],
        [1621987200000, 312566215516.573],
        [1622073600000, 334435114557.1313],
        [1622160000000, 319404492002.5219],
        [1622246400000, 282228507934.91974],
        [1622332800000, 268672927561.12476],
        [1622419200000, 278270782653.56213],
        [1622505600000, 314463232386.7883],
        [1622592000000, 306488816882.1444],
        [1622678400000, 315313344228.8267],
        [1622764800000, 333964598115.05035],
        [1622851200000, 312991097366.8545],
        [1622937600000, 302743417251.8253],
        [1623024000000, 315044778229.29706],
        [1623110400000, 299857516850.1987],
        [1623196800000, 294068580258.7281],
        [1623283200000, 304761367058.1624],
        [1623369600000, 289444120869.42664],
        [1623456000000, 272748408238.43475],
        [1623542400000, 278223731471.048],
        [1623628800000, 292767118262.08405],
        [1623715200000, 300405113218.4146],
        [1623801600000, 298324322330.12646],
        [1623888000000, 274666655404.67456],
        [1623974400000, 275415199641.5411],
        [1624060800000, 260039646334.19235],
        [1624147200000, 253396709038.79898],
        [1624233600000, 261478202409.18997],
        [1624320000000, 223202708788.72086],
        [1624406400000, 216876757208.59253],
        [1624492800000, 229518675836.76538],
        [1624579200000, 231644258719.40115],
        [1624665600000, 213871849531.6296],
        [1624752000000, 209989265221.48502],
        [1624838400000, 229354016311.0035],
        [1624924800000, 243040539437.43396],
        [1625011200000, 253607549372.41992],
        [1625097600000, 265951575833.03497],
        [1625184000000, 247210647940.18707],
        [1625270400000, 250754468066.2188],
        [1625356800000, 259079167729.78622],
        [1625443200000, 271472550895.4458],
        [1625529600000, 258719784082.81683],
        [1625616000000, 270697947572.19247],
        [1625702400000, 270116728160.1227],
        [1625788800000, 248124573571.6789],
        [1625875200000, 251611015863.3228],
        [1625961600000, 247860822594.06396],
        [1626048000000, 250027222000.60455],
        [1626134400000, 238086643137.17004],
        [1626220800000, 227135827760.92883],
        [1626307200000, 232939479730.00745],
        [1626393600000, 223534356736.75906],
        [1626480000000, 218477848474.5608],
        [1626566400000, 221838551362.95905],
        [1626652800000, 222305469349.28598],
        [1626739200000, 214106247241.3399],
        [1626825600000, 209392844466.0158],
        [1626912000000, 233005814487.5354],
        [1626998400000, 236554595198.76672],
        [1627084800000, 247068794868.14566],
        [1627171200000, 255023351844.8617],
        [1627257600000, 258067996305.1722],
        [1627344000000, 261543645427.02673],
        [1627430400000, 267339757878.51932],
        [1627516800000, 268838784855.6686],
        [1627603200000, 278785893426.22424],
        [1627689600000, 287344044145.01373],
        [1627776000000, 297505707149.10004],
        [1627862400000, 297290528238.90314],
        [1627948800000, 306483554365.1923],
        [1628035200000, 294760000421.45154],
        [1628121600000, 319613239659.44226],
        [1628208000000, 330223699956.3277],
        [1628294400000, 337961452767.8669],
        [1628380800000, 368235028077.73975],
        [1628467200000, 352872000029.7298],
        [1628553600000, 363242219000.13837],
        [1628640000000, 368146417310.3951],
        [1628726400000, 371120421692.36896],
        [1628812800000, 356667351344.9034],
        [1628899200000, 387650495386.42346],
        [1628985600000, 383333523376.4307],
        [1629072000000, 387075388591.617],
        [1629158400000, 369733082268.3038],
        [1629244800000, 354390048289.18036],
        [1629331200000, 357527359807.9584],
        [1629417600000, 360685430760.7975],
        [1629504000000, 383483277312.2806],
        [1629590400000, 378500212583.48236],
        [1629676800000, 380718215752.5816],
        [1629763200000, 389578100858.59686],
        [1629849600000, 373455291323.226],
        [1629936000000, 378980836224.52765],
        [1630022400000, 367860898491.3749],
        [1630108800000, 383644799228.1656],
        [1630195200000, 380750077080.94684],
        [1630281600000, 378786220548.79956],
        [1630368000000, 378977088509.6317],
        [1630454400000, 403657783560.1046],
        [1630540800000, 444243407062.371],
        [1630627200000, 444936758974.6008],
        [1630713600000, 462019852288.094],
        [1630800000000, 456929768632.2518],
        [1630886400000, 463973074693.0737],
        [1630972800000, 463012228435.44434],
        [1631059200000, 404516339491.4938],
        [1631145600000, 413866162089.43915],
        [1631232000000, 403936419964.8866],
        [1631318400000, 376789025177.2447],
        [1631404800000, 383321475836.21576],
        [1631491200000, 402834673220.724],
        [1631577600000, 387934440817.1596],
        [1631664000000, 402557959131.82196],
        [1631750400000, 421477522819.9356],
        [1631836800000, 419510540250.7301],
        [1631923200000, 401297385415.4547],
        [1632009600000, 403306688199.6298],
        [1632096000000, 392513622186.47833],
        [1632182400000, 348966852277.93604],
        [1632268800000, 322261202754.7664],
        [1632355200000, 359840797180.9415],
        [1632441600000, 371681653192.37537],
        [1632528000000, 349627373001.0197],
        [1632614400000, 347010586155.9232],
        [1632700800000, 359680654321.7558],
        [1632787200000, 346015184170.8788],
        [1632873600000, 330646942649.06793],
        [1632960000000, 335476370747.3378],
        [1633046400000, 355017980604.5097],
        [1633132800000, 388950236002.1381],
        [1633219200000, 399455864987.66345],
        [1633305600000, 403971441903.55585],
        [1633392000000, 400955543856.1877],
        [1633478400000, 415494260964.53455],
        [1633564800000, 423560000564.3804],
        [1633651200000, 423610266229.8694],
        [1633737600000, 419918377734.5956],
        [1633824000000, 421836191767.0316],
        [1633910400000, 404765054558.19244],
        [1633996800000, 415159189478.8265],
        [1634083200000, 412579624206.76843],
        [1634169600000, 425077639326.6074],
        [1634256000000, 447060207235.15424],
        [1634342400000, 457796523350.2234],
        [1634428800000, 453064999520.12646],
        [1634515200000, 453191180361.09015],
        [1634601600000, 443346920004.58325],
        [1634688000000, 457653555321.406],
        [1634774400000, 491589810670.4509],
        [1634860800000, 481528471021.37964],
        [1634947200000, 471642481174.71185],
        [1635033600000, 493125402509.4343],
        [1635120000000, 482164548780.28937],
        [1635206400000, 499491060520.2567],
        [1635292800000, 489450382752.54156],
        [1635379200000, 466683206492.3765],
        [1635465600000, 505970778337.4693],
        [1635552000000, 521746545883.6817],
        [1635638400000, 511066583044.32874],
        [1635724800000, 507889381626.9908],
        [1635811200000, 511441678721.3504],
        [1635897600000, 539884557845.201],
        [1635984000000, 543311992233.9774],
        [1636070400000, 534623910067.6353],
        [1636156800000, 531116824617.7209],
        [1636243200000, 534139628777.6332],
        [1636329600000, 546469946549.37506],
        [1636416000000, 571665766496.0859],
        [1636502400000, 560964266378.8303],
        [1636588800000, 548699344220.5352],
        [1636675200000, 560226056358.6504],
        [1636761600000, 552938385233.9376],
        [1636848000000, 551228905630.0963],
        [1636934400000, 546963335748.59625],
        [1637020800000, 541673876615.1028],
        [1637107200000, 501391963927.7868],
        [1637193600000, 507387229348.5558],
        [1637280000000, 472465696561.25507],
        [1637366400000, 509116804761.8423],
        [1637452800000, 524536655944.9339],
        [1637539200000, 514388218179.73724],
        [1637625600000, 485391988431.9048],
        [1637712000000, 515843824526.39667],
        [1637798400000, 505609296276.3257],
        [1637884800000, 535082405000.054],
        [1637971200000, 479414339295.46326],
        [1638057600000, 483997326749.38934],
        [1638144000000, 508385171334.4435],
        [1638230400000, 527331112023.3339],
        [1638316800000, 549758919636.0618],
        [1638403200000, 544051345075.8544],
        [1638489600000, 537814995667.8384],
        [1638576000000, 502863051143.01685],
        [1638662400000, 485429065737.4593],
        [1638748800000, 497049834886.77576],
        [1638835200000, 515778007028.56696],
        [1638921600000, 511409310852.7245],
        [1639008000000, 525850414062.19104],
        [1639094400000, 494841071220.93524],
        [1639180800000, 467266449092.32294],
        [1639267200000, 484278596802.5053],
        [1639353600000, 490316653455.3707],
        [1639440000000, 449137302105.9432],
        [1639526400000, 457658155854.8058],
        [1639612800000, 476630620294.2814],
        [1639699200000, 471365651341.3005],
        [1639785600000, 462267569583.3989],
        [1639872000000, 471195598700.43604],
        [1639958400000, 467396185408.52454],
        [1640044800000, 471319155562.1543],
        [1640131200000, 480570348192.474],
        [1640217600000, 475334762859.67194],
        [1640304000000, 488290658809.1535],
        [1640390400000, 482498690510.26416],
        [1640476800000, 488792890035.21063],
        [1640563200000, 484795501758.7465],
        [1640649600000, 481030381194.1921],
        [1640736000000, 453976220406.2239],
        [1640822400000, 432082393736.3374],
        [1640908800000, 441654436877.796],
        [1640995200000, 439790867552.76666],
        [1641081600000, 449804685410.7303],
        [1641168000000, 456852400304.9327],
        [1641254400000, 448609586227.47644],
        [1641340800000, 454574318762.6068],
        [1641427200000, 424088794962.4891],
        [1641513600000, 407652437129.1328],
        [1641600000000, 381283293110.7904],
        [1641686400000, 369734313550.987],
        [1641772800000, 375219467789.3698],
        [1641859200000, 367654760059.0176],
        [1641945600000, 387505881839.82996],
        [1642032000000, 402763358249.81146],
        [1642118400000, 388039946028.0792],
        [1642204800000, 394664610644.99524],
        [1642291200000, 396899234936.6389],
        [1642377600000, 400232172277.67896],
        [1642464000000, 383428420242.12994],
        [1642550400000, 377918294939.4783],
        [1642636800000, 371296193085.12195],
        [1642723200000, 360580494818.73],
        [1642809600000, 306644499897.7337],
        [1642896000000, 286799916366.57184],
        [1642982400000, 302626363351.5348],
        [1643068800000, 292022326101.0083],
        [1643155200000, 293702336549.67474],
        [1643241600000, 295723346868.75024],
        [1643328000000, 287829476402.6202],
        [1643414400000, 304305302571.33734],
        [1643500800000, 310059710546.2545],
        [1643587200000, 311602914058.75037],
        [1643673600000, 320354563857.1168],
        [1643760000000, 334032944115.64844],
        [1643846400000, 321296521004.9529],
        [1643932800000, 317503695895.34064],
        [1644019200000, 354558118293.0548],
        [1644105600000, 361600442694.43896],
        [1644192000000, 366038313761.43805],
        [1644278400000, 376351043607.997],
        [1644364800000, 374043919421.316],
        [1644451200000, 387321193246.79065],
        [1644537600000, 368377710139.77985],
        [1644624000000, 350133590835.08734],
        [1644710400000, 347517755313.83966],
        [1644796800000, 345252969326.4971],
        [1644883200000, 351368910987.00305],
        [1644969600000, 381791121976.2447],
        [1645056000000, 376071884044.5087],
        [1645142400000, 345252572787.0988],
        [1645228800000, 333997569707.84204],
        [1645315200000, 330814655802.00714],
        [1645401600000, 315015971538.73315],
        [1645488000000, 308836894427.69006],
        [1645574400000, 317004525462.3889],
        [1645660800000, 310997586955.65283],
        [1645747200000, 311533514951.14],
        [1645833600000, 333625150661.6757],
        [1645920000000, 332645349329.944],
        [1646006400000, 314566178973.5596],
        [1646092800000, 349056335389.1799],
        [1646179200000, 355855644056.71844],
        [1646265600000, 353807222059.12616],
        [1646352000000, 340585349633.38696],
        [1646438400000, 314336377085.58026],
        [1646524800000, 319334318240.9434],
        [1646611200000, 306675297178.70435],
        [1646697600000, 299834957580.4126],
        [1646784000000, 309291567872.3395],
        [1646870400000, 327144318378.6509],
        [1646956800000, 313054369877.37134],
        [1647043200000, 307992205234.7309],
        [1647129600000, 309539252974.5316],
        [1647216000000, 302103577555.32056],
        [1647302400000, 310467033555.37006],
        [1647388800000, 314931220494.93384],
        [1647475200000, 333204590005.1927],
        [1647561600000, 338097336083.8262],
        [1647648000000, 353539105454.5327],
        [1647734400000, 353770129278.67426],
        [1647820800000, 343170506313.24133],
        [1647907200000, 347666776668.2674],
        [1647993600000, 356813271188.88025],
        [1648080000000, 363715804798.513],
        [1648166400000, 373177111114.7027],
        [1648252800000, 373074920726.7511],
        [1648339200000, 377825418684.3759],
        [1648425600000, 394486853105.0086],
        [1648512000000, 399977979554.21063],
        [1648598400000, 408153998267.8274],
        [1648684800000, 407460530687.7479],
        [1648771200000, 394628513775.2061],
        [1648857600000, 414854897478.46454],
        [1648944000000, 413595262456.1556],
        [1649030400000, 423410516389.8548],
        [1649116800000, 423367935034.98956],
        [1649203200000, 412944890807.9602],
        [1649289600000, 382808702033.7895],
        [1649376000000, 388867320187.2107],
        [1649462400000, 382662893229.3914],
        [1649548800000, 392551461530.81824],
        [1649635200000, 388982079663.2629],
        [1649721600000, 360149250762.0514],
        [1649808000000, 365661388410.7189],
        [1649894400000, 375767680796.0061],
        [1649980800000, 363962080917.9128],
        [1650067200000, 366695398817.36127],
        [1650153600000, 369787917596.8767],
        [1650240000000, 360748880962.95123],
        [1650326400000, 368758484169.854],
        [1650412800000, 373782979942.61957],
        [1650499200000, 370910549448.9755],
        [1650585600000, 359917773311.257],
        [1650672000000, 357702343019.6129],
        [1650758400000, 354358143094.8383],
        [1650844800000, 352895958715.2752],
        [1650931200000, 362590296737.94165],
        [1651017600000, 338098235743.8579],
        [1651104000000, 348355348857.35516],
        [1651190400000, 353388170472.60077],
        [1651276800000, 340291788026.5969],
        [1651363200000, 330211251741.10095],
        [1651449600000, 341626180826.6119],
        [1651536000000, 345080135066.0433],
        [1651622400000, 336655132684.7217],
        [1651708800000, 355229761723.3485],
        [1651795200000, 332103738939.991],
        [1651881600000, 325971900416.40796],
        [1651968000000, 319030900103.94275],
        [1652054400000, 304412453306.29724],
        [1652140800000, 271594796946.79037],
        [1652227200000, 283082341315.2594],
        [1652313600000, 250066823474.8895],
        [1652400000000, 238137944005.85858],
        [1652486400000, 243391440608.9294],
        [1652572800000, 249319115268.20465],
        [1652659200000, 259350256988.01163],
        [1652745600000, 244741893933.73114],
        [1652832000000, 253640825085.77072],
        [1652918400000, 232056327519.7834],
        [1653004800000, 244359004842.60373],
        [1653091200000, 237366807798.8157],
        [1653177600000, 239102942424.56775],
        [1653264000000, 247730054115.44598],
        [1653350400000, 238722810744.1854],
        [1653436800000, 239375431792.86758],
        [1653523200000, 235347664835.4594],
        [1653609600000, 219247293980.15558],
        [1653696000000, 208622909553.50162],
        [1653782400000, 217791356992.8858],
        [1653868800000, 219535934457.98206],
        [1653955200000, 241204871683.00946],
        [1654041600000, 235235428449.11987],
        [1654128000000, 221596716649.26758],
        [1654214400000, 222487488380.58923],
        [1654300800000, 214879733566.94196],
        [1654387200000, 217832477905.49496],
        [1654473600000, 218561175507.6209],
        [1654560000000, 225224111085.68793],
        [1654646400000, 220727955347.00992],
        [1654732800000, 217320792647.69748],
        [1654819200000, 216720355679.05618],
        [1654905600000, 201384347491.6296],
        [1654992000000, 185302828001.8518],
        [1655078400000, 176649606143.59042],
        [1655164800000, 146072737222.6309],
        [1655251200000, 146866764082.71503],
        [1655337600000, 148791271623.67947],
        [1655424000000, 129200159744.2964],
        [1655510400000, 132057112459.09528],
        [1655596800000, 120148911164.20312],
        [1655683200000, 136214925416.2125],
        [1655769600000, 136360308696.36774],
        [1655856000000, 136466936749.80061],
        [1655942400000, 127762640725.99548],
        [1656028800000, 138845063633.00702],
        [1656115200000, 150370804175.61023],
        [1656201600000, 150822450290.39703],
        [1656288000000, 145727702946.91232],
        [1656374400000, 144903396178.83267],
        [1656460800000, 138578887988.78128],
        [1656547200000, 133275228451.34706],
        [1656633600000, 125647331913.1351],
        [1656720000000, 128274306967.60861],
        [1656806400000, 127772810160.63632],
        [1656892800000, 128552380046.80464],
        [1656979200000, 137709680960.0187],
        [1657065600000, 135655130079.77824],
        [1657152000000, 142216914175.49112],
        [1657238400000, 148622605231.4957],
        [1657324800000, 148022842649.44733],
        [1657411200000, 145667751203.8659],
        [1657497600000, 139844141994.57858],
        [1657584000000, 131363492852.48433],
        [1657670400000, 124831295049.54146],
        [1657756800000, 132986401488.71631],
        [1657843200000, 142767764050.56015],
        [1657929600000, 147677565536.3019],
        [1658016000000, 161839496479.48514],
        [1658102400000, 161551223982.244],
        [1658188800000, 187518878564.43118],
        [1658275200000, 184299116474.0005],
        [1658361600000, 183383314168.63928],
        [1658448000000, 188807814065.77225],
        [1658534400000, 183933879174.97644],
        [1658620800000, 185766674503.14],
        [1658707200000, 192833875418.8718],
        [1658793600000, 176371406952.78534],
        [1658880000000, 172489862867.98703],
        [1658966400000, 195761014376.11777],
        [1659052800000, 206347207632.4567],
        [1659139200000, 210767555013.8352],
        [1659225600000, 203433082401.51196],
        [1659312000000, 201684547325.2247],
        [1659398400000, 195962280445.4563],
        [1659484800000, 197496591505.0525],
        [1659571200000, 194280005714.29996],
        [1659657600000, 192923352504.82288],
        [1659744000000, 206550599256.62897],
        [1659830400000, 203417937064.14215],
        [1659916800000, 203847481552.73074],
        [1660003200000, 213209087779.66412],
        [1660089600000, 204260348576.8655],
        [1660176000000, 222103417229.72577],
        [1660262400000, 225973452853.74725],
        [1660348800000, 235254593664.57745],
        [1660435200000, 237972056306.98938],
        [1660521600000, 232593263080.43414],
        [1660608000000, 229440415801.9887],
        [1660694400000, 225581585656.9299],
        [1660780800000, 220665870251.26813],
        [1660867200000, 222652366350.8803],
        [1660953600000, 194817731984.87317],
        [1661040000000, 189621068133.33334],
        [1661126400000, 195324471844.68927],
        [1661212800000, 193708156170.03223],
        [1661299200000, 199889229686.9934],
        [1661385600000, 199117634174.20898],
        [1661472000000, 204240810436.6186],
        [1661558400000, 181238520850.37888],
        [1661644800000, 179887478326.98642],
        [1661731200000, 173645963258.54285],
        [1661817600000, 187437853967.29883],
        [1661904000000, 184447549243.54855],
        [1661990400000, 186670000430.8127],
        [1662076800000, 191078487039.94174],
        [1662163200000, 189678877652.6803],
        [1662249600000, 187266246294.68723],
        [1662336000000, 190082046955.22556],
        [1662422400000, 194714097035.8242],
        [1662508800000, 188733785486.0839],
        [1662595200000, 196797197824.30237],
        [1662681600000, 196882691710.49237],
        [1662768000000, 206873029263.2678],
        [1662854400000, 214315849498.72748],
        [1662940800000, 211895058510.3476],
        [1663027200000, 205793007270.87155],
        [1663113600000, 189778580452.97882],
        [1663200000000, 196550513818.88647],
        [1663286400000, 177918273632.48117],
        [1663372800000, 172471655154.62585],
        [1663459200000, 177299639477.4967],
        [1663545600000, 161378107849.57123],
        [1663632000000, 166370229681.14825],
        [1663718400000, 159844149938.74484],
        [1663804800000, 151084586604.48166],
        [1663891200000, 160415097114.1742],
        [1663977600000, 160823914264.80762],
        [1664064000000, 159030822515.26553],
        [1664150400000, 156146367767.27133],
        [1664236800000, 160767471777.8733],
        [1664323200000, 160800122124.8998],
        [1664409600000, 161716193676.29504],
        [1664496000000, 161267455734.36823],
        [1664582400000, 160462955554.32846],
        [1664668800000, 158410068981.48102],
        [1664755200000, 154934842225.90906],
        [1664841600000, 159951067075.9052],
        [1664928000000, 164781680358.15567],
        [1665014400000, 163475522281.134],
        [1665100800000, 163307579801.84253],
        [1665187200000, 160084472602.33664],
        [1665273600000, 159083423929.71472],
        [1665360000000, 159586330117.54977],
        [1665446400000, 156180434676.1384],
        [1665532800000, 154657511483.1829],
        [1665619200000, 156488571589.45505],
        [1665705600000, 155703285133.85605],
        [1665792000000, 156801885520.45496],
        [1665878400000, 154496140301.9263],
        [1665964800000, 157219790372.87076],
        [1666051200000, 160690333948.33112],
        [1666137600000, 158082173402.60727],
        [1666224000000, 154808369275.81442],
        [1666310400000, 154512893268.0924],
        [1666396800000, 156673724237.28812],
        [1666483200000, 158365108378.3375],
        [1666569600000, 164218113409.75653],
        [1666656000000, 161858058865.06808],
        [1666742400000, 176051206695.99817],
        [1666828800000, 189173389239.5451],
        [1666915200000, 182723289741.9848],
        [1667001600000, 187324850268.18915],
        [1667088000000, 195052502985.67032],
        [1667174400000, 191500820025.9781],
        [1667260800000, 189478578997.59592],
        [1667347200000, 190428736966.3317],
        [1667433600000, 183368006193.10986],
        [1667520000000, 184398997533.25903],
        [1667606400000, 198053929735.65234],
        [1667692800000, 196264485041.5907],
        [1667779200000, 189763621397.94974],
        [1667865600000, 188875473098.02014],
        [1667952000000, 160813058133.9897],
        [1668038400000, 131794377265.61275],
        [1668124800000, 156380990539.85645],
        [1668211200000, 155222424523.53937],
        [1668297600000, 151059842003.22357],
        [1668384000000, 147426038251.7689],
        [1668470400000, 149987178583.21732],
        [1668556800000, 151062413577.56232],
        [1668643200000, 146462319554.31644],
        [1668729600000, 144894942760.29672],
        [1668816000000, 146145817586.6032],
        [1668902400000, 146971097131.67468],
        [1668988800000, 137964810712.74976],
        [1669075200000, 133733639793.52232],
        [1669161600000, 137050514642.06917],
        [1669248000000, 142854533237.69275],
        [1669334400000, 145091739838.44122],
        [1669420800000, 144810246844.91675],
        [1669507200000, 145222050633.46432],
        [1669593600000, 144507830172.60718],
        [1669680000000, 141048501904.6694],
        [1669766400000, 146749281622.5983],
        [1669852800000, 156777674228.40683],
        [1669939200000, 153787135286.89285],
        [1670025600000, 155766037022.93884],
        [1670112000000, 150322759022.75156],
        [1670198400000, 154484879835.4558],
        [1670284800000, 151960138515.5798],
        [1670371200000, 153112232020.5009],
        [1670457600000, 148778387852.61035],
        [1670544000000, 154438830699.8818],
        [1670630400000, 152405570526.73016],
        [1670716800000, 152480155389.30975],
        [1670803200000, 152290250844.83786],
        [1670889600000, 153743004467.3702],
        [1670976000000, 159205793114.4748],
        [1671062400000, 157633274799.26862],
        [1671148800000, 152444818382.92126],
        [1671235200000, 140289330990.1754],
        [1671321600000, 143321475845.90793],
        [1671408000000, 143142028259.18048],
        [1671494400000, 140848456817.4131],
        [1671580800000, 146802833364.30045],
        [1671667200000, 146039250786.06482],
        [1671753600000, 146741009624.09518],
        [1671840000000, 147069822795.65387],
        [1671926400000, 147173116811.52798],
        [1672012800000, 146840669268.71204],
        [1672099200000, 147697269147.94653],
        [1672185600000, 146030514727.00162],
        [1672272000000, 143241827136.73636],
        [1672358400000, 144831301452.10547],
        [1672444800000, 144611517916.40677],
        [1672531200000, 144188788703.97467],
        [1672617600000, 144712215098.15164],
        [1672704000000, 146443084804.90094],
        [1672790400000, 146422424046.4976],
        [1672876800000, 151322902029.0838],
        [1672963200000, 150805231900.39493],
        [1673049600000, 153115414332.1746],
        [1673136000000, 152351777734.96948],
        [1673222400000, 154857941332.0086],
        [1673308800000, 159397504473.27673],
        [1673395200000, 160999220384.61914],
        [1673481600000, 167839113151.03107],
        [1673568000000, 170890542806.26074],
        [1673654400000, 174798760594.58777],
        [1673740800000, 187276048380.94897],
        [1673827200000, 186901700956.48312],
        [1673913600000, 190122961411.69482],
        [1674000000000, 188982728685.09735],
        [1674086400000, 183517931282.58932],
        [1674172800000, 186967330908.32364],
        [1674259200000, 200011161010.51965],
        [1674345600000, 195908718395.08084],
        [1674432000000, 196512572594.40756],
        [1674518400000, 196541663696.43738],
        [1674604800000, 187270576772.9432],
        [1674691200000, 194402223992.62006],
        [1674777600000, 193276131177.59738],
        [1674864000000, 192677648328.43668],
        [1674950400000, 189301295262.2017],
        [1675036800000, 198339307428.10373],
        [1675123200000, 188787565951.33054],
        [1675209600000, 191042852121.47128],
        [1675296000000, 197724834027.55963],
        [1675382400000, 198613736403.15057],
        [1675468800000, 200784153460.47113],
        [1675555200000, 201099652782.7474],
        [1675641600000, 196817854756.22067],
        [1675728000000, 194725338782.41467],
        [1675814400000, 201779050385.86017],
        [1675900800000, 199107556553.15936],
        [1675987200000, 186306817307.09564],
        [1676073600000, 182839491763.3784],
        [1676160000000, 185826666441.3843],
        [1676246400000, 182722050130.99646],
        [1676332800000, 181589830341.34802],
        [1676419200000, 187619568130.73468],
        [1676505600000, 201595282287.4858],
        [1676592000000, 198370400841.4434],
        [1676678400000, 204534589183.7981],
        [1676764800000, 203613145994.36063],
        [1676851200000, 202999098993.98264],
        [1676937600000, 204900256888.84143],
        [1677024000000, 199387951962.05505],
        [1677110400000, 198153971276.35593],
        [1677196800000, 198619189860.05594],
        [1677283200000, 193773223390.3348],
        [1677369600000, 192056375721.21072],
        [1677456000000, 197696596339.3676],
        [1677542400000, 197018105507.06073],
        [1677628800000, 193276888983.6779],
        [1677715200000, 200476156508.34192],
        [1677801600000, 198520753673.54544],
        [1677888000000, 189178795460.26093],
        [1677974400000, 189002414068.6852],
        [1678060800000, 188337456156.2138],
        [1678147200000, 188803304807.84476],
        [1678233600000, 187945419858.86072],
        [1678320000000, 185138878803.2572],
        [1678406400000, 173232609854.28854],
        [1678492800000, 172474466355.71472],
        [1678579200000, 176915178456.41873],
        [1678665600000, 190440681263.21286],
        [1678752000000, 202294154847.0903],
        [1678838400000, 205738833552.65433],
        [1678924800000, 199607487558.33243],
        [1679011200000, 202411234958.15692],
        [1679097600000, 215660560704.77524],
        [1679184000000, 213123927233.32803],
        [1679270400000, 217844457987.9951],
        [1679356800000, 209763828559.92316],
        [1679443200000, 218008486913.68356],
        [1679529600000, 210285658211.08432],
        [1679616000000, 219518418514.19247],
        [1679702400000, 212072713258.261],
        [1679788800000, 211355889911.33264],
        [1679875200000, 214528709136.8291],
        [1679961600000, 206685090340.53693],
        [1679966032000, 205793527761.5422],
      ],
      total_volumes: [
        [1438905600000, 90622.0],
        [1438992000000, 368070.0],
        [1439164800000, 400464.0745435489],
        [1439251200000, 1518998.2053373822],
        [1439337600000, 2073893.3450314845],
        [1439424000000, 4380142.704000345],
        [1439510400000, 4355618.378552116],
        [1439596800000, 2519633.2708181436],
        [1439683200000, 3032657.656405736],
        [1439769600000, 1880092.2147175823],
        [1439856000000, 1696013.472335737],
        [1439942400000, 1537200.8409894055],
        [1440028800000, 2967730.5022106003],
        [1440115200000, 1742881.8081502889],
        [1440201600000, 914588.6539745613],
        [1440288000000, 1610695.8727506467],
        [1440374400000, 696476.5553267623],
        [1440460800000, 1053169.3862050485],
        [1440547200000, 1151700.041593045],
        [1440633600000, 681460.2394411361],
        [1440720000000, 715178.7899308845],
        [1440806400000, 607200.891453558],
        [1440892800000, 1045589.321256734],
        [1440979200000, 1197102.0492865555],
        [1441065600000, 610368.2430900692],
        [1441152000000, 629746.119316548],
        [1441238400000, 583923.7452450913],
        [1441324800000, 333502.66115673864],
        [1441411200000, 350815.4668948872],
        [1441497600000, 317470.0943419727],
        [1441584000000, 487005.2028033498],
        [1441670400000, 419148.8018252036],
        [1441756800000, 367774.3318949896],
        [1441843200000, 639527.9174929534],
        [1441929600000, 1901710.694902119],
        [1442016000000, 800623.2998453851],
        [1442102400000, 658895.1959938455],
        [1442188800000, 739213.7259180878],
        [1442275200000, 725816.471124354],
        [1442361600000, 629370.0849072988],
        [1442448000000, 910704.9512256783],
        [1442534400000, 478606.3969218407],
        [1442620800000, 387803.6167124918],
        [1442707200000, 514836.10862474947],
        [1442793600000, 429253.50431215833],
        [1442880000000, 485738.9553869821],
        [1442966400000, 204065.40590088838],
        [1443052800000, 818263.068224302],
        [1443139200000, 901151.6190115963],
        [1443225600000, 379124.1859007531],
        [1443312000000, 408380.6390382605],
        [1443398400000, 1214056.2117823916],
        [1443484800000, 936630.3296074625],
        [1443571200000, 640217.6226458604],
        [1443657600000, 588839.7815035215],
        [1443744000000, 220969.48495190177],
        [1443830400000, 163820.85347975112],
        [1443916800000, 104370.45073472841],
        [1444003200000, 221647.20706785333],
        [1444089600000, 268523.03787593375],
        [1444176000000, 216715.50684861522],
        [1444262400000, 310876.1441075317],
        [1444348800000, 379233.1220398259],
        [1444435200000, 145554.93808006204],
        [1444521600000, 87074.79961764446],
        [1444608000000, 108895.52921643597],
        [1444694400000, 297117.07710031554],
        [1444780800000, 944847.8047126097],
        [1444867200000, 376563.0715091993],
        [1444953600000, 247071.24723507115],
        [1445040000000, 317377.7617794195],
        [1445126400000, 133661.67045159836],
        [1445212800000, 240250.37656809334],
        [1445299200000, 352361.0618641571],
        [1445385600000, 599041.0131522977],
        [1445472000000, 979304.0724230009],
        [1445558400000, 866798.4888542511],
        [1445644800000, 259157.66241063716],
        [1445731200000, 476617.738601437],
        [1445817600000, 1174026.819064362],
        [1445904000000, 1887568.9059290001],
        [1445990400000, 2447633.7976275166],
        [1446076800000, 2236842.210445422],
        [1446163200000, 2384549.998119618],
        [1446249600000, 652271.5873221146],
        [1446336000000, 603996.1990296076],
        [1446422400000, 970665.6783058537],
        [1446508800000, 1878273.0109212757],
        [1446595200000, 3218064.880847628],
        [1446681600000, 1202885.3800460405],
        [1446768000000, 913629.1696859167],
        [1446854400000, 904631.8699629814],
        [1446940800000, 1040086.7545033679],
        [1447027200000, 1972521.3874033373],
        [1447113600000, 865031.5412400892],
        [1447200000000, 1243322.7527190507],
        [1447286400000, 826829.6689207307],
        [1447372800000, 545086.2658886773],
        [1447459200000, 361586.11600976856],
        [1447545600000, 432023.407831642],
        [1447632000000, 616346.9298519328],
        [1447718400000, 1128249.5445129166],
        [1447804800000, 687203.3331021931],
        [1447891200000, 434385.481510446],
        [1447977600000, 606560.338362119],
        [1448064000000, 454926.9190034523],
        [1448150400000, 371962.8833336563],
        [1448236800000, 437126.39426833094],
        [1448323200000, 354914.08621214016],
        [1448409600000, 708867.1776788787],
        [1448496000000, 959696.4719438321],
        [1448582400000, 398595.4996438545],
        [1448668800000, 465064.93868912815],
        [1448755200000, 436939.13020833576],
        [1448841600000, 775372.5405749859],
        [1448928000000, 643109.8579217136],
        [1449014400000, 494205.89284799533],
        [1449100800000, 541681.1368684795],
        [1449187200000, 241947.49444270384],
        [1449273600000, 226084.41071520853],
        [1449360000000, 429681.2894745185],
        [1449446400000, 494657.61802976346],
        [1449532800000, 435966.80884823814],
        [1449619200000, 627128.9260492469],
        [1449705600000, 374543.00586710183],
        [1449792000000, 765839.8738111159],
        [1449878400000, 1385279.0437324098],
        [1449964800000, 279257.6104401555],
        [1450051200000, 562723.38601539],
        [1450137600000, 573171.6204861925],
        [1450224000000, 207316.98728030306],
        [1450310400000, 641112.3505143464],
        [1450396800000, 297866.39712775947],
        [1450483200000, 333313.4238328429],
        [1450569600000, 467489.5933259762],
        [1450656000000, 543846.3978062818],
        [1450742400000, 468144.5604338489],
        [1450828800000, 333657.8603050268],
        [1450915200000, 353090.34035883],
        [1451001600000, 169208.7604895867],
        [1451088000000, 424123.09670471714],
        [1451174400000, 208247.21865890338],
        [1451260800000, 228592.5875954824],
        [1451347200000, 211396.56750766764],
        [1451433600000, 584414.4705682999],
        [1451520000000, 668846.07044826],
        [1451606400000, 202032.13336863558],
        [1451692800000, 252880.2353165073],
        [1451779200000, 402755.9084078687],
        [1451865600000, 341506.82136802946],
        [1451952000000, 217443.23809763335],
        [1452038400000, 328507.1523817127],
        [1452124800000, 642269.5176489778],
        [1452211200000, 554403.4018238547],
        [1452297600000, 254045.62911324683],
        [1452384000000, 404315.93496207293],
        [1452470400000, 1025253.0383450065],
        [1452556800000, 2962550.3945903103],
        [1452643200000, 1003321.3604582713],
        [1452729600000, 731050.8483768429],
        [1452816000000, 4049516.2124520643],
        [1452902400000, 2377737.1425659666],
        [1452988800000, 1176009.00668505],
        [1453075200000, 4073710.1202780646],
        [1453161600000, 1684557.2914079635],
        [1453248000000, 2899771.051018662],
        [1453334400000, 1670627.3218903034],
        [1453420800000, 1585027.991812104],
        [1453507200000, 2212028.7971573095],
        [1453593600000, 9578400.618786106],
        [1453680000000, 10884776.210849365],
        [1453766400000, 11669540.231742326],
        [1453852800000, 7048626.2187527],
        [1453939200000, 5043090.697552952],
        [1454025600000, 6527897.661629626],
        [1454112000000, 3674090.3975050296],
        [1454198400000, 5834817.706592737],
        [1454284800000, 3991411.3414352452],
        [1454371200000, 3602402.308067327],
        [1454457600000, 4030697.3392438097],
        [1454544000000, 3532651.020271136],
        [1454630400000, 2640353.6147680376],
        [1454716800000, 1924574.7180617019],
        [1454803200000, 7099203.5519039435],
        [1454889600000, 9781404.736808997],
        [1454976000000, 16355217.945349474],
        [1455062400000, 29316397.613274623],
        [1455148800000, 28752968.923665255],
        [1455235200000, 9902598.244079141],
        [1455321600000, 25637385.479897276],
        [1455408000000, 11552061.707776096],
        [1455494400000, 13786358.783861183],
        [1455580800000, 15444552.58629855],
        [1455667200000, 14847989.04999859],
        [1455753600000, 17707643.869024027],
        [1455840000000, 7215680.13205692],
        [1455926400000, 8703306.94050525],
        [1456012800000, 6458218.23306439],
        [1456099200000, 15140246.890369605],
        [1456185600000, 13534870.66434678],
        [1456272000000, 11396482.659191374],
        [1456358400000, 20614025.807056963],
        [1456444800000, 9515283.257397331],
        [1456531200000, 8967228.051799431],
        [1456617600000, 9683081.005927766],
        [1456704000000, 7546069.554011689],
        [1456790400000, 20945587.168326743],
        [1456876800000, 29743237.911609612],
        [1456963200000, 40441406.846499346],
        [1457049600000, 35604151.84878607],
        [1457136000000, 54303350.00956653],
        [1457222400000, 41487265.46159489],
        [1457308800000, 31891736.915083926],
        [1457395200000, 26473661.601431258],
        [1457481600000, 28637679.638494223],
        [1457568000000, 26154096.467449],
        [1457654400000, 21083465.444312476],
        [1457740800000, 49863322.38648788],
        [1457827200000, 41625885.68412731],
        [1457913600000, 56738456.43221119],
        [1458000000000, 30654519.10842079],
        [1458086400000, 23016882.43185901],
        [1458172800000, 41222397.150388725],
        [1458259200000, 56331932.49799413],
        [1458345600000, 26241552.22233686],
        [1458432000000, 17365910.955602583],
        [1458518400000, 28692538.03850463],
        [1458604800000, 19752147.756938808],
        [1458691200000, 19194415.657153998],
        [1458777600000, 25440037.393962998],
        [1458864000000, 13369781.43339712],
        [1458950400000, 9099232.99988865],
        [1459036800000, 16296116.833151689],
        [1459123200000, 23146182.980729047],
        [1459209600000, 17227685.66894123],
        [1459296000000, 18966041.620488863],
        [1459382400000, 14483801.493105622],
        [1459468800000, 11085851.666571325],
        [1459555200000, 8250251.46099724],
        [1459641600000, 6190954.954391588],
        [1459728000000, 8612716.039295983],
        [1459814400000, 19160809.10508679],
        [1459900800000, 16326497.505610662],
        [1459987200000, 12647730.142575275],
        [1460073600000, 14041839.904038262],
        [1460160000000, 12961939.644313943],
        [1460246400000, 42148631.996420056],
        [1460332800000, 14850316.572718466],
        [1460419200000, 22286939.435105387],
        [1460505600000, 30653780.43975513],
        [1460592000000, 13178565.115089094],
        [1460678400000, 11510142.230195371],
        [1460764800000, 9164981.891612116],
        [1460851200000, 23406128.933429755],
        [1460937600000, 20650475.766265374],
        [1461024000000, 11931697.805021903],
        [1461110400000, 14929127.402227022],
        [1461196800000, 12241737.003326477],
        [1461283200000, 12838519.618996318],
        [1461369600000, 14263735.838354647],
        [1461456000000, 10251449.903605143],
        [1461542400000, 9849771.90571352],
        [1461628800000, 17588527.338440873],
        [1461715200000, 18898653.05760324],
        [1461801600000, 10503191.403122593],
        [1461888000000, 8232651.035704131],
        [1461974400000, 36023474.61132939],
        [1462060800000, 16329848.834566616],
        [1462147200000, 40531647.2594358],
        [1462233600000, 20068196.22374318],
        [1462320000000, 16758119.374866204],
        [1462406400000, 17630163.279848542],
        [1462492800000, 20206557.47556278],
        [1462579200000, 10242971.602412278],
        [1462665600000, 14850766.415806817],
        [1462752000000, 10349090.132607356],
        [1462838400000, 8837274.15169184],
        [1462924800000, 14650764.255450204],
        [1463011200000, 22804335.07313709],
        [1463097600000, 27489270.96990509],
        [1463184000000, 18055946.18486381],
        [1463270400000, 8453161.311741682],
        [1463356800000, 27963225.28498735],
        [1463443200000, 36364157.81238995],
        [1463529600000, 62651459.72818858],
        [1463616000000, 53477909.077545226],
        [1463702400000, 48337340.57185815],
        [1463788800000, 24426995.172922097],
        [1463875200000, 17413663.620256484],
        [1463961600000, 21271913.57732469],
        [1464048000000, 41166028.01368196],
        [1464134400000, 25364775.557130534],
        [1464220800000, 19032556.07440177],
        [1464307200000, 53491048.64137498],
        [1464393600000, 44051409.767962486],
        [1464480000000, 26254518.55363852],
        [1464566400000, 13289827.482192438],
        [1464652800000, 39491270.92023739],
        [1464739200000, 20161807.321217276],
        [1464825600000, 9543717.946969878],
        [1464912000000, 21310804.97545615],
        [1464998400000, 14233023.489212893],
        [1465084800000, 9908484.139421096],
        [1465171200000, 7633717.194766483],
        [1465257600000, 19688483.52449011],
        [1465344000000, 14566862.117567817],
        [1465430400000, 6538415.617239344],
        [1465516800000, 9751904.43375697],
        [1465603200000, 9986576.468801256],
        [1465689600000, 34155133.25971257],
        [1465776000000, 33190471.728637412],
        [1465862400000, 57195562.16615153],
        [1465948800000, 16794245.386186022],
        [1466035200000, 33609803.05751324],
        [1466121600000, 176008430.131423],
        [1466208000000, 121410542.50029658],
        [1466294400000, 68987538.97211507],
        [1466380800000, 79924563.1953664],
        [1466467200000, 73815982.10368486],
        [1466553600000, 157698817.03531438],
        [1466640000000, 35210083.60811146],
        [1466726400000, 42975326.33415148],
        [1466812800000, 13474760.65320482],
        [1466899200000, 12716765.335899664],
        [1466985600000, 9026418.664550114],
        [1467072000000, 38649828.67116688],
        [1467158400000, 24102058.67142671],
        [1467244800000, 25109656.26842844],
        [1467331200000, 16090108.050608601],
        [1467417600000, 12572639.309558652],
        [1467504000000, 8919743.27120069],
        [1467590400000, 10816581.462869536],
        [1467676800000, 40761274.601703286],
        [1467763200000, 16042852.433356386],
        [1467849600000, 13787778.16557356],
        [1467936000000, 17821594.496114336],
        [1468022400000, 14035737.806230836],
        [1468108800000, 4559501.88558977],
        [1468195200000, 11938095.540816711],
        [1468281600000, 8944484.246364418],
        [1468368000000, 6087112.236380934],
        [1468454400000, 13698700.023209212],
        [1468540800000, 13798764.3887509],
        [1468627200000, 10138776.97248824],
        [1468713600000, 20447413.599733796],
        [1468800000000, 13898138.245649716],
        [1468886400000, 20242192.241273765],
        [1468972800000, 29894784.28435568],
        [1469059200000, 24473811.51309662],
        [1469145600000, 72391413.22249371],
        [1469232000000, 72744516.35031606],
        [1469318400000, 71639748.40237246],
        [1469404800000, 33694267.533380985],
        [1469491200000, 76432694.99620306],
        [1469577600000, 48754958.64137967],
        [1469664000000, 19481593.529681668],
        [1469750400000, 10125341.279974585],
        [1469836800000, 8150653.892600032],
        [1469923200000, 20238765.447837573],
        [1470009600000, 17147499.527635228],
        [1470096000000, 60708724.33736284],
        [1470182400000, 44330639.67738887],
        [1470268800000, 29307740.66438063],
        [1470355200000, 10956639.34702048],
        [1470441600000, 11692994.41648213],
        [1470528000000, 11883004.411650596],
        [1470614400000, 10967961.760577636],
        [1470700800000, 29672733.520185594],
        [1470787200000, 23499542.868399795],
        [1470873600000, 24164966.873272106],
        [1470960000000, 24135899.78447133],
        [1471046400000, 6655535.198454872],
        [1471132800000, 7543085.399330939],
        [1471219200000, 5064027.898267308],
        [1471305600000, 5249879.8049723925],
        [1471392000000, 5256928.972461218],
        [1471478400000, 4567012.374311765],
        [1471564800000, 3192133.226690042],
        [1471651200000, 5076175.103616041],
        [1471737600000, 3197471.3310449845],
        [1471824000000, 4831490.757852666],
        [1471910400000, 4721007.823312022],
        [1471996800000, 3221362.082246617],
        [1472083200000, 6231804.810184036],
        [1472169600000, 4403460.384900961],
        [1472256000000, 1903199.5609802417],
        [1472342400000, 4055631.700066124],
        [1472428800000, 5810173.643565886],
        [1472515200000, 5550355.265152284],
        [1472601600000, 7446354.073382742],
        [1472688000000, 19546439.786958296],
        [1472774400000, 7683911.541524928],
        [1472860800000, 10099601.667450976],
        [1472947200000, 5035842.952199199],
        [1473033600000, 3788155.072558386],
        [1473120000000, 4815696.850474364],
        [1473206400000, 4664621.634283545],
        [1473292800000, 7944145.032547882],
        [1473379200000, 3826059.408074749],
        [1473465600000, 4731455.712652934],
        [1473552000000, 6472336.880957989],
        [1473638400000, 4554653.825275872],
        [1473724800000, 6637362.777464354],
        [1473811200000, 3846492.7555122753],
        [1473897600000, 4500681.300284119],
        [1473984000000, 14494427.653257197],
        [1474070400000, 6237001.347482052],
        [1474156800000, 18914756.790781923],
        [1474243200000, 11627825.017810639],
        [1474329600000, 28747263.944959663],
        [1474416000000, 25166170.46583509],
        [1474502400000, 23902556.17228226],
        [1474588800000, 7256064.666833526],
        [1474675200000, 7988975.03646825],
        [1474761600000, 4488914.611269901],
        [1474848000000, 5602575.391137662],
        [1474934400000, 5399539.834538619],
        [1475020800000, 6516563.112363243],
        [1475107200000, 4241372.3389502475],
        [1475193600000, 4568583.2756650625],
        [1475280000000, 4727545.880665653],
        [1475366400000, 2764266.958777093],
        [1475452800000, 5821790.035410628],
        [1475539200000, 6702196.6980442945],
        [1475625600000, 6285719.065320643],
        [1475712000000, 9120081.098095493],
        [1475798400000, 6504030.816998282],
        [1475884800000, 8853947.552639049],
        [1475971200000, 5440893.661712],
        [1476057600000, 9479462.92900281],
        [1476144000000, 8989864.764158806],
        [1476230400000, 7565516.582482849],
        [1476316800000, 6550532.31361221],
        [1476403200000, 3393733.0814907444],
        [1476489600000, 3047472.2344031925],
        [1476576000000, 2051473.3981454277],
        [1476662400000, 4329663.894079154],
        [1476748800000, 14699505.269326214],
        [1476835200000, 7727576.680042059],
        [1476921600000, 4811381.15158085],
        [1477008000000, 2496841.1791091952],
        [1477094400000, 3995386.223650696],
        [1477180800000, 2843471.0792325726],
        [1477267200000, 2417942.610433313],
        [1477353600000, 13528339.239714485],
        [1477440000000, 6113958.130893516],
        [1477526400000, 8682323.137549385],
        [1477612800000, 8907341.45947652],
        [1477699200000, 21618613.51080939],
        [1477785600000, 12714282.56566218],
        [1477872000000, 9254943.69991251],
        [1477958400000, 17471506.378140815],
        [1478044800000, 7632829.9444044735],
        [1478131200000, 11623516.370152857],
        [1478217600000, 5924309.345130352],
        [1478304000000, 3076392.2948442292],
        [1478390400000, 3218494.7703560493],
        [1478476800000, 2949427.1027813354],
        [1478563200000, 4085085.3360599373],
        [1478649600000, 12761164.948392091],
        [1478736000000, 2902119.1234792797],
        [1478822400000, 4630773.4709864445],
        [1478908800000, 7585168.214197377],
        [1478995200000, 7517444.6862510005],
        [1479081600000, 2576156.680039528],
        [1479168000000, 4844767.740034414],
        [1479254400000, 7762025.46717357],
        [1479340800000, 5552737.539381997],
        [1479427200000, 8802335.674523208],
        [1479513600000, 3851758.816579985],
        [1479600000000, 4110666.001854235],
        [1479686400000, 2465979.6564902803],
        [1479772800000, 9216379.377587905],
        [1479859200000, 3470748.2472650427],
        [1479945600000, 13786747.083724402],
        [1480032000000, 6006996.215784014],
        [1480118400000, 2161817.2787581105],
        [1480204800000, 6689742.924678162],
        [1480291200000, 4477356.374190036],
        [1480377600000, 13917166.749888523],
        [1480464000000, 9134285.15725555],
        [1480550400000, 4918051.799250141],
        [1480636800000, 15380798.551991535],
        [1480723200000, 10486224.197755983],
        [1480809600000, 6571191.489582562],
        [1480896000000, 15221226.681976186],
        [1480982400000, 56439748.77481539],
        [1481068800000, 25080911.317097366],
        [1481155200000, 12722352.036778716],
        [1481241600000, 10533790.230199138],
        [1481328000000, 5983385.689231349],
        [1481414400000, 4100522.2593546156],
        [1481500800000, 6987403.012327321],
        [1481587200000, 2905152.217437659],
        [1481673600000, 3686497.057651392],
        [1481760000000, 8295155.05874539],
        [1481846400000, 2694314.912774315],
        [1481932800000, 5469007.7856543865],
        [1482019200000, 3489317.4199424963],
        [1482105600000, 3326290.3901573],
        [1482192000000, 3764440.2234887206],
        [1482278400000, 6468951.581053176],
        [1482364800000, 12618111.15631556],
        [1482451200000, 14660852.226076419],
        [1482537600000, 5396882.270456221],
        [1482624000000, 6089953.448754245],
        [1482710400000, 4090569.3333637784],
        [1482796800000, 6649186.1155613605],
        [1482883200000, 13844753.339093078],
        [1482969600000, 20692130.792193126],
        [1483056000000, 7751538.43158162],
        [1483142400000, 3754739.209030752],
        [1483228800000, 7811699.12614274],
        [1483315200000, 8192075.241699923],
        [1483401600000, 24617090.479933955],
        [1483488000000, 30283873.09261184],
        [1483574400000, 31832239.059713636],
        [1483660800000, 23015612.49187432],
        [1483747200000, 15178458.281250305],
        [1483833600000, 9726315.158380691],
        [1483920000000, 18147872.43406206],
        [1484006400000, 8483903.665315015],
        [1484092800000, 15650725.63529802],
        [1484179200000, 10113120.406065958],
        [1484265600000, 7919565.412407908],
        [1484352000000, 4879177.658292587],
        [1484438400000, 4332221.110583241],
        [1484524800000, 5886593.4906641105],
        [1484611200000, 14660002.940091308],
        [1484697600000, 8053530.980182525],
        [1484784000000, 6357101.908723087],
        [1484870400000, 7935034.033635709],
        [1484956800000, 8840650.535766678],
        [1485043200000, 5158451.375139222],
        [1485129600000, 5620391.166760423],
        [1485216000000, 5709572.008183221],
        [1485302400000, 5749423.917130453],
        [1485388800000, 6557326.8506697165],
        [1485475200000, 5497367.838993973],
        [1485561600000, 5053699.38083557],
        [1485648000000, 3291646.7339461427],
        [1485734400000, 5394044.15637189],
        [1485820800000, 8686038.921618612],
        [1485907200000, 9511590.154111855],
        [1485993600000, 6770261.660819854],
        [1486080000000, 8348606.141529283],
        [1486166400000, 10294011.746300206],
        [1486252800000, 3675287.6308482876],
        [1486339200000, 4068555.061902807],
        [1486425600000, 4749524.532223364],
        [1486512000000, 6195998.7672163835],
        [1486598400000, 13014114.532155838],
        [1486684800000, 8465560.862563102],
        [1486771200000, 5365544.876506018],
        [1486857600000, 4159375.2547445847],
        [1486944000000, 4407682.629750895],
        [1487030400000, 31343305.129852153],
        [1487116800000, 12601119.248384409],
        [1487203200000, 10436344.691817228],
        [1487289600000, 7412007.524208957],
        [1487376000000, 5979769.378102808],
        [1487462400000, 5083526.063930682],
        [1487548800000, 6982638.937362893],
        [1487635200000, 12724890.253553009],
        [1487721600000, 6874100.081802634],
        [1487808000000, 8909149.88275321],
        [1487894400000, 13610109.543739341],
        [1487980800000, 12468242.903672196],
        [1488067200000, 13782004.348183315],
        [1488153600000, 20501520.187154144],
        [1488240000000, 43441932.36968612],
        [1488326400000, 26801192.633228768],
        [1488412800000, 63520413.50856188],
        [1488499200000, 63619640.043579124],
        [1488585600000, 17012295.23543211],
        [1488672000000, 16685230.67871547],
        [1488758400000, 19157704.333576992],
        [1488844800000, 22162502.616289888],
        [1488931200000, 25853719.80005299],
        [1489017600000, 19983777.62089817],
        [1489104000000, 57671666.8607414],
        [1489190400000, 49677899.622201264],
        [1489276800000, 49928738.77542156],
        [1489363200000, 171741714.0352735],
        [1489449600000, 70373909.66103722],
        [1489536000000, 116848018.75719893],
        [1489622400000, 278693085.2513411],
        [1489708800000, 334923528.91465944],
        [1489795200000, 151727339.68589038],
        [1489881600000, 151889492.84525648],
        [1489968000000, 100344408.21407366],
        [1490054400000, 59182511.95934919],
        [1490140800000, 66568697.13198635],
        [1490227200000, 64280704.82629739],
        [1490313600000, 186199954.72870487],
        [1490400000000, 161617494.53457117],
        [1490486400000, 85306682.68239024],
        [1490572800000, 72421320.02870055],
        [1490659200000, 63445924.96171639],
        [1490745600000, 94092094.51316193],
        [1490832000000, 51703487.39219208],
        [1490918400000, 93559373.29078574],
        [1491004800000, 57319943.03719086],
        [1491091200000, 86493796.3560341],
        [1491177600000, 107658222.89744815],
        [1491264000000, 94009509.91013359],
        [1491350400000, 77394936.33704934],
        [1491436800000, 83596063.24955817],
        [1491523200000, 45710556.43334019],
        [1491609600000, 47830509.431582466],
        [1491696000000, 23843828.235415436],
        [1491782400000, 30011146.706036303],
        [1491868800000, 24362779.834156487],
        [1491955200000, 64060330.38766039],
        [1492041600000, 105834573.1245954],
        [1492128000000, 57312861.70789763],
        [1492214400000, 44031479.42837937],
        [1492300800000, 18940687.571405288],
        [1492387200000, 29334200.22162203],
        [1492473600000, 46490458.14501291],
        [1492560000000, 49801919.05302366],
        [1492646400000, 51865276.227512024],
        [1492732800000, 29790338.033409435],
        [1492819200000, 25473890.075759377],
        [1492905600000, 29345869.98540767],
        [1492992000000, 41775081.579924054],
        [1493078400000, 29265025.365697548],
        [1493164800000, 90529456.77094631],
        [1493251200000, 186806133.133835],
        [1493337600000, 270510184.7218242],
        [1493424000000, 126594019.26335718],
        [1493510400000, 173218958.1717786],
        [1493596800000, 290126273.0476898],
        [1493683200000, 138794895.4622068],
        [1493769600000, 115183975.0640192],
        [1493856000000, 269928948.78367954],
        [1493942400000, 233798440.24945286],
        [1494028800000, 95042529.8296724],
        [1494115200000, 109489063.89598095],
        [1494201600000, 166178760.5428186],
        [1494288000000, 258922158.28469768],
        [1494374400000, 109313275.86282022],
        [1494460800000, 94641972.77458185],
        [1494547200000, 90420897.82119603],
        [1494633600000, 59897616.43618652],
        [1494720000000, 48280690.88076472],
        [1494806400000, 118176219.01917155],
        [1494892800000, 96955301.31501137],
        [1494979200000, 116563051.53420986],
        [1495065600000, 148398962.68830538],
        [1495152000000, 380753404.2666354],
        [1495238400000, 211606740.98925158],
        [1495324800000, 362117265.2049605],
        [1495411200000, 733476989.0001667],
        [1495497600000, 314107904.8358022],
        [1495584000000, 465231566.895268],
        [1495670400000, 455310601.9269435],
        [1495756800000, 320845434.46142286],
        [1495843200000, 524489553.9146857],
        [1495929600000, 275566686.9159719],
        [1496016000000, 317142762.9142854],
        [1496102400000, 820279582.1697654],
        [1496188800000, 649753307.2629591],
        [1496275200000, 438784642.5121543],
        [1496361600000, 226780300.74337018],
        [1496448000000, 199543169.93195236],
        [1496534400000, 405540899.41456276],
        [1496620800000, 233840017.29033926],
        [1496707200000, 421823672.7720283],
        [1496793600000, 231988781.8625898],
        [1496880000000, 181079369.60149828],
        [1496966400000, 268066729.96297646],
        [1497052800000, 801852630.5281926],
        [1497139200000, 636143278.2379793],
        [1497225600000, 1421483466.375218],
        [1497312000000, 735420495.2051558],
        [1497398400000, 564686366.697392],
        [1497484800000, 1019438927.4375795],
        [1497571200000, 404203523.11443627],
        [1497657600000, 299739757.59496593],
        [1497744000000, 315402069.35223436],
        [1497830400000, 255793325.43759757],
        [1497916800000, 517663237.25955987],
        [1498003200000, 719062539.4411728],
        [1498089600000, 373363641.5631702],
        [1498176000000, 217363813.30567747],
        [1498262400000, 216259454.09568173],
        [1498348800000, 492617160.2802557],
        [1498435200000, 892409899.091193],
        [1498521600000, 739337521.914465],
        [1498608000000, 735209015.2786213],
        [1498694400000, 467820573.9428333],
        [1498780800000, 359285362.4912947],
        [1498867200000, 364629591.9530462],
        [1498953600000, 352969409.70496273],
        [1499040000000, 244798911.4174002],
        [1499126400000, 239212708.08591032],
        [1499212800000, 269182917.884202],
        [1499299200000, 182999371.28307688],
        [1499385600000, 257098223.16767168],
        [1499472000000, 237052454.07736254],
        [1499558400000, 126590931.37364098],
        [1499644800000, 411832452.472758],
        [1499731200000, 628400853.3093524],
        [1499817600000, 480090644.3759754],
        [1499904000000, 311100836.347462],
        [1499990400000, 282809496.9510771],
        [1500076800000, 245487302.92215428],
        [1500163200000, 594995539.910624],
        [1500249600000, 527360935.2359769],
        [1500336000000, 979531260.0237657],
        [1500422400000, 769335320.255077],
        [1500508800000, 731711069.5424181],
        [1500595200000, 390393974.2217934],
        [1500681600000, 229970082.7943966],
        [1500768000000, 204467773.72653127],
        [1500854400000, 144284203.8135455],
        [1500940800000, 358021108.5784135],
        [1501027200000, 291270680.5567596],
        [1501113600000, 155763232.69062254],
        [1501200000000, 312701503.6657654],
        [1501286400000, 420378363.7250074],
        [1501372800000, 278403491.47329795],
        [1501459200000, 256040954.6827099],
        [1501545600000, 794803694.1987914],
        [1501632000000, 310747315.777984],
        [1501718400000, 229219665.889338],
        [1501804800000, 203505640.27122915],
        [1501891200000, 615120367.3016751],
        [1501977600000, 640103600.4602818],
        [1502064000000, 435385633.8349678],
        [1502150400000, 678573131.3986272],
        [1502236800000, 1197172634.0328116],
        [1502323200000, 448574816.64381045],
        [1502409600000, 411643039.51414186],
        [1502496000000, 485474525.24875605],
        [1502582400000, 689079789.7596157],
        [1502668800000, 384478726.7916482],
        [1502755200000, 526951176.2194243],
        [1502841600000, 640592816.9629201],
        [1502928000000, 641280744.7518665],
        [1503014400000, 590470398.7743214],
        [1503100800000, 553702157.2699106],
        [1503187200000, 342822961.8711704],
        [1503273600000, 1743909692.665895],
        [1503360000000, 898344262.6106703],
        [1503446400000, 466474643.49241275],
        [1503532800000, 397752658.9354108],
        [1503619200000, 451950699.05019414],
        [1503705600000, 333839327.838868],
        [1503792000000, 370052131.98739076],
        [1503878400000, 519874393.4238931],
        [1503964800000, 767166476.1357825],
        [1504051200000, 852991842.1703743],
        [1504137600000, 414360266.00919527],
        [1504224000000, 505643412.793286],
        [1504310400000, 958653961.776176],
        [1504396800000, 671189521.5578377],
        [1504483200000, 1278523027.0732577],
        [1504569600000, 1099567125.3565156],
        [1504656000000, 672471698.8217244],
        [1504742400000, 431394249.7466129],
        [1504828800000, 660392679.7301207],
        [1504915200000, 315515992.3564483],
        [1505001600000, 456818455.0948669],
        [1505088000000, 363007787.84414524],
        [1505174400000, 583871859.8283461],
        [1505260800000, 721738885.8360373],
        [1505347200000, 929861557.1062983],
        [1505433600000, 1617179859.467345],
        [1505520000000, 586258300.894896],
        [1505606400000, 336444511.1569665],
        [1505692800000, 935880977.9429078],
        [1505779200000, 518505103.8522604],
        [1505865600000, 322301352.00916153],
        [1505952000000, 470196974.05520844],
        [1506038400000, 343728639.336796],
        [1506124800000, 365565238.54476005],
        [1506211200000, 473679365.7914858],
        [1506297600000, 367147869.49683565],
        [1506384000000, 240690671.2951268],
        [1506470400000, 394839374.31574607],
        [1506556800000, 344032408.65444744],
        [1506643200000, 401420308.2527799],
        [1506729600000, 234494285.9120729],
        [1506816000000, 208656622.10220405],
        [1506902400000, 228753338.77109602],
        [1506988800000, 213674545.194013],
        [1507075200000, 167895948.31847614],
        [1507161600000, 166289551.89366713],
        [1507248000000, 223872523.77550793],
        [1507334400000, 259031771.53758535],
        [1507420800000, 195574551.40328008],
        [1507507200000, 422803467.0821769],
        [1507593600000, 262661724.03535348],
        [1507680000000, 179994094.52122995],
        [1507766400000, 389123581.441959],
        [1507852800000, 1062936136.5475445],
        [1507939200000, 384671082.3691576],
        [1508025600000, 513925811.79312485],
        [1508112000000, 536987705.5520873],
        [1508198400000, 368660409.98234016],
        [1508284800000, 459286354.2531358],
        [1508371200000, 251750069.63289016],
        [1508457600000, 270984512.97597694],
        [1508544000000, 291385148.2978586],
        [1508630400000, 227830530.53531405],
        [1508716800000, 357572834.9115806],
        [1508803200000, 522634263.28484994],
        [1508889600000, 224745395.63136017],
        [1508976000000, 175211825.16558704],
        [1509062400000, 165062612.7639997],
        [1509148800000, 178675171.42583302],
        [1509235200000, 400151389.4858402],
        [1509321600000, 245646777.54787138],
        [1509408000000, 275058601.9442181],
        [1509494400000, 343876003.8559511],
        [1509580800000, 727168619.8327091],
        [1509667200000, 493531953.8114265],
        [1509753600000, 317726072.07408786],
        [1509840000000, 250477635.77464697],
        [1509926400000, 472946830.30819535],
        [1510012800000, 415882643.8192555],
        [1510099200000, 755752328.1239891],
        [1510185600000, 691815366.2495632],
        [1510272000000, 650505791.3247348],
        [1510358400000, 618676053.5502169],
        [1510444800000, 1172943695.0944433],
        [1510531200000, 783366133.855764],
        [1510617600000, 507718197.434228],
        [1510704000000, 518053273.608587],
        [1510790400000, 540577805.468288],
        [1510876800000, 408873409.223705],
        [1510963200000, 437969175.189351],
        [1511049600000, 892315491.355752],
        [1511136000000, 511630930.906117],
        [1511222400000, 667087478.419292],
        [1511308800000, 502690893.802197],
        [1511395200000, 1357607759.02526],
        [1511481600000, 1710984791.93773],
        [1511568000000, 1062451148.98556],
        [1511654400000, 848847109.547722],
        [1511740800000, 982703877.999528],
        [1511827200000, 640921145.689493],
        [1511913600000, 1864835257.85043],
        [1512000000000, 1302713009.05289],
        [1512086400000, 731926955.703011],
        [1512172800000, 571377802.810273],
        [1512259200000, 513723192.525671],
        [1512345600000, 559902818.597381],
        [1512432000000, 590010796.38345],
        [1512518400000, 5911434.14179457],
        [1512604800000, 968306459.942749],
        [1512691200000, 1578944197.85945],
        [1512777600000, 1109058208.5207],
        [1512864000000, 900203443.334746],
        [1512950400000, 1035160071.24411],
        [1513036800000, 3691983511.74634],
        [1513123200000, 2717113879.86409],
        [1513209600000, 2551109082.71176],
        [1513296000000, 1942292719.83027],
        [1513382400000, 1323743950.88971],
        [1513468800000, 1297439773.48386],
        [1513555200000, 2104028876.27651],
        [1513641600000, 2383800980.37899],
        [1513728000000, 2174389927.16457],
        [1513814400000, 1886649146.02117],
        [1513900800000, 2635463381.90133],
        [1513987200000, 1292257943.85486],
        [1514073600000, 1224218821.95787],
        [1514160000000, 1242520658.38317],
        [1514246400000, 1046697072.25852],
        [1514332800000, 910830156.598811],
        [1514419200000, 1012380621.45529],
        [1514505600000, 1172554374.08768],
        [1514592000000, 1306193221.81905],
        [1514678400000, 1127368142.17048],
        [1514764800000, 1101627635.65143],
        [1514851200000, 2720973272.07018],
        [1514937600000, 2308657205.9772067],
        [1515024000000, 2756635496.077742],
        [1515110400000, 2894788528.9368753],
        [1515196800000, 1824841952.503006],
        [1515283200000, 2286423001.2638764],
        [1515369600000, 4813539670.332469],
        [1515456000000, 4248463429.84743],
        [1515542400000, 5901388488.269492],
        [1515628800000, 3701485302.9898286],
        [1515715200000, 2569674473.5809693],
        [1515801600000, 3149029707.9682436],
        [1515888000000, 2771837301.4899707],
        [1515974400000, 2438212396.6501827],
        [1516060800000, 5197858118.489889],
        [1516147200000, 5505023202.5208645],
        [1516233600000, 3220457431.1306267],
        [1516320000000, 2067139497.7426836],
        [1516406400000, 1927757662.931302],
        [1516492800000, 1834167990.4392762],
        [1516579200000, 2128266201.553245],
        [1516665600000, 2162095341.3385787],
        [1516752000000, 1871168506.347743],
        [1516838400000, 2098100041.2531192],
        [1516924800000, 2126139932.407397],
        [1517011200000, 1592501816.2673755],
        [1517097600000, 3105991887.223209],
        [1517184000000, 2005499364.9335327],
        [1517270711070, 2006770995.7220535],
        [1517356800000, 2570454739.6687236],
        [1517443200000, 2148302756.922482],
        [1517529600000, 3414246407.713859],
        [1517616000000, 4640850229.358906],
        [1517702400000, 1878004123.5207362],
        [1517788800000, 1882681773.9790668],
        [1517875200000, 2941430688.7490797],
        [1517961600000, 5021975963.611412],
        [1518048323681, 2635662719.4442654],
        [1518134400000, 2256661883.3019075],
        [1518220800000, 1609823406.4155498],
        [1518307200000, 1811750053.1438994],
        [1518393600000, 1606757570.924482],
        [1518480000000, 1342080234.6450908],
        [1518566400000, 1215799275.6549883],
        [1518652800000, 1659598861.037234],
        [1518739200000, 1674187629.7427762],
        [1518825600000, 1274489447.9994729],
        [1518912000000, 1331103327.3327596],
        [1518998400000, 1443742228.731157],
        [1519084800000, 1113969168.9102838],
        [1519171200000, 1376504167.75521],
        [1519257600000, 1249190216.7073753],
        [1519344000000, 1255677307.494043],
        [1519430400000, 1553348119.733452],
        [1519516800000, 1133793473.7387242],
        [1519603200000, 971919367.4852569],
        [1519689600000, 1273600054.5671887],
        [1519776000000, 1185779363.0730155],
        [1519862400000, 1088578648.6068828],
        [1519948800000, 1046744161.7080027],
        [1520035200000, 1065182064.2918291],
        [1520121600000, 968839520.3151623],
        [1520208000000, 921438207.058072],
        [1520294400000, 996644678.1014967],
        [1520380800000, 1163651036.9598706],
        [1520467200000, 1517892302.80913],
        [1520553600000, 1253573581.5186775],
        [1520640000000, 1559125766.2324708],
        [1520726400000, 1019304765.7600274],
        [1520812800000, 1044019470.3085319],
        [1520899200000, 1041451579.0783635],
        [1520985600000, 914239003.1825721],
        [1521072000000, 1222860953.9396286],
        [1521158400000, 1314501765.2298925],
        [1521244800000, 939394446.556439],
        [1521331200000, 922526847.9439002],
        [1521417600000, 2515158736.529607],
        [1521504000000, 1668238269.8238573],
        [1521590400000, 1363997499.3256834],
        [1521676800000, 1242490235.347],
        [1521763200000, 1029637021.1311402],
        [1521849600000, 1024506177.6381531],
        [1521936000000, 722258331.0537022],
        [1522022400000, 654302263.778145],
        [1522108800000, 1130010306.3489618],
        [1522195200000, 1205007525.3029315],
        [1522281600000, 899161524.3411528],
        [1522368000000, 1501135895.8124254],
        [1522454400000, 1552652584.610018],
        [1522540800000, 970454263.2346877],
        [1522627200000, 928418706.5875922],
        [1522713600000, 725811930.9112238],
        [1522800000000, 938277656.037438],
        [1522886400000, 888566484.4804863],
        [1522972800000, 767721856.0504649],
        [1523059200000, 590720199.8519591],
        [1523145600000, 549082514.9320759],
        [1523232000000, 565597670.5997823],
        [1523318400000, 1095742137.015535],
        [1523404800000, 687260482.4501439],
        [1523491200000, 816313720.2347212],
        [1523577600000, 1871680188.8730233],
        [1523664000000, 1771917382.798235],
        [1523750400000, 960941082.4787031],
        [1523836800000, 1113471501.5870776],
        [1523923200000, 952394918.7009709],
        [1524009600000, 834810907.6434356],
        [1524096000000, 827471474.463687],
        [1524182400000, 1223410365.7024539],
        [1524268800000, 1477100076.3424082],
        [1524355200000, 1412079787.2669172],
        [1524441600000, 1226887273.8293471],
        [1524528000000, 1175262573.641298],
        [1524614400000, 2013232706.0861015],
        [1524700800000, 2772144667.1931686],
        [1524787200000, 1616604527.0569918],
        [1524873600000, 1327872538.0128767],
        [1524960000000, 1170961463.496074],
        [1525046400000, 1180427164.5645165],
        [1525132800000, 1271088003.6155422],
        [1525219200000, 1342793449.0474029],
        [1525305600000, 1153897333.4141612],
        [1525392000000, 1005515083.8603067],
        [1525478400000, 1967182623.5957444],
        [1525564800000, 1617671981.240539],
        [1525651200000, 1742487117.419987],
        [1525737600000, 2808727749.1170845],
        [1525824000000, 1562833107.5547128],
        [1525910400000, 1517875906.0296729],
        [1525996800000, 1329169267.9922028],
        [1526083200000, 1867453050.653334],
        [1526169600000, 1574237121.080983],
        [1526256000000, 1358354339.8603525],
        [1526342400000, 1747256249.2920547],
        [1526428800000, 1318045201.5495617],
        [1526515200000, 1283393781.5952938],
        [1526601600000, 1148132528.5857544],
        [1526688000000, 1140013811.1318896],
        [1526774400000, 955540974.5773942],
        [1526860800000, 964430375.9997903],
        [1526947200000, 914750523.9094018],
        [1527033600000, 1134342772.6066892],
        [1527120000000, 1636502158.2149532],
        [1527206400000, 1578836653.594426],
        [1527292800000, 1130488777.7931898],
        [1527379200000, 793211715.4555622],
        [1527465600000, 936703218.9329598],
        [1527552000000, 1349222685.5995286],
        [1527638400000, 1304128810.668677],
        [1527724800000, 1076970536.7091825],
        [1527811200000, 1016848280.1956758],
        [1527897600000, 988913896.7405567],
        [1527984000000, 917573393.7371987],
        [1528070400000, 960786811.8699535],
        [1528156800000, 998788030.4591345],
        [1528243200000, 981693246.7757119],
        [1528329600000, 883508366.7942146],
        [1528416000000, 987621968.4491141],
        [1528502400000, 774931924.148761],
        [1528588800000, 704024660.4544114],
        [1528675200000, 1323637042.9511685],
        [1528761600000, 1163129332.5094237],
        [1528848000000, 1183551767.9992313],
        [1528934400000, 1330197064.145758],
        [1529020800000, 1634517037.5387144],
        [1529107200000, 1129671619.800608],
        [1529193600000, 732126705.7704039],
        [1529280000000, 675191594.9936471],
        [1529366400000, 861532499.7213074],
        [1529452800000, 1047558826.1450363],
        [1529539200000, 983081480.6999408],
        [1529625600000, 901042405.2653681],
        [1529712000000, 1473054828.325441],
        [1529798400000, 1088716168.438927],
        [1529884800000, 1813906376.293752],
        [1529971200000, 3935188498.9796495],
        [1530057600000, 3145279252.081028],
        [1530144000000, 1459598903.6298532],
        [1530230400000, 1247515217.4657764],
        [1530316800000, 1274641927.2798553],
        [1530403200000, 1242421813.7124994],
        [1530489600000, 1389903375.1050892],
        [1530576000000, 1756088768.4057357],
        [1530662400000, 1747988600.5163236],
        [1530748800000, 1569050880.151065],
        [1530835200000, 1727252653.8580823],
        [1530921600000, 1573448525.1336815],
        [1531008000000, 1498055824.3153505],
        [1531094400000, 1475654563.4116213],
        [1531180800000, 1637687610.915009],
        [1531267200000, 1785960365.2391424],
        [1531353600000, 1290310142.3535857],
        [1531440000000, 1431811643.4801674],
        [1531526400000, 1446717925.4764767],
        [1531612800000, 1123965513.5737858],
        [1531699200000, 1144805112.5639584],
        [1531785600000, 2202047226.057058],
        [1531872000000, 1948063360.783312],
        [1531958400000, 1914258994.0043561],
        [1532044800000, 1540257887.5019171],
        [1532131200000, 1657375888.3844986],
        [1532217600000, 1274976611.9245467],
        [1532304000000, 1171779230.1386185],
        [1532390400000, 1376984398.4663281],
        [1532476800000, 1761639225.2861466],
        [1532563200000, 1567839914.2626874],
        [1532649600000, 1425778759.9011617],
        [1532736000000, 1318960361.3308747],
        [1532822400000, 15896613818.38507],
        [1532908800000, 8096703017.25451],
        [1532995200000, 1658111274.2681992],
        [1533081600000, 1576813468.9990995],
        [1533168000000, 5779965392.271022],
        [1533254400000, 2616667154.5040126],
        [1533340800000, 2104388036.4109633],
        [1533427200000, 1809713052.7134383],
        [1533513600000, 2106705188.799999],
        [1533600000000, 2315454592.965536],
        [1533686400000, 1851482817.3237607],
        [1533772800000, 1935897432.9447079],
        [1533859200000, 2317855524.1517854],
        [1533945600000, 2027472927.1744547],
        [1534032000000, 2371854614.6728854],
        [1534118400000, 2055561310.2074306],
        [1534204800000, 2305624514.7211037],
        [1534291200000, 3134794913.6870646],
        [1534377600000, 2818580241.5689964],
        [1534464000000, 2409323174.6488853],
        [1534550400000, 2930409554.8592534],
        [1534636800000, 2806352498.6709337],
        [1534723200000, 2503896967.514467],
        [1534809600000, 2209797060.591189],
        [1534896000000, 2099627671.6781328],
        [1534982400000, 2312267790.5229416],
        [1535068800000, 2099144009.1521382],
        [1535155200000, 2215723321.9649477],
        [1535241600000, 1727946125.1412983],
        [1535328000000, 1905358200.9155493],
        [1535414400000, 2157563439.765662],
        [1535500800000, 2548394710.003952],
        [1535587200000, 2536785879.3731794],
        [1535673600000, 2491159403.542024],
        [1535760000000, 2200676475.6614757],
        [1535846400000, 2591135538.1810236],
        [1535932800000, 2297225428.9853034],
        [1536019200000, 2057722570.6458614],
        [1536105600000, 2979570499.016402],
        [1536192000000, 4099897499.876869],
        [1536278400000, 2701061396.205367],
        [1536364800000, 2789431368.228549],
        [1536451200000, 2540332938.5796466],
        [1536537600000, 3175761389.4267607],
        [1536624000000, 2491788678.425953],
        [1536710400000, 2596452765.8883944],
        [1536796800000, 2604494814.9428654],
        [1536883200000, 3501498582.9411836],
        [1536969600000, 3131560545.2123165],
        [1537056000000, 2852106466.4745975],
        [1537142400000, 2369163812.379939],
        [1537228800000, 2973745225.343125],
        [1537315200000, 2669510295.5159802],
        [1537401600000, 2532964971.008765],
        [1537488000000, 2506415802.825286],
        [1537574400000, 3224898332.902585],
        [1537660800000, 2253360127.7800627],
        [1537747200000, 2228170858.2763815],
        [1537833600000, 2153893196.3090963],
        [1537920000000, 4690848657.831447],
        [1538006400000, 2266901693.2743306],
        [1538092800000, 2324850663.3816543],
        [1538179200000, 1941656837.4249225],
        [1538265600000, 2157048771.9024014],
        [1538352000000, 1696510081.8875268],
        [1538438400000, 1380169412.0950716],
        [1538524800000, 1289588248.9376829],
        [1538611200000, 1484341832.0366879],
        [1538697600000, 1299403541.2219515],
        [1538784000000, 1343329878.055222],
        [1538870400000, 1289880374.6702132],
        [1538956800000, 1219257804.378523],
        [1539043200000, 1307100279.2014499],
        [1539129600000, 1144035692.1601665],
        [1539216000000, 1221609498.330973],
        [1539302400000, 1985897938.5728974],
        [1539388800000, 1432531941.2853491],
        [1539475200000, 1075912682.4856188],
        [1539561600000, 1120326805.1559036],
        [1539648000000, 3003242429.5916],
        [1539734400000, 1278234751.758753],
        [1539820800000, 1230898825.0612144],
        [1539907200000, 1267340948.4957962],
        [1539993600000, 1230724742.5840461],
        [1540080000000, 1154390188.6976511],
        [1540166400000, 1070945888.3231828],
        [1540252800000, 1242296100.6213374],
        [1540339200000, 1232758658.0737],
        [1540425600000, 1220612744.1480913],
        [1540512000000, 1165395713.6574717],
        [1540598400000, 1294283606.8483193],
        [1540684800000, 1055337328.7702934],
        [1540771200000, 1039726472.1950811],
        [1540857600000, 1525796041.9084504],
        [1540944000000, 977416380.6935779],
        [1541030400000, 1270047763.0015414],
        [1541116800000, 1320296639.6714208],
        [1541203200000, 1261558842.1750631],
        [1541289600000, 1031402141.4720232],
        [1541376000000, 1796786475.6827538],
        [1541462400000, 1497004600.6516602],
        [1541548800000, 1767914756.9357538],
        [1541635200000, 1482170343.362153],
        [1541721600000, 1330922735.7940972],
        [1541808000000, 1088745373.4360032],
        [1541894400000, 1068409319.3349147],
        [1541980800000, 1300514887.7948813],
        [1542067200000, 1282532597.6221092],
        [1542153600000, 1395382434.391138],
        [1542240000000, 2658768934.172415],
        [1542326400000, 2461869578.310328],
        [1542412800000, 1724796640.6795118],
        [1542499200000, 1412767953.2110136],
        [1542585600000, 1419370369.0170794],
        [1542672000000, 2804845847.3432374],
        [1542758400000, 3210253186.2157793],
        [1542844800000, 2864570364.233047],
        [1542931200000, 1749233437.3121877],
        [1543017600000, 1739142608.9564946],
        [1543104000000, 1678866535.7412324],
        [1543190400000, 2438195895.932367],
        [1543276800000, 1897018311.0723217],
        [1543363200000, 1997220602.5212777],
        [1543449600000, 2140930653.1883004],
        [1543536000000, 1607356549.8743916],
        [1543622400000, 1719140725.5823185],
        [1543708800000, 1471310196.1165853],
        [1543795200000, 1311180616.0078957],
        [1543881600000, 1272979823.5054636],
        [1543968000000, 1334764925.9641466],
        [1544054400000, 1338392608.1239889],
        [1544140800000, 1619374013.0384092],
        [1544227200000, 2504222326.1841803],
        [1544313600000, 1807021801.6527622],
        [1544400000000, 1591278001.7639914],
        [1544486400000, 1295907311.516668],
        [1544572800000, 1392270657.2968814],
        [1544659200000, 1232429721.5102735],
        [1544745600000, 1269694470.9247181],
        [1544832000000, 1325123179.3098264],
        [1544918400000, 1109693017.2554052],
        [1545004800000, 1198587935.4117327],
        [1545091200000, 1696264039.588314],
        [1545177600000, 1751770011.1099293],
        [1545264000000, 1971206305.0304084],
        [1545350400000, 2413040598.1809735],
        [1545436800000, 2146527658.029341],
        [1545523200000, 1843031240.6757457],
        [1545609600000, 3038557582.0165663],
        [1545696000000, 4190149358.5431848],
        [1545782400000, 2981293112.474187],
        [1545868800000, 2263075668.319348],
        [1545955200000, 1964406471.6911712],
        [1546041600000, 2611941307.2773275],
        [1546128000000, 2754610335.045736],
        [1546214400000, 2326472259.1586623],
        [1546300800000, 1822123555.3749323],
        [1546387200000, 1831181165.2598188],
        [1546473600000, 2970382678.100767],
        [1546560000000, 2147721864.232185],
        [1546646400000, 2802750465.265354],
        [1546732800000, 2751810037.277045],
        [1546819200000, 2604841786.5535765],
        [1546905600000, 2288173707.0961676],
        [1546992000000, 2024950462.4209144],
        [1547078400000, 1895694764.7753518],
        [1547164800000, 2728309575.30694],
        [1547251200000, 2081742756.9099514],
        [1547337600000, 1504750867.1261935],
        [1547424000000, 1642681383.2372842],
        [1547510400000, 2141032095.0550327],
        [1547596800000, 2179445232.6582036],
        [1547683200000, 2113608536.6644716],
        [1547769600000, 1953391667.2246928],
        [1547856000000, 1689783955.342064],
        [1547942400000, 1843518995.3753238],
        [1548028800000, 1968418396.7324762],
        [1548115200000, 1719454581.2400157],
        [1548201600000, 1765861558.4971473],
        [1548288000000, 1599707091.7203567],
        [1548374400000, 1605735349.503223],
        [1548460800000, 1612015659.44269],
        [1548547200000, 1433112570.853001],
        [1548633600000, 1753825291.7971783],
        [1548720000000, 2332780149.277323],
        [1548806400000, 2555950268.3740425],
        [1548892800000, 2493952726.425111],
        [1548979200000, 1980504563.8608692],
        [1549065600000, 2189489172.098924],
        [1549152000000, 1859087081.855614],
        [1549238400000, 1970373516.398768],
        [1549324800000, 1910734433.7085884],
        [1549411200000, 1850805735.245364],
        [1549497600000, 2028390258.2091599],
        [1549584000000, 1809028295.0201623],
        [1549670400000, 3337030323.848282],
        [1549756800000, 2281124257.2366414],
        [1549843200000, 2720772395.722468],
        [1549929600000, 2802017940.5234113],
        [1550016000000, 2573128709.502421],
        [1550102400000, 2635011194.1226845],
        [1550188800000, 2745287445.7246704],
        [1550275200000, 2374950565.9874945],
        [1550361600000, 2286482856.840245],
        [1550448000000, 4003447879.040646],
        [1550534400000, 5528376735.104817],
        [1550620800000, 4958547530.735177],
        [1550707200000, 4674193073.576877],
        [1550793600000, 4028897436.931248],
        [1550880000000, 3866449781.9766116],
        [1550966400000, 5041062545.201769],
        [1551052800000, 6907323014.255601],
        [1551139200000, 5199003327.451232],
        [1551225600000, 4013821729.4520674],
        [1551312000000, 4639489690.788092],
        [1551398400000, 4363574713.481887],
        [1551484800000, 3152662372.3438797],
        [1551571200000, 3386932026.236029],
        [1551657600000, 3658774171.8825684],
        [1551744000000, 4360833026.630624],
        [1551830400000, 5188731980.705806],
        [1551916800000, 4644218449.362553],
        [1552003200000, 4578357693.783855],
        [1552089600000, 4522056070.475344],
        [1552176000000, 4296123041.029174],
        [1552262400000, 4499641386.635785],
        [1552348800000, 3785480350.370964],
        [1552435200000, 4188517679.4991765],
        [1552521600000, 3621660451.668175],
        [1552608000000, 3916095404.0189743],
        [1552694400000, 4756481151.442661],
        [1552780800000, 4786242985.088408],
        [1552867200000, 3163231174.800602],
        [1552953600000, 4436947537.81063],
        [1553040000000, 4016707231.683015],
        [1553126400000, 4301026829.020336],
        [1553212800000, 5329540237.069148],
        [1553299200000, 4676895061.111653],
        [1553385600000, 4329648785.084198],
        [1553472000000, 4828779391.134742],
        [1553558400000, 5128191497.587813],
        [1553644800000, 6365187624.764251],
        [1553731200000, 6058290711.3394575],
        [1553817600000, 6421741403.9849205],
        [1553904000000, 6309182468.745683],
        [1553990400000, 6431145135.476545],
        [1554076800000, 5681255417.619743],
        [1554163200000, 5806385520.250954],
        [1554249600000, 11972928895.801353],
        [1554336000000, 14345018962.49493],
        [1554422400000, 18960578265.68538],
        [1554508800000, 8154465149.927939],
        [1554595200000, 8041146224.6182995],
        [1554681600000, 8774486908.148108],
        [1554768000000, 9174797057.457947],
        [1554854400000, 7853653932.961174],
        [1554940800000, 8535409687.957658],
        [1555027200000, 9492297742.469435],
        [1555113600000, 6864550131.632152],
        [1555200000000, 4895342267.034281],
        [1555286400000, 5453899413.191777],
        [1555372800000, 6208683988.248793],
        [1555459200000, 5341068833.609714],
        [1555545600000, 5326967582.034817],
        [1555632000000, 7052905652.127466],
        [1555718400000, 7221388008.444495],
        [1555804800000, 6156525100.7694435],
        [1555891200000, 6664047714.540679],
        [1555977600000, 6402115847.390519],
        [1556064000000, 7113521465.307921],
        [1556150400000, 8048012710.936328],
        [1556236800000, 7178205516.338611],
        [1556323200000, 7692660385.390277],
        [1556409600000, 5979883531.17244],
        [1556496000000, 5768664295.690624],
        [1556582400000, 6411778889.018952],
        [1556668800000, 7295538037.156365],
        [1556755200000, 5827474260.897809],
        [1556841600000, 6382424498.642877],
        [1556928000000, 7370814310.015348],
        [1557014400000, 7462922637.386197],
        [1557100800000, 6585100748.204202],
        [1557187200000, 8296752441.893426],
        [1557273600000, 9534335324.779873],
        [1557360000000, 6667762034.758085],
        [1557446400000, 6902719143.315466],
        [1557532800000, 8161017266.9209385],
        [1557619200000, 15122302157.846966],
        [1557705600000, 11582482850.21803],
        [1557792000000, 11346705276.931213],
        [1557878400000, 13905655019.126186],
        [1557964800000, 13672541332.505766],
        [1558051200000, 18005081606.855213],
        [1558137600000, 16370442950.862896],
        [1558224000000, 9809558171.875607],
        [1558310400000, 10660302136.0779],
        [1558396800000, 11264583865.095984],
        [1558483200000, 10173591139.714605],
        [1558569600000, 9779773115.360765],
        [1558656000000, 11366080280.52015],
        [1558742400000, 9874907867.701994],
        [1558828800000, 9323604229.835455],
        [1558915200000, 11071430353.366852],
        [1559001600000, 12655030646.34605],
        [1559088000000, 9175086732.606298],
        [1559174400000, 10947486779.437864],
        [1559260800000, 13789629797.840866],
        [1559347200000, 13065602415.97646],
        [1559433600000, 9939944521.868128],
        [1559520000000, 9394858671.397644],
        [1559606400000, 9490495448.140297],
        [1559692800000, 9485736882.825798],
        [1559779200000, 9425766068.31484],
        [1559865600000, 8404331947.2354],
        [1559952000000, 8150833842.114468],
        [1560038400000, 7354875776.242826],
        [1560124800000, 8029562832.321171],
        [1560211200000, 8199127073.858642],
        [1560297600000, 8182713237.348147],
        [1560384000000, 12143678886.284647],
        [1560470400000, 8992183283.907227],
        [1560556800000, 9249836453.371191],
        [1560643200000, 8326314916.480807],
        [1560729600000, 11214016847.926468],
        [1560816000000, 10113175117.058073],
        [1560902400000, 9119385783.21149],
        [1560988800000, 8141550514.028044],
        [1561075200000, 9013711196.951782],
        [1561161600000, 13984421402.12714],
        [1561248000000, 14958216407.156822],
        [1561334400000, 11379261835.846235],
        [1561420800000, 10801735316.460266],
        [1561507200000, 11894108469.494308],
        [1561593600000, 20783128904.669895],
        [1561680000000, 17565136833.06944],
        [1561766400000, 13481294400.562168],
        [1561852800000, 15410762009.028484],
        [1561939200000, 14452227264.203045],
        [1562025600000, 14541502314.74893],
        [1562112000000, 13634093714.668394],
        [1562198400000, 13852646285.058842],
        [1562284800000, 11865088600.655111],
        [1562371200000, 12482686164.985281],
        [1562457600000, 11073979598.076387],
        [1562544000000, 9431071377.576225],
        [1562630400000, 11412190952.131702],
        [1562716800000, 12539512334.617245],
        [1562803200000, 14363666681.915071],
        [1562889600000, 11259727245.112816],
        [1562976000000, 9759415139.820307],
        [1563062400000, 8942739616.455101],
        [1563148800000, 10112411751.87944],
        [1563235200000, 12728221879.918568],
        [1563321600000, 11546792446.85422],
        [1563408000000, 11495306974.325901],
        [1563494400000, 12224826620.798044],
        [1563580800000, 9394172336.260527],
        [1563667200000, 9443236308.126741],
        [1563753600000, 8668234008.782793],
        [1563840000000, 9159417328.812357],
        [1563926400000, 8343544802.577731],
        [1564012800000, 8036309076.5856],
        [1564099200000, 8431628740.248658],
        [1564185600000, 6261416237.370375],
        [1564272000000, 6935857584.275074],
        [1564358400000, 6820635279.878914],
        [1564444800000, 7155796188.164937],
        [1564531200000, 6861118022.849301],
        [1564617600000, 7854507842.961148],
        [1564704000000, 7106350020.545052],
        [1564790400000, 7476612908.300348],
        [1564876800000, 7118505874.499466],
        [1564963200000, 7801234062.836402],
        [1565049600000, 10565615079.886549],
        [1565136000000, 9971872218.817013],
        [1565222400000, 9705696057.326073],
        [1565308800000, 8972857706.734287],
        [1565395200000, 9017297468.618519],
        [1565481600000, 7866305403.719613],
        [1565568000000, 8817555226.301624],
        [1565654400000, 6858803450.515639],
        [1565740800000, 7468627796.213287],
        [1565827200000, 9066370572.253965],
        [1565913600000, 9569868633.162851],
        [1566000000000, 9104078191.86573],
        [1566086400000, 7074381392.731569],
        [1566172800000, 7608103574.120334],
        [1566259200000, 7984979710.387964],
        [1566345600000, 7427661565.776037],
        [1566432000000, 7533577003.439183],
        [1566518400000, 8373678956.808694],
        [1566604800000, 8310335766.427433],
        [1566691200000, 6816227733.539867],
        [1566777600000, 6925363690.298179],
        [1566864000000, 7808973221.715419],
        [1566950400000, 6715792817.800851],
        [1567036800000, 6630138918.5056305],
        [1567123200000, 7553876741.007874],
        [1567209600000, 6149887965.938229],
        [1567296000000, 6422265680.054329],
        [1567382400000, 5582323205.083545],
        [1567468800000, 6929999813.24363],
        [1567555200000, 6839270834.390837],
        [1567641600000, 6753228074.432234],
        [1567728000000, 6972203243.611649],
        [1567814400000, 7990465595.274032],
        [1567900800000, 7289035615.537996],
        [1567987200000, 7427353150.500337],
        [1568073600000, 8230569258.881535],
        [1568160000000, 6808322892.822258],
        [1568246400000, 7572468713.673471],
        [1568332800000, 6514468640.712693],
        [1568419200000, 6577694623.403602],
        [1568505600000, 7236471987.494058],
        [1568592000000, 6872781110.7933855],
        [1568678400000, 8404868032.960358],
        [1568764800000, 10534577182.03602],
        [1568851200000, 10026926701.837385],
        [1568937600000, 11660216013.817453],
        [1569024000000, 8563835819.843909],
        [1569110400000, 8291876955.773469],
        [1569196800000, 8770978542.694231],
        [1569283200000, 8336808955.502666],
        [1569369600000, 11738404290.834139],
        [1569456000000, 10971536373.46625],
        [1569542400000, 8887474208.578917],
        [1569628800000, 7741411781.909645],
        [1569715200000, 8420640752.534281],
        [1569801600000, 6622811335.857158],
        [1569888000000, 8531793896.531498],
        [1569974400000, 7030264696.061253],
        [1570060800000, 7011073715.725833],
        [1570147200000, 6571609557.977975],
        [1570233600000, 6788268823.555099],
        [1570320000000, 6878002599.189638],
        [1570406400000, 6183872973.064911],
        [1570492800000, 8651483187.380627],
        [1570579200000, 7965287586.524669],
        [1570665600000, 9901588880.945726],
        [1570752000000, 8489850464.079381],
        [1570838400000, 9464732026.7697],
        [1570924800000, 7092415247.875073],
        [1571011200000, 7218077682.306935],
        [1571097600000, 6948746949.167441],
        [1571184000000, 7255387196.496509],
        [1571270400000, 7574783773.934067],
        [1571356800000, 6589528029.318176],
        [1571443200000, 8019508916.141182],
        [1571529600000, 6653033262.426412],
        [1571616000000, 7186266521.867684],
        [1571702400000, 6738537534.893596],
        [1571788800000, 6863039381.640138],
        [1571875200000, 8423014823.046386],
        [1571961600000, 7385746457.861195],
        [1572048000000, 11568719162.372278],
        [1572134400000, 13413299279.150604],
        [1572220800000, 11927463512.844177],
        [1572307200000, 10802432065.022448],
        [1572393600000, 10858105798.943794],
        [1572480000000, 10475563029.033878],
        [1572566400000, 10677408737.976376],
        [1572652800000, 8966993687.026836],
        [1572739200000, 17556115439.989822],
        [1572825600000, 15978664590.342894],
        [1572912000000, 10711288571.709585],
        [1572998400000, 10976995833.162132],
        [1573084800000, 10229062756.046371],
        [1573171200000, 8952840250.325546],
        [1573257600000, 9317711239.968254],
        [1573344000000, 7371484625.797002],
        [1573430400000, 9145731474.140394],
        [1573516800000, 8436450179.250551],
        [1573603200000, 7224692515.135974],
        [1573689600000, 7174004374.33889],
        [1573776000000, 8007041886.640145],
        [1573862400000, 9104427564.529264],
        [1573948800000, 7196313424.600481],
        [1574035200000, 6738152838.897733],
        [1574121600000, 8026165759.571411],
        [1574208000000, 7790094393.890497],
        [1574294400000, 6713792276.519455],
        [1574380800000, 8718872247.235916],
        [1574467200000, 12883154422.38589],
        [1574553600000, 7399048990.785586],
        [1574640000000, 7849310718.476399],
        [1574726400000, 9382188064.705387],
        [1574812800000, 7520914600.00864],
        [1574899200000, 8634745624.644365],
        [1574985600000, 6528542635.067198],
        [1575072000000, 7145810322.087986],
        [1575158400000, 5436245310.980454],
        [1575244800000, 6544172474.357911],
        [1575331200000, 5011809194.132204],
        [1575417600000, 5245524511.511721],
        [1575504000000, 7074240395.510329],
        [1575590400000, 6421963684.296958],
        [1575676800000, 5806189343.207808],
        [1575763200000, 4749924969.069943],
        [1575849600000, 6027777721.176993],
        [1575936000000, 5789441263.561269],
        [1576022400000, 6047605226.725084],
        [1576108800000, 5883082276.821148],
        [1576195200000, 6766489316.62679],
        [1576281600000, 5946736085.342134],
        [1576368000000, 6322939248.375952],
        [1576454400000, 6837194965.911324],
        [1576540800000, 8956132139.815428],
        [1576627200000, 9871891691.072153],
        [1576713600000, 11532439096.28435],
        [1576800000000, 7865020994.018279],
        [1576886400000, 5979828257.7039175],
        [1576972800000, 4862554350.74467],
        [1577059200000, 7466406124.29696],
        [1577145600000, 8703094374.30469],
        [1577232000000, 7694981359.909612],
        [1577318400000, 6446543347.919613],
        [1577404800000, 7594710628.052688],
        [1577491200000, 7290590722.529487],
        [1577577600000, 6709535782.463888],
        [1577664000000, 6514129764.108342],
        [1577750400000, 8424570388.279623],
        [1577836800000, 7103187150.687103],
        [1577923200000, 6623732040.964472],
        [1578009600000, 6497297884.22486],
        [1578096000000, 9655245099.720835],
        [1578182400000, 6958393690.018681],
        [1578268800000, 7260645128.406949],
        [1578355200000, 9514201882.396584],
        [1578441600000, 9440938787.519835],
        [1578528000000, 10095847759.294762],
        [1578614400000, 7280640218.292043],
        [1578700800000, 10273370788.312328],
        [1578787200000, 9737605323.282524],
        [1578873600000, 8488793477.5950365],
        [1578960000000, 8019794567.486078],
        [1579046400000, 18970204899.197784],
        [1579132800000, 17862385531.73745],
        [1579219200000, 14605493158.53319],
        [1579305600000, 15915322019.78247],
        [1579392000000, 15808136411.02366],
        [1579478400000, 14003433869.000366],
        [1579564800000, 10983216126.222286],
        [1579651200000, 8267420452.9121065],
        [1579737600000, 8809189828.884594],
        [1579824000000, 9017537604.34573],
        [1579910400000, 11043498594.604164],
        [1579996800000, 8673816085.484137],
        [1580083200000, 10518901915.39258],
        [1580169600000, 12435457692.695446],
        [1580256000000, 14300512538.139254],
        [1580342400000, 15565369994.796808],
        [1580428800000, 18170945326.39071],
        [1580515200000, 18506953476.934044],
        [1580601600000, 19083817630.29935],
        [1580688000000, 21543158277.49522],
        [1580774400000, 23519056716.26084],
        [1580860800000, 21734656893.64477],
        [1580947200000, 27708721578.171207],
        [1581033600000, 30730370695.67516],
        [1581120000000, 34073387340.7219],
        [1581206400000, 35852075948.97941],
        [1581292800000, 37065973224.819984],
        [1581379200000, 40180881296.506096],
        [1581465600000, 17260236147.946793],
        [1581552000000, 25083848405.774593],
        [1581638400000, 31919298278.23887],
        [1581724800000, 32486783383.59592],
        [1581811200000, 31021139510.840595],
        [1581897600000, 26307305450.095947],
        [1581984000000, 28621805406.267815],
        [1582070400000, 33038477053.26572],
        [1582156800000, 24634102799.07547],
        [1582243200000, 34355959852.746918],
        [1582329600000, 18672669187.760765],
        [1582416000000, 14860160863.37393],
        [1582502400000, 15734092874.96997],
        [1582588800000, 21019871769.54907],
        [1582675200000, 15216321566.877127],
        [1582761600000, 20470479538.054836],
        [1582848000000, 23994578699.806026],
        [1582934400000, 19715536659.914295],
        [1583020800000, 13967572974.821035],
        [1583107200000, 16557363028.554333],
        [1583193600000, 18301098060.91034],
        [1583280000000, 19133763158.937866],
        [1583366400000, 14844945103.395332],
        [1583452800000, 16056678004.768778],
        [1583539200000, 16938887970.316448],
        [1583625600000, 15033855133.023136],
        [1583712000000, 18839637103.648037],
        [1583798400000, 17900321700.644154],
        [1583884800000, 10622282127.512653],
        [1583971200000, 14699687559.439268],
        [1584057600000, 17357843633.111378],
        [1584144000000, 24412190319.85676],
        [1584230400000, 8678897518.712492],
        [1584316800000, 10971643911.795376],
        [1584403200000, 11161656313.89003],
        [1584489600000, 10514054745.732649],
        [1584576000000, 9829287702.00789],
        [1584662400000, 13910341816.437582],
        [1584748800000, 16267979993.668526],
        [1584835200000, 12633534610.010733],
        [1584921600000, 10177508398.873247],
        [1585008000000, 11596650460.730272],
        [1585094400000, 12119249137.978954],
        [1585180800000, 10616935307.838089],
        [1585267200000, 7749490793.658535],
        [1585353600000, 10183491264.54011],
        [1585440000000, 11067855577.856102],
        [1585526400000, 7718875104.828544],
        [1585612800000, 8469234427.790874],
        [1585699200000, 7869741296.803818],
        [1585785600000, 7848601147.336895],
        [1585872000000, 13645052823.766476],
        [1585958400000, 11621521038.709623],
        [1586044800000, 9863619646.24454],
        [1586131200000, 8271697077.94318],
        [1586217600000, 15337221519.354977],
        [1586304000000, 16711178690.848326],
        [1586390400000, 12116309211.63937],
        [1586476800000, 11971791025.372421],
        [1586563200000, 14135915530.589357],
        [1586649600000, 11128989594.360977],
        [1586736000000, 13002801329.50846],
        [1586822400000, 13286483792.048328],
        [1586908800000, 11468431211.774742],
        [1586995200000, 8578826590.313573],
        [1587081600000, 17430629608.3789],
        [1587168000000, 15220772945.895311],
        [1587254400000, 14284812698.801844],
        [1587340800000, 13763176713.456295],
        [1587427200000, 15901061387.704433],
        [1587513600000, 12178214643.078756],
        [1587600000000, 14853382137.991045],
        [1587686400000, 6903118239.477871],
        [1587772800000, 13419651870.125168],
        [1587859200000, 14452583265.899439],
        [1587945600000, 13530756223.951258],
        [1588032000000, 17659706480.60195],
        [1588118400000, 14644384537.090769],
        [1588204800000, 20966892392.04868],
        [1588291200000, 23630952297.4432],
        [1588377600000, 17137878522.355497],
        [1588464000000, 14287655444.044804],
        [1588550400000, 16158461349.767452],
        [1588636800000, 17901489283.101368],
        [1588723200000, 14345915703.711891],
        [1588809600000, 18128961143.16994],
        [1588896000000, 15142506242.294548],
        [1588982400000, 18337241719.88773],
        [1589068800000, 15514402336.324596],
        [1589155200000, 19756507796.247448],
        [1589241600000, 18768379406.38769],
        [1589328000000, 13706747451.019007],
        [1589414400000, 13403589148.76194],
        [1589500800000, 18153298514.018234],
        [1589587200000, 13130818660.241241],
        [1589673600000, 11676763398.063229],
        [1589760000000, 13449821514.26736],
        [1589846400000, 13342260442.297512],
        [1589932800000, 10220660537.90651],
        [1590019200000, 10429639020.80288],
        [1590105600000, 8110302356.370864],
        [1590192000000, 10636959193.321962],
        [1590278400000, 8524288901.148943],
        [1590364800000, 10782742329.00735],
        [1590451200000, 9317131818.625378],
        [1590537600000, 8593529312.403248],
        [1590624000000, 8773050350.183073],
        [1590710400000, 12329460719.291054],
        [1590796800000, 10335270102.499737],
        [1590883200000, 13546265992.193748],
        [1590969600000, 10887047331.601736],
        [1591056000000, 12532381844.259752],
        [1591142400000, 11228877888.97363],
        [1591228800000, 9023955445.45879],
        [1591315200000, 10063518956.568722],
        [1591401600000, 8222827212.494165],
        [1591488000000, 7581565794.828065],
        [1591574400000, 9398819027.550787],
        [1591660800000, 8129322897.065843],
        [1591747200000, 8592468949.874516],
        [1591833600000, 7584308101.602023],
        [1591920000000, 12109538778.82486],
        [1592006400000, 9191505535.416174],
        [1592092800000, 6588386730.111501],
        [1592179200000, 6517043494.317247],
        [1592265600000, 9539612843.334753],
        [1592352000000, 6857067974.879582],
        [1592438400000, 6079869078.290233],
        [1592524800000, 6063250096.3478],
        [1592611200000, 6840801770.229276],
        [1592697600000, 5400222130.457475],
        [1592784000000, 4995955268.45639],
        [1592870400000, 5973345536.2072935],
        [1592956800000, 6291513184.395153],
        [1593043200000, 8149316604.925404],
        [1593129600000, 6321009243.432307],
        [1593216000000, 6513998360.988546],
        [1593302400000, 6164981016.058191],
        [1593388800000, 5641785424.366106],
        [1593475200000, 5792207632.621344],
        [1593561600000, 5282884955.518924],
        [1593648000000, 5307320170.136238],
        [1593734400000, 4427853855.208807],
        [1593820800000, 4553181088.144414],
        [1593907200000, 4910504255.511211],
        [1593993600000, 5250547694.457692],
        [1594080000000, 7214622724.058187],
        [1594166400000, 6390063707.992301],
        [1594252800000, 6855809513.431496],
        [1594339200000, 5454877568.354481],
        [1594425600000, 4948468762.536561],
        [1594512000000, 4350900773.68197],
        [1594598400000, 5293860444.738397],
        [1594684800000, 5773853723.2969055],
        [1594771200000, 4794731175.010703],
        [1594857600000, 4622070139.084404],
        [1594944000000, 4836661397.807636],
        [1595030400000, 4344879745.807146],
        [1595116800000, 4349365185.3359585],
        [1595203200000, 4452847140.177009],
        [1595289600000, 5152984578.126705],
        [1595376000000, 5121955556.196016],
        [1595462400000, 6556501920.467992],
        [1595548800000, 7634745874.600546],
        [1595635200000, 7547527176.1284075],
        [1595721600000, 10358352414.143063],
        [1595808000000, 10227817625.90999],
        [1595894400000, 15060479190.224041],
        [1595980800000, 12462060871.173037],
        [1596067200000, 10343143710.27412],
        [1596153600000, 10534706857.54779],
        [1596240000000, 11638423647.390888],
        [1596326400000, 15379516251.5158],
        [1596412800000, 15202899455.056835],
        [1596499200000, 11174150665.410692],
        [1596585600000, 12113993540.45853],
        [1596672000000, 10578439440.459143],
        [1596758400000, 11175559421.02824],
        [1596844800000, 12469778424.949451],
        [1596931200000, 7446547697.815684],
        [1597017600000, 5940323179.041791],
        [1597104000000, 12717549263.402819],
        [1597190400000, 13124713653.81531],
        [1597276800000, 12808587803.77308],
        [1597363200000, 17422050835.673008],
        [1597449600000, 15546677422.815374],
        [1597536000000, 10265309385.857273],
        [1597622400000, 12680404379.026093],
        [1597708800000, 11697848303.8365],
        [1597795200000, 12062999738.137259],
        [1597881600000, 12854733824.945929],
        [1597968000000, 10564357237.821798],
        [1598054400000, 12518435194.162006],
        [1598140800000, 10612983427.483643],
        [1598227200000, 9775318835.217514],
        [1598313600000, 11007565809.594412],
        [1598400000000, 12857413913.19107],
        [1598486400000, 10454041361.377256],
        [1598572800000, 10466849103.532475],
        [1598659200000, 10196208352.394411],
        [1598745600000, 9631959320.390598],
        [1598832000000, 12945668314.960146],
        [1598918400000, 14228693735.746506],
        [1599004800000, 18804560687.28111],
        [1599091200000, 19315593282.634296],
        [1599177600000, 17364384113.17322],
        [1599264000000, 10260946593.268202],
        [1599350400000, 22254663654.420143],
        [1599436800000, 21920007828.96099],
        [1599523200000, 14769191933.857542],
        [1599609600000, 14799132842.838894],
        [1599696000000, 13120851004.434223],
        [1599782400000, 74747424441.3974],
        [1599868800000, 12937594458.734825],
        [1599955200000, 11465259432.878637],
        [1600041600000, 13172306578.937866],
        [1600128000000, 14124838468.354868],
        [1600214400000, 12209389923.086823],
        [1600300800000, 13298202038.469078],
        [1600387200000, 16748693289.537083],
        [1600473600000, 9883257470.927006],
        [1600560000000, 11046298234.18736],
        [1600646400000, 11369621273.059267],
        [1600732800000, 14837815989.716738],
        [1600819200000, 11967106210.545612],
        [1600905600000, 9839917613.109154],
        [1600992000000, 12209550797.233208],
        [1601078400000, 10740036294.329079],
        [1601164800000, 10859942552.210592],
        [1601251200000, 9973839924.612421],
        [1601337600000, 9818467156.628036],
        [1601424000000, 9708794838.84551],
        [1601510400000, 8037484324.980594],
        [1601596800000, 11835729247.851833],
        [1601683200000, 12194440257.52427],
        [1601769600000, 8933121301.40709],
        [1601856000000, 8905022839.294907],
        [1601942400000, 9285381014.80383],
        [1602028800000, 10280978397.300406],
        [1602115200000, 8044228155.819704],
        [1602201600000, 9330978624.154116],
        [1602288000000, 11301702589.430536],
        [1602374400000, 10673988207.878847],
        [1602460800000, 8000631027.644073],
        [1602547200000, 12458841248.929943],
        [1602633600000, 9741612768.02954],
        [1602720000000, 10673398919.618017],
        [1602806400000, 10155629419.116392],
        [1602892800000, 9375570544.203642],
        [1602979200000, 8087278469.473757],
        [1603065600000, 7527761590.194419],
        [1603152000000, 8995049004.81075],
        [1603238400000, 7492023938.775781],
        [1603324800000, 12728437763.916903],
        [1603411200000, 12229478488.701916],
        [1603497600000, 11171063368.78023],
        [1603584000000, 9333976948.222593],
        [1603670400000, 8489563012.362298],
        [1603756800000, 14982520071.004465],
        [1603843200000, 10384433736.852089],
        [1603929600000, 11312414332.858248],
        [1604016000000, 10294153382.842941],
        [1604102400000, 10598788554.534933],
        [1604188800000, 9009356073.612816],
        [1604275200000, 8655139278.000864],
        [1604361600000, 8492071098.35507],
        [1604448000000, 9513314551.14123],
        [1604534400000, 11648560618.489246],
        [1604620800000, 13606373470.76514],
        [1604707200000, 16264349016.82086],
        [1604793600000, 15146746571.79792],
        [1604880000000, 11155033976.455217],
        [1604966400000, 12036099592.129189],
        [1605052800000, 7873172432.585956],
        [1605139200000, 11456937853.526192],
        [1605225600000, 11096201853.108873],
        [1605312000000, 10814357418.247974],
        [1605398400000, 9774490045.968235],
        [1605484800000, 8946780761.302334],
        [1605571200000, 9874800384.414816],
        [1605657600000, 12534266981.990389],
        [1605744000000, 15390748756.847664],
        [1605830400000, 10960245344.173328],
        [1605916800000, 15567496836.779345],
        [1606003200000, 17270632325.076218],
        [1606089600000, 20803203330.719395],
        [1606176000000, 22868894446.116894],
        [1606262400000, 19964356486.3813],
        [1606348800000, 15178975818.650188],
        [1606435200000, 25993581649.401203],
        [1606521600000, 14714678172.404446],
        [1606608000000, 9637463879.915295],
        [1606694400000, 13623124682.204208],
        [1606780800000, 18811269166.61186],
        [1606867200000, 23557071520.39523],
        [1606953600000, 14839044995.297548],
        [1607040000000, 12249197831.015257],
        [1607126400000, 14326184164.36095],
        [1607212800000, 11764206188.844515],
        [1607299200000, 11453823478.195438],
        [1607385600000, 10010900862.848228],
        [1607472000000, 13501656447.613894],
        [1607558400000, 14024278560.088686],
        [1607644800000, 11590043392.322668],
        [1607731200000, 11334198380.977062],
        [1607817600000, 9511330147.764025],
        [1607904000000, 9082894202.250328],
        [1607990400000, 7794572085.232029],
        [1608076800000, 10160486192.82214],
        [1608163200000, 14781462845.092134],
        [1608249600000, 19729196033.853626],
        [1608336000000, 16512361338.574877],
        [1608422400000, 14703775003.28234],
        [1608508800000, 12845420666.191206],
        [1608595200000, 14563648896.243877],
        [1608681600000, 11548425748.838194],
        [1608768000000, 16795838161.84157],
        [1608854400000, 15132872519.529186],
        [1608940800000, 13125875205.90394],
        [1609027200000, 14640527408.48169],
        [1609113600000, 24721301579.88044],
        [1609200000000, 22707049400.143177],
        [1609286400000, 17170428247.761232],
        [1609372800000, 13293869466.928808],
        [1609459200000, 18166112097.655663],
        [1609545600000, 13843183079.15519],
        [1609632000000, 20443662605.68775],
        [1609718400000, 140906534190.58786],
        [1609804800000, 62285144708.39812],
        [1609891200000, 47148245967.40434],
        [1609977600000, 47886847354.00142],
        [1610064000000, 43042878463.81648],
        [1610150400000, 48132624602.86217],
        [1610236800000, 34851118090.66404],
        [1610323200000, 44541377224.12463],
        [1610409600000, 67107873698.86543],
        [1610496000000, 37495863245.02223],
        [1610582400000, 32166268470.073753],
        [1610668800000, 34339866026.50668],
        [1610755200000, 39499008134.408424],
        [1610841600000, 38277998837.56892],
        [1610928000000, 35508914212.36928],
        [1611014400000, 30802895108.229195],
        [1611100800000, 52274850962.437416],
        [1611187200000, 52021227614.32531],
        [1611273600000, 49658518028.423325],
        [1611360000000, 48657448380.08583],
        [1611446400000, 30774910632.149376],
        [1611532800000, 39913977039.25946],
        [1611619200000, 44124188232.248085],
        [1611705600000, 40367492548.543144],
        [1611792000000, 38752412145.036766],
        [1611878400000, 32668893126.715786],
        [1611964800000, 49454503567.54182],
        [1612051200000, 29581914651.14533],
        [1612137600000, 24723054987.874157],
        [1612224000000, 28544048877.677177],
        [1612310400000, 42952410181.632454],
        [1612396800000, 42202356538.206825],
        [1612483200000, 42668487953.81155],
        [1612569600000, 39147540640.174576],
        [1612656000000, 36776919163.51647],
        [1612742400000, 38849634841.44165],
        [1612828800000, 45540524354.89187],
        [1612915200000, 42127693213.60732],
        [1613001600000, 40563120176.97226],
        [1613088000000, 33868453070.396435],
        [1613174400000, 36105354379.40371],
        [1613260800000, 33305061601.17683],
        [1613347200000, 30218599726.89195],
        [1613433600000, 37652347195.44906],
        [1613520000000, 33252538989.620327],
        [1613606400000, 33582379213.069286],
        [1613692800000, 34799956643.698685],
        [1613779200000, 34796767875.96328],
        [1613865600000, 44957939435.53464],
        [1613952000000, 33064349740.90669],
        [1614038400000, 53799590366.036766],
        [1614124800000, 62714754623.34122],
        [1614211200000, 40313400449.155266],
        [1614297600000, 31736119376.25847],
        [1614384000000, 36682073651.905846],
        [1614470400000, 28221473460.214203],
        [1614556800000, 35420463945.47327],
        [1614643200000, 31604939381.863796],
        [1614729600000, 29049758215.725],
        [1614816000000, 30352405292.089714],
        [1614902400000, 29848246930.850037],
        [1614988800000, 28537887161.018692],
        [1615075200000, 31094969328.98433],
        [1615161600000, 29416647567.957836],
        [1615248000000, 35353973627.380775],
        [1615334400000, 31147565432.965343],
        [1615420800000, 31746963272.74199],
        [1615507200000, 31257932436.53519],
        [1615593600000, 28436574756.548508],
        [1615680000000, 32785949401.529545],
        [1615766400000, 26379565439.25233],
        [1615852800000, 32950345872.852455],
        [1615939200000, 30686971237.08834],
        [1616025600000, 30744899541.009495],
        [1616112000000, 29651777827.921936],
        [1616198400000, 27345983317.046707],
        [1616284800000, 29495472699.399334],
        [1616371200000, 29228757709.8678],
        [1616457600000, 31376731220.175137],
        [1616544000000, 28758919428.479744],
        [1616630400000, 38413713634.36278],
        [1616716800000, 26325124557.630264],
        [1616803200000, 24804294373.644676],
        [1616889600000, 21118379279.876865],
        [1616976000000, 19859272131.74501],
        [1617062400000, 32618518751.6075],
        [1617148800000, 28496809577.711266],
        [1617235200000, 28955571443.672318],
        [1617321600000, 32076778812.4586],
        [1617408000000, 35467665330.67185],
        [1617494400000, 34872897876.651085],
        [1617580800000, 26817722961.480415],
        [1617667200000, 30867393925.630993],
        [1617753600000, 33391498272.783157],
        [1617840000000, 39604003543.54452],
        [1617926400000, 27145394978.191498],
        [1618012800000, 25076796421.07279],
        [1618099200000, 32243242817.777157],
        [1618185600000, 25369863410.044476],
        [1618272000000, 29096213677.73583],
        [1618358400000, 35760747636.05728],
        [1618444800000, 43351853140.30176],
        [1618531200000, 37106765660.11614],
        [1618617600000, 43982805217.511246],
        [1618704000000, 35627176091.57985],
        [1618790400000, 61890227351.97623],
        [1618876800000, 40335730705.29044],
        [1618963200000, 44737302423.76558],
        [1619049600000, 43726884251.94589],
        [1619136000000, 63343308067.3157],
        [1619222400000, 69126869461.51704],
        [1619308800000, 35640594314.43296],
        [1619395200000, 36744402576.60037],
        [1619481600000, 42791797037.03227],
        [1619568000000, 37611597877.629944],
        [1619654400000, 42878743228.1645],
        [1619740800000, 38536865198.32878],
        [1619827200000, 35477884578.62226],
        [1619913600000, 36721647718.00421],
        [1620000000000, 35727432415.51426],
        [1620086400000, 64192650586.42862],
        [1620172800000, 78437235045.70189],
        [1620259200000, 63552342594.19045],
        [1620345600000, 50430169752.9615],
        [1620432000000, 44980768421.369],
        [1620518400000, 60174921874.24125],
        [1620604800000, 61625657297.19764],
        [1620691200000, 80827215917.6633],
        [1620777600000, 67869198116.36811],
        [1620864000000, 78772032254.80873],
        [1620950400000, 97383442023.26581],
        [1621036800000, 63587729060.25979],
        [1621123200000, 58150860876.149025],
        [1621209600000, 67531308123.83328],
        [1621296000000, 75100584775.8453],
        [1621382400000, 59160464538.280525],
        [1621468800000, 117905041184.06494],
        [1621555200000, 92538167827.51727],
        [1621641600000, 115000901891.803],
        [1621728000000, 102524751568.4531],
        [1621814400000, 77839858613.53922],
        [1621900800000, 73496058830.83229],
        [1621987200000, 92468701273.98392],
        [1622073600000, 53115222704.62064],
        [1622160000000, 44070236220.034096],
        [1622246400000, 49493519120.23643],
        [1622332800000, 47141249182.27268],
        [1622419200000, 37392021393.940125],
        [1622505600000, 44445830387.932175],
        [1622592000000, 39898648753.4186],
        [1622678400000, 39148856525.29615],
        [1622764800000, 38104663185.40115],
        [1622851200000, 39996583175.266685],
        [1622937600000, 34731979704.601685],
        [1623024000000, 29050162248.93885],
        [1623110400000, 37385872210.25046],
        [1623196800000, 48344195673.521126],
        [1623283200000, 43973432438.082634],
        [1623369600000, 33075957436.760677],
        [1623456000000, 26751786966.47868],
        [1623542400000, 28235882061.70689],
        [1623628800000, 29382118964.283043],
        [1623715200000, 28128172338.347244],
        [1623801600000, 26803957680.025055],
        [1623888000000, 26150320394.527775],
        [1623974400000, 25708624827.013702],
        [1624060800000, 26204484920.993828],
        [1624147200000, 20456963374.959637],
        [1624233600000, 26235850446.54907],
        [1624320000000, 39499960407.7835],
        [1624406400000, 38755019936.85197],
        [1624492800000, 28098744044.509525],
        [1624579200000, 22447489241.67468],
        [1624665600000, 25981381067.41728],
        [1624752000000, 24249510320.705112],
        [1624838400000, 24316408028.131123],
        [1624924800000, 30629329805.72042],
        [1625011200000, 27149143846.958942],
        [1625097600000, 29046221905.503418],
        [1625184000000, 25874247678.48856],
        [1625270400000, 22869223901.008],
        [1625356800000, 19954299562.699074],
        [1625443200000, 21119209512.99698],
        [1625529600000, 22592756940.415127],
        [1625616000000, 24748897613.709663],
        [1625702400000, 23352988376.91004],
        [1625788800000, 24133566100.864506],
        [1625875200000, 22262234450.01033],
        [1625961600000, 18091111748.440804],
        [1626048000000, 14249909363.131065],
        [1626134400000, 16403901753.797194],
        [1626220800000, 18210922217.703205],
        [1626307200000, 20007437042.5854],
        [1626393600000, 17978198759.76909],
        [1626480000000, 17448403933.724445],
        [1626566400000, 14920352990.216908],
        [1626652800000, 15355055465.803198],
        [1626739200000, 15768554506.425838],
        [1626825600000, 20634025154.91444],
        [1626912000000, 25849497421.028152],
        [1626998400000, 21655718476.41885],
        [1627084800000, 20760571836.05241],
        [1627171200000, 19333415460.111233],
        [1627257600000, 16557827418.503304],
        [1627344000000, 35197538271.146904],
        [1627430400000, 28862032737.553215],
        [1627516800000, 23171592869.594303],
        [1627603200000, 18266719970.961597],
        [1627689600000, 24040211451.365524],
        [1627776000000, 21579519281.279892],
        [1627862400000, 25689002090.279446],
        [1627948800000, 26664958251.58228],
        [1628035200000, 26304380467.43418],
        [1628121600000, 29356647318.25012],
        [1628208000000, 37970347683.56547],
        [1628294400000, 30876444992.275696],
        [1628380800000, 38870720439.186905],
        [1628467200000, 34209269717.32262],
        [1628553600000, 38510599666.551865],
        [1628640000000, 30456934757.02111],
        [1628726400000, 28540625893.874588],
        [1628812800000, 28111908833.730755],
        [1628899200000, 25989682658.6423],
        [1628985600000, 21723717067.76398],
        [1629072000000, 24412292890.901497],
        [1629158400000, 25922917846.046463],
        [1629244800000, 28303010380.704197],
        [1629331200000, 23192419118.647858],
        [1629417600000, 20590444717.44903],
        [1629504000000, 22079051284.799744],
        [1629590400000, 19339185909.36738],
        [1629676800000, 17023426890.400778],
        [1629763200000, 22119621924.56181],
        [1629849600000, 23531754575.679913],
        [1629936000000, 20438475652.044525],
        [1630022400000, 18755498273.80065],
        [1630108800000, 18470749435.075993],
        [1630195200000, 13957284069.585827],
        [1630281600000, 14110206249.483368],
        [1630368000000, 21741061738.593838],
        [1630454400000, 32201644522.644287],
        [1630540800000, 34465100677.998825],
        [1630627200000, 28115776510.367878],
        [1630713600000, 30463347266.426533],
        [1630800000000, 24002848309.4245],
        [1630886400000, 19760598542.300426],
        [1630972800000, 20190616165.855145],
        [1631059200000, 42541561062.44531],
        [1631145600000, 34175479497.51584],
        [1631232000000, 25796050344.025276],
        [1631318400000, 24111446202.204918],
        [1631404800000, 19742335705.039883],
        [1631491200000, 18149841767.144417],
        [1631577600000, 25897933725.98018],
        [1631664000000, 21080767347.82575],
        [1631750400000, 18985547836.191357],
        [1631836800000, 22391476966.877094],
        [1631923200000, 19039612529.444134],
        [1632009600000, 16278153590.132133],
        [1632096000000, 14500103698.494116],
        [1632182400000, 28477674119.56528],
        [1632268800000, 32062935710.168865],
        [1632355200000, 27084409011.05875],
        [1632441600000, 19536554032.084408],
        [1632528000000, 28504692815.75815],
        [1632614400000, 22533679023.57236],
        [1632700800000, 24916718280.69273],
        [1632787200000, 20890327693.72457],
        [1632873600000, 18975426962.499523],
        [1632960000000, 17522543131.581055],
        [1633046400000, 19400423828.622337],
        [1633132800000, 24368105704.246353],
        [1633219200000, 21335051735.331825],
        [1633305600000, 18130363405.685154],
        [1633392000000, 19898468513.475533],
        [1633478400000, 19024921944.49268],
        [1633564800000, 24709364134.235626],
        [1633651200000, 21186801824.571068],
        [1633737600000, 18144469093.779636],
        [1633824000000, 13815631715.44782],
        [1633910400000, 18106607784.26291],
        [1633996800000, 20424407322.58066],
        [1634083200000, 20207646949.24054],
        [1634169600000, 18300885349.637314],
        [1634256000000, 21679175672.91577],
        [1634342400000, 23144188055.382],
        [1634428800000, 18154520779.65643],
        [1634515200000, 17263171427.44574],
        [1634601600000, 18931081929.44796],
        [1634688000000, 17449609317.97657],
        [1634774400000, 21926381355.419468],
        [1634860800000, 50122173541.0596],
        [1634947200000, 21674013294.746418],
        [1635033600000, 16499701301.345774],
        [1635120000000, 16494229912.456104],
        [1635206400000, 17031857290.344103],
        [1635292800000, 18925943956.175625],
        [1635379200000, 28770879596.15931],
        [1635465600000, 29989860066.02069],
        [1635552000000, 25462638679.97447],
        [1635638400000, 15937401968.575453],
        [1635724800000, 18516249996.186913],
        [1635811200000, 19235929520.306248],
        [1635897600000, 22135519614.732147],
        [1635984000000, 22744538221.14315],
        [1636070400000, 19583405367.2671],
        [1636156800000, 16182205113.440348],
        [1636243200000, 17697965751.156593],
        [1636329600000, 14665949273.051767],
        [1636416000000, 21226347241.356174],
        [1636502400000, 20669583046.287785],
        [1636588800000, 25152889711.576366],
        [1636675200000, 19048319256.420074],
        [1636761600000, 20883749031.792934],
        [1636848000000, 13954188613.807226],
        [1636934400000, 13927559432.546179],
        [1637020800000, 17939105212.670113],
        [1637107200000, 30162258590.6919],
        [1637193600000, 23532734478.929985],
        [1637280000000, 23825764061.571724],
        [1637366400000, 21398451638.777546],
        [1637452800000, 16407598047.996004],
        [1637539200000, 15090104727.278082],
        [1637625600000, 21563803786.49851],
        [1637712000000, 23393688031.318764],
        [1637798400000, 19881953842.36485],
        [1637884800000, 20241529505.687588],
        [1637971200000, 29276733766.839596],
        [1638057600000, 16500509901.66011],
        [1638144000000, 17408610005.868984],
        [1638230400000, 20150915710.995937],
        [1638316800000, 31531135168.773865],
        [1638403200000, 27957934803.559887],
        [1638489600000, 22586539837.216267],
        [1638576000000, 27620915094.003757],
        [1638662400000, 40547950440.47308],
        [1638748800000, 25848780907.958004],
        [1638835200000, 30676805365.753136],
        [1638921600000, 21808603057.7599],
        [1639008000000, 19661953642.45195],
        [1639094400000, 22680202938.506203],
        [1639180800000, 26799083668.888447],
        [1639267200000, 19796149221.596004],
        [1639353600000, 14046344322.674688],
        [1639440000000, 24089144863.40074],
        [1639526400000, 20766055169.54314],
        [1639612800000, 26881290260.577652],
        [1639699200000, 19166579837.021267],
        [1639785600000, 23209497898.091805],
        [1639872000000, 18728700443.97014],
        [1639958400000, 15960000522.484169],
        [1640044800000, 21439121593.0351],
        [1640131200000, 16630181836.72027],
        [1640217600000, 14022344430.178228],
        [1640304000000, 18571462177.267685],
        [1640390400000, 13351470832.627863],
        [1640476800000, 11869342790.219467],
        [1640563200000, 11815773894.02084],
        [1640649600000, 11725230819.88359],
        [1640736000000, 18009599978.903225],
        [1640822400000, 15528813680.462475],
        [1640908800000, 13445245167.227766],
        [1640995200000, 14298378834.359474],
        [1641081600000, 9948120591.378103],
        [1641168000000, 10069690529.898535],
        [1641254400000, 12462872357.255108],
        [1641340800000, 13853786397.222265],
        [1641427200000, 18782873941.21831],
        [1641513600000, 24467182063.00624],
        [1641600000000, 24359369448.53183],
        [1641686400000, 15966769090.792395],
        [1641772800000, 11906478540.265388],
        [1641859200000, 19655314714.55994],
        [1641945600000, 14922195360.075489],
        [1642032000000, 15285972614.67417],
        [1642118400000, 13999771252.160748],
        [1642204800000, 12316286854.849726],
        [1642291200000, 8659658467.059889],
        [1642377600000, 9002833937.226437],
        [1642464000000, 11938682376.103683],
        [1642550400000, 12700158860.46528],
        [1642636800000, 12762451059.939085],
        [1642723200000, 13389582889.566511],
        [1642809600000, 29314647992.596836],
        [1642896000000, 28250265353.796116],
        [1642982400000, 17123741957.54585],
        [1643068800000, 28735520619.97796],
        [1643155200000, 16139625164.237488],
        [1643241600000, 21415081805.12725],
        [1643328000000, 16482912982.01206],
        [1643414400000, 14326314172.126577],
        [1643500800000, 10742621684.922014],
        [1643587200000, 8937393969.826124],
        [1643673600000, 13818587053.759966],
        [1643760000000, 13176431108.286674],
        [1643846400000, 13860391591.281128],
        [1643932800000, 21844552552.403343],
        [1644019200000, 18766789788.28106],
        [1644105600000, 13036212065.007109],
        [1644192000000, 9025772577.382162],
        [1644278400000, 14427983727.88122],
        [1644364800000, 16856365272.811357],
        [1644451200000, 14005361496.44425],
        [1644537600000, 18990367974.117947],
        [1644624000000, 16560032290.708168],
        [1644710400000, 11367427285.455873],
        [1644796800000, 9020562479.291342],
        [1644883200000, 12117644856.003006],
        [1644969600000, 14189484930.460047],
        [1645056000000, 13120593628.157278],
        [1645142400000, 16242836366.095669],
        [1645228800000, 16064045339.56507],
        [1645315200000, 9956229564.570753],
        [1645401600000, 12215462928.563446],
        [1645488000000, 19206327576.145523],
        [1645574400000, 16856627508.447924],
        [1645660800000, 13713218952.651205],
        [1645747200000, 30992409365.835472],
        [1645833600000, 17751279835.572117],
        [1645920000000, 11984293384.913609],
        [1646006400000, 17309819254.70233],
        [1646092800000, 19832961026.345818],
        [1646179200000, 19012291817.365406],
        [1646265600000, 17023997910.606855],
        [1646352000000, 13786702924.254955],
        [1646438400000, 15538211354.189482],
        [1646524800000, 8652610729.437431],
        [1646611200000, 9377488374.702219],
        [1646697600000, 15295190068.57191],
        [1646784000000, 14681251925.804962],
        [1646870400000, 15208159162.669767],
        [1646956800000, 13843643777.451271],
        [1647043200000, 12313723771.430836],
        [1647129600000, 6288630630.865574],
        [1647216000000, 8570037700.961715],
        [1647302400000, 10951215810.637651],
        [1647388800000, 12439580087.508198],
        [1647475200000, 19785262485.166782],
        [1647561600000, 12593189074.637096],
        [1647648000000, 14540256794.800894],
        [1647734400000, 10359721811.614828],
        [1647820800000, 11304953073.460001],
        [1647907200000, 13890729058.80007],
        [1647993600000, 15671928047.784338],
        [1648080000000, 14257143986.39263],
        [1648166400000, 16202188701.767122],
        [1648252800000, 15532463157.18736],
        [1648339200000, 7615867102.157496],
        [1648425600000, 11980084883.788645],
        [1648512000000, 18514225350.60662],
        [1648598400000, 17936369661.586555],
        [1648684800000, 14183468637.6598],
        [1648771200000, 16222256121.186861],
        [1648857600000, 19701116865.137077],
        [1648944000000, 22091140764.533768],
        [1649030400000, 14019828281.404171],
        [1649116800000, 16925919013.954468],
        [1649203200000, 15114055501.97898],
        [1649289600000, 26109763334.73009],
        [1649376000000, 16149775914.530878],
        [1649462400000, 17523939555.353264],
        [1649548800000, 9316769843.73387],
        [1649635200000, 10005373137.102016],
        [1649721600000, 21142997359.75918],
        [1649808000000, 18977679663.55036],
        [1649894400000, 15452917855.24062],
        [1649980800000, 15895586504.785799],
        [1650067200000, 12543243551.278517],
        [1650153600000, 9063939048.316748],
        [1650240000000, 9543306566.22193],
        [1650326400000, 18188817400.079117],
        [1650412800000, 13472639207.148006],
        [1650499200000, 15155965878.384497],
        [1650585600000, 20529419340.296413],
        [1650672000000, 16155619718.968117],
        [1650758400000, 8633203957.82989],
        [1650844800000, 9196851418.265764],
        [1650931200000, 22037549638.799118],
        [1651017600000, 17750798288.906208],
        [1651104000000, 17036298768.907473],
        [1651190400000, 18249410920.251778],
        [1651276800000, 17902735173.807156],
        [1651363200000, 12618462103.401344],
        [1651449600000, 14489173444.820097],
        [1651536000000, 17701813870.710365],
        [1651622400000, 12836324477.777216],
        [1651708800000, 17336934449.712643],
        [1651795200000, 20868783132.923206],
        [1651881600000, 20516541977.493565],
        [1651968000000, 12528069434.498928],
        [1652054400000, 20582973186.12796],
        [1652140800000, 34392685561.846504],
        [1652227200000, 36844564808.016556],
        [1652313600000, 46548009611.49195],
        [1652400000000, 41621733327.158905],
        [1652486400000, 23979529161.232746],
        [1652572800000, 14395772634.811949],
        [1652659200000, 14395455889.043333],
        [1652745600000, 20068743681.95205],
        [1652832000000, 17005002529.405003],
        [1652918400000, 15892119501.206236],
        [1653004800000, 17828306698.714794],
        [1653091200000, 14638100162.773735],
        [1653177600000, 7551508571.492984],
        [1653264000000, 9623174527.738039],
        [1653350400000, 14627311157.92938],
        [1653436800000, 12880481567.942186],
        [1653523200000, 12554611854.439222],
        [1653609600000, 22669347246.961388],
        [1653696000000, 24344535948.91357],
        [1653782400000, 12004308559.066864],
        [1653868800000, 9767849361.351788],
        [1653955200000, 18803016950.09315],
        [1654041600000, 17409076011.07384],
        [1654128000000, 19904831726.00975],
        [1654214400000, 16444980823.786707],
        [1654300800000, 15012416794.069233],
        [1654387200000, 8295912402.90654],
        [1654473600000, 8385254999.857006],
        [1654560000000, 16623006597.793545],
        [1654646400000, 23647547692.445885],
        [1654732800000, 17712874976.607395],
        [1654819200000, 10469080004.414614],
        [1654905600000, 17639990086.322327],
        [1654992000000, 20409428647.9328],
        [1655078400000, 22843765038.754616],
        [1655164800000, 47571733027.00858],
        [1655251200000, 32772246927.059067],
        [1655337600000, 39679810344.68712],
        [1655424000000, 18016510161.232018],
        [1655510400000, 14067259810.778109],
        [1655596800000, 21112670623.39463],
        [1655683200000, 21676867429.837547],
        [1655769600000, 19817753726.316406],
        [1655856000000, 14838197887.543182],
        [1655942400000, 14331217471.338781],
        [1656028800000, 14247953151.150192],
        [1656115200000, 16288488163.424995],
        [1656201600000, 11692717741.724768],
        [1656288000000, 11375746747.404722],
        [1656374400000, 12044359298.246725],
        [1656460800000, 13181896477.373173],
        [1656547200000, 14755440934.326208],
        [1656633600000, 15402670572.44401],
        [1656720000000, 17420131787.73736],
        [1656806400000, 9601263305.049065],
        [1656892800000, 7939419238.560307],
        [1656979200000, 13827342516.34925],
        [1657065600000, 15393490017.737787],
        [1657152000000, 14422132961.962864],
        [1657238400000, 13170522513.182203],
        [1657324800000, 15346600702.3086],
        [1657411200000, 7870254817.813988],
        [1657497600000, 10374466605.406368],
        [1657584000000, 11693162178.034164],
        [1657670400000, 11555798332.527388],
        [1657756800000, 17936886119.90067],
        [1657843200000, 16474223860.658737],
        [1657929600000, 16817194904.2499],
        [1658016000000, 19025831554.91271],
        [1658102400000, 15797648344.457474],
        [1658188800000, 26437290054.27533],
        [1658275200000, 27749085857.707005],
        [1658361600000, 21728126383.56131],
        [1658448000000, 19411405082.967407],
        [1658534400000, 18084368731.242767],
        [1658620800000, 14901008279.154797],
        [1658707200000, 16058331978.02615],
        [1658793600000, 19852493229.276333],
        [1658880000000, 17711985937.250435],
        [1658966400000, 22792557484.269226],
        [1659052800000, 27508245872.984703],
        [1659139200000, 21792598232.364918],
        [1659225600000, 14236780342.012003],
        [1659312000000, 12894604186.287697],
        [1659398400000, 15515408282.681604],
        [1659484800000, 19414686138.72405],
        [1659571200000, 15548536682.141968],
        [1659657600000, 14074560038.427649],
        [1659744000000, 17717475728.243652],
        [1659830400000, 11231103244.36643],
        [1659916800000, 9464715954.716835],
        [1660003200000, 15566998953.351175],
        [1660089600000, 24284375402.607098],
        [1660176000000, 21699442049.07331],
        [1660262400000, 21837068069.82725],
        [1660348800000, 15849264839.752771],
        [1660435200000, 14816745160.91679],
        [1660521600000, 12172210305.158232],
        [1660608000000, 18310871317.455875],
        [1660694400000, 14240230761.887741],
        [1660780800000, 18427553152.35174],
        [1660867200000, 13363780756.798216],
        [1660953600000, 22687362580.095345],
        [1661040000000, 16404512934.336613],
        [1661126400000, 14174702673.113022],
        [1661212800000, 16648637311.974203],
        [1661299200000, 17182337491.18613],
        [1661385600000, 15322907185.334568],
        [1661472000000, 12905826689.355816],
        [1661558400000, 23170035981.438354],
        [1661644800000, 15440053448.984152],
        [1661731200000, 10575504871.684057],
        [1661817600000, 16528644851.715965],
        [1661904000000, 19704720195.3797],
        [1661990400000, 18596055350.613735],
        [1662076800000, 15076286516.891344],
        [1662163200000, 15857654357.09863],
        [1662249600000, 8500114333.500416],
        [1662336000000, 7827505125.483585],
        [1662422400000, 11815125728.969868],
        [1662508800000, 20860201644.41259],
        [1662595200000, 18560005397.32841],
        [1662681600000, 16676188643.15121],
        [1662768000000, 19418675070.680107],
        [1662854400000, 12521808520.701172],
        [1662940800000, 11692754175.395372],
        [1663027200000, 16770566236.30973],
        [1663113600000, 22286700927.338142],
        [1663200000000, 17198033718.180206],
        [1663286400000, 24787864366.63655],
        [1663372800000, 17892819034.618076],
        [1663459200000, 10309763882.142286],
        [1663545600000, 14696599277.019012],
        [1663632000000, 17757533838.1638],
        [1663718400000, 13605986462.228132],
        [1663804800000, 18732743241.62821],
        [1663891200000, 17517188721.084026],
        [1663977600000, 17599766731.24086],
        [1664064000000, 11251326609.48999],
        [1664150400000, 10552647869.67358],
        [1664236800000, 15270197375.846754],
        [1664323200000, 16685874856.004007],
        [1664409600000, 16571161476.253872],
        [1664496000000, 11834328252.392088],
        [1664582400000, 11849125783.759304],
        [1664668800000, 5052407840.714767],
        [1664755200000, 6227268235.758528],
        [1664841600000, 8749663554.66095],
        [1664928000000, 8587836597.469869],
        [1665014400000, 8502549881.905833],
        [1665100800000, 10653358724.953413],
        [1665187200000, 9123112355.727316],
        [1665273600000, 4942603337.902456],
        [1665360000000, 4753165267.937541],
        [1665446400000, 7492362032.739503],
        [1665532800000, 8314705467.191551],
        [1665619200000, 7512521119.536691],
        [1665705600000, 15374968702.824959],
        [1665792000000, 11792024290.983032],
        [1665878400000, 5743906844.859241],
        [1665964800000, 6318629607.96271],
        [1666051200000, 8639535764.06259],
        [1666137600000, 9536615488.397163],
        [1666224000000, 8128527355.67816],
        [1666310400000, 8816030159.087742],
        [1666396800000, 8731620089.4909],
        [1666483200000, 4996733236.216461],
        [1666569600000, 7794024007.581724],
        [1666656000000, 10789079575.113602],
        [1666742400000, 19624530679.956947],
        [1666828800000, 24505395072.046234],
        [1666915200000, 17752430680.403706],
        [1667001600000, 15641652314.210123],
        [1667088000000, 17044927217.751923],
        [1667174400000, 10366405350.250761],
        [1667260800000, 14900137160.396046],
        [1667347200000, 19199900507.15055],
        [1667433600000, 27498708818.133373],
        [1667520000000, 22537458293.692245],
        [1667606400000, 36953923774.760704],
        [1667692800000, 21174006976.119827],
        [1667779200000, 22195586345.876583],
        [1667865600000, 30344896841.852493],
        [1667952000000, 32343101742.62143],
        [1668038400000, 29253284762.659542],
        [1668124800000, 24039423467.45047],
        [1668211200000, 17355612789.319725],
        [1668297600000, 8636739779.554464],
        [1668384000000, 7719000328.307081],
        [1668470400000, 12056844339.739658],
        [1668556800000, 8940477028.374247],
        [1668643200000, 8870530650.446701],
        [1668729600000, 7075882101.619532],
        [1668816000000, 5600678512.529781],
        [1668902400000, 3722348151.0022244],
        [1668988800000, 6826180055.62674],
        [1669075200000, 10372965080.612862],
        [1669161600000, 9220536958.839794],
        [1669248000000, 9189536303.575544],
        [1669334400000, 6955523718.221009],
        [1669420800000, 5823202533.487156],
        [1669507200000, 5271100859.564753],
        [1669593600000, 4150923882.274703],
        [1669680000000, 6467273323.741678],
        [1669766400000, 7132972094.453388],
        [1669852800000, 9889408117.518559],
        [1669939200000, 6400063074.120894],
        [1670025600000, 5644934957.241694],
        [1670112000000, 5183647936.284697],
        [1670198400000, 4842789176.374092],
        [1670284800000, 5795682744.772271],
        [1670371200000, 4765089563.175744],
        [1670457600000, 5392077793.333963],
        [1670544000000, 5929103713.4824705],
        [1670630400000, 5743935111.263781],
        [1670716800000, 3136321665.2746277],
        [1670803200000, 3179021750.4472365],
        [1670889600000, 5107100671.713232],
        [1670976000000, 8667920250.973438],
        [1671062400000, 8063349707.439905],
        [1671148800000, 6014960880.483797],
        [1671235200000, 9306680537.671982],
        [1671321600000, 4893469369.238545],
        [1671408000000, 3175705524.420867],
        [1671494400000, 4281620919.9824796],
        [1671580800000, 6463130153.512681],
        [1671667200000, 4086534541.450752],
        [1671753600000, 5190165001.535945],
        [1671840000000, 4777025971.87922],
        [1671926400000, 2443348024.2152143],
        [1672012800000, 3694243212.8174734],
        [1672099200000, 3071221733.631151],
        [1672185600000, 4221450706.7612934],
        [1672272000000, 5177421363.012343],
        [1672358400000, 4174715683.6280565],
        [1672444800000, 4110733112.8102927],
        [1672531200000, 2891351007.4251895],
        [1672617600000, 2179799161.0385365],
        [1672704000000, 3845095582.093461],
        [1672790400000, 3385566691.456905],
        [1672876800000, 6727208484.546551],
        [1672963200000, 4052351544.8201857],
        [1673049600000, 5222706873.557585],
        [1673136000000, 2439388823.0572276],
        [1673222400000, 3401917835.2694077],
        [1673308800000, 8615288094.160769],
        [1673395200000, 6390063040.044838],
        [1673481600000, 6779329581.863201],
        [1673568000000, 13360962683.072271],
        [1673654400000, 8386970426.225097],
        [1673740800000, 15505376250.712307],
        [1673827200000, 7074931051.0836935],
        [1673913600000, 9434570734.551754],
        [1674000000000, 8482482040.670269],
        [1674086400000, 11809477723.65587],
        [1674172800000, 6871702648.394773],
        [1674259200000, 9228892411.460192],
        [1674345600000, 9663018503.707115],
        [1674432000000, 8233555835.053316],
        [1674518400000, 9037743837.862547],
        [1674604800000, 9078639809.982983],
        [1674691200000, 11946053798.490387],
        [1674777600000, 10076436860.255999],
        [1674864000000, 9593062923.130283],
        [1674950400000, 6523949724.808857],
        [1675036800000, 10037370816.308008],
        [1675123200000, 10977781767.502348],
        [1675209600000, 7544090211.946499],
        [1675296000000, 9295116915.9373],
        [1675382400000, 12542560274.342657],
        [1675468800000, 9528083087.520357],
        [1675555200000, 6766208820.07522],
        [1675641600000, 8211226952.330717],
        [1675728000000, 7825100535.008893],
        [1675814400000, 9180247305.88792],
        [1675900800000, 8766845417.320044],
        [1675987200000, 12447757846.416866],
        [1676073600000, 9882779899.96751],
        [1676160000000, 6070233325.97355],
        [1676246400000, 6128794710.192691],
        [1676332800000, 11573812955.970678],
        [1676419200000, 11723184974.304256],
        [1676505600000, 11079231384.245422],
        [1676592000000, 13388835215.08776],
        [1676678400000, 10807323083.841963],
        [1676764800000, 5863446679.020431],
        [1676851200000, 7499517187.119554],
        [1676937600000, 8435810846.264387],
        [1677024000000, 9728814194.484383],
        [1677110400000, 9836391621.850903],
        [1677196800000, 9698749201.902168],
        [1677283200000, 9609840835.076471],
        [1677369600000, 6117521125.045025],
        [1677456000000, 6357994998.206232],
        [1677542400000, 7916573299.24559],
        [1677628800000, 6883711561.9226],
        [1677715200000, 8672222866.055677],
        [1677801600000, 7930395056.206914],
        [1677888000000, 11179316252.20772],
        [1677974400000, 4800994312.370927],
        [1678060800000, 62172854406.22754],
        [1678147200000, 6627693313.532333],
        [1678233600000, 8523808476.527845],
        [1678320000000, 11881879818.725237],
        [1678406400000, 10861201441.135424],
        [1678492800000, 14696381616.840977],
        [1678579200000, 18345198261.395977],
        [1678665600000, 13967891188.499998],
        [1678752000000, 65211714924.508385],
        [1678838400000, 19833762797.583607],
        [1678924800000, 17257648020.36215],
        [1679011200000, 30201930394.76128],
        [1679097600000, 14459727611.041805],
        [1679184000000, 11976182095.296051],
        [1679270400000, 10745813975.10349],
        [1679356800000, 12973595973.359987],
        [1679443200000, 10831022955.199936],
        [1679529600000, 13820716099.501862],
        [1679616000000, 10980967034.937744],
        [1679702400000, 11601017881.786583],
        [1679788800000, 8023849174.389528],
        [1679875200000, 8417258785.455161],
        [1679961600000, 9757415238.165146],
        [1679966032000, 9834170722.84476],
      ],
    };
    for (let item of hisotricalEthPrice.prices) {
      let date = new Date(item[0]);
      item[0] = date.toISOString().split("T")[0];
    }

    const res = alchemy.core.getAssetTransfers({
      fromBlock: "0x0",
      toBlock: "latest",
      toAddress: address,
      withMetadata: true,
      excludeZeroValue: true,
      fromAddress: "0x59728544b08ab483533076417fbbb2fd0b17ce3a",
      category: ["erc20"],
    });

    const firstPage = await res;
    let looks = 0;
    let looksCount = 0;
    let total = 0;
    let actualLooksRareSpent = 0;

    for (const events of firstPage.transfers) {
      if (events.asset === "WETH") {
        let indexOfPrice = hisotricalEthPrice.prices.findIndex(
          (element) =>
            element[0] === events.metadata.blockTimestamp.substring(0, 10)
        );
        actualLooksRareSpent =
          actualLooksRareSpent +
          (hisotricalEthPrice.prices[indexOfPrice][1] * events.value) / 46.5;
        console.log(events.metadata.blockTimestamp);
        total = total + events.value;
        looksCount = looksCount + 1;
        looks = looks + events.value / 46.5;
      }
    }
    let pageKey = firstPage.pageKey;
    try {
      if (pageKey) {
        let counter = 0;
        while (pageKey) {
          const nextKey = alchemy.core.getAssetTransfers({
            fromBlock: "0x0",
            toBlock: "latest",
            toAddress: address,
            fromAddress: "0x59728544b08ab483533076417fbbb2fd0b17ce3a",
            withMetadata: true,
            category: ["erc20"],
            pageKey: pageKey.toString(),
          });
          const nextPage = await nextKey;
          for (const events of nextPage.transfers) {
            if (events.asset === "WETH") {
              let indexOfPrice = hisotricalEthPrice.prices.findIndex(
                (element) =>
                  element[0] === events.metadata.blockTimestamp.substring(0, 10)
              );
              actualLooksRareSpent =
                actualLooksRareSpent +
                (hisotricalEthPrice.prices[indexOfPrice][1] * events.value) /
                  46.5;
              console.log(events.metadata.blockTimestamp);
              total = total + events.value;
              looksCount = looksCount + 1;
              looks = looks + events.value / 46.5;
            }
          }
          pageKey = nextPage.pageKey;
          if (pageKey) {
            counter += 1;

            continue;
          } else {
            let round = looks.toFixed(2);
            if (looksCount > 0) setLookCount(looksCount);
            if (round > 0) setLook(round);
            if (total > 0) setTotalSpentTwo(total);
            if (actualLooksRareSpent > 0)
              setLooksRareTotalSpent(actualLooksRareSpent);
            break;
          }
        }
      } else if (pageKey === undefined) {
        let round = looks.toFixed(2);
        if (looksCount > 0) setLookCount(looksCount);
        if (round > 0) setLook(round);
        if (total > 0) setTotalSpentTwo(total);
        if (actualLooksRareSpent > 0)
          setLooksRareTotalSpent(actualLooksRareSpent);
        const firstPageLength = firstPage.transfers.length;
      }
    } catch (err) {
      setLoading(false);
      setStart(true);
      setError("The address is wrong. Try Again!");
      console.log("Something went wrong. Try Again!", +err);
    }
  } catch (error) {
    console.log(error);
  }
};

const getHistoricalEthPrice = async () => {
  let error = null;
  let response;
  do {
    error = null;
    const options = {
      method: "GET",
      url: `https://api.coingecko.com/api/v3/coins/ethereum/market_chart?vs_currency=USD&days=3000`,
      headers: {
        accept: "*/*",
        "User-Agent":
          "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_11_5) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/50.0.2661.102 Safari/537.36",
        referrer: "https://coingecko.com",
        "X-API-KEY": "",
      },
    };
    try {
      response = await axios.request(options).then(({ data }) => {
        return data;
      });
    } catch (error) {
      error = "error";
    }
  } while (error === "error");
  return response;
};
